<template>
    <div class="search">
        <input v-if="mode=='boeken'" @input="handleInput" placeholder="Zoek op naam of auteur ">
        <input v-if="mode=='module' && $i18n.locale === 'nl'" @input="handleInput" placeholder="Zoek op titel of vak ">
        <input v-if="mode=='module' && $i18n.locale === 'en'" @input="handleInput" placeholder="Search for title or study ">
        <input v-if="mode=='feedback' && $i18n.locale === 'nl'" @input="handleInput" placeholder="Zoek op titel of vak ">
        <input v-if="mode=='feedback' && $i18n.locale === 'en'" @input="handleInput" placeholder="Search for student or title ">
        <input v-if="mode=='feedbackEN'" @input="handleInput" placeholder="Search for student or title ">
        <input v-if="mode=='leerlingen'" @input="handleInput" placeholder="Zoek op achternaam of email ">
        <img src="/assets/search.svg">
    </div>
</template>


<script>
export default{
    methods: {
        handleInput(event){
            this.$emit("search", event.target.value)
        }
    },
    props: ["mode"],
}
</script>


<style lang="scss">
.search{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    background: #F8F7F7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 32.5px;
    padding: 0px 24px;
    width: calc(100% - 300px);
    margin-left: 30px;

    input{
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        width: 100%;
        outline: none;
    }

    img{
        margin-left: 24px;
        height: 22px;
    }

}
</style>