<template>
  <div>
    <!-- <Header v-if="!is_fullscreen"></Header> -->
    <div :class="{fs: is_fullscreen}" class="app-content">
      <router-view />
      <!-- <Footer v-if="!is_fullscreen" class="footer"></Footer> -->
    </div>
    <v-snackbar
        v-model="showSnackbar"
        :timeout="snackbarTimeout"
        location= "top right"
        :color="snackbarColor"
        multi-line
      >
      {{ snackbarText }}

      <template v-slot:actions>
        <v-btn
          color="white"
          variant="text"
          @click="showSnackbar = false"
        >
          Sluiten
        </v-btn>
      </template>
    </v-snackbar>
    
    <!-- CookieConsent Component -->
    <CookieConsent />
  </div>
</template>

<script>
import CookieConsent from '@/components/Cookies';

export default {
  components: {
    CookieConsent,
  },
  created(){
    this.windowResizeHandler()
    window.addEventListener("resize", this.windowResizeHandler);
  },
  computed: {
    is_fullscreen(){
      // const full_path = this.$router.currentRoute.fullPath
      return this.$route.path.split("/").slice(0,-1).join("/") == "/student/reader"
    },
    showSnackbar: {
      get() {
        return this.$store.state.showSnackbar
      },
      set(show){
        this.$store.commit("modify_snackbar_show", show)
      }
    },
    snackbarText(){
      return this.$store.state.snackbarText
    },
    snackbarTimeout(){
      return this.$store.state.snackbarTimeout
    },
    snackbarColor(){
      return this.$store.state.snackbarColor
    },
  },
  unmounted() {
    window.removeEventListener("resize", this.windowResizeHandler);
  },
  methods: {
    windowResizeHandler() {
      this.$store.commit("windowSize", {
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
  },
  watch:{
    $route (to){
      this.$store.commit("errorMessage", null)
      if(to.query.snackbar != null && to.query.snackbar != ""){
        // this.showSnackbar = true
        // this.snackbarText = to.query.snackbar
        // this.snackbarColor = to.query.snackbarType == "success" ? "#02e26b" : (to.query.snackbarType == "warning" ? "#00ffff" : "#ff0000")

        this.$store.commit("show_snackbar", {
          text: to.query.snackbar,
          type: to.query.snackbarType
        })

        // remove the snackbar parameter from query, so that refresh doesn't trigger it again
        const {snackbar: _, snackbarType: __, ...rest} = to.query;

        // we use replacec to not trigger a refresh as that would remove the snackbar immediately
        this.$router.replace({
          query: rest
        });
      }
      
    }
  } 
}
</script>

<style lang="scss">
html{
  box-sizing: content-box !important;
  overflow-y: auto !important;
}

@font-face {
  font-family: "main-font";
  src: local("Montserrat"),   url(./assets/fonts/Montserrat-VariableFont_wght.ttf) format("truetype");
}

@font-face {
  font-family: "roboto";
  src: local("Roboto"),   url(./assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}

body{
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-content{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100lvw;

  &.fs{
    top: 0px;
    min-height: 100vh;
  }
}

h2{
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;

  /* identical to box height */

  color: #000000;
}

h3{
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;

  /* identical to box height */

  color: #000000;
}

.horizontal{
  display: flex;
}

button{
  background-color: $secondary-color !important;
  color: #fff !important;
  padding: 10px 20px;
  border-radius: 9px;
  margin-top: 40px;
}

a{
  color: $secondary-color;
  text-decoration: none !important;
}

.fixed{
  position: fixed;
}

.v-snackbar__actions{
  button{
    background: none !important;
  }
}
</style>
