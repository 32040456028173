<template>
    <div class="better-button">
        <div class="button-image">
            <slot></slot>
        </div>
        <span>{{text}}</span>
        
    </div>
</template>
<script>
export default{
    props: ["text"]
}
</script>

<style lang="scss">
.better-button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 20px;
    background-color: $secondary-color;
    cursor: pointer;
    color: $white;
    width: 200px;
    border-radius: 4px;

    .button-image{
        height: 20px;
        

        img{
            height: 100%;
        }
    }

    span{
        margin: 0px 10px;
    }
    
}
</style>