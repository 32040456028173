<template>
    <div class="feedback-leraar">
        <Popup class="module-popup" :show="verwijderPopup" @close="stopVerwijderen">
            <h2>{{ $t('confirmDeleteAssignment') }}</h2>
            <BetterButton 
                :text="$t('delete')"
                @click="verwijderen(verwijderfeedback)"
            ></BetterButton>
        </Popup>
        <Popup class="module-popup" :show="popup" @close="stopVerwijderen">
            <h2>Let op! Je kan alleen opdrachten die je vandaag geupload hebt opnieuw proberen feedback te genereren. De rest is verwijderd vanwege privacy.</h2>
            <BetterButton 
                :text="'Opnieuw proberen'"
                @click="refreshFeedback(feedbackRefresh)"
            ></BetterButton>
        </Popup>
        <Popup class="module-popup" :show="showDonePopup" @close="afronden()">
            <h2>{{ $t('assignmentAddedSuccess') }}</h2>
            <BetterButton 
                :text="$t('complete')"
                @click="afronden"
            ></BetterButton>
        </Popup>
        <Sidebar mode="teacher" active="Feedback"></Sidebar>
        <div class="content" :class="{'mobile-extend': toggledSidebar}">
            <DashboardHeader mode="feedback" @search="filterBooks"></DashboardHeader>
            
            <div class="scrollable-content">
                <div class="nieuwe-feedback">
                    <h2>{{ $t('feedbackOverview') }}</h2>
                    <div class="nieuwe-button">
                        <div v-if="$i18n.locale === 'nl'" class="nieuwe" @click="$router.push({name:'feedback-module'})">{{ $t('newFeedback') }}</div>
                        <div v-if="$i18n.locale === 'en'" class="nieuwe" @click="$router.push({name:'feedback-module-en'})">{{ $t('newFeedback') }}</div>
                    </div>
                </div>

                <div class="table-wrapper">
                    <table>
                        <thead>
                            <tr>
                                <th>{{ $t('feedback') }}</th>
                                <th>{{ $t('student') }}</th>
                                <th>{{ $t('title') }}</th>
                                <th>{{ $t('date') }}</th>
                                <th>{{ $t('category') }}</th>
                                <th>{{ $t('delete') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr  class="table-margin" v-for="feed in filtered_feedback" :key="feed.titel" >
                                    <td v-if="feed.feedback!='' && $i18n.locale === 'en' && feed.error!='failed' && feed.error!='file_download_error'" class="informatie" @click="openFeedbackEN(feed.id)">Open feedback</td>
                                    <td v-if="feed.feedback!='' && $i18n.locale === 'nl' && feed.error!='failed' && feed.error!='file_download_error'" class="informatie" @click="openFeedback(feed.id)">Open feedback</td>
                                    <td v-if="feed.error=='failed' && !uploaden[feed.id]" class="informatie" @click="refreshFeedback1(feed.id)">Er ging iets mis: <button>Probeer opnieuw</button> </td>
                                    
                                    <td v-if="feed.error=='file_download_error'">Er is iets mis gegaan met het uploaden, upload een nieuwe opdracht</td>
                                    <td v-if="feed.feedback=='' && feed.error!='file_download_error' && feed.error!='failed' || uploaden[feed.id] " class="informatie" ><v-progress-circular

      indeterminate

      class="loading-spinner"
    ></v-progress-circular></td>
                                    <td  >{{ feed.leerling_anoniem }} </td>
                                    <td >{{ feed.titel }}</td>
                                    <td  > {{ feed.date }}</td>
                                    <td > {{ feed.titel_rubriek }}</td>
                                    <td v-if="feed.taal=='nl' " class="verwijderen" @click="verwijderFeedback(feed.id)">Verwijder feedback</td>

    
                                    <td v-if="feed.taal=='en' " class="verwijderen" @click="verwijderFeedback(feed.id)">Delete feedback</td>
                                    
                                </tr>
                            </tbody>
                        </table>


                </div>
                
            </div>
            


            
        </div>
    </div>
</template>

<script>
import Sidebar from '@/components/Dashboard/SidebarLeraar.vue'
import DashboardHeader from '@/components/Dashboard/DashboardHeader.vue'
import DashboardHeading from '@/components/Dashboard/DashboardHeading.vue'
import Popup from '@/components/Widgets/Popup.vue'
import BetterButton from '@/components/Widgets/BetterButton.vue'
export default{
    components: {Sidebar, DashboardHeader, DashboardHeading, Popup, BetterButton},
    data () {
        return{
            search: "",
            verwijderfeedback: 0,
            verwijderPopup: false,
            checkFeedback: true,
            feedbackInterval: null,
            isComponentMounted: true,
            uploaden: [false],
            popup: false,
            feedbackRefresh: 0,
            showDonePopup: false,
        }

    },
    computed: {
        // checkFeedback() {
        //     let allFeedbackNotEmpty = true; // Assume initially that all feedback is not empty
        //     for (let i = 0; i < this.feedback.length; i++) {
        //         if (this.feedback[i].feedback === "") {
        //             allFeedbackNotEmpty = false; // If any feedback is empty, set the flag to false
        //             break; // No need to continue checking, we already found an empty feedback
        //         }
        //     }
        //     this.checkFeedback = !allFeedbackNotEmpty; // Set checkFeedback to true if any feedback is empty, false otherwise
        //     console.log('setting', this.checkFeedback);
        // },

        feedback(){
            return this.$store.state.feedback_leraar
        },
        toggledSidebar(){
            return this.$store.state.mobileExtend
        },
        filtered_feedback(){
            const vm = this
            return vm.feedback.filter(feedback => {
                return (
                    
                    (feedback.titel.toLowerCase().includes(vm.search.toLowerCase())) ||
                    (feedback.leerling_anoniem.toLowerCase().includes(vm.search.toLowerCase())) 
                   
                )
            })
        },
        matchingTitel() {
            // Ensure 'feed' is defined and not null before attempting to find an item
            if (this.feed && Array.isArray(this.feed)) {
            const item = this.feed.find(item => item.id === this.verwijderfeedback);
            return item ? item.titel : 'No title found';
            }
            return 'Loading...'; // or return a default value indicating the data is not yet available
        }
        
    },
    async created() {
    // Initial call to fetch feedback
    await this.fetchFeedback();

    // Set up interval to periodically fetch feedback
    // for(let i=0; i < this.feedback.length; i++){
    //     if(this.feedback[i].feedback==""){
    //         this.feedbackInterval = setInterval(async () => {
    //             if(this.feedback[i].feedback=="" && this.checkFeedback==true){
    //                 await this.fetchFeedback();
    //                 console.log('checken')
    //             }else{
    //                 console.log('stop checken')
    //             }
                
    // }, 10000); // Adjust the interval as needed (currently set to 1 minute)
    //     }
    // }
    // this.feedbackInterval = setInterval(async () => {
    //         if (this.isComponentMounted) {
    //             for (let i = 0; i < this.feedback.length; i++) {
    //                 if (this.feedback[i].feedback === "" && this.checkFeedback === true) {
    //                     await this.fetchFeedback();
    //                     console.log('checken')
    //                     break; // Exit the loop after the first check
    //                 } else {
    //                     console.log('stop checken')
    //                 }
    //             }
    //         }
    //     }, 10000);
   
  },
  beforeDestroy() {
    this.isComponentMounted = false;
        // Clear the interval when the component is destroyed
        clearInterval(this.feedbackInterval);
    },
    watch: {
    feedback: {
        handler: function(newVal, oldVal) {
            this.handleFeedbackChange(newVal);
        },
        deep: true // To watch changes inside the feedback array
    }
},
    methods:{
        afronden(){
            this.showDonePopup = false
           
                this.$router.push({"name": "feedback-leraar"})
            
            
        },
    
        refreshFeedback1(feedback_id){
            this.popup = true
            this.feedbackRefresh = feedback_id
        },
        async refreshFeedback(feedback_id){
            this.popup = false
            this.uploaden[feedback_id] = true
        this.showDonePopup = true
        await this.$store.dispatch("refreshFeedback", {
            "feedback_id": feedback_id,
            "vak_id": this.$route.params.id,

        })

await this.$store.dispatch("get_feedback_leraar" , this.$route.params.id)
this.uploaden[feedback_id] = false


},
        async handleFeedbackChange(newVal) {
        let allFeedbackNotEmpty = true;
        for (let i = 0; i < newVal.length; i++) {
            if (newVal[i].feedback === "") {
                allFeedbackNotEmpty = false;
                break;
            }
        }
        this.checkFeedback = !allFeedbackNotEmpty;
        console.log('setting', this.checkFeedback);
        if (this.checkFeedback) {
            setTimeout(async () => {
        await this.$store.dispatch("get_feedback_leraar", this.$route.params.id);
    }, 5000);
        }
    },
        async fetchFeedback() {
      // Fetch feedback from the backend using your Vuex store dispatch
      await this.$store.dispatch("get_feedback_leraar", this.$route.params.id);
    },
         verwijderFeedback(feedback_id){
            this.verwijderfeedback = feedback_id
            this.verwijderPopup = true;
          
        },
        stopVerwijderen(){
            this.verwijderPopup = false;
            this.popup = false;
        },
        async verwijderen(feedback_id){
            
    
            await this.$store.dispatch("verwijderFeedback", {feedback_id})
            await this.$store.dispatch("get_feedback_leraar", this.$route.params.id)
            this.verwijderPopup = false;
        },
        openFeedback(id){
            console.log('test info')
            this.$router.push({
                name: "feedback-overview-leraar",
                params: {
                    id2: id,
                    id: this.$route.params.id,
                }
            })
        },
        openFeedbackEN(id){
            console.log('test info')
            this.$router.push({
                name: "feedback-overview-teacher",
                params: {
                    id: id,
                }
            })
        },
        filterBooks(query){
            
            
            this.search = query
        
        }

    },
    mounted() {
        document.title = "Eduface | Feedback";    },
  
}
</script>

<style lang="scss">
.feedback-leraar{


    .popup{
        display: flex !important;
        align-items: center;
        flex-direction: column;
        text-align: center;

        .invite-code{
            font-size: 30px;
            font-weight: bold;
            color: $base-color;
            margin: 20px 0px;
        }

    }
    .content{
        background-color: #F4F6FC;
        justify-content: space-between;
        position: absolute;
        width: calc(100% - 300px);
        padding: 0px 50px;
        top: 0px;
        left: 200px;
        min-height: 100vh;
}

.scrollable-content {
    overflow-x: auto;
    max-width: 100%; /* Adjust the width as needed */
    /* Other styles for your scrollable content container */
    .nieuwe-feedback{
        display: flex;
    }

    .nieuwe-button{
            margin-left: 20px;
            background-color: $base-color;
            color: #fff;
            border-radius: 20px;
            cursor: pointer;
            &:hover{
                opacity: 0.5;
            }

        }
        .nieuwe{
            padding: 5px 15px;
            background-color: $base-color;
            color: #fff;
            border-radius: 20px;
            cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
        }
}
.informatie{
    color: $base-color;
    cursor: pointer;

    &:hover{
        opacity: 0.8;
    }
}
.table-margin table tbody tr {
        display: block;
        margin-bottom: 10px;
        border-bottom: 1px solid transparent;
    }
    
    .table-margin table tbody tr:last-child {
        margin-bottom: 0; /* Remove margin-bottom for the last row */
    }
.table-wrapper {
    width: 100%;

    
    /* Adjust padding, margin, and other styles as needed */
    
}
.verwijderen{
    color: #004C4C;
    cursor: pointer;
            &:hover{
                opacity: 0.5;
                
            }
    
}
    .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    
  }

    .th, td {
    border: 1px solid #dddddd;
    
    height: 40px;
    width: 400px;
  
  }

  .th {
    background-color: #f2f2f2;
    text-align: left;
  }
  thead th {
    text-align: left;
  }
  

  td {
    text-align: left;
  }

}

@media (max-width: 900px){
    .feedback-leraar{

        /* .content{
            left: 10px;
            padding: 0px 0px;
            width: 100%;
        } */
        .content{
            left: 0px;
            width: calc(100% - 100px);
            .search{
                    display: none;
                }
            &.mobile-extend{
                display: block;
                left: 200px;
                width: calc(100% - 300px);
                

                
            }
            
        }
        
        
    }
    
}

@media (max-width: 600px){
        .content{
            padding: 0px 10px;
            width: calc(100% - 20px);
            &.mobile-extend{
                left: calc(100% - 70px);
                width: 70px;
                .scrollable-content{
                    display: none;
                }
            }

         

            
        }
    }
</style>