<script>
export default{

methods:{
    sendEmail() {
    const emailAddress = 'Jeroen.van.gessel@blockbook.nl';
    const subject = 'Contact';
    const body = 'Hier kun je vragen stellen of informatie sturen om met ons in contact te komen.';

    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  },
  klantenservice() {
    const emailAddress = 'info@blockbook.nl';
    const subject = 'Klantenservice';
    const body = 'Ons platform is vrij nieuw en nog steeds in development, er kunnen nog wat dingen misgaan of dingen zijn die nog niet werken. Stel hier al je vragen en wij zorgen ervoor om zo snel mogelijk te reageren.';

    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  },
  openPdf() {
  window.open('/assets/algemen-voorwaarden.pdf', '_blank');
},
openPdf1() {
  window.open('/assets/Privacyverklaring-Eduface.pdf', '_blank');
}
}
}

</script>

<template>
    <div class="footer2">
        <div class="space">

        </div>
        <div class="footer-informatie">
            <div class="bedrijf">
                <h2>
                    Bedrijf
                </h2>
                <div class="kvk">
                    89149033
                </div>
                <div class="adres">
                    Leiden <br/>
                    Langegracht 70 <br/>
                    2312NV Leiden
                </div>
            </div>
            <div class="contact">
                <h2>
                    Contact
                </h2>
                <div class="bellen">
                    <div>
                        <img
                            @click="$router.push({ name: 'Home' })"
                            src="/assets/Icon local phone.svg"
                   
                            alt="logo"
                            width="25"
                        />
                    </div>
                    <div>
                        Bel ons <br/>
                        + 316 3773 5018
                    </div>
                </div>
                <div class="mailen">
                    <div>
                        <img
                            @click="$router.push({ name: 'Home' })"
                            src="/assets/Icon mail outline.svg"
                        
                            alt="logo"
                            width="25"
                        />
                    </div>
                    <div>
                        Mail ons <br/>
                        info@eduface.me
                    </div>
                </div>
            </div>
            <div class="social-media">
                <h2>
                    Sociale media
                </h2>
                <div class="volgons">
                    Volg ons
                </div>
                <div class="linkedin">
                    <div>
                        <img
                            @click="$router.push({ name: 'Home' })"
                            src="/assets/Icon linkedin.svg"
                     
                            alt="logo"
                            width="25"
                        />
                    </div>
                    <div>
                        <a href="https://www.linkedin.com/company/98543805/admin/feed/posts/">LinkedIn</a>
                    </div>
                </div>
                <div class="instagram">
                    <div>
                        <img
                            @click="$router.push({ name: 'Home' })"
                            src="/assets/Icon instagram.svg"
                           
                            alt="logo"
                            width="25"
                        />
                    </div>
                    <div>
                        <a href="https://www.Instagram.com/blockbooknl"> Instagram</a>
                    </div>
                </div>
                <div class="tiktok">
                    <div>
                        <img
                            @click="$router.push({ name: 'Home' })"
                            src="/assets/Icon tiktok.svg"
          
                            alt="logo"
                            width="25"
                        />
                    </div>
                    <div>
                        <a a href="https://www.TikTok.com/@blockbooknl">Tiktok</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-plaatje">
            <div>
                <img
                @click="$router.push({ name: 'Home' })"
                src="/assets/logo.png"
                class="logo"
                alt="logo"
                width="190"
              />
            </div>
            <h2>
                Betere educatie door innovatie
            </h2>
        </div>  
        <div class="foot-voorwaarden">
            <div>© Copyright 2024 Eduface</div>
            <div class="algemene-voorwaarden" @click="openPdf">
                Algemene voorwaarden
            </div>
            <div class="privacy" @click="openPdf1">Privacybeleid</div>
        </div>
    </div>
   

</template>

<style scoped>
.v-container {
	width: auto !important;
}
.footer2{
    color: #252729;
    a {
		text-decoration: none;
		color: #252729
	}
    background: rgba(0, 224, 117, 0.30);
    font-size: 20px;
    .space{
        height: 80px;
    }
    .footer-informatie{
      
        display: flex;

        justify-content: center;
        gap: 80px;
        .bedrijf{
           

            .kvk{
                margin-top: 40px;
            }
            .adres{
                margin-top: 40px;
            }
        }
        .social-media{
           

            .volgons{
                margin-top: 40px;
            }
            .linkedin{
                margin-top: 20px;
                display: flex;
                align-items: center;
                cursor: pointer;
                gap: 8px;
                &:hover{
                    opacity: 0.5;
                    
                }
            }
            .instagram{
                margin-top: 20px;
                display: flex;
                align-items: center;
                gap: 8px;
                &:hover{
                    opacity: 0.5;
                    
                }
            }
            .tiktok{
                margin-top: 20px;
                display: flex;
                align-items: center;
                gap: 8px;
                &:hover{
                    opacity: 0.5;
                    
                }
            }
        }
        .contact{
            .bellen{
                margin-top: 40px;
                display: flex;
                align-items: center;
            }
            .mailen{
                margin-top: 40px;
                display: flex;
                align-items: center;
            }
        }
    }
    .foot-voorwaarden{
        display: flex;
        align-items: center;
        justify-content: center;

        .algemene-voorwaarden{
            margin: 0px 60px;
            cursor: pointer;
                &:hover{
                    opacity: 0.5;
                    
                }
        }
        .privacy{
            cursor: pointer;
                &:hover{
                    opacity: 0.5;
                    
                }
        }
    }
    .footer-plaatje{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 40px;
        margin-top: 80px;
    }
}
footer {
	margin-top: 300px;
	color: #004aad;
	background: rgba(0, 224, 117, 0.30);
	padding-top: 120px;
	padding-bottom: 80px;
	margin-top: -33px;

	.contact{
        cursor: pointer;
        &:hover{
            opacity: 0.5;
            
        }
    }
	
	a {
		text-decoration: none;
		color: #004aad;
	}
	h5 {
		margin-top: 20px;
		margin-bottom: 30px;
		font-weight: 600;
		font-size: 20px;
	}

	.navbar-brand {
		.title {
			font-weight: 600;
			font-size: 22px;
			line-height: 1.2;
		}
		.sub {
			font-size: 14px;
		}
	}
	.input-group {
		input {
			width: 40%;
			border-radius: 5px 0px 0px 5px;
			padding: 5px;
			outline: none !important;
		}
		button {
			transform: 0.3s all;
			background: #004aad;
			width: 40%;
			border-radius: 0px 5px 5px 0px;
			color: #fff;
			padding: 5px;
			border: 2px solid #004aad;
			&:hover {
				background: transparent;
				color: #004aad;
			}
		}
		.form-control {
			background: transparent;
			border: 2px solid #004aad;
			&:hover {
				background: #fff;
			}
		}
	}

	.input-group-text {
		background: #004aad;
		color: #fff;
		border-color: #004aad;
		cursor: pointer;
		font-weight: 600;
		transition: 0.3s all;
		&:hover {
			color: #004aad;
			background: #ffb64f;
		}
	}
	.social {
		padding-left: 100px;
		a {
			text-decoration: none;
			font-weight: 600;
		}
	}
}
@media (max-width: 550px){
        
        .footer2{
            font-size: 15px;
            .footer-informatie{
                gap: 0px;
                .bedrijf{
                    margin-left: 5px !important;
                }
            }
        }
   
    
}
</style>
