<template >
    <Login :redirect="redirect"></Login>
</template>
<script>
import Login from "@/views/Login.vue"
export default {
    components: {Login},
    computed: {
        redirect(){
            return this.$route.query.red
        }
    },
    created(){
        if(this.$route.query.red == null || this.$route.query.red == ""){
            this.$router.push({
                name: "login",
                query: { 
                    snackbarType: "warning",
                    snackbar: "Geen redirect URL gespecificeerd, u bent nu doorverwezen naar de loginpagina."
                }
            })
        }else{
            const publicToken = localStorage.getItem('publicToken')
            if(publicToken != null && publicToken != ""){
                const redirect = new URL(this.redirect)
                redirect.searchParams.append("publicToken",publicToken)
                open(redirect, "_self")
            }
        }
    }
}
</script>
<style lang="">
    
</style>