import { createApp } from 'vue'
import App from './App.vue'
import router from './router' // <---
import store from './store'
import { createI18n } from 'vue-i18n';



//import "aos/dist/aos.css";

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { messages } from '/messages';

// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap";

// import "../public/assets/main.css"

// Vue I18n instance
const i18n = createI18n({
  legacy: false, // for Vue 3
  locale: 'nl', // set initial locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
});

String.prototype.format = function () {
  var i = 0, args = arguments;
  return this.replace(/{}/g, function () {
    return typeof args[i] != 'undefined' ? args[i++] : '';
  });
};

const vuetify = createVuetify({
  components,
  directives,
})

const app = createApp(App).use(router).use(store).use(vuetify).use(i18n).mount('#app')

document.title = "Eduface";

export { i18n };

