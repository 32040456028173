<template>
    <div class="het-team">
      <div class="fixedMenu">
      <header class="header">
        <div v-if="!menuActive" class="hamburger-lines" @click="toggleMenu">
            <span class="line line1"></span>
            <span class="line line2"></span>
            <span class="line line3"></span>
        </div>
        <v-btn v-if="!menuActive && windowWidth < 992"
       flat
       class="rounded-pill border loginBtn3"
       @click="$router.push({ name: 'signup' })">
    Signup
</v-btn>
        <div class="eContainer banner mb-0" :class="{ 'is-active': menuActive }">
          <div class="eRow mt-2 mb-0">
            <div class="col-sm-5 col-xl-2 banner1 mb-0">
              <img
                @click="$router.push({ name: 'Home' })"
                src="/assets/logo.png"
                class="logo"
                alt="logo"
                width="190"
              />
              <img @click="toggleMenu"  v-if="menuActive && windowWidth < 576" src="/assets/cross.svg" width="30" class="cross" />
            </div>
            <!-- <input class="checkbox" type="checkbox" name="" id="" v-model="$store.state.mobileExtend"/> -->
            <div class="col-xl-5 col-sm-7  text-sm-end order-xl-2 mt-lg-2">
              <v-btn
                flat
                class="rounded-pill border text-capitalize loginBtn2 mt-2"
                @click="$router.push({ name: 'signup' })"
                >Signup</v-btn
              >
              <v-btn
                flat
                class="rounded-pill border text-capitalize loginBtn mt-2"
                @click="$router.push({ name: 'log-in' })"
                >Login</v-btn
              >
            </div>
            <div class="col-12 col-xl-5  text-lg-end order-xl-1 mb-0">
              <ul>
                <li>
                  <div @click="$router.push({ name: 'hoe-het-werkt' })" class="links">
                    Hoe werkt het
                  </div>
                </li>
                <li>
                  <div @click="$router.push({ name: 'het-team' })" class="links">Over ons</div>
                </li>
                <li>
                  <v-menu transition="scale-transition">
                    <template v-slot:activator="{ props }">
                      <div class="links dropdown" v-bind="props">Onderwijsassistent</div>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title
                          @click="$router.push({ name: 'assistentpaginaOmar' })"
                          class="assistentpage"
                          >Omar</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title
                          @click="$router.push({ name: 'assistentpaginaMarlou' })"
                          class="assistentpage"
                          >Marlou</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title
                          @click="$router.push({ name: 'assistentpaginaRuben' })"
                          class="assistentpage"
                          >Ruben</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </div>
    <div class="onsverhaal">
        <br/>
        <div class="ons">Ons verhaal</div>
        <h1 class="verhaal">
            Oprichters Menno en Jeroen waren zelf nog studenten toen ze zagen dat technologie beter gebruikt kan worden in het onderwijs.
        </h1>
    </div>
    <div class="container-quote">
        <div class="topspace"></div>
            <div class="quote-plaatje">
            <div class="plaatje">
                <img src="/assets/wiezijnwij.jpg" class="plaat" width="620">
            </div>
            <div class="quote">
                <div>
                    <img
                                @click="$router.push({ name: 'Home' })"
                                src="/assets/Icon format quote.svg"
            
                                alt="logo"
                                width="100"
                            />
                </div>
                <div>
                    "Elke keer als ik aan mijn docenten vroeg of ze meer lesmateriaal konden maken of feedback konden geven op mijn werk kreeg ik te horen dat ze hier geen tijd voor hadden."
                </div>
            </div>

        </div>
        <div class="bottomspace"></div>
    </div>
    <div class="missie-hulp">
        <br/>
        <div class="missie">Missie </div>
        <h1 class="doel">
            Ons doel is om docenten te helpen eenvoudig
nieuwe technologieën in het onderwijs te

integreren. Eduface bereikt dit met AI-
onderwijsassistenten die docenten ondersteunen

bij het geven van feedback en het creëren van
lesmateriaal.        </h1>
        <br/>
        <br/>
        <h1 class="hulp">
            Kan jij ons helpen bij onze missie?
        </h1>
        <button @click="sendEmail">Neem contact op</button>
    </div>
    <div class="container-bottom">
        <div class="bottom-team">
            <div class="eduface-team">
                <h1>Het team achter Eduface</h1>
                <br/>
                <div class="greenline"></div>
            </div>
            <div class="leden">
                <div class="lid">
                    <div class="lidd">
                        <div>
                            <img src="/assets/teamlid 1.png"  width="250">
                        </div>
                        <div>
                            Jeroen houdt van een uitdaging en had het idee om Eduface te starten samen met Menno. Hij heeft een achtergrond in Business development en is verantwoordelijk voor de sales, marketing, finance en strategie.
                        </div>
                        <div  class="linkedin">
                            <a href='https://www.linkedin.com/in/jeroen-van-gessel/'><img
                                    
                                    src="/assets/Icon linkedin.svg"
                            
                                    alt="logo"
                                    width="25"
                                /></a>
                        </div>
                        <div class="grayline">

                        </div>
                        <div>
                            <h2>CEO</h2>
                            
                            <h2>co-founder</h2>
                        </div>
                        <div>
                            <br/>
                            Jeroen van Gessel
                        </div>
                    </div>
                    
                </div>
                <div class="lid">
                    <div class="lidd">
                        <div>
                            <img src="/assets/teamlid 2.png"  width="250">
                        </div>
                        <div>
                            Menno houdt van hard werken en had het idee om Eduface te starten samen met Jeroen. Hij heeft een achtergrond in software development en AI, en is verantwoordelijk voor de software waaronder de site en de AI modellen.
                        </div>
                        <div  class="linkedin">
                            <a href='https://www.linkedin.com/in/menno-hahury-6222a125a/'><img
                                    
                                    src="/assets/Icon linkedin.svg"
                            
                                    alt="logo"
                                    width="25"
                                /></a>
                        </div>
                        <div class="grayline">

                        </div>
                        <div>
                            <h2>CTO</h2>
                            
                            <h2>co-founder</h2>
                        </div>
                        <div>
                            <br/>
                            Menno Hahury
                        </div>
                    </div>
                    
                </div>
                <div class="lid">
                    <div class="lidd">
                        <div>
                            <img src="/assets/teamlid 3.png"  width="250">
                        </div>
                        <div>
                            Dante is hardwerkend en leergierig, hij heeft een achtergrond in business development. Hij draagt dagelijkse bij aan de groei van Eduface door contact te hebben met docenten en voortdurent het product te innoveren. 
                        </div>
                        <div  class="linkedin">
                            <a href='https://www.linkedin.com/in/dante-torbed/'><img
                                    
                                    src="/assets/Icon linkedin.svg"
                            
                                    alt="logo"
                                    width="25"
                                /></a>
                        </div>
                        <div class="grayline">

                        </div>
                        <div>
                            <h2>Business development</h2>
                            
                            <h2>& sales</h2>
                        </div>
                        <div>
                            <br/>
                            Dante Torbed
                        </div>
                    </div>
                    
                </div>
                
                <div class="lid">
                    <div class="lidd">
                        <div>
                            <img src="/assets/teamlid 5.png"  width="250">
                        </div>
                        <div>
                            Robin combineert creativiteit met expertise in business development en marketing, en heeft een passie voor het ontdekken van innovatieve oplossingen op het gebied van merkpositionering.
                        </div>
                        <div  class="linkedin">
                            <a href='https://www.linkedin.com/in/robin-leuverink/'><img
                                    
                                    src="/assets/Icon linkedin.svg"
                            
                                    alt="logo"
                                    width="25"
                                /></a>
                        </div>
                        <div class="grayline">

                        </div>
                        <div>
                            <h2>Business development</h2>
                            
                            <h2>& marketing</h2>
                        </div>
                        <div>
                            <br/>
                            Robin Leuverink
                        </div>
                    </div>
                    
                </div>
                <div class="lid">
                    <div class="lidd">
                        <div>
                            <img src="/assets/teamlid 6.png"  width="250">
                        </div>
                        <div>
                            Timothy houdt zich binnen Eduface bezig met financiële modellen en juridische zaken. Daarnaast gebruikt hij zijn kennis om innovatieve ideeën uit te denken die bijdragen aan de duurzame groei van het bedrijf.
                        </div>
                        <div  class="linkedin">
                            <a href='https://www.linkedin.com/in/timothybernardhubertmetz/'><img
                                    
                                    src="/assets/Icon linkedin.svg"
                            
                                    alt="logo"
                                    width="25"
                                /></a>
                        </div>
                        <div class="grayline">

                        </div>
                        <div>
                            <h2>Legal </h2>
                            
                            <h2>expert</h2>
                        </div>
                        <div>
                            <br/>
                            Timothy Metz
                        </div>
                    </div>
                    
                </div>
                <div class="lid">
                    <div class="lidd">
                        <div>
                            <img src="/assets/teamlid 7.png"  width="250">
                        </div>
                        <div>
                            Mohamed heeft een passie voor videocontent voor bedrijven. Hij werkt elke dag hard aan pakkende
video’s maken die een boodschap vertellen. Mohamed is verantwoordelijk voor de video uitingen op
sociale media.                        </div>
                        <div  class="linkedin">
                            <a href='https://www.linkedin.com/in/mohamed-al-kabi-24683630b/'><img
                                    
                                    src="/assets/Icon linkedin.svg"
                            
                                    alt="logo"
                                    width="25"
                                /></a>
                        </div>
                        <div class="grayline">

                        </div>
                        <div>
                            <h2>Content </h2>
                            
                            <h2>creator</h2>
                        </div>
                        <div>
                            <br/>
                            Mohamed Al Kabi
                        </div>
                    </div>
                    
                </div>
                <div class="lid">
                    <div class="lidd">
                        <div>
                            <img src="/assets/teamlid 8.png"  width="250">
                        </div>
                        <div>
                            Rob heeft marketingbureau Maxlead opgezet en verkocht en is als adviseur aangesloten bij Eduface.
Rob adviseert over de marketing en sales strategie en deelt zo zijn enorme lading aan ervaring met
het team.                        </div>
                        <div  class="linkedin">
                            <a href='https://www.linkedin.com/in/robsteenbrink/'><img
                                    
                                    src="/assets/Icon linkedin.svg"
                            
                                    alt="logo"
                                    width="25"
                                /></a>
                        </div>
                        <div class="grayline">

                        </div>
                        <div>
                            <h2>Marketing </h2>
                            
                            <h2>Adviseur</h2>
                        </div>
                        <div>
                            <br/>
                            Rob Steenbrink
                        </div>
                    </div>
                    
                </div>
                
         

            </div>
        </div>
    </div>
    
        
        <Footer1></Footer1>
    </div>
    
</template>
<script>

import NavItem from '@/components/Dashboard/NavItem.vue'
import Footer1 from "@/components/Frontpage/Footer2.vue";
export default{
    components: {NavItem, Footer1},
    data () {
        return{
            menuActive: false,
    windowWidth: window.innerWidth,
        }

    },
    methods: {
        sendEmail() {
    const emailAddress = 'Jeroen.van.gessel@blockbook.nl';
    const subject = 'Contact';
    const body = 'Hier kun je vragen stellen of informatie sturen om met ons in contact te komen.';

    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  },
  handleResize() {
        this.windowWidth = window.innerWidth;
    },
    toggleMenu() {
        this.menuActive = !this.menuActive;
        console.log(this.menuActive)
    },
    },
    mounted() {
      document.title = "Eduface";  
      window.addEventListener('resize', this.handleResize);
      },

beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
},

}

</script>
<style>
.v-slide-group__container {
  display: block !important;
  width: 100%;
  text-align: center;
}
.v-slide-group__content {
  display: inline-block !important;
  margin: auto !important;
  background-color: #f2f2f2;
  border-radius: 45px;
  padding: 5px;
  height: 48px !important;
}
.v-slide-group__content button {
  border-radius: 45px !important;
  height: 38px !important;
}
.v-tab__slider {
  display: none;
}
.v-slide-group__content .v-btn {
  background-color: #f2f2f2 !important;
  color: #000 !important;
}
.v-btn.v-slide-group-item--active {
  background-color: #fff !important;
}
button.v-expansion-panel-title {
  background-color: #fff !important;
  color: #000 !important;
  padding: 0 0 0 15px !important;
  margin: 0 !important;
  display: block;
  width: calc(100% - 15px);
  min-height: 38px !important;
  font-weight: bold;
  font-size: 18px !important;
  line-height: 2;
}
v-expansion-panels {
  padding: 0 !important;
}
.v-expansion-panel {
  border-radius: 15px !important;
}

.v-expansion-panels--variant-inset > .v-expansion-panel--active {
  max-width: 100% !important;
}
/* header css */
.fixedMenu {
  padding: 36px 0 !important;
  margin-bottom: 60px !important;
}
.fixedMenu .header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  padding: 5px 0;
  position: fixed;
}
.header {
  height: auto !important;
}
.header ul {
  list-style: none;
  margin-top: 10px;
  margin-bottom: 0;
}
.header ul li {
  display: inline-block;
  padding: 5px;
}
.header ul li .links,
.dropdownBtn button {
  display: block;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 18px !important;
  font-weight: bold !important;
  margin: 0;
}
.header .v-btn__overlay {
  background-color: transparent !important;
}
.header .v-btn__content {
  padding: 5px 10px;
  cursor: pointer;
  font-size: 18px !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
}
.loginBtn2,
.loginBtn {
  letter-spacing: normal !important;
}
.dropdown {
  position: relative;
  padding-right: 20px !important;
}
.dropdown:after {
  position: absolute;
  content: '❮';
  right: 0;
  font-size: 16px;
  transform: rotate(-90deg);
  color: #414042 !important;
}
@media (max-width: 576px) {
  .logo {
    width: 150px;
  }
  .logo1{
    width: 400px;
  }
  .loginBtn2,
  .loginBtn {
    padding: 0px 5px !important;
    font-size: 10px !important;
  }
}
/* header css end */
</style>
<style lang="scss">
.het-team{


    // .container-bottom{
    //     width: 100%;
    //     display: flex;
    //     justify-content: center;
    //     .bottom-team{
    //     width: 95%;
    //     margin-top: 140px;
    //     background: rgba(0, 224, 117, 0.30);
    //     display: flex;
    //     justify-content: center;
    //     margin-bottom: 140px;
    //     flex-direction: column;

    //     .eduface-team{
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: center;
    //         align-items: center;
    //         margin-bottom: 40px;    

    //         .greenline{
    //             width: 150px;
    //             background-color:#005582;
    //             height: 6px;
    //         }
    //     }
    //     .leden{
    //         display: flex;
    //         justify-content: space-between;

    //         .lid{
    //             display: flex;
    //             flex-direction: column;
    //             align-items: center;
    //             justify-content: center;
    //             text-align: center;
    //             width: 250px;
    //             height: 100%;
    //             background-color: #fff;


    //             .lidd{
    //                 margin: 20px 0px;
    //             display: flex;
    //             flex-direction: column;
    //             align-items: center;
    //             justify-content: center;
    //             text-align: center;
    //             width: 90%;
    //             height: 100%;
    //             background-color: #fff;

    //             .linkedin{
    //                 margin-top: 40px;
    //             }
    //             .grayline{
    //                 margin-top: 10px;
    //                 height: 4px;
    //                 width: 80px;
    //                 background-color: gray;
    //                 margin-bottom: 20px;
    //             }
    //         }
    //         }
            
            
    //     }
    // }
    // }
    .container-bottom {
    width: 100%;
    display: flex;
    justify-content: center;
}

.bottom-team {
    border-radius: 20px;
    width: 95%;
    margin-top: 140px;
    background-color: #f6f2f7;
    display: flex;
    justify-content: center;
    margin-bottom: 140px;
    flex-direction: column;
}

.eduface-team {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;    

    .greenline {
        width: 150px;
        background-color: #005582;
        height: 6px;
    }
}

.leden {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Allows items to wrap onto the next line */
    gap: 20px; /* Adds space between children */
    margin-bottom: 30px;
}

.lid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 300px; /* Increased width */
    background-color: #fff;
}

.lidd {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 90%;
    background-color: #fff;
}

.linkedin {
    margin-top: 40px;
}

.grayline {
    margin-top: 10px;
    height: 4px;
    width: 80px;
    background-color: gray;
    margin-bottom: 20px;
}
    
    .missie-hulp{
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .missie{
            font-size: 30px;
        }
        .doel{
            width: 80%;
            text-align: center;
            font-size: 20px;
        }
        .hulp{
            width: 60%;
            text-align: center;
            margin-bottom: 40px;
        }
    }
    .onsverhaal{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .ons{
            font-size: 30px;
        }
        .verhaal{
            width: 60%;
            text-align: center;
            font-size: 20px;
        }
    }
    .container-quote {
    margin-top: 80px;
    background-color: #f6f2f7;
    overflow: hidden;  // Ensure no overflow issues
}

.topspace, .bottomspace {
    height: 40px;
}

.quote-plaatje {
    display: flex;
    align-items: center;
    justify-content: space-between;  // This will space out the two elements
    margin: 30px;
    flex-wrap: nowrap;  // Prevent wrapping by default
}

.plaatje, .quote {
    width: 50%;  // Start with each taking half the width
    display: flex;
    flex-direction: column;  // Align children vertically
    align-items: center;
    justify-content: center;
    text-align: center;
}

.plaatje img {
    max-width: 100%;  // Ensures the image is responsive
    height: auto;  // Maintain aspect ratio
}

.quote {
    font-size: 20px;
    padding: 0 15px;  // Padding for spacing on smaller screens
}

.quote img {
    margin-bottom: 20px;  // Space between SVG and text
}

/* Media Query for smaller screens */
@media (max-width: 960px) {
    .quote-plaatje {
        flex-direction: column;  // Stack vertically on smaller screens
    }

    .plaatje, .quote {
        width: 100%;  // Each takes full width
    }
}

    
}
.assistentpage{
  cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
    
}
.banner{
  margin-bottom: 10px;



  .banner1{
    display: flex;
    align-items: center;

    .overons{
      align-items: center;
      justify-content: center;
      margin-left: 150px;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
    }
    .hoehetwerkt{
      align-items: center;
      justify-content: center;
      margin-left: 170px;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
    }
  }
}
@media (max-width: 767px) {
	.overons,.hoehetwerkt  {
    margin-left: 15px !important;
        font-size: 14px !important;
        line-height: 1;
  }

  .logo{

    width: 150px;
        height: auto;
  }
}
.logo{
  cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
}
.fixedMenu {
	padding: 36px 0 !important;
	margin-bottom: 60px !important;
	
  }
  .fixedMenu .header {
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	background: #fff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
	padding: 5px 0;
	position: fixed;
  }
    span {
  font-family: 'League Spartan', sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #414042;
}
@media (max-width: 576px) {
  .edHome {
    margin: 0 15px;
  }
}

button {
  margin-top: 0 !important;
}
.mainHeading {
  letter-spacing: 2px;
}
.btn {
  background-color: #00e075 !important;
  box-shadow: none;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 16px;
  padding: 15px 25px;
  height: auto !important;
  color: #000 !important;
}
.loginBtn {
  background: transparent !important;
  color: #000 !important;
}
.loginBtn3 {

color: #fff !important;
background-color: 00e075;
margin-right: 80px;
margin-top: 60px !important;


}
.btn:hover {
  box-shadow: none;
}
.het-team{
    width: 100%;
    

    .button {
        display: inline-block;
        padding: 8px 16px;
        margin: 4px 2px;
        cursor: pointer;
        text-align: center;
        text-decoration: none;
        color: $secondary-color;
        background-color: $base-color;
        border: none;
        border-radius: 8px;
        font-family: Arial, sans-serif;
    }

    .button:hover {
        background-color: #005582;
    }

    .top{
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-left: 40px;
    }
    .presentatie{
        
        width: 700px;
        margin: 0px 30px;
    }
    .top-picture{

        width: 50%;
        height: 50%;
    }
    .top-tekst{
        width: 50%;
        .big-tekst{
            font-size: 40px;
            color: $base-color;

            .oranje{
                color: $secondary-color;
                font-weight: bold;
            }    
        }
        .small-tekst{
            font-size: 20px;
            color: $base-color;

            .oranje{
                color: $secondary-color;
                font-weight: bold;
            }
        }
    }
    .bottom{
        margin-top: 40px;

        // background-color: #EDEDED;
        height: 100%;

        .bottom-tekst{
            display: flex;
            justify-content: center;
        }
        .docenten{
            
            margin-top: 20px;
            display: flex;
            justify-content: center;
            margin-bottom: 40px;

            .docent{
                display: flex;
                flex-direction: column;
                text-align: center;
                margin-right: 20px;
            }
            .docent-plaatje{
                margin-bottom: 20px;
            }
        }

        .plaatjes {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto; /* Centers the .plaatjes div in the middle of the page horizontally */
}

.teamlid {
    display: flex;
    
    align-items: center; /* Centers content of teamlid vertically and horizontally */
    margin-bottom: 20px;
    font-weight: bold;
}

.informatie {
    width: 60%;

}
        

    }

    .small{
        font-size: 20px;
        color: $base-color;
        margin-top: 50px;

        .oranje{
            font-weight: bold;
            color: $secondary-color;
        }
    }

    .big{
        margin-top: 80px;
    }

    .no-top-margin{
        margin-top: 0px;
    }
    .webshop-but{
        margin-top: 10px;
        color: white;
        background-color: $secondary-color;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        font-weight: bold;
        padding: 10px 15px;
        border-radius: 20px;
        margin-right: 5px;
        width: auto;
        cursor: pointer;
        &:hover{
            opacity: 0.5;
            
        }
    }
    .webshop-link{
            display: flex;
            justify-content: flex-end;
            width: 5px;
            height: 20px;
            color: $base-color;
            margin-top: 50px;

        }



    .pizza{
        width: 70%;
    }
    .profiel-info{
        height: 40px;
        width: 600px;
    }
    .picture-profiel{
        margin-top: 60px;
        height: 400px;
        width: 600px;
    }

    @media (max-width: 1100px){

        .top{
            flex-direction: column;

            .top-tekst{
                width: 90%;
                margin-right: 70px;

                .big-tekst{
                    margin-top: 40px;
                    font-size: 30px;
                }

                .small-tekst{
                    font-size: 15px;
                }
            }

            .top-picture{
                display: flex;
                justify-content: center;
                width: 100%;
                margin-top: 40px;
            }
        }

        .bottom{
            flex-direction: column;

            .big{
                font-size: 30px;
                margin-top: 8px;
            }

            .small{
                font-size: 15px;
            }

            .doel{
                width: 100%;
                height: auto;
            }

            .missie{
                margin: 0;
                width: 100%;

                .personal-info{
                    margin-top: 20px;
                    .profiel{
                        width: 100%;
                        .profiel-info{
                            width: 100%;
                            img{
                                margin-top: 0px;
                                width: 100%;
                                height: calc(78vw - 160px);
                            }
                        }
                    }
                }
            }


            .small{
                margin-top: 0px;
            }

            .webshop-but{
                padding: 10px 15px;
                font-size: 16px;
            }

        }
    }

    @media (max-width: 650px){
        .bottom{
            margin-right: 60px;
            .teamlid{
                display: flex;
                flex-direction: column;
            }
            .missie{
                .personal-info{
                    .profiel{
                        .profiel-info{
                            img{
                                width: 100%;
                                height: calc(74vw - 40px);
                            }
                        }
                    }
                }
            }
        }

        
    }
}

@media (max-width: 1100px){
    .wiezijnwij{
        padding: 0px 80px;
        width: calc(100% - 160px);
    }

    
}

@media (max-width: 500px){
    .wiezijnwij{
        padding: 0px 20px;
        width: calc(100% - 40px);
    }

    
}

// @media (max-width: 900px){

// }

</style>