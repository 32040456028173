<script>

import NavItem from '@/components/Dashboard/NavItem.vue'

export default {
  props: ['id', 'vak', 'taal'],
  components: {
    NavItem,
  },
  data() {
    return {
      TheId: this.id,
    };
  },
  methods: {
    toggleMenu() {
      var element = document.getElementById("leftMenu");
      element.classList.toggle("show");
    },
  },
};
</script>

<template>
	<div class="sidemenu">
		<div id="leftMenu" class="">
			<nav>
				<div id="menuToggle" @click="toggleMenu()">
					<div></div>
					<div></div>
					<div></div>
				</div>
				<ul class="list-unstyled">
					<li>
						<router-link v-if="$store.state.role=='student'" class="item active" :to="'/student/module/overview/'+this.id">
							<svg xmlns="http://www.w3.org/2000/svg" fill="#333" height="1.6em" viewBox="0 0 448 512">
								<path
									d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"
								/>
								</svg>
							<a>Overview</a>
						</router-link>
                        <router-link v-if="$store.state.role=='leraar' && $i18n.locale === 'nl'" class="item active" :to="'/leraar/vak/'+ this.$route.params.id1+ '/module/overview/'+this.id">
							<svg xmlns="http://www.w3.org/2000/svg" fill="#333" height="1.6em" viewBox="0 0 448 512">
								<path
									d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"
								/>
								</svg>
								<a>Overview</a>
						</router-link>
						<router-link v-if="$store.state.role=='leraar' && $i18n.locale === 'en'" class="item active" :to="'/teacher/vak/'+ this.$route.params.id1+ '/module/overview/'+this.id">
							<svg xmlns="http://www.w3.org/2000/svg" fill="#333" height="1.6em" viewBox="0 0 448 512">
								<path
									d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"
								/>
								</svg>
								<a>Overview</a>
						</router-link>
					</li>
					<li>
						<router-link v-if="$store.state.role=='student'" class="item active" :to="'/student/module/toetsoverview/'+this.id">
							<svg xmlns="http://www.w3.org/2000/svg" fill="#333" height="1.6em" viewBox="0 0 512 512">
								<path
									d="M128 0C92.7 0 64 28.7 64 64v96h64V64H354.7L384 93.3V160h64V93.3c0-17-6.7-33.3-18.7-45.3L400 18.7C388 6.7 371.7 0 354.7 0H128zM384 352v32 64H128V384 368 352H384zm64 32h32c17.7 0 32-14.3 32-32V256c0-35.3-28.7-64-64-64H64c-35.3 0-64 28.7-64 64v96c0 17.7 14.3 32 32 32H64v64c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V384zM432 248a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"
								/>
							</svg>
							<a v-if="this.taal=='nl'">Persoonlijke toets</a>
							<a v-if="this.taal=='en'">Personal test</a>
						</router-link>
                        <router-link v-if="$store.state.role=='leraar' && $i18n.locale === 'nl'" class="item active" :to="'/leraar/vak/'+ this.$route.params.id1+ '/module/toetsoverview/'+this.id">
							<svg xmlns="http://www.w3.org/2000/svg" fill="#333" height="1.6em" viewBox="0 0 512 512">
								<path
									d="M128 0C92.7 0 64 28.7 64 64v96h64V64H354.7L384 93.3V160h64V93.3c0-17-6.7-33.3-18.7-45.3L400 18.7C388 6.7 371.7 0 354.7 0H128zM384 352v32 64H128V384 368 352H384zm64 32h32c17.7 0 32-14.3 32-32V256c0-35.3-28.7-64-64-64H64c-35.3 0-64 28.7-64 64v96c0 17.7 14.3 32 32 32H64v64c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V384zM432 248a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"
								/>
							</svg>
							<a v-if="this.taal=='nl'">Persoonlijke toets</a>
							<a v-if="this.taal=='en'">Personal test</a>
						</router-link>
						<router-link v-if="$store.state.role=='leraar' && $i18n.locale === 'en'" class="item active" :to="'/teacher/vak/'+ this.$route.params.id1+ '/module/testoverview/'+this.id">
							<svg xmlns="http://www.w3.org/2000/svg" fill="#333" height="1.6em" viewBox="0 0 512 512">
								<path
									d="M128 0C92.7 0 64 28.7 64 64v96h64V64H354.7L384 93.3V160h64V93.3c0-17-6.7-33.3-18.7-45.3L400 18.7C388 6.7 371.7 0 354.7 0H128zM384 352v32 64H128V384 368 352H384zm64 32h32c17.7 0 32-14.3 32-32V256c0-35.3-28.7-64-64-64H64c-35.3 0-64 28.7-64 64v96c0 17.7 14.3 32 32 32H64v64c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V384zM432 248a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"
								/>
							</svg>
							<a v-if="this.taal=='nl'">Persoonlijke toets</a>
							<a v-if="this.taal=='en'">Personal test</a>
						</router-link>
					</li>
					<li>
                        
						<router-link v-if="$store.state.role=='student'" class="item active" :to="'/student/module/oefententamen/'+this.id+'/'+this.vak">
							<svg xmlns="http://www.w3.org/2000/svg" fill="#333" height="1.6em" viewBox="0 0 384 512">
								<path
									d="M297.2 248.9C311.6 228.3 320 203.2 320 176c0-70.7-57.3-128-128-128S64 105.3 64 176c0 27.2 8.4 52.3 22.8 72.9c3.7 5.3 8.1 11.3 12.8 17.7l0 0c12.9 17.7 28.3 38.9 39.8 59.8c10.4 19 15.7 38.8 18.3 57.5H109c-2.2-12-5.9-23.7-11.8-34.5c-9.9-18-22.2-34.9-34.5-51.8l0 0 0 0c-5.2-7.1-10.4-14.2-15.4-21.4C27.6 247.9 16 213.3 16 176C16 78.8 94.8 0 192 0s176 78.8 176 176c0 37.3-11.6 71.9-31.4 100.3c-5 7.2-10.2 14.3-15.4 21.4l0 0 0 0c-12.3 16.8-24.6 33.7-34.5 51.8c-5.9 10.8-9.6 22.5-11.8 34.5H226.4c2.6-18.7 7.9-38.6 18.3-57.5c11.5-20.9 26.9-42.1 39.8-59.8l0 0 0 0 0 0c4.7-6.4 9-12.4 12.7-17.7zM192 128c-26.5 0-48 21.5-48 48c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-44.2 35.8-80 80-80c8.8 0 16 7.2 16 16s-7.2 16-16 16zm0 384c-44.2 0-80-35.8-80-80V416H272v16c0 44.2-35.8 80-80 80z"
								/>
							</svg>
							<a v-if="this.taal=='nl'">Oefententamen</a>
							<a v-if="this.taal=='en'">Practice exam</a>
						</router-link>
                        <router-link v-if="$store.state.role=='leraar' && $i18n.locale === 'nl'" class="item active" :to="'/leraar/vak/'+ this.$route.params.id1+ '/module/oefententamen/'+this.id">
							<svg xmlns="http://www.w3.org/2000/svg" fill="#333" height="1.6em" viewBox="0 0 384 512">
								<path
									d="M297.2 248.9C311.6 228.3 320 203.2 320 176c0-70.7-57.3-128-128-128S64 105.3 64 176c0 27.2 8.4 52.3 22.8 72.9c3.7 5.3 8.1 11.3 12.8 17.7l0 0c12.9 17.7 28.3 38.9 39.8 59.8c10.4 19 15.7 38.8 18.3 57.5H109c-2.2-12-5.9-23.7-11.8-34.5c-9.9-18-22.2-34.9-34.5-51.8l0 0 0 0c-5.2-7.1-10.4-14.2-15.4-21.4C27.6 247.9 16 213.3 16 176C16 78.8 94.8 0 192 0s176 78.8 176 176c0 37.3-11.6 71.9-31.4 100.3c-5 7.2-10.2 14.3-15.4 21.4l0 0 0 0c-12.3 16.8-24.6 33.7-34.5 51.8c-5.9 10.8-9.6 22.5-11.8 34.5H226.4c2.6-18.7 7.9-38.6 18.3-57.5c11.5-20.9 26.9-42.1 39.8-59.8l0 0 0 0 0 0c4.7-6.4 9-12.4 12.7-17.7zM192 128c-26.5 0-48 21.5-48 48c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-44.2 35.8-80 80-80c8.8 0 16 7.2 16 16s-7.2 16-16 16zm0 384c-44.2 0-80-35.8-80-80V416H272v16c0 44.2-35.8 80-80 80z"
								/>
							</svg>
							<a v-if="this.taal=='nl'">Oefententamen</a>
							<a v-if="this.taal=='en'">Practice exam</a>
						</router-link>
						<router-link v-if="$store.state.role=='leraar' && $i18n.locale === 'en' " class="item active" :to="'/teacher/vak/'+ this.$route.params.id1+ '/module/practice-exam/'+this.id">
							<svg xmlns="http://www.w3.org/2000/svg" fill="#333" height="1.6em" viewBox="0 0 384 512">
								<path
									d="M297.2 248.9C311.6 228.3 320 203.2 320 176c0-70.7-57.3-128-128-128S64 105.3 64 176c0 27.2 8.4 52.3 22.8 72.9c3.7 5.3 8.1 11.3 12.8 17.7l0 0c12.9 17.7 28.3 38.9 39.8 59.8c10.4 19 15.7 38.8 18.3 57.5H109c-2.2-12-5.9-23.7-11.8-34.5c-9.9-18-22.2-34.9-34.5-51.8l0 0 0 0c-5.2-7.1-10.4-14.2-15.4-21.4C27.6 247.9 16 213.3 16 176C16 78.8 94.8 0 192 0s176 78.8 176 176c0 37.3-11.6 71.9-31.4 100.3c-5 7.2-10.2 14.3-15.4 21.4l0 0 0 0c-12.3 16.8-24.6 33.7-34.5 51.8c-5.9 10.8-9.6 22.5-11.8 34.5H226.4c2.6-18.7 7.9-38.6 18.3-57.5c11.5-20.9 26.9-42.1 39.8-59.8l0 0 0 0 0 0c4.7-6.4 9-12.4 12.7-17.7zM192 128c-26.5 0-48 21.5-48 48c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-44.2 35.8-80 80-80c8.8 0 16 7.2 16 16s-7.2 16-16 16zm0 384c-44.2 0-80-35.8-80-80V416H272v16c0 44.2-35.8 80-80 80z"
								/>
							</svg>
							<a v-if="this.taal=='nl'">Oefententamen</a>
							<a v-if="this.taal=='en'">Practice exam</a>
						</router-link>
					</li>
				</ul>
			</nav>
		</div>
	</div>
	
</template>

<style lang="scss">
.sidemenu{
	margin-top: 20px;

	.goback{
		margin-bottom: 10px;
	}

	#leftMenu li {
		margin-bottom: 20px;
		border-radius: 5px;
		transition: 0.3s all;
	}
	#leftMenu li a {
		border-radius: 5px;
	}

	#leftMenu .item {
		display: block;
		padding: 16px 15px;
		color: #2c2a44;
		font-weight: 500;
		font-size: 20px;
		cursor: pointer;
		transition: 0.3s all;
	}
	#leftMenu .item.router-link-active,
	#leftMenu .item:hover {
		background-color: #002333;
		color: #fff;
	}
	#leftMenu .item svg {
		width: 50px;
		padding-right: 15px;
	}

	#leftMenu .item.router-link-active svg,
	#leftMenu .item:hover svg {
		fill: #fff;
	}
	#leftMenu .item {
		display: block;
		text-align: left;
		text-decoration: none;
	}
	#leftMenu {
		width: 300px;
		padding: 10px;
		transition: 0.3s all;
	}
	#menuToggle {
		width: 30px;
		position: absolute;
		right: -30px;
		background: #fff;
		padding: 5px;
		box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
		border-radius: 0 5px 5px 0;
		cursor: pointer;
		display: none;
		color: $base-color;
	}
	#menuToggle div {
		height: 3px;
		background: $base-color;
		margin-bottom: 3px;
	}

	@media only screen and (max-width: 3000px) {
        .sidemenu{
            
        }
		#leftMenu {
            margin-top: 150px;
			width: 280px;
			position: fixed;
			height: 100%;
			background: #fff;
			left: -280px;
			top: 0;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
			z-index: 9;
		}
		#leftMenu.show {
			left: 0;
		}
		#menuToggle {
			display: block;
		}
		#mainBody {
			width: 100%;
			margin-left: 20px;
		}
	}
}

</style>
