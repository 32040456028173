<template>
    <div class="leraar-beoordelingsformulier-overview">
        <Popup class="module-popup" :show="verwijderPopup" @close="stopVerwijderen">
            <h2>Weet je zeker dat je dit beoordelingsformulier wilt verwijderen</h2>
            <BetterButton 
                :text="$t('delete')"
                @click="verwijderen(verwijderfeedback)"
            ></BetterButton>
        </Popup>
        <Sidebar mode="teacher" active="Beoordelingsformulier"></Sidebar>
        <div class="content" :class="{'mobile-extend': toggledSidebar}">
            <DashboardHeader mode="feedback" @search="filterBooks"></DashboardHeader>
            
            <div class="scrollable-content">
                <div class="nieuwe-feedback">
                    <h2>Beoordelingsformulier overzicht</h2>
                    <div class="nieuwe-button">
                        <div v-if="$i18n.locale === 'nl'" class="nieuwe" @click="$router.push({name:'nieuwe-beoordelingsformulier'})">Nieuw Beoordelingsformulier</div>
                        <div v-if="$i18n.locale === 'en'" class="nieuwe" @click="$router.push({name:'nieuwe-module-en'})">{{ $t('newFeedback') }}</div>
                    </div>
                </div>

                <div class="table-wrapper">
                    <h3>Let op! De titel van het beoordelingsformulier wordt ook de titel van de studenten opdracht als je gebruik maakt van de link</h3>
                    <table>
                        <thead>
                            <tr>

                                <th>Link opdracht feedback</th>
                                <th>Link codeer feedback</th>
                                <th>{{ $t('title') }}</th>
                                <th>Verwijderen</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr  class="table-margin" v-for="feed in filtered_modules" :key="feed.titel_rubriek" >

                                    <!-- <td v-if="feed.feedback==''" class="informatie" ><img src="/assets/laadbar.png" width="150" /></td> -->
                                    <td>
                                        <a :href="feed.token_link">
                                            {{ feed.token_link.substring(0, 30) }}
                                            <span v-if="feed.token_link.length > 30">...</span>
                                        </a>
                                    </td> 
                                    <td>
                                        <a :href="feed.codeer_link">
                                            {{ feed.codeer_link.substring(0, 30) }}
                                            <span v-if="feed.token_link.length > 30">...</span>
                                        </a>
                                    </td>                                           
                                    <td >{{ feed.titel_rubriek }}</td>
                                    <td class="verwijderen" @click="verwijderFeedback(feed.beoordelingsformulier_id)">Verwijder beoordelingsformulier</td>

    
                                    <!-- <td v-if="feed.taal=='en'" class="verwijderen" @click="moduleVerwijderen(feed.id)">Delete module</td> -->
                                    
                                </tr>
                            </tbody>
                        </table>


                </div>
                
            </div>
            


            
        </div>
    </div>
</template>
<script>
import Sidebar from '@/components/Dashboard/SidebarLeraar.vue'
import DashboardHeader from '@/components/Dashboard/DashboardHeader.vue'
import DashboardHeading from '@/components/Dashboard/DashboardHeading.vue'
import Popup from '@/components/Widgets/Popup.vue'
import BetterButton from '@/components/Widgets/BetterButton.vue'
export default{
    mounted() {
        document.title = "Eduface | Beoordelingsformulier";    },
  
    data(){
        return {
            search: "",
            isVerwijderen: false,
            verwijderfeedback: 0,
            verwijderPopup: false,
        }
        
    },
    components: {Sidebar, DashboardHeader, DashboardHeading, Popup, BetterButton},
    computed: {
        modules(){
            return this.$store.state.beoordelingsformulier_leraar
        },
        toggledSidebar(){
            return this.$store.state.mobileExtend
        },
        filtered_modules(){
            const vm = this
            return vm.modules.filter(module => {
                return (
                    (module.titel_rubriek.toLowerCase().includes(vm.search.toLowerCase())) 
                )
            })
        },
        
    },
  
    async created() {
    // Initial call to fetch feedback
    await this.fetchModule();
  
    for(let i=0; i < this.modules.length; i++){
        if(this.modules[i].klaar=="" || this.modules[i].klaar_examenvragen=="" || this.modules[i].klaar_weekvragen=="" || this.modules[i].klaar=="failed" || this.modules[i].klaar_examenvragen=="failed" || this.modules[i].klaar_weekvragen=="failed"){
              // Set up interval to periodically fetch feedback
                this.moduleInterval = setInterval(async () => {
                await this.fetchModule();
                }, 20000); // Adjust the interval as needed (currently set to 1 minute)
        }
    }
  
  },
    methods:{
        stopVerwijderen(){
            this.verwijderPopup = false;
            this.popup = false;
        },
        async verwijderen(beoordelingsformulier_id){
            
    
            await this.$store.dispatch("verwijderBeoordelingsformulier", {beoordelingsformulier_id})
            await this.$store.dispatch("get_beoordelingsformulier_leraar", this.$route.params.id)
            this.verwijderPopup = false;
        },
        verwijderFeedback(beoordelingsformulier_id){
            console.log(beoordelingsformulier_id)
            this.verwijderfeedback = beoordelingsformulier_id
            console.log(this.verwijderfeedback)
            this.verwijderPopup = true;
          
        },

        async fetchModule() {
      // Fetch feedback from the backend using your Vuex store dispatch
      await this.$store.dispatch("get_beoordelingsformulier_leraar", this.$route.params.id);
    },
        actieVerwijderen(){
            this.verwijderen = true;
        },
        async annuleerverwijderen(){
            this.verwijderen = false;
            await this.$store.dispatch("get_beoordelingsformulier_leraar", this.$route.params.id)
        },
        filterBooks(query){
            
            
            this.search = query
        
        },
    }
    
    
    
}
</script>
<style lang="scss">
.leraar-beoordelingsformulier-overview{


    .popup{
        display: flex !important;
        align-items: center;
        flex-direction: column;
        text-align: center;

        .invite-code{
            font-size: 30px;
            font-weight: bold;
            color: $base-color;
            margin: 20px 0px;
        }

    }
    .content{
        background-color: #F4F6FC;
        justify-content: space-between;
        position: absolute;
        width: calc(100% - 300px);
        padding: 0px 50px;
        top: 0px;
        left: 200px;
        min-height: 100vh;
}

.scrollable-content {
    overflow-x: auto;
    max-width: 100%; /* Adjust the width as needed */
    /* Other styles for your scrollable content container */
    .nieuwe-feedback{
        display: flex;
    }

    .nieuwe-button{
            margin-left: 20px;
            background-color: $base-color;
            color: #fff;
            border-radius: 20px;
            cursor: pointer;
            &:hover{
                opacity: 0.5;
            }

        }
        .nieuwe{
            padding: 5px 15px;
            background-color: $base-color;
            color: #fff;
            border-radius: 20px;
            cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
        }
}
.informatie{
    color: $base-color;
    cursor: pointer;

    &:hover{
        opacity: 0.8;
    }
}
.table-margin table tbody tr {
        display: block;
        margin-bottom: 10px;
        border-bottom: 1px solid transparent;
    }
    
    .table-margin table tbody tr:last-child {
        margin-bottom: 0; /* Remove margin-bottom for the last row */
    }
.table-wrapper {
    width: 100%;

    
    /* Adjust padding, margin, and other styles as needed */
    
}
.verwijderen{
    color: #004C4C;
    cursor: pointer;
            &:hover{
                opacity: 0.5;
                
            }
    
}
    .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    
  }

    .th, td {
    border: 1px solid #dddddd;
    
    height: 40px;
    width: 400px;
  
  }

  .th {
    background-color: #f2f2f2;
    text-align: left;
  }
  thead th {
    text-align: left;
  }
  

  td {
    text-align: left;
  }

}

@media (max-width: 900px){
    .leraar-beoordelingsformulier-overview{

        /* .content{
            left: 10px;
            padding: 0px 0px;
            width: 100%;
        } */
        .content{
            left: 0px;
            width: calc(100% - 100px);
            .search{
                    display: none;
                }
            &.mobile-extend{
                display: block;
                left: 200px;
                width: calc(100% - 300px);
                

                
            }
            
        }
        
        
    }
    
}

@media (max-width: 600px){
        .content{
            padding: 0px 10px;
            width: calc(100% - 20px);
            &.mobile-extend{
                left: calc(100% - 70px);
                width: 70px;
                .scrollable-content{
                    display: none;
                }
            }

         

            
        }
    }
</style>