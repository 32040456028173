<template>
    <div class=siderbar-leraar>
        <div class="sidebar-leraar2" :class="{'mobile-extend': mobileExtend}">
            <div class="siderbar-color">
                <div>
            <div  v-if="$i18n.locale === 'en'"   @click="profielEN" class="profile-feedback">
                
                
                <img class="hoedje" src="/assets/Blockbookhoedje.svg" width="200"/>
                
                <div class="profile-details">
                    <div v-if="$i18n.locale === 'en'"  class="profile-banner-welkom">
                        Teacher
                    </div>
                    <div v-if="$i18n.locale === 'nl'"  class="profile-banner-welkom">
                        {{this.$store.state.username}}
                    </div>
                    <div class="profile-banner-username">
                        
                    </div>
                </div>
            </div>
            <div  v-if="$i18n.locale === 'nl'"   @click="profiel" class="profile-feedback">
                
                
                <img class="hoedje" src="/assets/hoedjeeduface.png" width="80"/>
                
                <div class="profile-details">
                    <div v-if="$i18n.locale === 'en'"  class="profile-banner-welkom">
                        Teacher
                    </div>
                    <div v-if="$i18n.locale === 'nl'"  class="profile-banner-welkom">
                        {{this.$store.state.username}}
                    </div>
                    <div class="profile-banner-username">
                        
                    </div>
                </div>
            </div>

            <div class="navigation-menu" v-if="$i18n.locale === 'nl'">
                <NavItem  text="Dashboard" :active="active=='dashboard'" v-if="mode!='teacher'" :href="'leraar'">
                    <img :src="active=='dashboard' ? '/assets/dashboard_active.svg' : '/assets/dashboard.svg'"/>
                </NavItem>
                <!-- <div class="navigation"> -->
                    <!-- <div class="navigation-line">''</div> -->
                    <NavItem  text="Dashboard" :active="active=='dashboard'" v-if="mode=='teacher'" :href="'dashboard-vak'">
                        <img :src="active=='dashboard' ? '/assets/dashboard_active.svg' : '/assets/dashboard.svg'"/>
                    </NavItem>
                <!-- </div> -->
                <!-- <div class="navigation">
                    <div class="navigation-line"></div>
                </div> -->
                
                <!-- <NavItem v-if="mode == 'student'" text="Winkel" :active="active=='winkelen'" href="winkelen">
                    <img :src="active=='winkelen' ? '/assets/shopping-bag_active.svg' : '/assets/shopping-bag.svg' "/>
                </NavItem> -->
                <NavItem  text="Boeken" :active="active=='boeken'" href="boeken" v-if="mode != 'teacher' && mode!='leraar'"> 
                    <img :src="active=='boeken' ? '/assets/book_active.svg' : '/assets/book.svg'"/>
                </NavItem>
                <!-- <NavItem v-if="mode == 'student'" text="Modules" :active="active=='Modules'" href='modules-student' mode="student">
                    <img :src="active=='Modules' ? '/assets/view-module-active.svg' : '/assets/view-module.svg'"/>
                </NavItem>
                <NavItem v-if="mode == 'student'"  href='student-wallet' :active="active=='wallet'" text="Wallet">
                    <img :src="active=='wallet' ? '/assets/wallet_active.svg' : '/assets/wallet.svg'"/>
                </NavItem> -->
                <NavItem v-if="mode == 'uitgever'" href='uitgever-wallet' :active="active=='wallet'" text="Wallet">
                    <img :src="active=='wallet' ? '/assets/wallet_active.svg' : '/assets/wallet.svg'"/>
                </NavItem>
                <NavItem v-if="mode == 'uitgever'" text="Resultaten">
                    <img :src="active=='Resultaten' ? '/assets/user-circle_active.svg' : '/assets/bar-chart.svg'"/>
                </NavItem>
                <NavItem v-if="mode == 'teacher'" text="Feedback" @click="$router.push(`/leraar/vak/${$route.params.id}/feedback-overview`)"  :active="active=='Feedback'">
                    <img :src="active=='Feedback' ? '/assets/news-orange.svg' : '/assets/news.svg'"/>
                </NavItem>
                <NavItem v-if="mode == 'teacher'" text="Beoordelings-formulier" @click="$router.push(`/leraar/vak/${$route.params.id}/beoordelingsformulier`)"  :active="active=='Beoordelingsformulier'">
                    <img :src="active=='Beoordelingsformulier' ? '/assets/copy-active.svg' : '/assets/copy.svg'"/>
                </NavItem>
                <NavItem v-if="mode == 'teacher'" text="Modules" :active="active=='Modules'" @click="$router.push(`/leraar/vak/${$route.params.id}/module-overview`)" mode="leraar">
                    <img :src="active=='Modules' ? '/assets/view-module-active.svg' : '/assets/view-module.svg'"/>
                </NavItem>
                <NavItem 
    v-if="mode == 'teacher'" 
    text="Opdrachten" 
    @click="$router.push(`/leraar/vak/${$route.params.id}/opdrachten`)"  
    :active="active=='Opdrachten'"
>
    <img :src="active=='Opdrachten' ? '/assets/assignment-active.svg' : '/assets/assignment.svg'"/>
    <span class="new-badge">Ik ben nieuw</span>
</NavItem>
               
                <NavItem v-if="mode == 'leraar'" text="Vakken" :active="active=='Vakken'" href='vakken' mode="leraar">
                    <img :src="active=='Vakken' ? '/assets/view-module-active.svg' : '/assets/view-module.svg'"/>
                </NavItem>
                <!-- <NavItem v-if="mode == 'teacher'" text="Leerlingen" :active="active=='Leerlingen'" href='leerlingen'>
                    <img :src="active=='Leerlingen' ? '/assets/profile-active.svg' : '/assets/profile.svg'"/>
                </NavItem> -->
                <NavItem v-if="mode == 'uitgever'" text="Facturen">
                    <img :src="active=='Facturen' ? '/assets/user-circle_active.svg' : '/assets/news.svg'"/>
                </NavItem>
                
                <!-- <NavItem v-if="mode == 'teacher'" text="Klas lijst" @click="$router.push(`/leraar/vak/${$route.params.id}/klaslijst`)" :active="active=='klas-lijst'">
                    <img :src="active=='klas-lijst' ? '/assets/user-circle_active.svg' : '/assets/user-circle.svg'"/>
                </NavItem>
                <NavItem v-if="mode == 'teacher'" text="Personalised learning" @click="$router.push(`/leraar/vak/${$route.params.id}/personalised-learning`)" :active="active=='Personalised learning'">
                    <img :src="active=='Personalised learning' ? '/assets/stats-bars2-orange.svg' : '/assets/stats-bars2.svg'"/>
                </NavItem> -->
                <!-- <NavItem v-if="mode == 'teacher'" text="Coming soon" @click="$router.push(`/leraar/vak/${$route.params.id}/coming-soon`)" :active="active=='Coming soon'">
                    <img :src="active=='Coming soon' ? '/assets/stats-bars2-orange.svg' : '/assets/stats-bars2.svg'"/>
                </NavItem> -->
                <!-- <NavItem  text="Onderwijs assistent" :active="active=='dashboardassistent'" v-if="mode=='teacher'"  :href="'dashboard-onderwijsassistent'">
                    <img :src="active=='dashboardassistent' ? '/assets/profile-active.svg' : '/assets/profile.svg'"/>
                </NavItem> -->
                
                
                <!-- <NavItem v-if="mode == 'student'" text="Feedback" href="feedback-student"  :active="active=='klas-lijst'">
                    <img :src="active=='Feedback' ? '/assets/news-orange.svg' : '/assets/news.svg'"/>
                </NavItem>
                 -->
                
            </div>
            <div class="navigation-menu" v-if="$i18n.locale === 'en'">
                <NavItem  text="Dashboard" :active="active=='dashboard'" href='teacher'>
                    <img :src="active=='dashboard' ? '/assets/dashboard_active.svg' : '/assets/dashboard.svg'"/>
                </NavItem>
                <!-- <NavItem v-if="mode == 'student'" text="Winkel" :active="active=='winkelen'" href="winkelen">
                    <img :src="active=='winkelen' ? '/assets/shopping-bag_active.svg' : '/assets/shopping-bag.svg' "/>
                </NavItem> -->
                <NavItem  text="Boeken" :active="active=='boeken'" href="boeken" v-if="mode != 'teacher'"> 
                    <img :src="active=='boeken' ? '/assets/book_active.svg' : '/assets/book.svg'"/>
                </NavItem>
                <!-- <NavItem v-if="mode == 'student'" text="Modules" :active="active=='Modules'" href='modules-student' mode="student">
                    <img :src="active=='Modules' ? '/assets/view-module-active.svg' : '/assets/view-module.svg'"/>
                </NavItem>
                <NavItem v-if="mode == 'student'"  href='student-wallet' :active="active=='wallet'" text="Wallet">
                    <img :src="active=='wallet' ? '/assets/wallet_active.svg' : '/assets/wallet.svg'"/>
                </NavItem> -->
                <NavItem v-if="mode == 'uitgever'" href='uitgever-wallet' :active="active=='wallet'" text="Wallet">
                    <img :src="active=='wallet' ? '/assets/wallet_active.svg' : '/assets/wallet.svg'"/>
                </NavItem>
                <NavItem v-if="mode == 'uitgever'" text="Resultaten">
                    <img :src="active=='Resultaten' ? '/assets/user-circle_active.svg' : '/assets/bar-chart.svg'"/>
                </NavItem>
                <NavItem v-if="mode == 'teacher'" text="Modules" :active="active=='Modules'" href='modules-teacher' mode="leraar">
                    <img :src="active=='Modules' ? '/assets/view-module-active.svg' : '/assets/view-module.svg'"/>
                </NavItem>
                <!-- <NavItem v-if="mode == 'teacher'" text="Leerlingen" :active="active=='Leerlingen'" href='leerlingen'>
                    <img :src="active=='Leerlingen' ? '/assets/profile-active.svg' : '/assets/profile.svg'"/>
                </NavItem> -->
                <NavItem v-if="mode == 'uitgever'" text="Facturen">
                    <img :src="active=='Facturen' ? '/assets/user-circle_active.svg' : '/assets/news.svg'"/>
                </NavItem>
                <NavItem v-if="mode == 'teacher'" text="Feedback" href="feedback-teacher"  :active="active=='Feedback'">
                    <img :src="active=='Feedback' ? '/assets/news-orange.svg' : '/assets/news.svg'"/>
                </NavItem>
                <!-- <NavItem v-if="mode == 'student'" text="Feedback" href="feedback-student"  :active="active=='Feedback'">
                    <img :src="active=='Feedback' ? '/assets/news-orange.svg' : '/assets/news.svg'"/>
                </NavItem> -->
                
                
            </div>
        </div>

        <div class="logo-bottom">
            <NavItem v-if="$i18n.locale === 'en'" text="Log out" href="logout">
                    <img src="/assets/exit.svg"/>
                </NavItem>
                <NavItem v-if="$i18n.locale === 'nl'" text="Log uit" href="logout">
                    <img src="/assets/exit.svg"/>
                </NavItem>
        </div>
            </div>
        
    </div>
    </div>
    
</template>


<script>
import NavItem from "@/components/Dashboard/NavItem.vue"
export default{
    components: {NavItem},
    props: ["active", "mode", "assistant"],
    computed: {
        mobileExtend(){
            return this.$store.state.mobileExtend
        },
        role(){
            return this.$store.state.role == null || this.$store.state.role == "" ? "student" : this.$store.state.role
        }
    },
    data(){
        return{

        }
            
        
    },
    methods: {
        profiel(){
           
            this.$router.push({
                name: "profiel-leraar",
                
            })
        },
        profielEN(){
           
           this.$router.push({
               name: "profile-teacher",
               
           })
       },
        
    }
}
</script>


<style lang="scss">
.siderbar-leraar{
    background-color: #F4F6FC;
    .sidebar-leraar2{

// margin: 7px 0px;
// margin-bottom: 5px;
// margin-left: 5px;
background-color: #F4F6FC;
display: flex;
flex-direction: column;
justify-content: space-between;
position: fixed;
top: 0px;
left: 0px;
width: 200px;
height: 100%;
color: $base-color;
.new-badge {
    background-color: #ff6b6b; /* You can choose a different color */
    color: white;
    font-size: 10px;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 5px;
    margin-left: 5px;
    display: inline-block;
}
.siderbar-color{
    margin: 7px 0px;
margin-bottom: 5px;
margin-left: 5px;
    background-color: #002333;
display: flex;
flex-direction: column;
justify-content: space-between;
position: fixed;
top: 0px;
left: 0px;
width: 200px;
height: 98%;
color: $base-color;
border-radius: 20px;
    .navigation-menu{
    margin-top: -70px;

    .navigation{
        color: #fff;
        display: flex;
        
        .navigation-line{
            padding: 20px 0px 0px 0px;
            background-color: #fff;
            height: 10px;
            margin-top: 20px;
        }
    }
}


.profile-feedback{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 100%;
    background-blend-mode: multiply, normal;
    background-size: cover;
    border-radius: 0px 0px 32px 0px;
    padding: 10px 0px;
    margin-bottom: 65px;
    margin-top: 50px;
    cursor: pointer;
    &:hover{
        opacity: 0.5;
        
    }

    .hoedje{
        margin-bottom: 10px;
        margin-right: 20px;
    }
    .avatar{
        width: 100px;
        height: 100px;
        border: 4px solid #EC7926;
        border-radius: 50%;
    }

    .profile-banner-welkom{
        width: 80%;
        height: 63px;

        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 28px;

        color: #fff !important; 
        margin-top: -20px;

        text-align: center;



    }
    .profile-details{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 200px;
    }

    .profile-banner-username{
        width: 80%;

        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 42px;
        text-align: center;

        margin-top: 40px;

        color: black;
    }
}
.logo-bottom{
    position: relative;
    display: flex;
    width: 100%;
    bottom: 20px;
    left: 5px;
    justify-content: center;

    img{
        height: 100%;
        max-width: 200px;
    }

}
}

}
}



@media (max-width: 899px){
   
        .sidebar-leraar2 {
      
        display: none !important;
        &.mobile-extend{
                display: flex !important;
            }
    }
    
    
}

@media (max-width: 600px){
    .sidebar-leraar{
        
        flex-direction: column;
        justify-content: space-between;
        &.mobile-extend{
            display: flex;
            width: calc(100% - 70px);
        }
        .hoedje{
            width: 300px;
        }

        .profile-banner{
            height: 100px;
            flex-direction: row;
            .profile-details{
                width: calc(100% - 130px);
            }
            .profile-banner-welkom{
                width: 300px;
            }
            .profile-banner-username{
                width: 300px;
            }
            .avatar{
                // width: 80px;
                // height: 80px;
                // margin-left: 40px;
                display: none;
            }
        }
        .nav-item{
            padding: 40px 0px 0px 40px;
            font-size: 20px;

            .text{
                font-size: 20px;
            }
        }

        .logo-bottom{
            justify-content: flex-start;
        }
    }
}
</style>