
<template>
	<div v-if="item" class="row ovItem" :class="{'bewerken': bewerken}">
		<div class="">
			<div class="left"></div>
			<div class="d-flex mb-4">
				<div class="flex-shrink-0">
					
				</div>
				<div class="flex-grow-1 ms-4">
					
					<Input
						v-if="bewerken && $i18n.locale === 'en'"
						class="mb-1"
                        v-model="item.titel"
						placeholder="Type a title for your weekoverview or leave this field empty..."
                    ></Input>
					<Input
						v-if="bewerken && $i18n.locale === 'en'"
						type="text"
                        v-model="item.tekst"
						placeholder="Type information for your weekoverview or keep this field empty..."
                    ></Input>
					<Input
						v-if="bewerken && $i18n.locale === 'nl'"
						class="mb-1"
                        v-model="item.titel"
						placeholder="Type een titel voor uw weekoverview of houd dit veld leeg..."
                    ></Input>
					<Input
						v-if="bewerken && $i18n.locale === 'nl'"
						type="text"
                        v-model="item.tekst"
						placeholder="Type inhoud voor uw weekoverview of houd dit veld leeg..."
                    ></Input>
					<h4 v-if="!bewerken" class="mb-1">{{ item.titel }}</h4>
					
					<span v-if="!bewerken && mode=='toets' && item.taal=='nl'">Test je kennis met deze toets van {{ item.titel }}</span>
					<span v-if="!bewerken && mode=='toets' && item.taal=='en'">Test your knowledge with this test from {{ item.titel }}</span>
				</div>
			</div>
		</div>

		<div v-if="!bewerken" class="col-lg-4.1">
			<div class="ovRight">
				<div v-if="mode=='toets'">
					<router-link v-if="$store.state.role=='student'" class="btn btn-lg btn-primary mt-4" :to="'/student/module/toets/'+item.module_id+'/'+item.weeknummer+'/'+item.vak">{{ $t('makeTest') }}</router-link>
					<router-link v-if="$store.state.role=='leraar' && $i18n.locale === 'nl'" class="btn btn-lg btn-primary mt-4" :to="'/leraar/vak/'+ this.$route.params.id1+ '/module/toets/'+item.module_id+'/'+item.weeknummer">{{ $t('viewTest') }}</router-link>
					<router-link v-if="$store.state.role=='leraar' && $i18n.locale === 'en'" class="btn btn-lg btn-primary mt-4" :to="'/teacher/vak/'+ this.$route.params.id1+ '/module/test/'+item.module_id+'/'+item.weeknummer">{{ $t('viewTest') }}</router-link>
				</div>
				<div v-if="mode=='overview'" class="row">
					<div class="col-xl-8.1 col-md-8.1 col-lg-12">
						<div class="d-flex">
							
							<div  class="flex-grow-1 ms-3">
								<h4 v-if="item.taal=='nl'">Leer de stof: {{ item.tekst}}</h4>
								<h4 v-if="item.taal=='en'">Learn the content: {{ item.tekst}}</h4>
								
							</div>
							<div class="bekijk">
								<router-link v-if="$store.state.role=='student'" class="btn btn-lg btn-primary mt-4" :to="'/student/module/informatie/'+item.module_id+'/'+item.weeknummer+'/'+item.vak">{{ $t('view') }}</router-link>
								<router-link v-if="$store.state.role=='leraar' && $i18n.locale === 'en' " class="btn btn-lg btn-primary mt-4" :to="'/teacher/vak/'+ this.$route.params.id1+ '/module/information/'+item.module_id+'/'+item.weeknummer">{{ $t('view') }}</router-link>
								<router-link v-if="$store.state.role=='leraar' && $i18n.locale === 'nl' " class="btn btn-lg btn-primary mt-4" :to="'/leraar/vak/'+ this.$route.params.id1+ '/module/informatie/'+item.module_id+'/'+item.weeknummer">{{ $t('view') }}</router-link>

							</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Input from '@/components/Form/BetterInput.vue'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
	bewerken: {
		type: Boolean,
		required: true,
	},
    mode: {
      type: String,
      required: true,
    },
  },
  components: {Input},
  data(){
	return{
		title: this.item.title,
		id: this.item.id
	}
  }
};
</script>

<style lang="scss">
.ovItem {
	padding: 40px 0;

	&:not(:last-child) {
		border-bottom: 1px solid #d4dff9;
	}

	svg path {
		fill: #004aad;
	}

	.date {
		text-align: center;
		border: 1px solid #d3dff9;
		border-radius: 5px;
		padding: 3px;
		font-weight: 500;
		color: #9c9db8;
		font-size: 14px;
		padding: 6px 3px;

		
	}

	.date span {
		color: #516692;
		display: block;
		font-size: 22px;
	}

	.ovRight {
		background: #f8fafe;
		border: 1px solid #d4dff9;
		border-radius: 5px;
		padding: 20px;
	}

	.progItem {
		width: 200px;
		display: inline-block;
	}
	.progItem .progress {
		height: 10px;
		margin-top: 10px;
	}
	.progLeft {
		display: inline-block;
		width: 150px;
		padding-right: 5px;
	}
	.progress-bar {
		background-color: #ec7926;
		border-radius: 5px;
	}

	.mb-1{
		font-weight: bold;

		.input-header{
			height: 0px;
		}
	}

	&.bewerken{
		padding-top: 0px;
		.ms-4{
			margin-inline-start: 0px !important;
		}
	}
	.bekijk{
		margin-right: 40px;
	}
	
}





</style>
