<template>
<div class="student-assistent">
    <div class="edHome">
    <header>
      <div class="eContainer">
        <div class="eRow my-2">
          <div class="col">
            <img src="/assets/logo.png" class="logo" alt="logo" width="180" />
          </div>
          <div class="col text-end">
            <v-btn flat class="rounded-pill border px-10 text-capitalize loginBtn" @click="this.$router.push('log-in')">Login</v-btn>
          </div>
        </div>
      </div>
    </header>
    <div class="upload">
        <h1 class="upload-text">
            Upload je opdracht hier naar je docent
        </h1>
        <div class="docent-informatie">
            <span>Laat je opdracht nakijken door de onderwijsassistent {{ informatie_docent.opleidingsniveau}} van {{ informatie_docent.username }} </span>
           

        </div>
        <div class="docent-informatie">

            <span> {{ informatie_docent.titel_rubriek}} </span>
        </div>
        <div class="docent-informatie">

        <span> {{ informatie_docent.vak}} </span>
        </div>
        <div class="upload-informatie">
            <Input2
                    title="Taal*"
                    v-model="taal"
                    type="select"
                    :options="options.talen"
                    mode="feedback"
                   
                ></Input2>
            <Input2
                    
                    v-model="leerling_anoniem"
                    type="textsmall"
                    mode="feedback"
                    tekst="Leerling(uniek per student)*"
                ></Input2>
                <Input2
                        
                        v-model="opdracht"
                        type="file"
                        filetype="application/pdf"
                        mode="feedback"
                    ></Input2>
        </div>
        <div class="upload-informatie">
            <div v-if="!geupload" class="selectfile" @click="bevestig" >
                   
                   Bevestigen
           </div>

           <h1 v-if="geupload">Bedankt voor je opdracht, niet <span class="refreshen">refreshen</span>, {{ informatie_docent.opleidingsniveau}} is bezig met je opdracht! </h1>
               <h3 v-if="this.feedback.feedback!='' && this.dowload==true">Referesh als je nog een opdracht wilt uploaden</h3>
               <button v-if="this.feedback.feedback!='' && this.dowload==true" @click="exporteerFeedback" class="exporteer">Exporteer feedback</button>
    
           <div>



  </div>
        </div>


</div>
    


</div>



</div>


</template>

<script>
import Sidebar from '@/components/Dashboard/SidebarLeraar.vue'
import DashboardHeader from '@/components/Dashboard/DashboardHeader.vue'
import DashboardHeading from '@/components/Dashboard/DashboardHeading.vue'
import Input2 from '@/components/Form/BetterInput.vue'
import router from '@/router'
import jsPDF from 'jspdf'
export default{
    data(){
        return {
          dowload: false,
            counter: 1,
            geupload: false,  
            leerling_anoniem: "",
            beoordelingpopup: false,
            buttonText: "Upload opdracht(pdf)",
            buttonColor: "gray",
            doel: "",
            onderwerp: "",
            search: "",
            naamStudent: "",
            verwijderen: false,
            rubric: "",
            opdracht: "",
            beoordelingsformuliernaam: "",
            vak: "",
            moeilijkheid: "",
            omschrijving: "",
            opdracht: null,
            vormvereisten: null,
            showPopup: false,
            selectedItem: null, // Variable to store the selected item index
            selectedItemId: null,
            selectedBeoordeling: "",
            beoordelingtitel: false,
            student_id: "122",
            titel: "",
            taal: "",
            language: "",
            options: {
                talen: {
                    "Engels": "en",
                    "Nederlands": "nl",
                   
                },
                languages: {
                    "English": "en",
                    "Dutch": "nl",
                   
                },
               
            },
            options2: {
                moeilijkheid: {
                    "Simpel": "simple",
                    "Normaal": "normal",
                    "Complex": "advanced",
                },
                moeilijkheid2: {
                    "Simple": "simple",
                    "Normal": "normal",
                    "Advanced": "advanced",
                },
               
            },
            files: [],
            
        }
        
    },
    mounted() {
      document.title = "Eduface | Opdracht link";    },
    components: {Sidebar, DashboardHeader, DashboardHeading, Input2},
    watch: {
    feedback_id(newValue) {
        if (newValue !== "") {
            console.log('Feedback ID is set:', newValue);
            // Perform further actions here based on the new value
        } else {
            console.log('Feedback ID is empty');
            // Handle cases where feedback ID might be empty
        }
    },
    feedback(newValue2) {
        if (newValue2 !== "") {
            console.log('Feedback is set:', newValue2);
            // Perform further actions here based on the new value
        } else {
            console.log('Feedback is empty');
            // Handle cases where feedback ID might be empty
        }
    }
},
     computed: {
      feedback(){
            return this.$store.state.feedbackk
        },
        feedbackk(){
          return this.$store.state.feedback
        },
        toggledSidebar(){
            return this.$store.state.mobileExtend
        },
        informatie_docent(){
            return this.$store.state.beoordelingsformulier
        },
        feedback_id(){
            return this.$store.state.feedback_id
        },
     },
    methods: {
      async exporteerFeedback() {
  const doc = new jsPDF();
  const maxWidth = 190;
  const imageWidth = 50;
  // Initial settings for the image
  const startY = 10; // Starting Y position for the image
  const imageHeight = 16; // Height for the image
  const spaceAfterImage = 10; // Space between the image and text

  // Image URLs or Base64 strings
  const topRightImageUrl = '/assets/Feedbackoranje2.png';
  const bottomLeftImageUrl = '/assets/Feedbackblauw.png';
  const imageUrl = '/assets/Blockbooklogowit.png';

  // Placeholder for image loading logic
  const loadImgData = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  // Load images
  const topRightImgData = await loadImgData(topRightImageUrl);
  const bottomLeftImgData = await loadImgData(bottomLeftImageUrl);
  const imgData = await loadImgData(imageUrl);

  // Function to add images to the background of the current page
  const addBackgroundImages = () => {
  // For the top right image, set x as the page width minus the image width and y to 0 (or close to it for a slight margin)
  doc.addImage(topRightImgData, 'PNG', doc.internal.pageSize.getWidth() - 40, 0, 40, 40); // Adjust size as needed

  // For the bottom left image, position it at the bottom left corner of the page
  doc.addImage(bottomLeftImgData, 'PNG', 0, doc.internal.pageSize.getHeight() - 50, 50, 50); // Adjust size as needed

  // Add the third image as before
  doc.addImage(imgData, 'PNG', 10, startY, imageWidth, imageHeight);
};

  // Initial call to add images to the first page
  addBackgroundImages();

  // Adjust starting Y position for text to be below the non-overlapping image
  let textStartY = startY + imageHeight + spaceAfterImage;

  // Add text and manage pages
  const textLines = doc.splitTextToSize(this.feedback.feedback, maxWidth);

  textLines.forEach((line) => {
    if (textStartY > 280) { // Check if a new page is needed
      doc.addPage();
      addBackgroundImages(); // Add background images to the new page
      textStartY = startY + imageHeight + spaceAfterImage; // Ensure text starts below the image on new pages
    }
    doc.text(line, 10, textStartY);
    textStartY += 10; // Adjust for line height
  });

  // Save the PDF
  doc.save('feedback.pdf');
},
      async fetchFeedback() {
      // Fetch feedback from the backend using your Vuex store dispatch
      if(this.feedback_id==""){
        console.log('waiting')
      }else{
        await this.$store.dispatch("get_feedback_offline", this.feedback_id);
        this.dowload = true
      }
    },
        helloWorld(){
            console.log("test")
        },
        bevestig() {
        if(this.taal == "" && this.language == ""){
        this.$store.commit("show_snackbar", {
                    text: "Kies een taal!",
                    type: "warning"
                })
      }else if(this.leerling_anoniem == ""){
        this.$store.commit("show_snackbar", {
                    text: "Kies een leerling",
                    type: "warning"
                })
      }else if(this.opdracht == null){
        this.$store.commit("show_snackbar", {
                    text: "Kies een opdracht!",
                    type: "warning"
                })
      }
      else if(this.counter == 2){
        this.$store.commit("show_snackbar", {
                    text: "Deze opdracht heb je al geupload, refresh!",
                    type: "warning"
                })
      }
      else{
        this.showPopup = true
        if(this.taal == ""){
            this.$store.dispatch("nieuweFeedback", {
                leerling_anoniem: this.leerling_anoniem,
                titel: this.informatie_docent.titel_rubriek,
                opdracht: this.opdracht,
                beoordelingsformulier_id: this.informatie_docent.beoordelingsformulier_id,
                taal: this.options.languages[this.language],
                onderwerp: this.informatie_docent.titel_rubriek,
                doel: this.informatie_docent.titel_rubriek,
                model: this.informatie_docent.opleidings,
                vak_id: this.$route.params.id
            })
            this.geupload=true
        }else{
            this.$store.dispatch("nieuweFeedbackOffline", {
                leerling_anoniem: this.leerling_anoniem,
                titel: this.informatie_docent.titel_rubriek,
                opdracht: this.opdracht,
                beoordelingsformulier_id: this.informatie_docent.beoordelingsformulier_id,
                taal: this.options.talen[this.taal],
                onderwerp: this.informatie_docent.titel_rubriek,
                doel: this.informatie_docent.titel_rubriek,
                model: this.informatie_docent.opleidings,
                vak_id: this.$route.params.id,
                leraar: this.informatie_docent.id,
            })
            this.geupload=true
            this.leerling_anoniem = ""
            this.opdracht = null
            this.taal = ""
            this.counter = 2
            
        }
            
      }

      
    },
    },
    async created(){
                  // Initial call to fetch feedback

    await this.fetchFeedback();
// Set up interval to periodically fetch feedback
if(this.feedback.feedback==""){


        console.log('feedback call wordt gemaakt')
        this.feedbackInterval = setInterval(async () => {
  await this.fetchFeedback();
}, 10000); // Adjust the interval as needed (currently set to 1 minute)
  
      
  
}
        this.$store.dispatch("get_beoordelingsformulier", {
                id: this.$route.params.id,
                token: this.$route.params.token,

            })



    }
}

</script>



<style scoped>
*,
.student-assistent{

  .refreshen{
        color: #00e075;
      }
    .upload{
        margin-top: 80px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .upload-text{
            display: flex;
            justify-content: center;
        }
        .docent-informatie{
            display: flex;
            justify-content: center;
            font-size: 18px;
        }
        .upload-informatie{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

        }
        .selectfile{

            width: 300px;
            height: 60px;
            background-color: #eeeff8;
            cursor: pointer;
            border-radius: 8px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 20px;
            margin-top: 40px;
            border: 1px solid black;
            margin-bottom: 40px;
        }
    }

    span {
  font-family: 'League Spartan', sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #414042;
}
@media (max-width: 576px) {
  .edHome {
    margin: 0 15px;
  }
}

button {
  margin-top: 0 !important;
}
.mainHeading {
  letter-spacing: 2px;
}
.btn {
  background-color: #00e075 !important;
  box-shadow: none;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 16px;
  padding: 15px 25px;
  height: auto !important;
  color: #000 !important;
}
.loginBtn {
  background: transparent !important;
  color: #000 !important;
}
.btn:hover {
  box-shadow: none;
}


}

</style>




