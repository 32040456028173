<template>
    <div class="feedback-leraar">
        <Popup class="module-popup" :show="verwijderPopup" @close="stopVerwijderen">
            <h2>{{ $t('confirmDeleteAssignment') }}</h2>
            <BetterButton 
                :text="$t('delete')"
                @click="verwijderen(verwijderfeedback)"
            ></BetterButton>
        </Popup>
        <Popup class="module-popup" :show="popup" @close="stopVerwijderen">
            <h2>Let op! Je kan alleen opdrachten die je vandaag geupload hebt opnieuw proberen feedback te genereren. De rest is verwijderd vanwege privacy.</h2>
            <BetterButton 
                :text="'Opnieuw proberen'"
                @click="refreshFeedback(feedbackRefresh)"
            ></BetterButton>
        </Popup>
        <Sidebar mode="teacher" active="Opdrachten"></Sidebar>
        <div class="content" :class="{'mobile-extend': toggledSidebar}">
            <DashboardHeader mode="feedback" @search="filterBooks"></DashboardHeader>
            
            <div class="scrollable-content">
                <div class="nieuwe-feedback">
                    <h2>Gepersonaliseerd inzicht in jouw studenten</h2>
                    
                </div>
                <h3>Studenten met vergelijkbare fouten</h3>
             
                <div>Student group: {{extractStudentGroup(opdracht_pl.student_group)}}</div>
                <div>Student mistakes: {{ extractMistakes(opdracht_pl.student_group)}}</div>
                <br/>
                <h3>Hoe ver zijn de studenten met het behalen van hun leerdoelen</h3>
                <div>{{ cleanGeneratedText(opdracht_pl.learning_progress) }}</div>
                <br/>
                <h2>Gepersonaliseerd leren</h2>
                <h3>Nieuwe opdracht om van de fouten te leren</h3>
                <div>{{ cleanGeneratedText(opdracht_pl.case_pl) }}</div>
                <h3>Beoordelingsformulier zodat {{this.$store.state.onderwijsassistent}} straks weer feedback kan geven </h3>
                <div v-html="cleanAndReplaceSplitCriteria(opdracht_pl.beoordelingsformulier_pl)"></div>

            </div>
            


            
        </div>
    </div>
</template>

<script>
import Sidebar from '@/components/Dashboard/SidebarLeraar.vue'
import DashboardHeader from '@/components/Dashboard/DashboardHeader.vue'
import DashboardHeading from '@/components/Dashboard/DashboardHeading.vue'
import Popup from '@/components/Widgets/Popup.vue'
import BetterButton from '@/components/Widgets/BetterButton.vue'
export default{
    components: {Sidebar, DashboardHeader, DashboardHeading, Popup, BetterButton},
    data () {
        return{
            search: "",
            verwijderfeedback: 0,
            verwijderPopup: false,
            checkFeedback: true,
            feedbackInterval: null,
            isComponentMounted: true,
            uploaden: [false],
            popup: false,
            feedbackRefresh: 0,
        }

    },
    computed: {
        // checkFeedback() {
        //     let allFeedbackNotEmpty = true; // Assume initially that all feedback is not empty
        //     for (let i = 0; i < this.feedback.length; i++) {
        //         if (this.feedback[i].feedback === "") {
        //             allFeedbackNotEmpty = false; // If any feedback is empty, set the flag to false
        //             break; // No need to continue checking, we already found an empty feedback
        //         }
        //     }
        //     this.checkFeedback = !allFeedbackNotEmpty; // Set checkFeedback to true if any feedback is empty, false otherwise
        //     console.log('setting', this.checkFeedback);
        // },

        feedback(){
            return this.$store.state.feedback_leraar
        },
        opdracht_pl(){
            return this.$store.state.opdracht_leraar_pl
        },
        toggledSidebar(){
            return this.$store.state.mobileExtend
        },
        filtered_feedback(){
            const vm = this
            return vm.feedback.filter(feedback => {
                return (
                    
                    (feedback.titel.toLowerCase().includes(vm.search.toLowerCase())) ||
                    (feedback.leerling_anoniem.toLowerCase().includes(vm.search.toLowerCase())) 
                   
                )
            })
        },
        matchingTitel() {
            // Ensure 'feed' is defined and not null before attempting to find an item
            if (this.feed && Array.isArray(this.feed)) {
            const item = this.feed.find(item => item.id === this.verwijderfeedback);
            return item ? item.titel : 'No title found';
            }
            return 'Loading...'; // or return a default value indicating the data is not yet available
        }
        
    },
    async created() {
        await this.$store.dispatch("get_opdracht_leraar_pl", this.$route.params.id2)
    // Initial call to fetch feedback
    await this.fetchFeedback();

    // Set up interval to periodically fetch feedback
    // for(let i=0; i < this.feedback.length; i++){
    //     if(this.feedback[i].feedback==""){
    //         this.feedbackInterval = setInterval(async () => {
    //             if(this.feedback[i].feedback=="" && this.checkFeedback==true){
    //                 await this.fetchFeedback();
    //                 console.log('checken')
    //             }else{
    //                 console.log('stop checken')
    //             }
                
    // }, 10000); // Adjust the interval as needed (currently set to 1 minute)
    //     }
    // }
    // this.feedbackInterval = setInterval(async () => {
    //         if (this.isComponentMounted) {
    //             for (let i = 0; i < this.feedback.length; i++) {
    //                 if (this.feedback[i].feedback === "" && this.checkFeedback === true) {
    //                     await this.fetchFeedback();
    //                     console.log('checken')
    //                     break; // Exit the loop after the first check
    //                 } else {
    //                     console.log('stop checken')
    //                 }
    //             }
    //         }
    //     }, 10000);
   
  },
  beforeDestroy() {
    this.isComponentMounted = false;
        // Clear the interval when the component is destroyed
        clearInterval(this.feedbackInterval);
    },
    watch: {
    feedback: {
        handler: function(newVal, oldVal) {
            this.handleFeedbackChange(newVal);
        },
        deep: true // To watch changes inside the feedback array
    }
},
    methods:{
        cleanAndReplaceSplitCriteria(data) {
  // Step 1: Remove markdown-like formatting tokens ('###', '####', '**', etc.)
  let cleanedText = data.replace(/[#*]+/g, '').trim();
  
  // Step 2: Replace all instances of 'SPLIT_CRITERIA' with '<br/>'
  cleanedText = cleanedText.replace(/SPLIT_CRITERIA/g, '<br/>');
  
  return cleanedText;
},
        cleanGeneratedText(data) {
  // Remove markdown-like formatting tokens ('###', '####', '**', etc.)
  return data.replace(/[#*]+/g, '').trim();
},

        extractMistakes(data) {
  // Match the mistakes text from the JSON string
  let mistakesMatch = data.match(/"mistakes":\s*"([\s\S]*?)"/);
  if (mistakesMatch) {
    // Extract the mistakes text
    let mistakes = mistakesMatch[1];
    // Replace all '\n' characters with actual newline characters
    return mistakes.replace(/\\n/g, '\n');
  }
  return '';
},
        extractStudentGroup(data) {
      let studentGroupMatch = data.match(/"student_group":\s*"([^"]+)"/);
      return studentGroupMatch ? studentGroupMatch[1] : '';
    },
        refreshFeedback1(feedback_id){
            this.popup = true
            this.feedbackRefresh = feedback_id
        },
        async refreshFeedback(feedback_id){
            this.uploaden[feedback_id] = true
        this.showPopup = true
        await this.$store.dispatch("refreshFeedback", {
            "feedback_id": feedback_id,
            "vak_id": this.$route.params.id,

        })

        await this.$store.dispatch("get_feedback_leraar_op", {
                "id": this.$route.params.id,
                "id2": this.$route.params.id2
                    })
this.uploaden[feedback_id] = false
this.popup = false
},
        async handleFeedbackChange(newVal) {
        let allFeedbackNotEmpty = true;
        for (let i = 0; i < newVal.length; i++) {
            if (newVal[i].feedback === "") {
                allFeedbackNotEmpty = false;
                break;
            }
        }
        this.checkFeedback = !allFeedbackNotEmpty;
        console.log('setting', this.checkFeedback);
        if (this.checkFeedback) {
            setTimeout(async () => {
                await this.$store.dispatch("get_feedback_leraar_op", {
                "id": this.$route.params.id,
                "id2": this.$route.params.id2
                    })
    }, 5000);
        }
    },
        async fetchFeedback() {
      // Fetch feedback from the backend using your Vuex store dispatch
      await this.$store.dispatch("get_feedback_leraar_op", {
                "id": this.$route.params.id,
                "id2": this.$route.params.id2
                    })
    },
         verwijderFeedback(feedback_id){
            this.verwijderfeedback = feedback_id
            this.verwijderPopup = true;
          
        },
        stopVerwijderen(){
            this.verwijderPopup = false;
            this.popup = false;
        },
        async verwijderen(feedback_id){
            
    
            await this.$store.dispatch("verwijderFeedback", {feedback_id})
            await this.$store.dispatch("get_feedback_leraar_op", {
                "id": this.$route.params.id,
                "id2": this.$route.params.id2
                    })
            this.verwijderPopup = false;
            this.popup = false
        },
        openFeedback(id){
            console.log('test info')
            this.$router.push({
                name: "op-feedback-overview",
                params: {
                    id2: id,
                    id: this.$route.params.id,
                    id3: this.$route.params.id2,
                }
            })
        },
        openFeedbackEN(id){
            console.log('test info')
            this.$router.push({
                name: "feedback-overview-teacher",
                params: {
                    id: id,
                }
            })
        },
        filterBooks(query){
            
            
            this.search = query
        
        }

    },
    mounted() {
        document.title = "Eduface | Feedback";    },
  
}
</script>

<style lang="scss">
.feedback-leraar{


    .popup{
        display: flex !important;
        align-items: center;
        flex-direction: column;
        text-align: center;

        .invite-code{
            font-size: 30px;
            font-weight: bold;
            color: $base-color;
            margin: 20px 0px;
        }

    }
    .content{
        background-color: #F4F6FC;
        justify-content: space-between;
        position: absolute;
        width: calc(100% - 300px);
        padding: 0px 50px;
        top: 0px;
        left: 200px;
        min-height: 100vh;
}

.scrollable-content {
    overflow-x: auto;
    max-width: 100%; /* Adjust the width as needed */
    /* Other styles for your scrollable content container */
    .nieuwe-feedback{
        display: flex;
    }

    .nieuwe-button{
            margin-left: 20px;
            background-color: $base-color;
            color: #fff;
            border-radius: 20px;
            cursor: pointer;
            &:hover{
                opacity: 0.5;
            }

        }
        .nieuwe{
            padding: 5px 15px;
            background-color: $base-color;
            color: #fff;
            border-radius: 20px;
            cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
        }
}
.informatie{
    color: $base-color;
    cursor: pointer;

    &:hover{
        opacity: 0.8;
    }
}
.table-margin table tbody tr {
        display: block;
        margin-bottom: 10px;
        border-bottom: 1px solid transparent;
    }
    
    .table-margin table tbody tr:last-child {
        margin-bottom: 0; /* Remove margin-bottom for the last row */
    }
.table-wrapper {
    width: 100%;

    
    /* Adjust padding, margin, and other styles as needed */
    
}
.verwijderen{
    color: #004C4C;
    cursor: pointer;
            &:hover{
                opacity: 0.5;
                
            }
    
}
    .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    
  }

    .th, td {
    border: 1px solid #dddddd;
    
    height: 40px;
    width: 400px;
  
  }

  .th {
    background-color: #f2f2f2;
    text-align: left;
  }
  thead th {
    text-align: left;
  }
  

  td {
    text-align: left;
  }

}

@media (max-width: 900px){
    .feedback-leraar{

        /* .content{
            left: 10px;
            padding: 0px 0px;
            width: 100%;
        } */
        .content{
            left: 0px;
            width: calc(100% - 100px);
            .search{
                    display: none;
                }
            &.mobile-extend{
                display: block;
                left: 200px;
                width: calc(100% - 300px);
                

                
            }
            
        }
        
        
    }
    
}

@media (max-width: 600px){
        .content{
            padding: 0px 10px;
            width: calc(100% - 20px);
            &.mobile-extend{
                left: calc(100% - 70px);
                width: 70px;
                .scrollable-content{
                    display: none;
                }
            }

         

            
        }
    }
</style>