<template>
    <div class="login-page">
        <OnboardingPage 
            v-if="loginModus == 'student' && $i18n.locale === 'nl'"
            banner="login-student" 
            title="Welkom terug!"
            bannerText="Inloggen voor" 
            bannerBold="studenten"
        >
        <Form action="inloggen" @submit="login" @keyup.enter="login">
            <div class="top-text">
                <div class="title">{{ $t('loginTitle') }}</div>
                <div class="subtitle">{{ $t('noAccountQuestion') }} <RouterLink :to="{name: 'acstudent'}"><a>{{ $t('register') }}</a></RouterLink></div>
            </div>
            <div class="onboarding-nav">
                <div class="active">{{ $t('student') }}</div>
                <div @click="loginModus = 'uitgever'">{{ $t('publisher') }}</div>
                <div @click="loginModus = 'leraar'">{{ $t('teacher') }}</div>
            </div>
            <Input v-if="$i18n.locale === 'nl'" title="Gebruikersnaam of E-mail" v-model="email"></Input>
            <Input v-if="$i18n.locale === 'nl'" title="Wachtwoord" type="password" v-model="wachtwoord">
                <RouterLink :to="{name: 'wachtwoordVergeten'}"><a>{{ $t('forgotPassword') }}</a></RouterLink>
            </Input>
            <Input v-if="$i18n.locale === 'en'" title="Username or E-mail" v-model="email"></Input>
            <Input v-if="$i18n.locale === 'en'" title="Password" type="password" v-model="wachtwoord">
                <RouterLink :to="{name: 'wachtwoordVergeten'}"><a>{{ $t('forgotPassword') }}</a></RouterLink>
            </Input>
        </Form>
        </OnboardingPage>
        <!-- <OnboardingPage 
            v-if="loginModus == 'student' && $i18n.locale === 'en'"
            banner="login-student" 
            title="Welkom back!"
            bannerText="Login for" 
            bannerBold="students"
        >
        <Form action="inloggen" @submit="login" @keyup.enter="login">
            <div class="top-text">
                <div class="title">{{ $t('loginTitle') }}</div>
                <div class="subtitle">{{ $t('noAccountQuestion') }} <RouterLink :to="{name: 'acstudent'}"><a>{{ $t('register') }}</a></RouterLink></div>
            </div>
            <div class="onboarding-nav">
                <div class="active">{{ $t('student') }}</div>
                <div @click="loginModus = 'uitgever'">{{ $t('publisher') }}</div>
                <div @click="loginModus = 'leraar'">{{ $t('teacher') }}</div>
            </div>
            <Input v-if="$i18n.locale === 'nl'" title="Gebruikersnaam of E-mail" v-model="email"></Input>
            <Input v-if="$i18n.locale === 'nl'" title="Wachtwoord" type="password" v-model="wachtwoord">
                <RouterLink :to="{name: 'wachtwoordVergeten'}"><a>{{ $t('forgotPassword') }}</a></RouterLink>
            </Input>
            <Input v-if="$i18n.locale === 'en'" title="Username or E-mail" v-model="email"></Input>
            <Input v-if="$i18n.locale === 'en'" title="Password" type="password" v-model="wachtwoord">
                <RouterLink :to="{name: 'wachtwoordVergeten'}"><a>{{ $t('forgotPassword') }}</a></RouterLink>
            </Input>
        </Form>
        </OnboardingPage> -->
        <OnboardingPage 
            v-if="loginModus == 'uitgever' && $i18n.locale === 'nl'"
            banner="login-uitgever" 
            title="Welkom terug!"
            bannerText="Inloggen voor" 
            bannerBold="uitgevers"
        >
        <Form action="inloggen" @submit="login" @keyup.enter="login">
            <div class="top-text">
                <div class="title">{{ $t('loginTitle') }}</div>
                <div class="subtitle">{{ $t('noAccountQuestionPublisher') }} <RouterLink :to="{name: 'acuitgever'}"><a>{{ $t('register') }}</a></RouterLink></div>
            </div>
            <div class="onboarding-nav">
                <div @click="loginModus = 'student'">{{ $t('student') }}</div>
                <div class="active">{{ $t('publisher') }}</div>
                <div @click="loginModus = 'leraar'">{{ $t('teacher') }}</div>
            </div>
            <Input v-if="$i18n.locale === 'nl'" title="Bedrijfsnaam of E-mail" v-model="email"></Input>
            <Input v-if="$i18n.locale === 'nl'" title="Wachtwoord" type="password" v-model="wachtwoord">
                <RouterLink :to="{name: 'wachtwoordVergeten'}"><a>{{ $t('forgotPassword') }}</a></RouterLink>
            </Input>
            <Input v-if="$i18n.locale === 'en'" title="Company name or E-mail" v-model="email"></Input>
            <Input v-if="$i18n.locale === 'en'" title="Password" type="password" v-model="wachtwoord">
                <RouterLink :to="{name: 'wachtwoordVergeten'}"><a>{{ $t('forgotPassword') }}</a></RouterLink>
            </Input>
        </Form>
        </OnboardingPage>
        <!-- <OnboardingPage 
            v-if="loginModus == 'uitgever' && $i18n.locale === 'en'"
            banner="login-uitgever" 
            title="Welkom back!"
            bannerText="Login for" 
            bannerBold="publishers"
        >
        <Form action="inloggen" @submit="login" @keyup.enter="login">
            <div class="top-text">
                <div class="title">{{ $t('loginTitle') }}</div>
                <div class="subtitle">{{ $t('noAccountQuestionPublisher') }} <RouterLink :to="{name: 'acuitgever'}"><a>{{ $t('register') }}</a></RouterLink></div>
            </div>
            <div class="onboarding-nav">
                <div @click="loginModus = 'student'">{{ $t('student') }}</div>
                <div class="active">{{ $t('publisher') }}</div>
                <div @click="loginModus = 'leraar'">{{ $t('teacher') }}</div>
            </div>
            <Input v-if="$i18n.locale === 'nl'" title="Bedrijfsnaam of E-mail" v-model="email"></Input>
            <Input v-if="$i18n.locale === 'nl'" title="Wachtwoord" type="password" v-model="wachtwoord">
                <RouterLink :to="{name: 'wachtwoordVergeten'}"><a>{{ $t('forgotPassword') }}</a></RouterLink>
            </Input>
            <Input v-if="$i18n.locale === 'en'" title="Company name or E-mail" v-model="email"></Input>
            <Input v-if="$i18n.locale === 'en'" title="Password" type="password" v-model="wachtwoord">
                <RouterLink :to="{name: 'wachtwoordVergeten'}"><a>{{ $t('forgotPassword') }}</a></RouterLink>
            </Input>
        </Form>
        </OnboardingPage> -->
        <OnboardingPage 
            v-if="loginModus == 'leraar' && $i18n.locale === 'nl'"
            banner="login-leraar" 
            title="Welkom terug!"
            bannerText="Inloggen voor" 
            bannerBold="leraren"
        >
        <Form action="inloggen" @submit="login" @keyup.enter="login">
            <div class="top-text">
                <div class="title">{{ $t('loginTitle') }}</div>
                <div class="subtitle">{{ $t('noAccountQuestion') }} <RouterLink :to="{name: 'acleraar'}"><a>{{ $t('register') }}</a></RouterLink></div>
            </div>
            <div class="onboarding-nav">
                <div @click="loginModus = 'student'">{{ $t('student') }}</div>
                <div @click="loginModus = 'uitgever'">{{ $t('publisher') }}</div>
                <div class="active">{{ $t('teacher') }}</div>
            </div>
            <Input v-if="$i18n.locale === 'nl'" title="Gebruikersnaam of E-mail" v-model="email"></Input>
            <Input v-if="$i18n.locale === 'nl'" title="Wachtwoord" type="password" v-model="wachtwoord">
                <RouterLink :to="{name: 'wachtwoordVergeten'}"><a>{{ $t('forgotPassword') }}</a></RouterLink>
            </Input>
            <Input v-if="$i18n.locale === 'en'" title="Username or E-mail" v-model="email"></Input>
            <Input v-if="$i18n.locale === 'en'" title="Password" type="password" v-model="wachtwoord">
                <RouterLink :to="{name: 'wachtwoordVergeten'}"><a>{{ $t('forgotPassword') }}</a></RouterLink>
            </Input>
        </Form>
        </OnboardingPage>
        <OnboardingPage 
            v-if="loginModus == 'leraar' && $i18n.locale === 'en'"
            banner="login-leraar" 
            title="Welkom back!"
            bannerText="Login for" 
            bannerBold="Teachers"
        >
        <Form action="inloggen" @submit="loginEn" @keyup.enter="loginEn">
            <div class="top-text">
                <div class="title">{{ $t('loginTitle') }}</div>
                <div class="subtitle">{{ $t('noAccountQuestion') }} <RouterLink :to="{name: 'acteacher'}"><a>{{ $t('register') }}</a></RouterLink></div>
            </div>
            <div class="onboarding-nav">
                
                <div class="active">{{ $t('teacher') }}</div>
            </div>
            <Input v-if="$i18n.locale === 'nl'" title="Gebruikersnaam of E-mail" v-model="email"></Input>
            <Input v-if="$i18n.locale === 'nl'" title="Wachtwoord" type="password" v-model="wachtwoord">
                <RouterLink :to="{name: 'wachtwoordVergeten'}"><a>{{ $t('forgotPassword') }}</a></RouterLink>
            </Input>
            <Input v-if="$i18n.locale === 'en'" title="Username or E-mail" v-model="email"></Input>
            <Input v-if="$i18n.locale === 'en'" title="Password" type="password" v-model="wachtwoord">
                <RouterLink :to="{name: 'forgotPassword'}"><a>{{ $t('forgotPassword') }}</a></RouterLink>
            </Input>
        </Form>
        </OnboardingPage>
    </div>
</template>
<script>
import OnboardingPage from '@/components/Onboarding/OnboardingPage.vue';
import Form from '@/components/Form/Form.vue'
import Input from '@/components/Form/BetterInput.vue'
export default{
    components: {OnboardingPage, Form, Input},
    props: ["redirect"],
    data(){
        return {
            email: "",
            wachtwoord: "",
            loginModus: "leraar",
        }
    },
    methods: {
        async login(){
            await this.$store.dispatch("login",{
                password: this.wachtwoord,
                email: this.email,
                redirect: this.redirect,
                loginType: this.loginModus
            }).then(success => {
                this.email = ""
                this.wachtwoord = ""
            })

        },
        async loginEn(){
            await this.$store.dispatch("login",{
                password: this.wachtwoord,
                email: this.email,
                redirect: this.redirect,
                loginType: this.loginModus,
                language: "en"
            }).then(success => {
                this.email = ""
                this.wachtwoord = ""
            })

        },
    }
}
</script>
<style lang="scss">
.login-page{
    h1{
        color: $base-color;
    }

    .title{
        font-family: "Roboto";
        font-weight: 700;
        font-size: 35px;
        line-height: 56px;
    }

    .top-text{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .onboarding-nav{
        display: flex;
        align-items: center;
        margin-bottom: 70px;
        margin-top: 10px;

        div{
            margin-right: 10px;
            text-decoration: underline;
            cursor: pointer;
            &:hover{
                opacity: 0.6;
            }

            &.active{
                text-decoration: none;
                font-weight: bold;
                cursor: default;

                &:hover{
                    opacity: 1;
                }
            }
        }
    }
}


</style>