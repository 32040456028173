<template>
    <div ref="popup" @click="closePopup" class="popup-backdrop" :class="{'show': show}">
        <div class="popup">
            <slot></slot>
        </div>
        
    </div>
</template>


<script>
export default{
    props: ["show"],
    methods: {
        closePopup(e){
            const vm = this
            if(e.target == this.$refs['popup']){
                setTimeout(() => vm.$emit("close"), 100)
                
            }
            
        },
        cancelEvent(event){
            event.stopPropagation();
        }
    }
}
</script>


<style lang="scss">
.popup-backdrop{
    display: none;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(0,0,0,0.8);
    z-index: 100;

    &.show{
        display: flex;
    }

    .popup{
        width: 400px;
        border-radius: 8px;
        background: #fff;
        padding: 40px;

        

    }

    

}
</style>