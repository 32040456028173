<template>
    <div class="nieuw-beoordelingsfprmulier-page">
        <Popup class="module-popup" :show="showFinalPopup" @close="afronden()">
            <h2>{{ $t('assessmentFormAddedSuccess') }}</h2>
            <BetterButton 
                :text="$t('complete')"
                @click="afronden"
            ></BetterButton>
        </Popup>
        <Popup class="module-popup" :show="showWaitPopup">
            <h2>{{ $t('pleaseWait') }}</h2>
            <p>{{ $t('processingAssessmentForm') }}</p>
            <p>{{ $t('thisMayTakeAWhile') }}</p>
        </Popup>
        <Sidebar mode="teacher" active="Feedback"></Sidebar>
        <div class="content" :class="{'mobile-extend': toggledSidebar}">
            <DashboardHeader mode="module"  :hideSearch="true"></DashboardHeader>
            
            <div class="scrollable-content" v-if="$i18n.locale === 'nl'">              
                
                <DashboardHeading>Voeg een nieuw beoordelingsformulier toe</DashboardHeading>
                <Input
                    title="Beoordelingsformulier titel"
                    v-model="beoordelingsformuliernaam"
                ></Input>
           

                <div>
                    <Input
                        :title="'Beoordelingsformulier'"
                        v-model="beoordelingsformulier"
                        type="file"
                        hint="upload hier jouw beoordelingsformulier"
                        filetype="application/pdf"
                    ></Input>
                </div>
                
                
                
                <div @click="bevestig" class="beoordelingklaar" v-if="beoordelingsformuliernaam==''  || beoordelingsformulier==null">
                    Bevestigen
                </div>
                <BetterButton v-if="beoordelingsformuliernaam!=''  && beoordelingsformulier!=null"
                    text="Bevestigen"
                    @click="bevestig"
                ></BetterButton>

               
            </div>
            <div class="scrollable-content" v-if="$i18n.locale === 'en'">              
                
                <DashboardHeading>Add new rubric</DashboardHeading>
                <Input
                    title="Rubric title"
                    v-model="rubricname"
                ></Input>
        

                <div>
                    <Input
                        :title="'Rubric'"
                        v-model="beoordelingsformulier"
                        type="file"
                        hint="upload your rubric here"
                        filetype="application/pdf"
                    ></Input>
                </div>
                
                
                
                <div @click="bevestig" class="beoordelingklaar" v-if="rubricname=='' && $i18n.locale === 'en' || beoordelingsformulieropleiding==''  && $i18n.locale === 'en' || beoordelingsformulier==null  && $i18n.locale === 'en'">
                    Confirm
                </div>
                <div @click="bevestig" class="beoordelingklaar" v-if="beoordelingsformuliernaam=='' && $i18n.locale === 'nl' || beoordelingsformulieropleiding==''  && $i18n.locale === 'nl' || beoordelingsformulier==null  && $i18n.locale === 'nl'">
                    Bevestigen
                </div>
    
                <BetterButton v-if="beoordelingsformuliernaam!='' && beoordelingsformulieropleiding!='' && beoordelingsformulier!=null && $i18n.locale === 'nl'"
                    text="Bevestigen"
                    @click="bevestig"
                ></BetterButton>
                <BetterButton v-if="rubricname!='' && beoordelingsformulieropleiding!='' && beoordelingsformulier!=null && $i18n.locale === 'en'"
                    text="Confirm"
                    @click="bevestig"
                ></BetterButton>
               
            </div>
            


            
        </div>
        
    </div>
</template>

<script>
import Sidebar from '@/components/Dashboard/SidebarLeraar.vue'
import Input from '@/components/Form/BetterInput.vue'
import DashboardHeader from '@/components/Dashboard/DashboardHeader.vue'
import DashboardHeading from '@/components/Dashboard/DashboardHeading.vue'
import BetterButton from '@/components/Widgets/BetterButton.vue'
import ModuleWeekToets from './ModuleWeekToets.vue'
import Popup from '@/components/Widgets/Popup.vue'



export default{
    components: {DashboardHeading, DashboardHeader, Sidebar, Input, BetterButton, Popup},
    data(){
        return {
            beoordelingsformuliernaam: "",
            rubricname: "",
            beoordelingsformulieropleiding: "",
            beoordelingsformulier: null,
            showPopup: false,
        }
    },
    computed: {
        toggledSidebar(){
            return this.$store.state.mobileExtend
        },
        showFinalPopup(){
            return this.$store.state.popup
        },
        showWaitPopup(){
            return this.showPopup && !this.$store.state.popup
        },
        
    },
    methods: {
        closePopup(){
            this.$store.commit("showPopup", false)
        },
        bevestig(){
            if (this.beoordelingsformulier == null) {
                this.$store.commit("show_snackbar", {
                    text: "Geen beoordelingsformulier geselecteerd!",
                    type: "warning"
                })
            }else if(this.beoordelingsformuliernaam == "" && this.rubricname == ""){
                this.$store.commit("show_snackbar", {
                    text: "Kies een naam voor het beoordelingsformulier!",
                    type: "warning"
                })
            }else{
                this.showPopup = true
                if(this.beoordelingsformuliernaam == ""){
                    this.$store.dispatch("nieuwBeoordelingsformulier", {
                    beoordelingsformuliernaam: this.rubricname,
                    beoordelingsformulier: this.beoordelingsformulier,
                    vak_id: this.$route.params.id,
                })
                }else{
                    this.$store.dispatch("nieuwBeoordelingsformulier", {
                    beoordelingsformuliernaam: this.beoordelingsformuliernaam,
                    beoordelingsformulier: this.beoordelingsformulier,
                    vak_id: this.$route.params.id,
                })
                }
                
            }
            
        },
       
        afronden(){
            this.$store.commit("showPopup", false)
            if(this.beoordelingsformuliernaam == ""){
                this.$router.push({"name": "feedback-module-en"})
            }else{
                this.$router.push({"name": "feedback-module"})
            }
            
        }
    }
}
</script>

<style lang="scss">
.nieuw-beoordelingsfprmulier-page{

    .content{
        height: 800px;
        background-color: #F4F6FC;
        justify-content: space-between;
        position: absolute;
        width: calc(100% - 300px);
        padding: 0px 50px;
        top: 0px;
        left: 200px;
        height: 100vh;

        

        
    }
    .beoordelingklaar{
        background-color: gray;
        width: 200px;
       
        border-radius: 4px;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 5px 20px;
    }

    .extra-week{
        display: block;
        margin-bottom: 20px;
        margin-right: 20px;
    }

    .better-button{
        margin-bottom: 40px;
    }

    .slides-options{
        display: flex;
    }

    .popup{
        display: flex !important;
        align-items: center;
        flex-direction: column;
        text-align: center;

        .invite-code{
            font-size: 30px;
            font-weight: bold;
            color: $base-color;
            margin: 20px 0px;
        }

    }
    

    @media (max-width: 900px){
        .content{
            left: 0px;
            width: calc(100% - 100px);
            &.mobile-extend{
                display: block;
                left: 200px;
                width: calc(100% - 300px);

                .search{
                    display: none;
                }
            }
        }
    }

    @media (max-width: 600px){
        .content{
            padding: 0px 10px;
            width: calc(100% - 20px);
            &.mobile-extend{
                left: calc(100% - 70px);
                width: 70px;
                .scrollable-content{
                    display: none;
                }
            }

            
        }
    }
}
</style>
