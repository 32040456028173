<template>
    <div class="account-leraar">
        <div v-if="stap==1" @keyup.enter="verder1">
            <div class="inlog-header" >
                <div class="inlog-options">1. <span class="line-through">Account</span></div>
                <div class="inlog-options "><span class="optie-benik">2. E-mail</span></div>
                <div class="inlog-options">3. Personaliseer</div>

            </div>



            <div class="login-information">
                <div class="foto-kant">
                    <img src="/assets/registreren.png"  class="plaatje-inlog" />
                </div>
                <div class="informatie-kant" @keyup.enter="verder1">
              
                    <Input 
                        
                        v-model="Email"
                        type="textsmall"
                        mode="login"
                        tekst="E-mail"
                    ></Input>
                    <Input title="Wachtwoord" type="passwordd" mode="feedback" v-model="password"></Input>


                    <BetterButton @click="verder1" class="button">Verder</BetterButton>
                </div>
            </div>
            <div class="percentage">
                    <h2 @click="$router.push({name: 'log-in'})" class="naarAccount">Al een account? Klik <span class="groenredirect">hier</span> om naar de inlog te gaan</h2>
                    <img src="/assets/33button.png"  class="progress" />
                
            </div>
        </div>

        <div v-if="stap==2">
            <div class="inlog-header" >
                <div class="inlog-options">1. <span class="line-through" >Account</span></div>
                <div class="inlog-options "><span class="line-through">2. E-mail</span></div>
                <div class="inlog-options"><span class="optie-benik">3. Personaliseer</span></div>
          
            </div>



            <div class="login-information">
                <div class="foto-kant">
                    <img src="/assets/registreren1.png"  class="plaatje-inlog" />
                </div>
                <div class="informatie-kant" @keyup.enter="verder">
            
                    <Input 
                        
                        v-model="Naam"
                        type="textsmall"
                        mode="login"
                        tekst="Naam"
                    ></Input>
                    <Input class="input"
                    
                    title="Onderwijsniveau"
                    v-model="onderwijsniveau"
                    type="select"
                    :options="options.scholen"
                    mode="feedback"
                   
                ></Input>
                    <Input 
                        
                        v-model="Studie"
                        type="textsmall"
                        mode="login"
                        tekst="Studie"
                    ></Input>
                    <Input 
                        
                        v-model="School"
                        type="textsmall"
                        mode="login"
                        tekst="School"
                    ></Input>

                    <BetterButton @click="verder" class="button">Verder</BetterButton>
                </div>
            </div>
            <div class="percentage">
                
                    <img src="/assets/67button.png"  class="progress" />
                
            </div>
        </div>
        <div v-if="stap==3">
            <div class="inlog-header" >
                <div class="inlog-options">1. <span class="line-through">Account</span></div>
                <div class="inlog-options "><span class="line-through">2. E-mail</span></div>
                <div class="inlog-options"><span class="line-through">3. Personaliseer</span></div>
          
            </div>



            <div class="login-information">
                <div class="onsgevonden">
                    <div class="gevonden">Hoe heb jij jouw nieuwe <span class="blue">onderwijs</span><span class="oranje">assistent</span> gevonden?</div>
                    
                    <div 
                        class="option" 
                        v-for="(option, index) in opties" 
                        :key="index" 
                        @click="toggleOption(option)"
                        :class="{ 'selected': isSelected(option) }"
                    >
                        {{ option }}
                    </div>
                    
             
                    <BetterButton v-if="!inlog" @click="naarDashboard" class="button">Naar dashboard</BetterButton>
                    <v-progress-circular
      v-if="inlog"
      indeterminate

      class="loading-spinner"
    ></v-progress-circular>
                </div>
                
            </div>
            <div class="percentage">
                
                  
                    
            </div>
        </div>
        
        
    </div>
</template>
<script>
import OnboardingPage from '@/components/Onboarding/OnboardingPage.vue'
import Form from '@/components/Form/Form.vue'
import Input from '@/components/Form/BetterInput.vue'
import BetterButton from "@/components/Widgets/BetterButton.vue"
export default{
    components: {OnboardingPage, Form, Input, BetterButton},
    data(){
        return {
            inlog: false,
            password: "",
            herhaalPassword: "",
            Email: "",
            Naam: "",
            Studie: "",
            School: "",
            onderwijsniveau: "",
            username: "",
            email: "",
            password: "",
            herhaalPassword: "",
            success: false,
            button: false,
            failed: false,
            stap: 1,
            options: {
                talen: {
                    "Engels": "en",
                    "Nederlands": "nl",
                   
                },
                scholen: {
                    "MBO": "mbo",
                    "HBO": "hbo",
                    "WO": "uni",
                   
                },
               
            },
            selectedOption: '',
            opties: ["Collega's", "Google", "LinkedIn", "Instagram", "Anders"],
            selectedIndex: null,    
            selectedOptions: [],

        }
    },
    async created(){
        await this.$store.dispatch("check_login")
       
    },
    methods: {
        async naarDashboard(){
            this.inlog = true
            if (this.selectedOptions.length === 0) {
            this.$store.commit("show_snackbar", {
                text: "Kies minimaal 1 optie.",
                type: "warning"
            });                                         
        }else{
            await this.$store.dispatch("signupPersonalised",{
                    email: this.Email,
                    password: this.password,
                    naam: this.Naam,
                    role: "leraar",
                    onderwijsniveau: this.options.scholen[this.onderwijsniveau],
                    studie: this.Studie,
                    school: this.School,
                    gevonden: this.selectedOptions,

                })
        }
        },
        verder1(){
            if (this.Email == "") {
            this.$store.commit("show_snackbar", {
                        text: "Vul je email in.",
                        type: "warning"
                    })
                    
        }if (this.password == "") {
            this.$store.commit("show_snackbar", {
                        text: "Vul je wachtwoord in.",
                        type: "warning"
                    })
                    
        }else{
       
            this.stap = 2

        
        }
        },
        verder(){
            if(this.Naam == "" && this.stap == 2){
            this.$store.commit("show_snackbar", {
                        text: "Vul je naam in.",
                        type: "warning"
                    }) 

        }
        else if(this.onderwijsniveau == "" && this.stap == 2){
            this.$store.commit("show_snackbar", {
                        text: "Vul je onderwijsnivea in.",
                        type: "warning"
                    }) 

        }
        else if(this.Studie == "" && this.stap == 2){
            this.$store.commit("show_snackbar", {
                        text: "Vul je studie in.",
                        type: "warning"
                    }) 

        }
        else if(this.School == "" && this.stap == 2){
            this.$store.commit("show_snackbar", {
                        text: "Vul je school in.",
                        type: "warning"
                    }) 

        }else if(this.stap==2){
            this.stap = 3

        }
        },
        toggleOption(option) {
        if (this.isSelected(option)) {
            // If option is already selected, remove it from the array
            this.selectedOptions.splice(this.selectedOptions.indexOf(option), 1);
        } else {
            // If option is not selected, add it to the array
            this.selectedOptions.push(option);
        }
        console.log("Selected options:", this.selectedOptions);
    },
    isSelected(option) {
        // Check if the option is selected by checking if it exists in the array
        return this.selectedOptions.includes(option);
    },
        buttonPress(){
            this.button = true
        },
        async signupEN(){
            this.button = true
            if(this.password==this.herhaalPassword){
                
                await this.$store.dispatch("signup",{
                    username: this.username,
                    password: this.password,
                    email: this.email,
                    role: "leraar",
                    language: "en"
                })
                
                this.success = this.$store.state.signup_success
                this.failed = this.$store.state.signup_failed
                if(this.failed){
                    this.button = false
                }
            }else{
                this.$store.commit("show_snackbar", {
                    text: "De wachtwoorden matchen niet.",
                    type: "warning"
                })
                this.password = ""
                this.herhaalPassword = ""
                this.button = false
            }
            
        },
        async signup(){
            this.button = true
            if(this.password==this.herhaalPassword){
                await this.$store.dispatch("signup",{
                    username: this.username,
                    password: this.password,
                    email: this.email,
                    role: "leraar",
                })
                this.success = this.$store.state.signup_success
            }else{
                this.$store.commit("show_snackbar", {
                    text: "De wachtwoorden matchen niet.",
                    type: "warning"
                })
                this.password = ""
                this.herhaalPassword = ""
                this.button = false
            }
            
        }
    },
    mounted() {
        document.title = "Eduface | Registreren";    },
  
}
</script>
<style lang="scss">
.account-leraar{

    .inlog-header{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        font-size: 25px;

        .inlog-options{
            margin: 0px 30px;
         

            .optie-benik{
                font-weight: bold;
            }

            .line-through{
                text-decoration: line-through;
            }
        }
        
    }

    .login-information {
  display: flex;
  justify-content: center;
  height: 550px;


  .onsgevonden{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .button{
    margin-top: 40px;
    align-items: center;
    text-align: center;
    width: 200px;
    height: 45px;
    
  }

    .option {
    border: 2px solid black;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    width: 200px;
    height: 30px;
    font-size: 25px;
    border-radius: 40px;
    text-align: center;
    align-items: center;
  }
  .selected {
    border-color: $secondary-color;
  }

    .gevonden{
    font-size: 30px;
    font-weight: bold;
    margin-top: 40px;

    .blue{
        color: $base-color;

    }
    .oranje{
        color: $secondary-color;
    }

  }
  }
  
}

.foto-kant {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .plaatje-inlog{
    width: 600px;
  }
}

.informatie-kant {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;


  flex-direction: column;

  .input{
    margin-left: -30px;
  }
  .button{
    margin-top: 40px;
    align-items: center;
    text-align: center;
    width: 200px;
    height: 45px;
    
  }
}
.percentage{
    display: flex;
    flex-direction: column;
        align-items: center;
        justify-content: center;

        .naarAccount{
            cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
            .groenredirect{
                color: $secondary-color;
            }
        }

        .progress{
            width: 300px;
        }
}
    h1{
        color: $base-color;
    }

    .next-content{
        height: calc(100% - 80px);
        padding: 40px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .normal-title{
            font-family: "Roboto";
            font-weight: 700;
            font-size: 25px;
        }

        .normal-subtitle{
            font-family: "Roboto";
            font-size: 20px;
        }

        .normal-tip{
            font-family: "Roboto";
            font-size: 15px;
            line-height: 60px;
        }

        .row2{
            display: flex;

            img{
                width: 50%;
            }
        }
    }

    .title{
        font-family: "Roboto";
        font-weight: 700;
        font-size: 35px;
        line-height: 56px;
    }

    

    .top-text{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .onboarding-nav{
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        margin-top: 10px;

        div{
            margin-right: 10px;
            text-decoration: underline;
            cursor: pointer;
            color: #000;
            &:hover{
                opacity: 0.6;
            }

            &.active{
                text-decoration: none;
                font-weight: bold;
                cursor: default;

                &:hover{
                    opacity: 1;
                }
            }
        }
    }
}
@media (max-width: 900px){
    .account-leraar{
        .inlog-header{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        font-size: 15px;

        .inlog-options{
            margin: 0px 30px;
         

            .optie-benik{
                font-weight: bold;
            }

            .line-through{
                text-decoration: line-through;
            }
        }
        
    }

    .login-information {
  display: flex;
  justify-content: center;
  height: 550px;


  .onsgevonden{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .button{
    margin-top: 40px;
    align-items: center;
    text-align: center;
    width: 200px;
    height: 45px;
    
  }

    .option {
    border: 2px solid black;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    width: 200px;
    height: 30px;
    font-size: 25px;
    border-radius: 40px;
    text-align: center;
    align-items: center;
  }
  .selected {
    border-color: orange;
  }

    .gevonden{
    font-size: 30px;
    font-weight: bold;
    margin-top: 40px;

    .blue{
        color: $base-color;

    }
    .oranje{
        color: $secondary-color;
    }

  }
  }
  
}

.foto-kant {
display: none;
}

.informatie-kant {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;


  flex-direction: column;

  .input{
    margin-left: -30px;
  }
  .button{
    margin-top: 40px;
    align-items: center;
    text-align: center;
    width: 100px;
    height: 45px;
    
  }
}
.percentage{
    display: flex;
        align-items: center;
        justify-content: center;

        .progress{
            width: 200px;
        }
}
    }

    }

    @media (max-width: 600px){
        .account-leraar{
        .inlog-header{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        font-size: 15px;

        .inlog-options{
            margin: 0px 30px;
         

            .optie-benik{
                font-weight: bold;
            }

            .line-through{
                text-decoration: line-through;
            }
        }
        
    }

    .login-information {
  display: flex;
  justify-content: center;
  height: 550px;


  .onsgevonden{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .button{
    margin-top: 40px;
    align-items: center;
    text-align: center;
    width: 200px;
    height: 45px;
    
  }

    .option {
    border: 2px solid black;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    width: 200px;
    height: 30px;
    font-size: 25px;
    border-radius: 40px;
    text-align: center;
    align-items: center;
  }
  .selected {
    border-color: orange;
  }

    .gevonden{
    font-size: 30px;
    font-weight: bold;
    margin-top: 40px;

    .blue{
        color: $base-color;

    }
    .oranje{
        color: $secondary-color;
    }

  }
  }
  
}

.foto-kant {
display: none;
}

.informatie-kant {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;


  flex-direction: column;

  .input{
    margin-left: -30px;
  }
  .button{
    margin-top: 40px;
    align-items: center;
    text-align: center;
    width: 100px;
    height: 45px;
    
  }
}
.percentage{
    display: flex;
        align-items: center;
        justify-content: center;

        .progress{
            width: 200px;
        }
}
    }
    }
</style>