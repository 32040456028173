<template>
    <div v-if="mode!='genereren'"
        class="cover-module"
        :style="'background: url('+ cover + '); background-size: cover; background-position: center;'"
    >    
    </div>
    <div class="cover-module" v-else style="position: relative; width: 100%; height: 100%;">
    <img src="/assets/75button.png" style="width: 100%; height: 100%; object-fit: cover; object-position: center;" />

</div>
    
</template>
<script>
export default{
    props: ["cover", "logo", "mode"]
}

</script>

<style lang="scss">

.cover-module{
    position: relative;
    width: 20px;
    height: 180px;
    // margin-left: 15px;
    border-radius: 16px;

    
}

</style>