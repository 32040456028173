<template >
    <div class="brightspace-authenticatie">
      <div class="content" v-if="!bezig">
        <h1>De onderwijsassistenten van Eduface staan klaar om aan de slag te gaan met uw taak</h1>
        <h2>Bezig met authenticatie...</h2>
        <v-progress-circular
            indeterminate
            class="loading-spinner"
          ></v-progress-circular>
        </div>   
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        authCode: null,  // Authorization code extracted from the URL
        accessToken: null,
        auth_id: null, // Store the access token obtained from the API
      };
    },
    created() {
      this.extractAuthCode(); // Extract the code when the component is created
    },
    methods: {
      extractAuthCode() {
        // Create a new URL object from the current window location
        const url = new URL(window.location.href);
        // Extract the 'code' parameter from the URL
        this.authCode = url.searchParams.get('code');
  
        // Print the code to the console
        console.log("Authorization Code:", this.authCode);
  
        // If the auth code is available, proceed to fetch the token
        if (this.authCode) {
          this.fetchAccessToken();
        }
      },
      async fetchAccessToken() {
        const url = 'https://auth.brightspace.com/core/connect/token';
        const params = new URLSearchParams();
  
        // Set the parameters for the POST request
        params.append('grant_type', 'authorization_code');
        params.append('code', this.authCode);
        params.append('client_id', '14f8257c-b91b-495a-8d1b-6d3a49c50d55');
        params.append('redirect_uri', 'https://eduface.nl/auth/');
        params.append('client_secret', '7kHO4OdCWn0cBxaMRXkxKU7JovBw6KR0aD02m7FNMjY');  // Replace with your actual client_secret
  
        try {
          // Send the POST request to obtain the access token
          const response = await axios.post(url, params);
  
          // Store the access token in the data property
          this.accessToken = response.data.access_token;
  
          // Log the access token
          console.log("Access Token:", this.accessToken);
          
  try {
    // Retrieve the authorization token from the route parameters
   
    if (this.accessToken) {
      // Set up the config for the Axios request, including the Authorization header
      const config = {
        headers: {
          'Authorization': `Bearer ${this.accessToken}` // Include the Bearer token in the headers
        }
      };

      // Make the GET request to the API to get user information
      const response = await axios.get('https://eduface.d2l-partners.brightspace.com/d2l/api/lp/1.28/users/whoami', config);

      // Extract the Identifier from the response data
      const userId = response.data.Identifier;
      console.log('User Identifier:', userId);

      try {
    // Wait for the store action to complete
    const success = await this.$store.dispatch("newAuthentication", {
      user_id: userId,
      token: this.accessToken,
    });

    if (success) {
      this.auth_id = this.$store.state.auth_id
      this.$router.push(`/brightspace/feedbacks/${this.auth_id}`);
    } else {
      this.$store.commit("show_snackbar", {
        text: "Something went wrong, go back to brightspace and try again!",
        type: "warning"
      });
    }

  } catch (error) {
    console.error('Error storing user info:', error);
    // Handle the error as needed
    this.$store.commit("show_snackbar", {
      text: "Error storing user info. Please try again later.",
      type: "error"
    });
  }
    } else {
      console.error('No token found in route parameters.');
    }
  } catch (error) {
    console.error('Error fetching user information:', error);
  }

        } catch (error) {
          console.error('Error fetching access token:', error);
        }
      }
    }
  };
  </script>
<style lang="scss">
.brightspace-authenticatie{
  .content {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers content vertically */
  align-items: center; /* Centers content horizontally */
  text-align: center;
  min-height: 100vh; /* Ensures the content takes up the full height of the viewport */
  padding: 0 50px;
  margin: 0 auto; /* Centers the content horizontally */
}
}
</style>