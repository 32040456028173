<template>
    <div class="module-informatie">
        <div class="module-aanpassen" @click="bewerken()">
            
            <NavItem  @click="$router.go(-1)" class="webshop-link" :inverted="true" >
                <img src="/assets/arrowback_blauw.svg" />
                </NavItem>  
                    
           

                <button v-if="$store.state.role=='leraar' && !bewerkModus && informatie[0].taal=='nl'" class="aanpassen" @click="bewerken()">Stof aanpassen</button>
                <button v-if="$store.state.role=='leraar' && bewerkModus && informatie[0].taal=='nl'" class="aanpassen" @click="annuleerBewerken()">Aanpassing annuleren</button>
                <button v-if="$store.state.role=='leraar' && bewerkModus && informatie[0].taal=='nl'" class="aanpassen" @click="slaOpBewerken()">Aanpassing opslaan</button>
                <button v-if="$store.state.role=='leraar' && !bewerkModus && informatie[0].taal=='en'" class="aanpassen" @click="bewerken()">Adjust content</button>
                <button v-if="$store.state.role=='leraar' && bewerkModus && informatie[0].taal=='en'" class="aanpassen" @click="annuleerBewerken()">Cancel adjusting</button>
                <button v-if="$store.state.role=='leraar' && bewerkModus && informatie[0].taal=='en'" class="aanpassen" @click="slaOpBewerken()">Save adjustment</button>
        </div>
        
        <!-- <div class="pdf">
            <div>
                
                <label for="epub_file" class="btn">Select pdf</label>
                <input id="epub_file" type="file" accept=".pdf" @change="onEpubPicked" ref="pdfInput" />
            </div>
            <div @click="submit_formulier()" v-if="epub_attached" class="bevestigen">Bevestigen</div>
        
        </div> -->
        <div class="overview">
            
            <ZijMenu :id="this.$route.params.id" :vak="this.$route.params.vak" :taal="informatie[0].taal"></ZijMenu>
        
            <div class="mainBody">
                <div class="contentBody shadow p-5">
                    <h1>{{ this.$route.params.vak}}</h1>
                    <h1 v-if="informatie[0].taal=='nl'" class="c-black">Week {{this.$route.params.weeknummer}} stof </h1>
                    <h1 v-if="informatie[0].taal=='en'" class="c-black">Week {{this.$route.params.weeknummer}} content </h1>

                    <div v-if="bewerkModus" class="toevoegen last-input" @click="addAt(0)">
                        <div class="line"></div>
                        <div class="plus">+</div>
                    </div>
                    
                    <div v-for="(item, index) in informatie" :key="index">
                        <span class="order-button" style="font-weight: bold" v-if="bewerkModus">{{index+1 }}  </span>
                        <a 
                            v-if="bewerkModus && informatie.length != 1" 
                            class="order-button" 
                            href="javascript:void(0)"
                            @click="verwijderen(index)"
                        >{{ $t('delete') }} </a>
                        <a 
                            v-if="bewerkModus && index != informatie.length - 1" 
                            class="order-button" 
                            href="javascript:void(0)"
                            @click="move(index, 'down')"
                        >{{ $t('moveDown') }} </a>
                        <a 
                            v-if="bewerkModus && index != 0" 
                            class="order-button" 
                            href="javascript:void(0)"
                            @click="move(index, 'up')"
                        >{{ $t('moveUp') }} </a>
                        <Input
                            v-if="bewerkModus && $i18n.locale === 'en'"
                            class="mb-1"
                            type="text"
                            v-model="item.titel"
                            placeholder="Type a title for your information or leave this field empty..."
                        ></Input>
                        <Input
                            v-if="bewerkModus && $i18n.locale === 'en'"
                            type="text"
                            v-model="item.tekst"
                            placeholder="Type information for your content or keep this field empty..."
                            
                        ></Input>
                        <Input
                            v-if="bewerkModus && $i18n.locale === 'nl'"
                            class="mb-1"
                            type="text"
                            v-model="item.titel"
                            placeholder="Type een titel voor uw informatie of houd dit veld leeg..."
                        ></Input>
                        <Input
                            v-if="bewerkModus && $i18n.locale === 'nl'"
                            type="text"
                            v-model="item.tekst"
                            placeholder="Type inhoud voor uw informatie of houd dit veld leeg..."
                            
                        ></Input>
                        <div v-if="bewerkModus" class="toevoegen last-input" @click="addAt(index+1)">
                            <div class="line"></div>
                            <div class="plus">+</div>
                        </div>
                        <h3 v-if="!bewerkModus">{{ item.titel }}</h3>
                        <p v-if="!bewerkModus">{{ item.tekst }}</p>
                        
                    </div>
                    
                </div>
            </div>
        </div>
        
    </div>
        
        
    
</template>
<script>
import Input from '@/components/Form/BetterInput.vue'
import NavItem from '@/components/Dashboard/NavItem.vue'
import ZijMenu from '@/components/Module/ZijMenu.vue'
import OverviewLink from '@/components/Module/OverviewLink.vue'

export default{
    components: {NavItem, ZijMenu, OverviewLink, Input},
    props: ["mode"],
    data () {
        return{
            bewerkModus: false,
            selectedMainOverviewId: null,
            selectedSubOverviewId: null,
            selectedMainOverviewTitle: '',
            selectedSubOverview: null,
            selectedOverview: null,
            selectedPdf: null,
            epub_attached: false,
            epub_uploading: false,
            epub_base64: "",
       
        }

    },
    

    mounted() {
        document.title = "Eduface";    

    
  },

  computed: {
        informatie(){
            return this.$store.state.module_weekinformatie
        }
    },

  async created() {
    await this.$store.dispatch("get_module_weekinformatie", {
        id: this.$route.params.id,
        weeknummer: this.$route.params.weeknummer

    })
    
  },
    methods:{
        submit_formulier(){
            
            this.selectedPdf = null;
            this.epub_attached = false;
            this.$refs.pdfInput.value = '';
        },
        bewerken(){
            this.bewerkModus = true
        },
        async slaOpBewerken(){            
            await this.$store.dispatch("moduleWeekOverviewWijzigen", this.$store.state.module_weekinformatie)
            setTimeout(() => {this.bewerkModus = false}, 1)
        },
        async annuleerBewerken(){
            await this.$store.dispatch("get_module_weekinformatie", {
                id: this.$route.params.id,
                weeknummer: this.$route.params.weeknummer
            })
            this.bewerkModus = false
        },
        move(index, direction){
            const tmp = this.$store.state.module_weekinformatie[index]
            if(direction == 'up'){
                const tmpWeekStofID = this.$store.state.module_weekinformatie[index-1].week_stof_id
                this.$store.state.module_weekinformatie[index] = this.$store.state.module_weekinformatie[index-1]
                this.$store.state.module_weekinformatie[index].week_stof_id = tmp.week_stof_id
                this.$store.state.module_weekinformatie[index-1] = tmp
                this.$store.state.module_weekinformatie[index-1].week_stof_id = tmpWeekStofID

            }else if(direction == 'down'){
                const tmpWeekStofID = this.$store.state.module_weekinformatie[index+1].week_stof_id
                this.$store.state.module_weekinformatie[index] = this.$store.state.module_weekinformatie[index+1]
                this.$store.state.module_weekinformatie[index].week_stof_id = tmp.week_stof_id
                this.$store.state.module_weekinformatie[index+1] = tmp
                this.$store.state.module_weekinformatie[index+1].week_stof_id = tmpWeekStofID
            }
        },
        addAt(index){
            const insert = (arr, index, newItem) => [
                // part of the array before the specified index
                ...arr.slice(0, index),
                // inserted item
                newItem,
                // part of the array after the specified index
                ...arr.slice(index)
            ]

            const module_id = this.informatie[0].module_id

            this.$store.state.module_weekinformatie = insert( this.$store.state.module_weekinformatie, index, {
                "stof_id": this.$store.state.module_weekinformatie[0].stof_id,
                "week_stof_id": -1,
                "module_id": module_id,
                "titel": "",
                "tekst": "",
                "weeknummer": this.$route.params.weeknummer
            })

            const length = this.$store.state.module_weekinformatie.length

            for(var i = index; i < length-1; i++){
                const tmp = this.$store.state.module_weekinformatie[i+1].week_stof_id
                this.$store.state.module_weekinformatie[i+1].week_stof_id = this.$store.state.module_weekinformatie[i].week_stof_id
                this.$store.state.module_weekinformatie[i].week_stof_id = tmp
            }
        },
        verwijderen(index){
            this.$store.state.module_weekinformatie.splice(index, 1)
        }
    }
    
}

</script>
<style lang="scss" >
.module-informatie{
    width: 100%;
    padding: 0px 0px;

    .module-aanpassen{
        display: flex;
        justify-content: space-between;
        padding: 0 10px; 

        .aanpassen{
            margin-top: 20px;
            margin-right: 20px;
            width: 200px;
        }
    }
    

    .overview{
        display: flex;
        border-top: 1px solid #000;
        margin-top: 20px;
    }
    

    .pdf{
        margin-top: 90px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        
        
        .btn{
            margin-left: 20px;
        }
        .bevestigen{
            margin-left: 20px;
            color: white;
            width: 100px;
            height: 30px;
            border-radius: 20px;
            background-color: #EC7926;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:hover{
                opacity: 0.6;
            }
        }
    }
    .contentBody {
        background: #fff;
        border-radius: 10px;
    }
    .mainBody{
        width: calc(100% - 304px);
        padding: 40px 0px;
    
    }
    .shadow {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }
    .p-5 {
        padding: 3rem !important;
    }

    .mb-1{
        font-weight: bold;
		.input-header{
			height: 0px;
            display: none;
            .hint{
                display: none;
            }
		}
	
    }

    .last-input{
        margin-bottom: 60px;
    }

    .toevoegen{
        position: relative;
        display: flex;
        align-items: center;
        height: 20px;
        background: #fff;
        margin-top: 60px;
        cursor: pointer;

        &:hover{
            opacity: 0.6;
        }

        .line{
            height: 2px;
            width: 100%;
            background: $secondary-color;
        }

        .plus{
            position: absolute;
            width: 20px;
            height: 10px;
            top: -3px;
            left: calc(50% - 10px);
            background: #fff;
            text-align: center;
            padding-bottom: 10px;
            font-weight: bold;
            color: $secondary-color;
            
        }
    }


    
}    

@media (max-width: 900px){
   .module-informatie{
    .mainBody{
        width: 100%
    }
   }
    
        
        
}
    

</style>