<template>
    <div class="better-input2">
        <div class="input-header">
            <div>{{ title }}  <span class="hint">{{ hint }}</span> </div>
            <div>{{ subtitle }}</div>
            
            
        </div>
        <div class="container">
            <div v-if="type == 'select'" class="input-select feedback" tabindex="-1" @focusout="handleBlur">
  <input @input="handleSelectInput" @click="handleClick" :value="SelectOptionValue ? options[modelValue] : modelValue" />
  <div class="icon icon-down">
    <img src="/assets/arrow_down.svg" />
  </div>
  <div v-if="Array.isArray(filteredOptions)" :class="{ active: isSelected }" class="options">
    <div 
      class="option" 
      v-for="(option, index) in filteredOptions" 
      :key="index"
      @click="selectOption(option.value)"
    >
      {{ option.display }}
    </div>
  </div>
</div>
<div class="feedback1">

</div>

        </div>
        
    </div>
</template>
<script>
import Datepicker from 'vuejs3-datepicker';
import getBase64 from '../../utils/getBase64.js'

export default{
    components: {Datepicker},
    props: ["title", "type", 'modelValue', "subtitle", "hint", "options", "filetype", "placeholder", "SelectOptionValue"],
    data(){
        return{
            isSelected: false,
            files: []
        }
    },
    computed:{
        filteredOptions() {
    console.log('Model Value:', this.modelValue);
    console.log('Options:', this.options);

    if (Array.isArray(this.options)) {
      if (!this.modelValue) {
        // New behavior when modelValue is empty or null
        return this.options.map(option => ({
          display: typeof option === 'string' ? option : option.text,
          value: typeof option === 'string' ? option : option.value,
        }));
      }
      return this.options.filter(option => {
        if (typeof option === 'string') {
          return option.toLowerCase().includes(this.modelValue.toLowerCase()) && option !== this.modelValue;
        } else if (typeof this.modelValue === 'string' && option.text) {
          return option.text.toLowerCase().includes(this.modelValue.toLowerCase()) && option !== this.modelValue;
        }
        return false;
      }).map(option => ({
        display: option.text || option,
        value: option.value || option,
      }));
    } else {
      const valuesArray = Object.values(this.options).flat();
      if (!this.modelValue) {
        // New behavior when modelValue is empty or null
        return valuesArray.map(option => ({
          display: option,
          value: option, // Assuming option is an integer in this case
        }));
      }
      return valuesArray.filter(option => {
        if (typeof this.modelValue === 'string') {
          return option.toLowerCase().includes(this.modelValue.toLowerCase()) && option !== this.modelValue;
        }
        return false;
      }).map(option => ({
        display: option,
        value: option, // Assuming option is an integer in this case
      }));
    }
  },



    },
    methods: {
        fileSelected(e) {
            this.files = e.target.files
            getBase64(e.target.files[0], base64 => {
                this.$emit('update:modelValue', base64)
            })

        },
        handleDate(date){
            console.log("selecting: " + date)
            if(date == null){
                this.$emit('update:modelValue', null)
            }else{
                date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
                this.$emit('update:modelValue', date.toISOString().split('T')[0].replace("-", "/").replace("-", "/"))
            }
            this.$emit('modified')
            
        },
        handleClick(){
            const vm = this
            
            this.$emit('update:modelValue', "")
            this.$emit('modified')
            setTimeout(() => vm.isSelected = true, 150)


            
        },
        handleBlur(){
            const vm = this
            setTimeout(() => vm.isSelected = false, 200)
        },
        handleSelectInput(event){
            this.$emit('update:modelValue', event.target.value)
        },
        selectOption(option){
            this.$emit('update:modelValue', option)
            this.$emit('modified')
        }
    }
}
</script>

<style lang="scss">
.better-input2{
    margin: 10px 0px;

    .vuejs3-datepicker__value{
        border: 1px solid #787878
    }
    .container{
        position: relative; /* Create a positioning context for child divs */
        width: 100%; /* Set the width and height as needed */
        height: 80px;
        .feedback{
            position: relative; 
            
            border-radius: 8px;
            z-index: 2;
            background-color: #fff;
            width: 400px;
            display: flex;
            justify-content: center;
                align-items: center;
                flex-direction: column;
        }
        .feedback1{
            position: absolute; /* Position this div absolutely within the container */
            top: 10px; /* Adjust top, left, right, and bottom as needed */
            left: 0;
            width: 400px;
            height: 40px;
            transform: skew(-15deg);
            border-radius: 8px;
            margin-left: 10px;
            background-color: $base-color;
            z-index: 1;
        }
    }

    textarea{
        // overflow: hidden !important;
        padding: 5px 20px;
        width: calc(100% - 40px);
        min-height: 80px;
        border: 1px solid #787878;
        border-radius: 4px;
        cursor: text;
    }

    .file{
        position: relative;
        height: 40px;

        .file-upload{
            opacity: 0;
            height: 20px;
            cursor: pointer !important;
            z-index: 10;
        }

        .file-upload-cover{
            position: relative;
            top: -32px;
            pointer-events:none;
            cursor: default !important;
            color: #787878;
            
        }


    }

    .input-select{
        position: relative;
        

        .icon{
            position: absolute;
            top: 5px;
            right: 5px;
            height: 20px;
            width: 20px;
            cursor: pointer;
            
            img{
                width: 100%;

            }
        }

        .options{
            position: absolute;
            display: none;
            background-color: #e6e6e6;
            max-height: 100px;
            width: calc(100% + 10px);
            overflow-y: auto;
            z-index: 101;
            margin-top: 120px;
            

            &.active{
                display: block;
            }

            .option{
                display: flex;
                height: 30px;
                padding-left: 10px;
                align-items: center;
                cursor: pointer;

                &:hover{
                    background-color: $white;
                    color: rgba(0,0,0,0.9)
                }
            }
        }
    }

    .input-header{
        display: flex;
        justify-content: space-between;

        .hint{
            margin: 10px;
            font-size: 13px;
            color: rgba(0,0,0,0.5)

        }
    }
    

    input{
        padding: 5px 20px;
        width: calc(100% - 40px);
        height: 20px;
        border: 1px solid #787878;
        border-radius: 4px;
    }

    .calendar{
        position: absolute;
        top: unset;
        bottom: 60px;
    }

    .input-calendar{
        padding: 5px 10px;
        width: calc(100%);
        height: 33px;
        border: 1px solid #787878;
        border-radius: 4px;
    }

    .vuejs3-datepicker{
        width: 100%;
    }

    .vuejs3-datepicker__calendar-topbar{
        background-color: $base-color;
    }

    .vuejs3-datepicker__typeablecalendar{
        position: absolute;
        top: 8px;
        display: flex;
        justify-content: flex-end;
        width: calc(100% - 20px);
        pointer-events: none;
    }
}
</style>