<template>
    <div class="nieuw-module-page">
        <Popup class="module-popup" :show="showFinalPopup" @close="afronden()">
            <h2>{{ $t('moduleAddedSuccess') }}</h2>
            <p>{{ $t('saveInviteCode') }}</p>
            <div class="invite-code">{{ displayAccessCode }}</div>
            <BetterButton 
                :text="$t('complete')"
                @click="afronden"
            ></BetterButton>
        </Popup>
        <Popup class="module-popup" :show="showWaitPopup">
            <h2>{{ $t('pleaseWait') }}</h2>
            <p>{{ $t('processingModule') }}</p>
            <p>{{ $t('thisMayTakeAWhile') }}</p>
        </Popup>
        <Sidebar mode="teacher" active="Modules"></Sidebar>
        <div class="content" :class="{'mobile-extend': toggledSidebar}">
            <DashboardHeader mode="module"  :hideSearch="true"></DashboardHeader>
            

         
                <div class="scrollable-content" v-if="$i18n.locale === 'nl'">              
                
                <DashboardHeading>Voeg een nieuwe module toe</DashboardHeading>
                <Input
                    title="In welke taal is de module?"
                    v-model="taal"
                    type="select"
                    :options="options.talen"
                    mode="feedback"
                   
                ></Input>
                <Input
                    tekst="Module titel"
                    
                    v-model="moduleNaam"
                    type="textsmall"
                    mode="feedback"
                ></Input>
                <!-- <Input
                    tekst="Opleiding"
                    v-model="moduleOpleiding"
                    type="textsmall"
                    mode="feedback"
                ></Input> -->
                <Input
                    tekst="Module onderwerp"
                    v-model="moduleOnderwerp"
                    type="text"
                    mode="feedback"
                    title="Input AI"
                ></Input>
                <Input 
                    title="Aantal vragen(maximaal 15)"
                    v-model="aantalVragen"
                    type="textsmall"
                    mode="nummer"
                    @input="validateQuestionType"
                ></Input>
                <Input
                    title="Wat voor soort vragen?"
                    v-model="vraag"
                    type="select"
                    :options="options2.opties"
                    mode="feedback"
                    @input="validateQuestionType"
                ></Input>
                <Input v-if="this.vraag == 'Allebei'"
                    title="Aantal meerkeuze"
                    v-model="aantalMeerkeuze"
                    type="textsmall"
                    mode="nummer"
                    @input="validateQuestionType"
                />
                <Input v-if="this.vraag == 'Allebei'"
                    title="Aantal open"
                    v-model="aantalOpen"
                    type="textsmall"
                    mode="nummer"
                    @input="validateQuestionType"
                />
                <!-- <Input
                    title="Module Cover"
                    v-model="moduleCover"
                    type="file"
                    hint="upload hier jouw cover"
                    filetype=".jpg"
                    mode="cover"
                ></Input> -->

                <div v-for="(item, index) in slides" :key="index">
                    <Input
                    :title="'PDF van slides: week '+ (index+1)"
                        v-model="slides[index]"
                        type="file"
                        filetype="application/pdf"
                        mode="module"
                    ></Input>
                    <Input
                    title="Input AI"
                        :tekst="'Doel van week '+ (index+1)"
                        v-model="weekdoelen[index]"
                        type="textsmall"
                        mode="feedback"
                    ></Input>
                    <Input
                    title="Input AI"
                        :tekst="'Voorbeeld vraag voor week '+ (index+1)"
                        v-model="vragen[index]"
                        type="textsmall"
                        mode="feedback"
                    ></Input>
                </div>
                
                <div class="slides-options">
                    <a class="extra-week" href="javascript:void(0)" @click="extraWeek">Nog een week toevoegen</a>
                    <a v-if="slides.length > 1" class="extra-week" href="javascript:void(0)" @click="minderWeek">Een week verwijderen</a>
                </div>
                

                <BetterButton 
                    text="Bevestigen"
                    @click="validateAndSendData"
                ></BetterButton>

               
            </div>
            
                
            
            
           
            <div class="scrollable-content" v-if="$i18n.locale === 'en'">              
                
                <DashboardHeading>Add new module</DashboardHeading>
                <Input
                    title="In what language is the module?"
                    v-model="language"
                    type="select"
                    :options="options.languages"
                    mode="feedback"
                   
                ></Input>
                <Input
                    tekst="Module title"
                    
                    v-model="moduleNaam"
                    type="textsmall"
                    mode="feedback"
                ></Input>
                <!-- <Input
                    tekst="Study"
                    v-model="moduleOpleiding"
                    type="textsmall"
                    mode="feedback"
                ></Input> -->
                <Input
                    tekst="Module subject"
                    v-model="moduleOnderwerp"
                    type="text"
                    mode="feedback"
                    title="Input AI"
                ></Input>

                

                <Input
                    title="Module Cover"
                    v-model="moduleCover"
                    type="file"
                    hint="upload hier jouw cover"
                    filetype=".jpg"
                    mode="cover"
                ></Input>

                <div v-for="(item, index) in slides" :key="index">
                    <Input
                    :title="'PDF from slides: week '+ (index+1)"
                        v-model="slides[index]"
                        type="file"
                        filetype="application/pdf"
                        mode="module"
                    ></Input>
                    <Input
                    title="Input AI"
                        :tekst="'Goal for week '+ (index+1)"
                        v-model="weekdoelen[index]"
                        type="textsmall"
                        mode="feedback"
                    ></Input>
                    <Input
                    title="Input AI"
                        :tekst="'What kind of questions for week '+ (index+1)"
                        v-model="vragen[index]"
                        type="textsmall"
                        mode="feedback"
                    ></Input>
                </div>
                
                <div class="slides-options">
                    <a class="extra-week" href="javascript:void(0)" @click="extraWeek">Add another week</a>
                    <a v-if="slides.length > 1" class="extra-week" href="javascript:void(0)" @click="minderWeek">Delete this week</a>
                </div>
                

                <BetterButton 
                    text="Complete"
                    @click="validateAndSendData"
                ></BetterButton>

               
            </div>
            


            
        </div>
        
    </div>
</template>

<script>
import Sidebar from '@/components/Dashboard/SidebarLeraar.vue'
import Input from '@/components/Form/BetterInput.vue'
import DashboardHeader from '@/components/Dashboard/DashboardHeader.vue'
import DashboardHeading from '@/components/Dashboard/DashboardHeading.vue'
import BetterButton from '@/components/Widgets/BetterButton.vue'
import ModuleWeekToets from './ModuleWeekToets.vue'
import Popup from '@/components/Widgets/Popup.vue'



export default{
    components: {DashboardHeading, DashboardHeader, Sidebar, Input, BetterButton, Popup},
    data(){
        return {
            aantalVragen: 1, // update to null values if users typing in values is preferred or accidental overflow is an issue
            aantalMeerkeuze: 0,
            aantalOpen: 0,
            moduleOnderwerp: "",
            moduleNaam: "",
            moduleOmschrijving: "",
            moduleCover: "",
            vraag: "",
            moduleOpleiding: "",
            slides: [null],
            weekdoelen: [""],
            vragen: [""],
            showPopup: false,
            taal: "",
            language: "",
            options: {
                talen: {
                    "Engels": "en",
                    "Nederlands": "nl",
                   
                },
                languages: {
                    "English": "en",
                    "Dutch": "nl",
                   
                },
               
            },
            options2: {
                opties: {
                    "Meerkeuze": "multiple_choice",
                    "Open": "open",
                    "Allebei": "mixed",
                   
                },
                languages: {
                    "English": "en",
                    "Dutch": "nl",
                   
                },
               
            }
        }
    },
    mounted() {
        document.title = "Eduface | Module toevoegen";
    },
  
    watch: {
        vraag() {
            this.validateQuestionType()
            // console.log('vraag: ', this.vraag, 'aV: ', this.aantalVragen, 'aM: ', this.aantalMeerkeuze, 'aO: ', this.aantalOpen)
        },
        aantalVragen() {
            this.validateQuestionType()
            // console.log('vraag: ', this.vraag, 'aV: ', this.aantalVragen, 'aM: ', this.aantalMeerkeuze, 'aO: ', this.aantalOpen)
        },
        aantalMeerkeuze() {
            if (this.vraag === 'Allebei') this.adjustAantalOpen()
            // console.log('vraag: ', this.vraag, 'aV: ', this.aantalVragen, 'aM: ', this.aantalMeerkeuze, 'aO: ', this.aantalOpen)
        },
        aantalOpen() {
            if (this.vraag === 'Allebei') this.adjustAantalMeerkeuze()
            // console.log('vraag: ', this.vraag, 'aV: ', this.aantalVragen, 'aM: ', this.aantalMeerkeuze, 'aO: ', this.aantalOpen)
        },
    },

    computed: {
        toggledSidebar(){
            return this.$store.state.mobileExtend
        },
        showFinalPopup(){
            return this.$store.state.popup
        },
        showWaitPopup(){
            return this.showPopup && !this.$store.state.popup
        },
        displayAccessCode(){
            if (this.$store.state.accessCode.match(/.{1,4}/g) == null){
                return null
            }
            return this.$store.state.accessCode.match(/.{1,4}/g).join(' ')
        },
        calculatedAantalOpen() {
            return this.aantalVragen - this.aantalMeerkeuze;
        }
    },

    methods: {
        // Method to manage question type counters, ensure they are within bounds and respect token limit.
        validateQuestionType() {
            // Define max tokens.
            const maxQuestions = 15

            // Set type counters based on selected question type.
            switch (this.vraag) {
                // Reset when user changes question type.
                case '':
                    this.aantalMeerkeuze = 0
                    this.aantalOpen = 0
                // Assign total question amount to selected type and empty the other.
                case 'Meerkeuze':
                    this.aantalMeerkeuze = this.aantalVragen
                    this.aantalOpen = 0
                    break
                case 'Open':
                    this.aantalOpen = this.aantalVragen
                    this.aantalMeerkeuze = 0
                    break
                // Adjust new minimum total questions and split.
                case 'Allebei':
                    this.aantalVragen = this.clampValue(this.aantalVragen, 2, maxQuestions)
                    this.balanceQuestionTypes()
                    break
                default:
                    break
            }
        },

        // Balance the values of aantalMeerkeuze and aantalOpen when both types are present
        balanceQuestionTypes() {
            const maxAllowed = this.aantalVragen - 1
            this.aantalMeerkeuze = this.clampValue(this.aantalMeerkeuze, 1, maxAllowed)
            this.aantalOpen = this.clampValue(this.aantalOpen, 1, maxAllowed)
        },

        // Helper method to set value bounds.
        // TODO: there is probably a native method for this functionality. Math.clampValue?
        clampValue(value, min, max) {
            return Math.max(min, Math.min(value, max))
        },

        // Adjust aantalOpen when aantalMeerkeuze changes and vice versa.
        adjustAantalOpen() {
            const minOpen = this.vraag === 'Allebei' ? 1 : 0
            this.aantalOpen = this.clampValue(this.aantalVragen - this.aantalMeerkeuze, minOpen, this.aantalVragen)
        },
        adjustAantalMeerkeuze() {
            const minMeerkeuze = this.vraag === 'Allebei' ? 1 : 0
            this.aantalMeerkeuze = this.clampValue(this.aantalVragen - this.aantalOpen, minMeerkeuze, this.aantalVragen)
        },
  
        validateAndSendData() {
           console.log(this.slides)
           console.log(this.weekdoelen)
           console.log(this.vragen)
    // Check if all arrays have at least one item
    for(let i = 0; i < this.slides.length; i++){
        if (this.slides[i]==null || this.weekdoelen[i]=="" || this.vragen[i]=="") {
        this.$store.commit("show_snackbar", {
                    text: "Geen pdf, doel of soort vraag ingevuld, of de informatie in de weken is niet gelijk aan elkaar!",
                    type: "warning"
                })
      
      return false;
    }
    }
    

    // Check if the lengths of the arrays match
  

    // Check if any of the slides is empty (assuming you can check for a non-empty file)
    for (let i = 0; i < this.slides.length; i++) {
      if (!this.slides[i]) { // Depending on how you're handling file inputs, you might need a more robust check here
        
        this.$store.commit("show_snackbar", {
                    text: "Geen pdf geselecteerd",
                    type: "warning"
                })
        return false;
      }
    }

    // If all validations pass, proceed to send the data to the backend
    this.bevestig();
  },
        closePopup(){
            this.$store.commit("showPopup", false)
        },
        bevestig(){
            if(this.taal == "" && this.language == ""){
                this.$store.commit("show_snackbar", {
                            text: "Kies een taal!",
                            type: "warning"
                        })
            }else if(this.moduleNaam == ""){
                this.$store.commit("show_snackbar", {
                    text: "Kies een module titel!",
                    type: "warning"
                })
            }else if(this.moduleOnderwerp == ""){
                this.$store.commit("show_snackbar", {
                    text: "Kies een module onderwerp!",
                    type: "warning"
                })
            }else if(this.aantalVragen == 0){
                this.$store.commit("show_snackbar", {
                    text: "Kies een aantal vragen",
                    type: "warning"
                })
            }else if(this.vraag == "Allebei"){
                if(this.aantalMeerkeuze==0){
                    this.$store.commit("show_snackbar", {
                    text: "Kies een aantal Meerkeuzevragen",
                    type: "warning"
                })
                }else if(this.aantalOpen==0){
                    this.$store.commit("show_snackbar", {
                    text: "Kies een aantal Openvragem",
                    type: "warning"
                })
                }else{

                    if(this.aantalVragen > 15){
                        this.aantalVragen = 15
                    }
                    if(this.aantalOpen + this.aantalMeerkeuze > 15){
                        this.aantalOpen = 7
                        this.aantalOpen = 8
                    }

                    this.showPopup = true
                    this.$store.dispatch("nieuweModule", {
                        moduleTitle: this.moduleNaam,
                        vak: this.$route.params.id,
                        moduleOmschrijving: this.moduleOmschrijving,
                        moduleOnderwerp: this.moduleOnderwerp,
                        taal: this.options.talen[this.taal],
                        weekdoelen: this.weekdoelen,
                        vragen: this.vragen,
                        slides: this.slides,
                        aantalVragen: this.aantalVragen,
                        aantalMeerkeuze: this.aantalMeerkeuze,
                        aantalOpen: this.aantalOpen,
                        typeVragen: this.options2.opties[this.vraag],
                        mode: "hbo"
                    })
                }
            }
            else{
                this.showPopup = true

                if(this.taal === ""){
                    this.$store.dispatch("nieuweModule", {
                        moduleTitle: this.moduleNaam,
                        vak: this.$route.params.id,
                        moduleOnderwerp: this.moduleOnderwerp,
                        taal: this.options.languages[this.language],
                        weekdoelen: this.weekdoelen,
                        vragen: this.vragen,
                        slides: this.slides,
                        mode: "hbo"
                    })
                }else{
                    this.$store.dispatch("nieuweModule", {
                        moduleTitle: this.moduleNaam,
                        vak: this.$route.params.id,
                        moduleOmschrijving: this.moduleOmschrijving,
                        moduleOnderwerp: this.moduleOnderwerp,
                        taal: this.options.talen[this.taal],
                        weekdoelen: this.weekdoelen,
                        vragen: this.vragen,
                        slides: this.slides,
                        aantalVragen: this.aantalVragen,
                        aantalMeerkeuze: this.aantalMeerkeuze,
                        aantalOpen: this.aantalOpen,
                        typeVragen: this.options2.opties[this.vraag],
                        mode: "hbo"
                    })
                }
                    
            }
            
        },
        extraWeek(){
            this.slides.push(null)
            this.weekdoelen.push("")
            this.vragen.push("")
        
        },
        minderWeek(){
        
            this.slides.pop()
       
           
        },
        afronden(){
            this.$store.commit("showPopup", false)
            if(this.taal === ""){
                this.$router.push({"name": "modules-teacher"})
            }else{
                this.$router.push({"name": "modules-leraar"})
            }
            
        }
    }
}
</script>

<style lang="scss">
.nieuw-module-page{
    background-color: #F4F6FC;
    min-height: 100vh;

    .splitsing{
        display: flex;
    }
    .content{
        background-color: #F4F6FC;
        justify-content: space-between;
        position: absolute;
        width: calc(100% - 300px);
        padding: 0px 50px;
        top: 0px;
        left: 200px;

        

        
    }

    .extra-week{
        display: block;
        margin-bottom: 20px;
        margin-right: 20px;
    }

    .better-button{
        margin-bottom: 40px;
    }

    .slides-options{
        display: flex;
    }

    .popup{
        display: flex !important;
        align-items: center;
        flex-direction: column;
        text-align: center;

        .invite-code{
            font-size: 30px;
            font-weight: bold;
            color: $base-color;
            margin: 20px 0px;
        }

    }
    
    

    @media (max-width: 900px){
        .content{
            left: 0px;
            width: calc(100% - 100px);
            &.mobile-extend{
                display: block;
                left: 200px;
                width: calc(100% - 300px);

                .search{
                    display: none;
                }
            }
        }
    }

    @media (max-width: 600px){
        .content{
            padding: 0px 10px;
            width: calc(100% - 20px);
            &.mobile-extend{
                left: calc(100% - 70px);
                width: 70px;
                .scrollable-content{
                    display: none;
                }
            }

            
        }
    }
}
</style>
