<template>
    <div class="dashboard-heading">

        <h2 class="title"><slot></slot></h2>
        <router-link v-if="urltext && href" class="url" :to="{name: href}">{{ urltext }}</router-link>
            
    </div>
    
</template>


<script>
export default{
    props: ["urltext", "href"],
    data(){
        return {

        }
    }
}
</script>


<style lang="scss">
.dashboard-heading{
    width: 100%;
    display: flex;
    align-items: flex-end;
    margin-bottom: 25px;

    .url{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        text-decoration-line: underline;
        margin-left: 22px;

        color: #EC7926;
        margin-bottom: 4px;
    }
}

@media (max-width: 600px){
    .dashboard-heading{
        flex-direction: column;
        align-items: flex-start;

        .url{
            margin-left: 0px;
        }
    }
    
}
</style>