<template>
    <div class="teacher-page">
        <Sidebar mode="teacher" active="dashboardassistent" :assistent="assistent"></Sidebar>
        <div class="content" :class="{'mobile-extend': toggledSidebar}">
            <DashboardHeader mode="uitgever" :hideSearch="true"></DashboardHeader>
            <div class="scrollable-content">
                <h1>Coming soon...</h1>
                <h1>Interacteer met je onderwijsassistent {{$store.state.onderwijsassistent}} voor een nog snellere en leukere manier om je educatie te innoveren  </h1>
                <br/>
                <h1>Automatic personalised learning for your students</h1>
                <br/>

            </div>
            
            
           

            <div>
                
            </div>
            
            
            
        </div>
    </div>
</template>
<script>
import Sidebar from '@/components/Dashboard/SidebarLeraar.vue'
import DashboardHeader from '@/components/Dashboard/DashboardHeader.vue'
import DashboardHeading from '@/components/Dashboard/DashboardHeading.vue'

export default{
    components: {Sidebar, DashboardHeader, DashboardHeading},
     computed: {
        toggledSidebar(){
            return this.$store.state.mobileExtend
        },
        informatie_docent(){
            return this.$store.state.informatie_docent
        },
     },
    methods: {
        helloWorld(){
            console.log("test")
        },
    },
    async created(){
        await this.$store.dispatch("informatie_docent")
    }
}

</script>

<style lang="scss">
.teacher-page{
    background-color: #F4F6FC;
    min-height: 100vh;
    .content{
        justify-content: space-between;
        position: absolute;
        width: calc(100% - 300px);
        top: 0px;
        left: 200px;
        padding: 0px 50px;
        overflow-x: hidden;
        .books{
            display: flex;
            
            .boek-plus{
                margin: 5px;

                &:first-child{
                    margin-left: 0px;
                }

                
            }
        }
        .onderwijs{
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .technologie{
            
            background-color: #eeeff8;
            width: 700px;
            height: 150px;
            margin-left: 150px;
            margin-top: 50px;
            border-radius: 4px;
            text-align: center;
            font-weight: bold;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            /* Horizontally center the content */
       

            .oranje{
            color: $secondary-color;
        }
            .blauw{
                color: $base-color;
            }
        }
        }
        
        .feedback-data{
            display: flex;
            width: 100%;
            margin-left: 150px;
            font-weight: bold;
            border-radius: 4px;
            font-size: 20px;
            flex-wrap: wrap;
            

            .oranje{
                color: $secondary-color;
            }
            .blauw{
                color: $base-color;
            }
            

            .minuten{
                display: flex;
                 
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: #eeeff8;
                width: 300px;
                height: 320px;
                margin-right: 100px;
                border-radius: 10px;
                text-align: center;
                .bespaard{
                    margin-top: 10px;
                    margin-left: 10px;
                }
                img{
                    margin-left: 25px;
                    width: 250px;
                }
            }
            .feedback{
                display: flex;

                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: #eeeff8;
                width: 300px;
                height: 320px;
                border-radius: 10px;
                margin-right: 200px;
                text-align: center;
                .bespaard{
                    margin-top: 10px;
                    margin-left: 10px;
                }
                img{
                    margin-left: 25px;
                    width: 215px;
                }
            }
        }

        .recent-books{
            position: relative;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            margin-bottom: 80px;

            .recent-book-container{
                width: 400px;
                margin-right: 20px;

                &:last-child{
                    width: 400px;
                    margin-right: 0px;
                }

                .horizontal{
                    justify-content: space-between;
                }
            }
        }
        .omzet{
            height: 50%;
            width: 50%;
        }
        .verkoop{
            height: 50%;
            width: 50%;
        }
        .big-informatie{
            font-size: 25px;
            .bold{
                font-weight: bold;
            }
        }
        .small-informatie{
            font-size: 15px;
            margin-bottom: 10px;
            .bold{
                font-weight: bold;
            }
        }
    }
}
@media (max-width: 900px){
    .teacher-page{
        .content{
            left: 0px;
            width: calc(100% - 100px);
            &.mobile-extend{
                display: block;
                left: 200px;
                width: calc(100% - 300px);

                
            }
            
        }
    }
        
    }
@media (max-width: 600px){
    .teacher-page{
        .content{
            padding: 0px 10px;
            width: calc(100% - 20px);
            
            &.mobile-extend{
                left: calc(100% - 70px);
                width: 70px;
                .scrollable-content{
                    display: none;
                }
            }

            .recent-books{
                margin-bottom: 50px;
                .boek-plus{
                    margin: 5px;

                    &:first-child{
                        margin-left: 0px;
                    }

                    
                }
            }

            .feedback-data{
                margin-left: 20px;
            }
            .onderwijs{
                .technologie{
                margin-left: 20px;
            }
            }
            
        }
    }
        
    }

</style>