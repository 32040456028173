<template>
    <div class="wachtwoord-vergeten">
        <div v-if="stap==1">
            <div class="inlog-header"  >
                <div class="inlog-options "><span class="optie-benik">Wachtwoord </span></div>
               
              

            </div>



            <div class="login-information">
                <div class="foto-kant">
                    <img src="/assets/registreren.png"  class="plaatje-inlog" />
                </div>
                <div class="informatie-kant" @keyup.enter="login">
              
                    <Input mode="login" title="E-mailadress or username" type="textsmall"  v-model="emailUsername"></Input>

                    <BetterButton v-if="!wachtwoordvergeet" @click="verder" class="button">Verder</BetterButton>
                    <v-progress-circular
      v-if="wachtwoordvergeet"
      indeterminate

      class="loading-spinner"
    ></v-progress-circular>                    
                </div>
            </div>

        </div>
        <div v-if="stap==2">
            <div class="inlog-header"  >
                <div class="inlog-options "><span class="optie-benik">Wachtwoord </span></div>
               
              

            </div>



            <div class="login-information">
                <div class="foto-kant">
                    <img src="/assets/registreren.png"  class="plaatje-inlog" />
                </div>
                <div class="informatie-kant" @keyup.enter="login">
              
                    <div class="row1">
                    <div class="normal-title">Verify your e-mailadress</div>
                    <div class="normal-subtitle">Check your inbox for intstructions</div>
                    <div class="normal-tip"><b>TIP!</b> Don't forget to check the spambox</div>
                </div>
  
                <div >
                    <div>Having trouble? Make contact with our customerservice.</div>
                </div>
                    
                </div>
            </div>

        </div>


      
        
        
    </div>
</template>
<script>

import Input from '@/components/Form/BetterInput.vue'
import BetterButton from "@/components/Widgets/BetterButton.vue"
import { i18n } from '../main'; 

export default{
    components: {Input, BetterButton},
    data(){
        return {
            emailUsername: "",
            stap: 1,
            taal: "en",
            wachtwoordvergeet: false,
        }
    },
    methods: {
        verder(){
            this.wachtwoordvergeet = true
            if(this.emailUsername==""){
                this.$store.commit("show_snackbar", {
                    text: "De email is nog niet ingevuld.",
                    type: "warning"
                })
            } else{
                const currentLocale = i18n.global.locale.value
         
                if(currentLocale=='nl'){
                    this.taal="nl"
                }
                this.$store.dispatch("wachtwoordVergeten",{
                    emailUsername: this.emailUsername,
                    taal: this.taal
                }).then(success => {
                    if(success){
                        this.stap=2
                    }else{
                        this.emailUsername = ""
                        this.wachtwoordvergeet = false
                    }
                })
                
            }

        },
    }
}

</script>

<style lang="scss">
.wachtwoord-vergeten{
    .normal-title{
            font-family: "Roboto";
            font-weight: 700;
            font-size: 25px;
        }

        .normal-subtitle{
            font-family: "Roboto";
            font-size: 20px;
        }

        .normal-tip{
            font-family: "Roboto";
            font-size: 15px;
            line-height: 60px;
        }


    .inlog-header{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        font-size: 25px;

        .inlog-options{
            margin: 0px 30px;
         

            .optie-benik{
                font-weight: bold;
            }

            .line-through{
                text-decoration: line-through;
                cursor: pointer;
                &:hover{
                    opacity: 0.6;
                }
      
            }
        }
        
    }

    .login-information {
  display: flex;
  justify-content: center;
  height: 550px;



  
}

.foto-kant {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .plaatje-inlog{
    width: 600px;
  }
}

.informatie-kant {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;


  flex-direction: column;

  .input{
    margin-left: -30px;
  }
  .button{
    margin-top: 40px;
    align-items: center;
    text-align: center;
    width: 200px;
    height: 45px;
    
  }
}
    h1{
        color: $base-color;
    }

    .next-content{
        height: calc(100% - 80px);
        padding: 40px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .normal-title{
            font-family: "Roboto";
            font-weight: 700;
            font-size: 25px;
        }

        .normal-subtitle{
            font-family: "Roboto";
            font-size: 20px;
        }

        .normal-tip{
            font-family: "Roboto";
            font-size: 15px;
            line-height: 60px;
        }

        .row2{
            display: flex;

            img{
                width: 50%;
            }
        }
    }

    .title{
        font-family: "Roboto";
        font-weight: 700;
        font-size: 35px;
        line-height: 56px;
    }

    

    .top-text{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .onboarding-nav{
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        margin-top: 10px;

        div{
            margin-right: 10px;
            text-decoration: underline;
            cursor: pointer;
            color: #000;
            &:hover{
                opacity: 0.6;
            }

            &.active{
                text-decoration: none;
                font-weight: bold;
                cursor: default;

                &:hover{
                    opacity: 1;
                }
            }
        }
    }
}
@media (max-width: 900px){
    .login-leraar{
        .inlog-header{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        font-size: 15px;

        .inlog-options{
            margin: 0px 30px;
         

            .optie-benik{
                font-weight: bold;
            }

            .line-through{
                text-decoration: line-through;
            }
        }
        
    }

    .login-information {
  display: flex;
  justify-content: center;
  height: 550px;


  .onsgevonden{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .button{
    margin-top: 40px;
    align-items: center;
    text-align: center;
    width: 200px;
    height: 45px;
    
  }

    .option {
    border: 2px solid black;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    width: 200px;
    height: 30px;
    font-size: 25px;
    border-radius: 40px;
    text-align: center;
    align-items: center;
  }
  .selected {
    border-color: orange;
  }

    .gevonden{
    font-size: 30px;
    font-weight: bold;
    margin-top: 40px;

    .blue{
        color: $base-color;

    }
    .oranje{
        color: $secondary-color;
    }

  }
  }
  
}

.foto-kant {
display: none;
}

.informatie-kant {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;


  flex-direction: column;

  .input{
    margin-left: -30px;
  }
  .button{
    margin-top: 40px;
    align-items: center;
    text-align: center;
    width: 100px;
    height: 45px;
    
  }
}
.percentage{
    display: flex;
        align-items: center;
        justify-content: center;

        .progress{
            width: 200px;
        }
}
    }

    }

    @media (max-width: 600px){
        .login-leraar{
        .inlog-header{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        font-size: 15px;

        .inlog-options{
            margin: 0px 30px;
         

            .optie-benik{
                font-weight: bold;
            }

            .line-through{
                text-decoration: line-through;
            }
        }
        
    }

    .login-information {
  display: flex;
  justify-content: center;
  height: 550px;


  .onsgevonden{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .button{
    margin-top: 40px;
    align-items: center;
    text-align: center;
    width: 200px;
    height: 45px;
    
  }

    .option {
    border: 2px solid black;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    width: 200px;
    height: 30px;
    font-size: 25px;
    border-radius: 40px;
    text-align: center;
    align-items: center;
  }
  .selected {
    border-color: orange;
  }

    .gevonden{
    font-size: 30px;
    font-weight: bold;
    margin-top: 40px;

    .blue{
        color: $base-color;

    }
    .oranje{
        color: $secondary-color;
    }

  }
  }
  
}

.foto-kant {
display: none;
}

.informatie-kant {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;


  flex-direction: column;

  .input{
    margin-left: -30px;
  }
  .button{
    margin-top: 40px;
    align-items: center;
    text-align: center;
    width: 100px;
    height: 45px;
    
  }
}
.percentage{
    display: flex;
        align-items: center;
        justify-content: center;

        .progress{
            width: 200px;
        }
}
    }
    }
</style>
