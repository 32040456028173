<template>
    <div class="module-toetsoverview">
        <div class="module-aanpassen">
            <div class="terug-knop">
                <NavItem v-if="$store.state.role=='student'"  href="modules-student" class="goback" :inverted="true" >
                    <img src="/assets/arrowback_blauw.svg" />
                    </NavItem> 
                    <NavItem v-if="$store.state.role === 'leraar' && $i18n.locale === 'nl'" @click="navigateToFeedbackOverview" class="goback" :inverted="true">
    <img src="/assets/arrowback_blauw.svg" />
</NavItem>
                    <NavItem v-if="$store.state.role=='leraar' && $i18n.locale === 'en'"  href="modules-teacher" class="goback" :inverted="true" >
                    <img src="/assets/arrowback_blauw.svg" />
                    </NavItem>
                    <div v-if="modules[0].taal=='nl'" class="tekst">Terug naar dashboard</div>
                    <div v-if="modules[0].taal=='en'" class="tekst">Back to dashboard</div>
            </div>
            

            

            <button v-if="$store.state.role=='leraar' && !bewerkModus && modules[0].taal=='nl'" class="aanpassen" @click="bewerken()">Overview aanpassen</button>
                <button v-if="$store.state.role=='leraar' && bewerkModus && modules[0].taal=='nl'" class="aanpassen" @click="annuleerBewerken()">Aanpassing annuleren</button>
                <button v-if="$store.state.role=='leraar' && bewerkModus && modules[0].taal=='nl'" class="aanpassen" @click="slaOpBewerken()">Aanpassing opslaan</button>
                <button v-if="$store.state.role=='leraar' && !bewerkModus && modules[0].taal=='en'" class="aanpassen" @click="bewerken()">Adjust overview</button>
                <button v-if="$store.state.role=='leraar' && bewerkModus && modules[0].taal=='en'" class="aanpassen" @click="annuleerBewerken()">Cancel adjusting</button>
                <button v-if="$store.state.role=='leraar' && bewerkModus && modules[0].taal=='en'" class="aanpassen" @click="slaOpBewerken()">Save adjustment</button>
    
       
            
        </div>
        
        <div class="overview">
            
            <ZijMenu :id="this.$route.params.id" :vak="modules[0].vak" :taal="modules[0].taal"></ZijMenu>
        
            <div class="mainBody">
                <div class="contentBody shadow p-5">
                    <h1 :class="{'bewerken': bewerkModus}">{{ modules[0].vak }}</h1>
                    
                    <div v-for="(item, index) in modules" :key="index">
                        <a 
                            v-if="bewerkModus && index != modules.length - 1" 
                            class="order-button" 
                            href="javascript:void(0)"
                            @click="move(index, 'down')"
                        >{{ $t('moveDown') }}</a>
                        <a 
                            v-if="bewerkModus && index != 0" 
                            class="order-button" 
                            href="javascript:void(0)"
                            @click="move(index, 'up')"
                        >{{ $t('moveUp') }}</a>
                        <OverviewLink :bewerken="bewerkModus" :item="item" mode="toets"></OverviewLink>
                    </div>
                    
                </div>
            </div>
        </div>
        
    </div>
        
        
    
</template>
<script>

import NavItem from '@/components/Dashboard/NavItem.vue'
import ZijMenu from '@/components/Module/ZijMenu.vue'
import OverviewLink from '@/components/Module/OverviewLink.vue'

export default{
    components: {NavItem, ZijMenu, OverviewLink},
    props: ["mode"],
    data () {
        return{
            bewerkModus: false,
            selectedMainOverviewId: null,
            selectedSubOverviewId: null,
            selectedMainOverviewTitle: '',
            selectedSubOverview: null,
            selectedOverview: null,
            selectedPdf: null,
            epub_attached: false,
            epub_uploading: false,
            epub_base64: "",
            
        }

    },
    

    mounted() {
        document.title = "Eduface";    

    
  },
  computed: {
        modules(){
            return this.$store.state.module_overviews
        }
    },
    async created() {
  
        await this.$store.dispatch("get_module_overviews", this.$route.params.id)
    
    },
  
    methods:{
        navigateToFeedbackOverview() {
        this.$router.push({ 
            name: 'modules-leraar', 
            params: { id: this.$route.params.id1 } 
        });
    },
        async onEpubPicked (event) {
            const files = event.target.files
            this.epub_uploading = true
            getBase64(files[0], base64 => {
                this.epub_uploading = false
                this.epub_attached = true 
                this.epub_base64 = base64
                // console.log(this.base64)
            })
            
        },
        submit_formulier(){
            
            this.selectedPdf = null;
            this.epub_attached = false;
            this.$refs.pdfInput.value = '';
        },
        bewerken(){
            this.bewerkModus = true
        },
        async slaOpBewerken(){
            await this.$store.dispatch("moduleOverviewWijzigen", this.$store.state.module_overviews)
            this.bewerkModus = false
        },
        async annuleerBewerken(){
            await this.$store.dispatch("get_module_overviews", this.$route.params.id)
            this.bewerkModus = false
        },
        move(index, direction){
            const tmp = this.$store.state.module_overviews[index]
            if(direction == 'up'){
                const tmpWeeknummer = this.$store.state.module_overviews[index-1].weeknummer
                this.$store.state.module_overviews[index] = this.$store.state.module_overviews[index-1]
                this.$store.state.module_overviews[index].weeknummer = tmp.weeknummer
                this.$store.state.module_overviews[index-1] = tmp
                this.$store.state.module_overviews[index-1].weeknummer = tmpWeeknummer
            }else if(direction == 'down'){
                const tmpWeeknummer = this.$store.state.module_overviews[index+1].weeknummer
                this.$store.state.module_overviews[index] = this.$store.state.module_overviews[index+1]
                this.$store.state.module_overviews[index].weeknummer = tmp.weeknummer
                this.$store.state.module_overviews[index+1] = tmp
                this.$store.state.module_overviews[index+1].weeknummer = tmpWeeknummer
                // this.$store.state.module_overviews[index+1].weeknummer = this.$store.state.module_overviews[index+1].weeknummer
            }
        }
    }
    
}

</script>
<style scoped >
.module-toetsoverview{
    width: 100%;
    padding: 0px 0px;

    
   .terug-knop{
    display: flex;
    justify-content: space-between;
    .tekst{
            margin-top: 30px;
            
        }
   }
    .module-aanpassen{
        display: flex;
        justify-content: space-between;
        padding: 0 10px; 

        .aanpassen{
            margin-top: 20px;
            margin-right: 20px;
            width: 200px;
        }
    }

    .overview{
        display: flex;
        border-top: 1px solid #000;
        margin-top: 20px;
    }
    

    .pdf{
        margin-top: 90px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        
        
        .btn{
            margin-left: 20px;
        }
        .bevestigen{
            margin-left: 20px;
            color: white;
            width: 100px;
            height: 30px;
            border-radius: 20px;
            background-color: #EC7926;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:hover{
                opacity: 0.6;
            }
        }
    }
    .contentBody {
        background: #fff;
        border-radius: 10px;
    }
    .mainBody{
        width: calc(100% - 304px);
        padding: 40px 0px;
    
    }
    .shadow {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }
    .p-5 {
        padding: 3rem !important;
    }
    .nav-item{
        width: calc(100% - 400px);
    }

    
}    

@media (max-width: 900px){
   .module-toetsoverview{
    .mainBody{
        width: 100%
    }
    .aanpassen{
    width: 100px !important;
   }

   .goback{
    margin-right: 50px;
   }
 
   

   
   
    
        
        
}
        
        
}
    
@media (max-width: 900px){
   .tekst{
    display: none;
   }
    
        
        
}
</style>