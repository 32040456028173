<template>
    <router-link class="nav-item" :to="{name: href}" :class="{'active': active}">

            <div class="icon" v-if="!inverted && mode!='logo'  ">
                <slot class="icon-slot"></slot>
            </div>

            <div class="icon-logo" v-if="!inverted && mode=='logo'">
                <slot class="icon-slot"></slot>
            </div>

            <div class="text" :class="{'active': active}" v-if="mode!='blue-bold'">
                {{ text }}
            </div>
            <div class="text-blue-bold" :class="{'active': active}" v-if="mode=='blue-bold'">
                {{ text }}
            </div>

            <div class="icon inverted" v-if="inverted">
                <slot class="icon-slot"></slot>
            </div>
            
    </router-link>
    
</template>


<script>
export default{
    props: ["text","inverted", "href", "active", "mode"],
    data(){
        return {

        }
    }
}
</script>


<style lang="scss">
.nav-item{
    display: flex;
    width: calc(100% - 60px);
    padding: 20px 30px 0px 30px;
    cursor: pointer;
    text-decoration: none;
    align-items: center;

    &.active{
        cursor: default;
    }

    &:hover{
        opacity: 0.5;

        &.active{
            opacity: 1;
        }
    }
    .icon{
        position: relative;
        width: 22px;
        height: 22px;
        margin-right: 15px;

        img{
            width: 22px;
            height: 22px;
        }

        &.inverted{
            margin-right: 0px;
            margin-left: 15px;
        }

        .icon-slot{
            width: 100%;
            height: 100%;

        }
        
    }
    .icon-logo{
        position: relative;
        width: 22px;
        height: 22px;
        margin-right: 15px;

        img{
            width: 90px;
            height: 60px;
        }

        &.inverted{
            margin-right: 0px;
            margin-left: 15px;
        }

        .icon-slot{
            width: 100%;
            height: 100%;

        }
    }
    .text{
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;

        color: #fff;

        &.active{
            color: $secondary-color;
        }


    }
    .text-blue-bold{
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: $base-color;

        &.active{
            color: $secondary-color;
        }
    }
}
</style>