<template>
    <div class="pagina-404">
        
        <div class="informatie404">
            <h1 class="linkback" @click="goHome()">Deze pagina bestaat niet, keer terug naar de homepagina</h1>
            <br>
            <h1 class="linkback" @click="$router.go(-1)">Of naar de vorige pagina</h1>
            <br>
            <h1 class="linkback" @click="goKlantenservice()">Is er iets misgegaan, neem contact op met onze klantenservice</h1>
       
        </div>

    </div>
        
        
    
</template>
<script>



export default{
    
    
    

    mounted() {
        document.title = "Eduface";    },
    methods: {
        goHome(){
            this.$router.push({
                name: "Home",
                
            })
        },
        goKlantenservice(){
            const emailAddress = 'info@blockbook.nl';
            const subject = 'Klantenservice';
            const body = 'Ons platform is vrij nieuw en nog steeds in development, er kunnen nog wat dingen misgaan of dingen zijn die nog niet werken. Stel hier al je vragen en wij zorgen ervoor om zo snel mogelijk te reageren.';

            const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

            window.location.href = mailtoLink;
        }
    }
    
}

</script>
<style >
.pagina-404{
    width: 100%;
    padding: 0px 0px;

    .informatie404{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 80px;

        .linkback{
            cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
        }

    }
    
    

    
}    


    

    


    

    

</style>