<template>
    <div class="better-input">
        <div class="input-header">
            <div>{{ title }}  <span class="hint">{{ hint }}</span> </div>
            <div>{{ subtitle }}</div>
            
            
        </div>
        
        <input 
            v-if="type != 'date' && type != 'select' && type != 'passwordd' && type != 'text' && type!='textsmall' && type != 'file'" @input="$emit('update:modelValue', $event.target.value)" :value="modelValue" :type="type" :placeholder="placeholder"/>
        <datepicker
            v-if="type == 'date'"
            ref="inputRef"
            :monday-first="true"
            :typeable="false"
            input-class="input-calendar"
            calendar-class="calendar"
            :modelValue="modelValue"
            :open-date="new Date('2001-01-01')"
            initial-view="year"
            @selected="handleDate"
        ></datepicker>
        <div v-if="mode=='feedback' && type=='select'" class="container-select">
            <div  
            class="input-select feedback-select" 
            v-if="type == 'select'" 
            tabindex="-1"
            @focusout="handleBlur" 
        >
            <input
                @input="handleSelectInput" 
                @click="handleClick"
                :value="SelectOptionValue ? options[modelValue] : modelValue" 
            />
            <!-- <div class="icon icon-down">
                <img src="/assets/arrow_down.svg"/>
            </div> -->
            <div v-if="Array.isArray(filteredOptions)" :class="{active: isSelected}" class="options1">
                <div 
                    class="option" 
                    v-for="(option, index) in filteredOptions" 
                    :key="index"
                    @click="selectOption(option)"
                >{{ option}}</div>
            </div>
            <div v-else :class="{active: isSelected}" class="options1">
                <div 
                    class="option" 
                    v-for="(key, index) in Object.keys(filteredOptions)" 
                    :key="index"
                    @click="selectOption(key)"
                >{{ SelectOptionValue ? options[key] : key }}</div>
            </div>
        </div>
        <div class="feedback-select1">

        </div>
        </div>
        
        <div 
            class="input-select" 
            v-if="type == 'select' && mode!='feedback'" 
            tabindex="-1"
            @focusout="handleBlur" 
        >
            <input
                
                @input="handleSelectInput" 
                @click="handleClick"
                :value="SelectOptionValue ? options[modelValue] : modelValue" 
            />
            <div class="icon icon-down">
                <img src="/assets/arrow_down.svg"/>
            </div>
            <div v-if="Array.isArray(filteredOptions)" :class="{active: isSelected}" class="options">
                <div 
                    class="option" 
                    v-for="(option, index) in filteredOptions" 
                    :key="index"
                    @click="selectOption(option)"
                >{{ option}}</div>
            </div>
            <div v-else :class="{active: isSelected}" class="options">
                <div 
                    class="option" 
                    v-for="(key, index) in Object.keys(filteredOptions)" 
                    :key="index"
                    @click="selectOption(key)"
                >{{ SelectOptionValue ? options[key] : key }}</div>
            </div>
        </div>

        <div class="container" v-if="mode=='feedback' && type=='text'">
            <textarea class="feedback"
            v-if="type=='text' && mode=='feedback'"
            @input="$emit('update:modelValue', $event.target.value)" 
            :placeholder="this.tekst"
            :value="modelValue"
        ></textarea>
        <div class="feedback1" >

        </div>
        </div>

        <div class="container-small" v-if="mode=='feedback' && type=='textsmall'">
            <textarea class="feedback-small"
            v-if="type=='textsmall' && mode=='feedback'"
            @input="$emit('update:modelValue', $event.target.value)" 
            :placeholder="this.tekst"
            :value="modelValue"
        ></textarea>
        <div class="feedback-small1" >

        </div>
        </div>
           <div class="container-small-text" v-if="mode=='feedback' && type=='passwordd'">
            <input 
        class="feedback-small"
        v-if="type=='passwordd' && mode=='feedback'"
        @input="$emit('update:modelValue', $event.target.value)" 
        :placeholder="tekst"
        :value="modelValue"
        type="password"
    >
        <div class="feedback-small2" >

        </div>
        </div>

        <div class="container-small-text" v-if="mode=='login'">
            <input 
        class="feedback-small"
        v-if=" mode=='login'"
        @input="$emit('update:modelValue', $event.target.value)" 
        :placeholder="tekst"
        :value="modelValue"
        type="text"
    >
        <div class="feedback-small2" >

        </div>
        </div>

        <div class="container-small-text" v-if="mode=='nummer'">
            <input 
        class="feedback-small"
        v-if=" mode=='nummer'"
        @input="$emit('update:modelValue', $event.target.value)" 
        :placeholder="tekst"
        :value="modelValue"
        type="number"
        :min="1"
        :max="15"
    >
        <div class="feedback-small2" >

        </div>
        </div>

        <textarea 
        v-if="type=='text' && mode!='feedback'"
            @input="$emit('update:modelValue', $event.target.value)" 
            :placeholder="placeholder"
            :value="modelValue"
        ></textarea>

        

        <div class="file" v-if="type=='file' && mode!='feedback' && mode!='feedback2' && mode!='vormvereisten' && mode!='module' && mode!='cover' && mode!='feedback-code'">
            <input class="file-upload" type="file" :accept="filetype" @change="fileSelected">
            <input class="file-upload-cover" :value="files.length == 0 ? 'Klik om bestand te uploaden...' : files[0].name" readonly>
        </div>

        <div class="file-feedback-upload">
            <div class="file-feedback" v-if="type=='file' && mode=='feedback2' && this.files[0]==null" @click="openFileInput"  >
                <input class="file-upload" ref="fileInput" type="file" :accept="filetype" style="display: none" @change="fileSelectedAllFiles">
                
                Upload opdracht
            </div>
            <div class="file-feedback1" v-if="type=='file' && mode=='feedback2' && this.files[0]!=null" @click="openFileInput"  >
                <input class="file-upload" ref="fileInput" type="file" :accept="filetype" style="display: none" @change="fileSelectedAllFiles">
                {{ files[0].name }}
                {{ fileExtension }}
            </div>



            <div class="file-feedback" v-if="type=='file' && mode=='feedback-code' && this.files[0]==null" @click="openFileInput"  >
                <input class="file-upload" ref="fileInput" type="file" :accept="filetype" style="display: none" @change="fileSelectedCode">
                
                Upload code
            </div>
            <div class="file-feedback1" v-if="type=='file' && mode=='feedback-code' && this.files[0]!=null" @click="openFileInput"  >
                <input class="file-upload" ref="fileInput" type="file" :accept="filetype" style="display: none" @change="fileSelectedCode">
                {{ files[0].name }}
                
            </div>
            <div class="file-feedback" v-if="type=='file' && mode=='feedback-code' && this.files[0]==null" @click="openFileInput"  >
                <input class="file-upload" ref="fileInput" type="file" :accept="filetype" style="display: none" @change="fileSelectedCode">
                
                Upload code
            </div>
            <div class="file-feedback1" v-if="type=='file' && mode=='feedback-code' && this.files[0]!=null" @click="openFileInput"  >
                <input class="file-upload" ref="fileInput" type="file" :accept="filetype" style="display: none" @change="fileSelectedCode">
                {{ files[0].name }}
                
            </div>
            <div class="file-feedback" v-if="type=='file' && mode=='cover' && this.files[0]==null" @click="openFileInput"  >
                <input class="file-upload" ref="fileInput" type="file" :accept="filetype" style="display: none" @change="fileSelected">
                
                Upload cover(jpg)
            </div>
            <div class="file-feedback1" v-if="type=='file' && mode=='cover' && this.files[0]!=null" @click="openFileInput"  >
                <input class="file-upload" ref="fileInput" type="file" :accept="filetype" style="display: none" @change="fileSelected">
                {{ files[0].name }}
                
            </div>
            <div class="file-feedback" v-if="type=='file' && mode=='feedback' && this.files[0]==null" @click="openFileInput"  >
                <input class="file-upload" ref="fileInput" type="file" :accept="filetype" style="display: none" @change="fileSelected">
                
                Upload opdracht(pdf)
            </div>
            <div class="file-feedback1" v-if="type=='file' && mode=='feedback' && this.files[0]!=null" @click="openFileInput"  >
                <input class="file-upload" ref="fileInput" type="file" :accept="filetype" style="display: none" @change="fileSelected">
                {{ files[0].name }}
                
            </div>
            <div  class="file-feedback" v-if="type=='file' && mode=='module' && this.files[0]==null && $i18n.locale === 'en'" @click="openFileInput"  >
                <input class="file-upload" ref="fileInput" type="file" :accept="filetype" style="display: none" @change="fileSelected">
                
                Upload lesson material(pdf)
            </div>
            <div  class="file-feedback" v-if="type=='file' && mode=='module' && this.files[0]==null && $i18n.locale === 'nl'" @click="openFileInput"  >
                <input class="file-upload" ref="fileInput" type="file" :accept="filetype" style="display: none" @change="fileSelected">
                
                Upload lesmateriaal(pdf)
            </div>
            <div class="file-feedback1" v-if="type=='file' && mode=='module' && this.files[0]!=null" @click="openFileInput"  >
                <input class="file-upload" ref="fileInput" type="file" :accept="filetype" style="display: none" @change="fileSelected">
                {{ files[0].name }}
                
            </div>
            <div class="file-feedback2" v-if="type=='file' && mode=='vormvereisten' && this.files1[0]==null" @click="openFileInput1"  >
                <input class="file-upload" ref="fileInput1" type="file" :accept="filetype" style="display: none" @change="fileSelected1">
                
                Upload vormvereisten(pdf)<br>
                Optioneel
            </div>
            <div class="file-feedback3" v-if="type=='file' && mode=='vormvereisten' && this.files1[0]!=null" @click="openFileInput1"  >
                <input class="file-upload" ref="fileInput1" type="file" :accept="filetype" style="display: none" @change="fileSelected1">
                {{ files1[0].name }}
                
            </div>
        </div>
        
        
        
        <slot></slot>
    </div>
</template>
<script>
import Datepicker from 'vuejs3-datepicker';
import getBase64 from '../../utils/getBase64.js'

export default{
    components: {Datepicker},
    props: ["title", "tekst", "type", 'modelValue', "subtitle", "hint", "options", "filetype", "placeholder", "SelectOptionValue", "mode"],
    data(){
        return{
            isSelected: false,
            files: [],
            files1: [],
            files2: [],
        }
    },
    computed:{
        filteredOptions(){
            
            if(Array.isArray(this.options)){
                if(this.modelValue == null){
                    return null
                }

                return this.options.filter(option => option.toLowerCase().includes(this.modelValue.toLowerCase()) && option != this.modelValue)
            }else{
                if(this.modelValue == null){
                    return {}
                }
                return Object.fromEntries(Object.entries(this.options).filter(([option,v]) => ("" + option).toLowerCase().includes(("" + this.modelValue).toLowerCase()) && option != this.modelValue));
            }
            
        },
        fileExtension() {
      if (this.files.length > 0 && this.files[0].name) {
        const name = this.files[0].name;
        const extension = name.split('.').pop();
        return `.${extension}`;
      }
      return '';
    },
    },
    methods: {
        openFileInput() {
      this.$refs.fileInput.click();
    },
    openFileInput1() {
      this.$refs.fileInput1.click();
    },
    openFileInput2() {
      this.$refs.fileInput2.click();
    },
      
        fileSelected(e) {
            this.files = e.target.files
            getBase64(e.target.files[0], base64 => {
                this.$emit('update:modelValue', base64)
            })

        },
        fileSelectedAllFiles(e) {
            this.files = e.target.files
            const extension = this.fileExtension;
            if(this.fileExtension == ".pdf"){
                getBase64(e.target.files[0], base64 => {
                    console.log('pdf')
                this.$emit('update:modelValue', base64);
                this.$emit('update:extensionValue', extension);
            })
            }else{
                const file = this.files[0];
                console.log('anders')
                // Create a FileReader to read the file's content
                const reader = new FileReader();
                
                // Set up what happens when the reading operation is completed
                reader.onload = (loadEvent) => {
                    // Now the file's content is available as a string
                    const fileContent = loadEvent.target.result;
                    
                    // Emitting the file content (or any other detail you wish to include)
                    this.$emit('update:modelValue', fileContent);
          this.$emit('update:extensionValue', extension);
                };

                // Start reading the file's content as a text string
                reader.readAsText(file);
            }
            

        },
        fileSelectedCode(e) {
    this.files = e.target.files;
    if (this.files.length > 0) {
        const file = this.files[0];
        
        // Create a FileReader to read the file's content
        const reader = new FileReader();
        
        // Set up what happens when the reading operation is completed
        reader.onload = (loadEvent) => {
            // Now the file's content is available as a string
            const fileContent = loadEvent.target.result;
            
            // Emitting the file content (or any other detail you wish to include)
            this.$emit('update:modelValue', fileContent);
        };

        // Start reading the file's content as a text string
        reader.readAsText(file);
    }
},
        fileSelected1(event) {
            this.files1 = event.target.files
            getBase64(event.target.files[0], base64 => {
                this.$emit('update:modelValue', base64)
            })

        },
        fileSelected2(event2) {
            this.files2 = event2.target.files
            getBase64(event2.target.files[0], base64 => {
                this.$emit('update:modelValue', base64)
            })

        },
        handleDate(date){
            console.log("selecting: " + date)
            if(date == null){
                this.$emit('update:modelValue', null)
            }else{
                date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
                this.$emit('update:modelValue', date.toISOString().split('T')[0].replace("-", "/").replace("-", "/"))
            }
            this.$emit('modified')
            
        },
        handleClick(){
            const vm = this
            
            this.$emit('update:modelValue', "")
            this.$emit('modified')
            setTimeout(() => vm.isSelected = true, 150)


            
        },
        handleBlur(){
            const vm = this
            setTimeout(() => vm.isSelected = false, 200)
        },
        handleSelectInput(event){
            this.$emit('update:modelValue', event.target.value)
        },
        selectOption(option){
            this.$emit('update:modelValue', option)
            this.$emit('modified')
        }
    }
}
</script>

<style lang="scss">
.better-input{
    margin: 10px 0px;

    .vuejs3-datepicker__value{
        border: 1px solid #787878
    }

    .container-small{
        position: relative; /* Create a positioning context for child divs */
        width: 100%; /* Set the width and height as needed */
        height: 100px;
        .feedback-small1{
            position: absolute; /* Position this div absolutely within the container */
            top: 10px; /* Adjust top, left, right, and bottom as needed */
            
            width: 440px;
            height: 100px;
            transform: skew(-15deg);
            border-radius: 8px;
            margin-left: 10px;
            background-color: $base-color;
            z-index: 1;
        }
        .feedback-small2{
            position: absolute; /* Position this div absolutely within the container */
            top: 10px; /* Adjust top, left, right, and bottom as needed */
            
            width: 440px;
            height: 35px;
            transform: skew(-15deg);
            border-radius: 8px;
            margin-left: 10px;
            background-color: $base-color;
            z-index: 1;
        }

        .feedback-small{
        position: relative; 
            transform: skew(-15deg);
            border-radius: 8px;
            z-index: 2;
            background-color: #fff;
            width: 400px;
            display: flex;
            justify-content: center;
                align-items: center;
                flex-direction: column;
                

        }
    }
    .container-small-text{
        position: relative; /* Create a positioning context for child divs */
        width: 100%; /* Set the width and height as needed */
        height: 50px;
        .feedback-small1{
            position: absolute; /* Position this div absolutely within the container */
            top: 10px; /* Adjust top, left, right, and bottom as needed */
            
            width: 440px;
            height: 100px;
            transform: skew(-15deg);
            border-radius: 8px;
            margin-left: 10px;
            background-color: $base-color;
            z-index: 1;
        }
        .feedback-small2{
            position: absolute; /* Position this div absolutely within the container */
            top: 10px; /* Adjust top, left, right, and bottom as needed */
            
            width: 440px;
            height: 35px;
            transform: skew(-15deg);
            border-radius: 8px;
            margin-left: 10px;
            background-color: $base-color;
            z-index: 1;
        }

        .feedback-small{
        position: relative; 
            transform: skew(-15deg);
            border-radius: 8px;
            z-index: 2;
            background-color: #fff;
            width: 400px;
            display: flex;
            justify-content: center;
                align-items: center;
                flex-direction: column;
                

        }
    }
    .container-select{
        position: relative; /* Create a positioning context for child divs */
        width: 100%; /* Set the width and height as needed */
        height: 100px;

        .feedback-select1{
            position: absolute; /* Position this div absolutely within the container */
            top: 10px; /* Adjust top, left, right, and bottom as needed */
           
            width: 400px;
            height: 40px;
            transform: skew(-15deg);
            border-radius: 8px;
            margin-left: 10px;
            background-color: $base-color;
            z-index: 1;
            
        }

        .feedback-select{
            position: relative; 
            border-radius: 8px;
            z-index: 2;
            background-color: #fff;
            width: 400px;
        
            justify-content: center;
                align-items: center;
                flex-direction: column;
                left: 1px;



                .options1{
            position: absolute;
            display: none;
            background-color: #e6e6e6;
            max-height: 100px;
            width: calc(100% + 10px);
            overflow-y: auto;
            z-index: 101;
          

            &.active{
                display: block;
            }

            .option{
                display: flex;
                height: 30px;
                padding-left: 10px;
                align-items: center;
                cursor: pointer;

                &:hover{
                    background-color: $white;
                    color: rgba(0,0,0,0.9)
                }
            }
        }     
        }
    }
    .container{
        position: relative; /* Create a positioning context for child divs */
        width: 100%; /* Set the width and height as needed */
        height: 100px;

        .feedback1{
            position: absolute; /* Position this div absolutely within the container */
            top: 10px; /* Adjust top, left, right, and bottom as needed */
           
            width: 640px;
            height: 100px;
            transform: skew(-15deg);
            border-radius: 8px;
            margin-left: 10px;
            background-color: $base-color;
            z-index: 1;
        }
        .feedback{
        position: relative; 
            transform: skew(-15deg);
            border-radius: 8px;
            z-index: 2;
            background-color: #fff;
            width: 600px;
            display: flex;
            justify-content: center;
                align-items: center;
                flex-direction: column;
              

        }
    }
    
        
    textarea{
        // overflow: hidden !important;
        padding: 5px 20px;
        width: calc(100% - 40px);
        min-height: 80px;
        border: 1px solid #787878;
        border-radius: 4px;
        cursor: text;
      

       
    }
    .file-feedback-upload{
        display: flex;

        .file-feedback{
        width: 300px;
            height: 60px;
            background-color: #eeeff8;
            cursor: pointer;
            border-radius: 8px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 20px;
            margin-right: 20px;
            border: 1px solid black;
            
    }
    .file-feedback1{
        width: 300px;
            height: 60px;
            background-color: $secondary-color;
            cursor: pointer;
            border-radius: 8px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 15px;
            margin-right: 20px;
            
    }
    .file-feedback2{
        width: 300px;
            height: 60px;
            background-color: #eeeff8;
            cursor: pointer;
            border-radius: 8px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 15px;
            margin-right: 20px;
            
    }
    .file-feedback3{
        width: 300px;
            height: 60px;
            background-color: $secondary-color;
            cursor: pointer;
            border-radius: 8px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 15px;
            margin-right: 20px;
            
    }
    }
    

    .file{
        position: relative;
        height: 40px;

        .file-upload{
            opacity: 0;
            height: 20px;
            cursor: pointer !important;
            z-index: 10;
        }

        .file-upload-cover{
            position: relative;
            top: -32px;
            pointer-events:none;
            cursor: default !important;
            color: #787878;
            
        }


    }

    .input-select{
        position: relative;
        width: 300px;

        .icon{
            position: absolute;
            top: 5px;
            right: 5px;
            height: 20px;
            width: 20px;
            cursor: pointer;
            
            img{
                width: 100%;

            }
        }

        .options{
            position: absolute;
            display: none;
            background-color: #e6e6e6;
            max-height: 100px;
            width: calc(100% + 10px);
            overflow-y: auto;
            z-index: 101;
            
            

            &.active{
                display: block;
            }

            .option{
                display: flex;
                height: 30px;
                padding-left: 10px;
                align-items: center;
                cursor: pointer;

                &:hover{
                    background-color: $white;
                    color: rgba(0,0,0,0.9)
                }
            }
        }

        .options1{
            position: absolute;
            display: none;
            background-color: #e6e6e6;
            max-height: 100px;
            width: calc(100% + 10px);
            overflow-y: auto;
            z-index: 101;
         

            &.active{
                display: block;
            }

            .option{
                display: flex;
                height: 30px;
                padding-left: 10px;
                align-items: center;
                cursor: pointer;

                &:hover{
                    background-color: $white;
                    color: rgba(0,0,0,0.9)
                }
            }
        }
    }

    .input-header{
        display: flex;
        justify-content: space-between;

        .hint{
            margin: 10px;
            font-size: 13px;
            color: rgba(0,0,0,0.5)

        }
    }
    

    input{
        padding: 5px 20px;
        width: calc(100% - 40px);
        height: 20px;
        border: 1px solid #787878;
        border-radius: 4px;
    }

    .calendar{
        position: absolute;
        top: unset;
        bottom: 60px;
    }

    .input-calendar{
        padding: 5px 10px;
        width: calc(100%);
        height: 33px;
        border: 1px solid #787878;
        border-radius: 4px;
    }

    .vuejs3-datepicker{
        width: 100%;
    }

    .vuejs3-datepicker__calendar-topbar{
        background-color: $base-color;
    }

    .vuejs3-datepicker__typeablecalendar{
        position: absolute;
        top: 8px;
        display: flex;
        justify-content: flex-end;
        width: calc(100% - 20px);
        pointer-events: none;
    }
}


@media (max-width: 576px) {
    .better-input{
        .container-small-text{
        position: relative; /* Create a positioning context for child divs */
        width: 100%; /* Set the width and height as needed */
        height: 50px;
        .feedback-small1{
            position: absolute; /* Position this div absolutely within the container */
            top: 10px; /* Adjust top, left, right, and bottom as needed */
            
            width: 320px;
            height: 100px;
            transform: skew(-15deg);
            border-radius: 8px;
            margin-left: 10px;
            background-color: $base-color;
            z-index: 1;
        }
        .feedback-small2{
            position: absolute; /* Position this div absolutely within the container */
            top: 10px; /* Adjust top, left, right, and bottom as needed */
            
            width: 320px;
            height: 35px;
            transform: skew(-15deg);
            border-radius: 8px;
            margin-left: 10px;
            background-color: $base-color;
            z-index: 1;
        }

        .feedback-small{
        position: relative; 
            transform: skew(-15deg);
            border-radius: 8px;
            z-index: 2;
            background-color: #fff;
            width: 300px;
            display: flex;
            justify-content: center;
                align-items: center;
                flex-direction: column;
                

        }
    }
    .container-select{
        position: relative; /* Create a positioning context for child divs */
        width: 100%; /* Set the width and height as needed */
        height: 80px;

        .feedback-select1{
            position: absolute; /* Position this div absolutely within the container */
            top: 10px; /* Adjust top, left, right, and bottom as needed */
           
            width: 300px;
            height: 40px;
            transform: skew(-15deg);
            border-radius: 8px;
            margin-left: 10px;
            background-color: $base-color;
            z-index: 1;
            
        }

        .feedback-select{
            position: relative; 
            border-radius: 8px;
            z-index: 2;
            background-color: #fff;
            width: 300px;
        
            justify-content: center;
                align-items: center;
                flex-direction: column;
                left: 1px;



                .options1{
            position: absolute;
            display: none;
            background-color: #e6e6e6;
            max-height: 100px;
            width: calc(100% + 10px);
            overflow-y: auto;
            z-index: 101;
          

            &.active{
                display: block;
            }

            .option{
                display: flex;
                height: 30px;
                padding-left: 10px;
                align-items: center;
                cursor: pointer;

                &:hover{
                    background-color: $white;
                    color: rgba(0,0,0,0.9)
                }
            }
        }     
        }
    }
    }
    
    
}

</style>



