<template>
    <div class="profiel-leraar">
        <ProfielLeraar></ProfielLeraar>
    </div>
</template>
<script>
import ProfielLeraar from '@/components/Dashboard/ProfielLeraar.vue';
export default{
    components: {ProfielLeraar},
    mounted() {
        document.title = "Eduface | Profiel";    },
  
}
</script>
<style lang="scss">
.profiel-leraar{
    h1{
        color: $base-color;
    }
}
</style>