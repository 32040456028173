<template>
    <div class="feedback-overview1">
      <div class="container">
        <div class="feedback-aanpassen">
           
              
                    <div class="terug-blockbook">
                      <NavItem  @click="$router.go(-1)" class="goback" :inverted="true" >
                      <img src="/assets/arrowback_blauw.svg" />
                      </NavItem>  
                      <img class="imgblockbook" src="/assets/eduface.png" />
                  </div>
                    <div><img class="imgblockbookroze" src="/assets/feedbackgroengrijs.png" /></div>
                    <div class="aanpassen-knop">
                      <div v-if=" !bewerkModus && feedback.taal=='nl'" class="aanpassen1" @click="bewerken()">Feedback aanpassen</div>
                    <div v-if=" bewerkModus && feedback.taal=='nl'" class="aanpassen2" @click="annuleerBewerken()">Aanpassing annuleren</div>
                    <div v-if=" bewerkModus && feedback.taal=='nl'" class="aanpassen1" @click="slaOpBewerken()">Aanpassing opslaan</div>
                    <div v-if=" !bewerkModus && feedback.taal=='en'" class="aanpassen1" @click="bewerken()">Adjust feedback</div>
                    <div v-if=" bewerkModus && feedback.taal=='en'" class="aanpassen2" @click="annuleerBewerken()">Cancel adjusting</div>
                    <div v-if=" bewerkModus && feedback.taal=='en'" class="aanpassen1" @click="slaOpBewerken()">Save adjustment</div>
            
                    </div>
                    
            
            <div><img class="imgblockbookoranje" src="/assets/feedbackgroen.png" /></div>
  
            
            
        </div>
        <div class="content">
  
          <div class="feedbackarea">
              <div class="feedback-info">
                  <h1>Feedback</h1>
                  <div :class="{'bewerken': bewerkModus}">{{ feedback.titel }}</div>
                  <div :class="{'bewerken': bewerkModus}">{{ feedback.leerling_anoniem }} </div>
            
              </div>
              <div class="feedbackparts">
            <div v-if="!bewerkModus"
              v-for="(line, index) in feedbackLines"
              :key="index"
              class="feedback-part"
            >
              <!-- Render plain text if bewerkModus is false -->
              <div>
            <span v-if="!isExpanded[index] && isMoreThanTwoWords(line)">{{ limitTextToTwoWords(line) }}</span>
            <span v-else>{{ line }}</span>
            <div v-if="isMoreThanTwoWords(line)" @click="toggleExpand(index)" class="enlarge">
              <img :src="isExpanded[index] ? '/assets/shrink2.svg' : '/assets/enlarge2.svg'" width="22" />
            </div>
          </div>
              <!-- Render Input component if bewerkModus is true -->
              <!-- <Input v-else v-model="feedbackLines[index]" type="text" /> -->
             <br>
           
            </div>
            
            <div v-if="bewerkModus"
              v-for="(line, index) in feedbackLines"
              :key="index"
              class="feedback-part2"
            >
              <!-- Render plain text if bewerkModus is false -->
              <!-- <div>{{ line }}</div> -->
              <!-- Render Input component if bewerkModus is true -->
              <Input v-model="feedbackLines[index]" type="text" />
            </div>
          </div>
          <br>
          <br>
          <button v-if="$i18n.locale === 'nl'" @click="exporteerFeedback" class="exporteer">Exporteer feedback</button>
          <button v-if="$i18n.locale === 'en'" @click="exporteerFeedback" class="exporteer">Export feedback</button>
          <!-- <button v-if="$i18n.locale === 'nl'" @click="downloadPdf" class="exporteer">Exporteer2 feedback</button> -->
          <button v-if="$i18n.locale === 'en'" @click="downloadPdf" class="exporteer">Export2 feedback</button>  
        </div>
        </div>
  
  
        <div class="feedback-aanpassen"><img class="imgblockbookblauw" src="/assets/feedbackgrijs.png" /></div>
      </div>
        
        
       
        
    </div>
        
        
    
  </template>
  <script>
  import Input from '@/components/Form/BetterInput.vue'
  import NavItem from '@/components/Dashboard/NavItem.vue'
  import ZijMenu from '@/components/Module/ZijMenu.vue'
  import OverviewLink from '@/components/Module/OverviewLink.vue'
  import jsPDF from 'jspdf'
  import { PDFDocument, rgb, AnnotationFlags, AnnotationBorderStyle } from 'pdf-lib';
  
  export default{
    components: {NavItem, ZijMenu, OverviewLink, Input},
    props: ["mode"],
    data () {
        return{
            bewerkModus: false,
            isExpanded: [],
            selectedMainOverviewId: null,
            selectedSubOverviewId: null,
            selectedMainOverviewTitle: '',
            selectedSubOverview: null,
            selectedOverview: null,
            selectedPdf: null,
            epub_attached: false,
            epub_uploading: false,
            epub_base64: "",
            search: "",
        }
  
    },
    
  
    mounted() {
      document.title = "Eduface";   
  
    
    },
    computed: {
        feedback(){
            return this.$store.state.feedback
        },
        feedbackLines() {
        return this.feedback.feedback.split('\n\n');
      },
    },
    
    async created() {
        await this.$store.dispatch("get_feedback", this.$route.params.id2)
       
    
    },
  
  
    methods:{
      async downloadPdf() {
  try {
    // Use the signed PDF URL from the backend response
    const pdfUrl = this.feedback.pdfUrl;

    // Create a temporary link element to trigger the download
    const a = document.createElement('a');
    a.href = pdfUrl;
    a.download = this.feedback.titel;  // Set the filename for the download
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);  // Cleanup the DOM
  } catch (error) {
    console.error('Error downloading PDF:', error);
  }
}




,

      async exporteerFeedback() {
  const doc = new jsPDF();
  const maxWidth = 190;
  const imageWidth = 50;
  const startY = 10;
  const imageHeight = 16;
  const spaceAfterImage = 10;

  const topRightImageUrl = '/assets/feedbackgroen2.png';
  const bottomLeftImageUrl = '/assets/feedbackgrijs2.png';
  const imageUrl = '/assets/logo.png';

  const loadImgData = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  const topRightImgData = await loadImgData(topRightImageUrl);
  const bottomLeftImgData = await loadImgData(bottomLeftImageUrl);
  const imgData = await loadImgData(imageUrl);

  const addBackgroundImages = () => {
    doc.addImage(topRightImgData, 'PNG', doc.internal.pageSize.getWidth() - 30, 0, 30, 30);
    doc.addImage(bottomLeftImgData, 'PNG', 0, doc.internal.pageSize.getHeight() - 20, 20, 20);
    doc.addImage(imgData, 'PNG', 10, startY, imageWidth, imageHeight);
  };

  addBackgroundImages();

  let textStartY = startY + imageHeight + spaceAfterImage;



  // Add the first text below the logo
  doc.setFontSize(16);
  doc.text(this.feedback.leerling_anoniem, 10, textStartY);
  textStartY += 10 + spaceAfterImage; // Adjusting space after the first text

  const textLines = doc.splitTextToSize(this.feedback.feedback, maxWidth);

  textLines.forEach((line) => {
    if (textStartY > 280) {
      doc.addPage();
      addBackgroundImages();
      textStartY = startY + imageHeight + spaceAfterImage;
    }
    doc.text(line, 10, textStartY);
    textStartY += 10;
  });

  doc.save(this.feedback.titel);
},
      limitTextToTwoWords(text) {
      const words = text.split(' ');
      if (words.length > 10) {
        return words.slice(0, 10).join(' ') + '...';
      }
      return text;
    },
    isMoreThanTwoWords(text) {
      const words = text.split(' ');
      return words.length > 10;
    },
    toggleExpand(index) {
      this.isExpanded[index] = !this.isExpanded[index];
    },
        async onEpubPicked (event) {
            const files = event.target.files
            this.epub_uploading = true
            getBase64(files[0], base64 => {
                this.epub_uploading = false
                this.epub_attached = true 
                this.epub_base64 = base64
                // console.log(this.base64)
            })
            
        },
        submit_formulier(){
            
            this.selectedPdf = null;
            this.epub_attached = false;
            this.$refs.pdfInput.value = '';
        },
        bewerken(){
            this.bewerkModus = true
        },
        async slaOpBewerken(){
          this.$store.state.feedback.feedback = this.feedbackLines.join('\n\n');
  
    
            await this.$store.dispatch("feedbackWijzigen", this.$store.state.feedback)
            this.bewerkModus = false
            await this.$store.dispatch("get_feedback", this.$route.params.id)
        },
        async annuleerBewerken(){
            await this.$store.dispatch("get_feedback", this.$route.params.id)
            this.bewerkModus = false
        },
    }
    
  }
  
  </script>
  <style lang="scss">
  .feedback-overview1{
    width: 100%;
    padding: 0px 0px;
    position: relative;

    .exporteer{
      position: absolute;
          right: 3px;
          bottom: 3px;
    }
  
    .container{
      position: relative; /* Create a positioning context for child divs */
          width: 100%; /* Set the width and height as needed */
          height: 100px;
  
          .feedback-aanpassen{
      position: absolute;
        display: flex;
       
        padding: 0 10px; 
        z-index: 1;
  
        .terug-blockbook{
          display: flex;
        }
        
        .imgblockbook{
      width: 150px;
      height: 40px;
      margin-top: 40px;
          
      margin-left: 10px;
      left: 0px;
    }
    .imgblockbookroze{
      width: 250px;
      height: 200px;
      margin-right: 80px;
      margin-top: -45px;
      z-index: 1;
    }
    .imgblockbookoranje{
      width: 250px;
      height: 200px;
      margin-right: 80px;
      margin-top: -45px;
      right: 0px;
      z-index: 1;
    }
    .imgblockbookblauw{
      width: 250px;
      height: 200px;
      margin-left: -100px;
      margin-top: -200px;
      margin-bottom: -200px;
      right: 0px;
      z-index: 1;
    }
    
        .goback{
          height: 80px;
        }
  
        .aanpassen-knop{
  
          width: 600px;
          display: flex;
          flex-wrap: wrap;
          
  
          .aanpassen1{
            margin-top: 20px;
            margin-right: 20px;
            width: 30%;
            height: 40px;
            color: $base-color;
            font-weight: bold;
            border: 1px solid black;
            border-radius: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            margin-top: 30px;
  
            cursor: pointer;
          &:hover{
                opacity: 0.6;
            }
        }
        .aanpassen2{
            margin-top: 20px;
            margin-right: 20px;
            width: 30%;
            height: 40px;
            color: $base-color;
            font-weight: bold;
            border: 1px solid black;
            border-radius: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            margin-top: 30px;
            margin-left: -80px;
            cursor: pointer;
          &:hover{
                opacity: 0.6;
            }
        }
        }
        
    }
  
    .content{
      
      display: flex;
      align-items: center;
      justify-content: center;
      
      
      .feedbackarea{
          position: relative;
        margin-top: 100px;
      border: 1px solid black;
      margin-left: 50px;
      margin-right: 50px;
      margin-bottom: 50px;
   
      align-items: center;
      justify-content: center;
      
      border-radius: 20px;
      background-color: white;
      z-index: 2;
      .feedback-info{
          margin-left: 20px;
          color: $base-color;
          font-weight: bold;
      }
      
      .feedbackparts{
  
          display: flex;
        flex-wrap: wrap;


        
          .feedback-part{
              background-color: #eeeff8;
              border-radius: 20px;
              color: $base-color;
              font-weight: bold;
              margin: 15px; /* Example margin for spacing between elements */
          width: calc(30% - 6px); /* Adjust the width as needed */
          min-height: 200px;
          height: 100%;
          border: 1px solid black;
          padding: 10px;
          text-align: center;
          justify-content: center;
          align-items: center;
          position: relative;

          .enlarge{
          position: absolute;
          right: 5px;
          bottom: 0px;

          cursor: pointer;
          &:hover{
                opacity: 0.6;
            }
        }
  
          @media (max-width: 768px) {
            width: calc(45% - 20px); /* Adjust for smaller screens */
          }
  
          @media (max-width: 480px) {
            width: calc(100% - 20px); /* Adjust for even smaller screens */
          }
      }
      .feedback-part2{
              
              border-radius: 20px;
              color: $base-color;
              font-weight: bold;
              min-height: 150px; /* Example margin for spacing between elements */
          width: calc(46% - 6px); /* Adjust the width as needed */
          
          
          padding: 10px;
  
          @media (max-width: 768px) {
            width: calc(50% - 20px); /* Adjust for smaller screens */
          }
  
          @media (max-width: 480px) {
            width: calc(100% - 20px); /* Adjust for even smaller screens */
          }
      }
      }
      
    }
    }
    }
  
    
  
    
    .terug-knop{
        display: flex;
        justify-content: space-between;
        .tekst{
            margin-top: 30px;
        }
    }
  
    .order-button{
        margin-right: 16px;
        margin-top: 40px;
  
        &:last-child{
            margin-right: 0px;
        }
    }
  
    .overview{
        display: flex;
       
        margin-top: 20px;
    }
    
    
    
  
    .pdf{
        margin-top: 90px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        
        
        .btn{
            margin-left: 20px;
        }
        .bevestigen{
            margin-left: 20px;
            color: white;
            width: 100px;
            height: 30px;
            border-radius: 20px;
            background-color: #EC7926;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:hover{
                opacity: 0.6;
            }
        }
    }
    .contentBody {
        background: #fff;
        border-radius: 10px;
    }
    .mainBody{
        width: 100%;
        padding: 40px 0px;
    
    }
    .shadow {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }
    .p-5 {
        padding: 3rem !important;
    }
  
  
    h3.bewerken{
        margin-bottom: 40px;
        
    }
  
    .nav-item{
        width: calc(100% - 400px);
    }
  
    
  }    
  
  @media (max-width: 900px){
   .module-overview{
    .mainBody{
        width: 100%
    }
   }
   .feedback-overview1{
    .container{
    .feedback-aanpassen{
    .aanpassen-knop{
      .aanpassen2{
    margin-left: 0px;
    width: 150px ;
   }
   .aanpassen1{
    width: 150px;
   }
    }
    
  }
   } 
   }
   
  
   
  
   .goback{
    margin-right: 50px;
   }
   .imgblockbookroze{
    display: none;
   }
  
    
        
        
  }
  
  @media (max-width: 650px){
    .imgblockbook{
    display: none;
   }
   .feedback-overview1{
    .aanpassen-knop{
    margin-left: -50px;
  
    .aanpassen1{
    width: 100px !important;
   }
   .aanpassen2{
    width: 100px !important;
   }
   }
   }
  
  
  
  }
  
  
  
  </style>