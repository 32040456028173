function getBase64(file, cb) {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
        cb(reader.result)
    }
    reader.onerror = function (error) {
        // TODO: Better error handling
      console.log('Error: ', error)
    }
}
module.exports = getBase64