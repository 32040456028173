<template>
    <div class="feedback">
        <Popup class="module-popup" :show="showFinalPopup" @close="afronden()">
            <h2>{{ $t('assignmentAddedSuccess') }}</h2>
            <BetterButton 
                :text="$t('complete')"
                @click="afronden"
            ></BetterButton>
        </Popup>
        <Popup class="module-popup" :show="showWaitPopup" >
            <h2>{{ $t('pleaseWait') }}</h2>
            <p>{{ $t('processingAssignment') }}</p>
            <p>{{ $t('thisMayTakeAWhile') }}</p>
        </Popup>
        <Popup class="feedback-popup" :show="beoordelingpopup" @close="closePopup()">
            <h2 class="beoordeling">{{ $t('assessmentForms') }}</h2>
            <div v-for="(item, index) in beoordelingsformulier" :key="index">
                    
                    <div>
                        <input 
                            type="radio"
                            :id="'item_' + index"
                            :value="index"
                            v-model="selectedItem"
                            required
                            @change="saveSelectedItemId(item.id)"
                        />
                        <label :for="'item_' + index">{{ item.vak }}: {{ item.titel }} </label>
                    </div>
                </div>
                <div class="bevestig-beoordeling" @click="bevestigBeoordeling" v-if="selectedItemId!=null">
                    {{ $t('confirm') }}
                </div>
        </Popup>
        <Sidebar mode="teacher" active="Opdrachten"></Sidebar>
        <div class="content" :class="{'mobile-extend': toggledSidebar}">
            <DashboardHeader mode="module" :hideSearch="true"></DashboardHeader>
            
            <div class="scrollable-content" v-if="$i18n.locale === 'nl'">
                <div class="nieuwe-beoordeling">
                    <h2>Samen met {{this.$store.state.onderwijsassistent}} nieuwe opdracht maken</h2>
      
             
                 

                </div>
                <!-- <Input2
                    
                    v-model="leerling_anoniem"
                    type="textsmall"
                    mode="feedback"
                    tekst="Leerling(uniek per student)*"
                ></Input2> -->
              

                    <div v-for="(opdracht, index) in opdrachten" :key="index" class="file-feedback-upload">
    <div class="file-feedback" v-if="!opdracht" @click="openFileInput(index)">
        <input 
            class="file-upload" 
            :ref="'fileInput' + index" 
            type="file" 
            :accept="'.pdf, .xlsx, .csv, .docx, .txt, .html, .css, .py, .java'" 
            style="display: none" 
            @change="fileSelectedAllFiles($event, index)"
        >
        Upload opdracht
    </div>
    <div class="file-feedback1" v-else @click="openFileInput(index)">
        <input 
            class="file-upload" 
            :ref="'fileInput' + index" 
            type="file" 
            :accept="'.pdf, .xlsx, .csv, .docx, .txt, .html, .css, .py, .java'" 
            style="display: none" 
            @change="fileSelectedAllFiles($event, index)"
        >
        {{ opdracht.name }}
    </div>
    <Input2
                    
                    v-model="leerlingen_anoniem[index]"
                    type="textsmall"
                    mode="feedback"
                    tekst="Leerling(uniek per student)*"
                ></Input2>
</div>


<div class="slides-options">
    <a class="extra-week" href="javascript:void(0)" @click="extraWeek">Nog een student toevoegen </a>
    <a v-if="opdrachten.length > 1" class="extra-week" href="javascript:void(0)" @click="minderWeek">Een student verwijderen</a>
</div>    


        
                

                <div class="selectfile" @click="bevestig" >
                    <!-- validateAndSendData -->
                        Bevestigen
                </div>

               
            </div>
            <div class="scrollable-content" v-if="$i18n.locale === 'en'">
                <div class="nieuwe-beoordeling">
                    <h2>Generate new feedback</h2>
                    <div class="nieuwe-button" @click="$router.push({name:'new-rubric'})">
                        <div class="nieuwe" >New Rubric</div>

                    </div>
                    <div class="nieuwe-button"  @click="$router.push({name:'feedback-bulk-module-en'})">
                        <div class="nieuwe"  >Bulk assignment</div>

                    </div>
                  

                </div>
               
                <Input2
                    title="Language*"
                    v-model="language"
                    type="select"
                    :options="options.languages"
                    mode="feedback"
                   
                ></Input2>
                
                <Input2
                    
                    v-model="leerling_anoniem"
                    type="textsmall"
                    mode="feedback"
                    tekst="Student(unique name)*"
                ></Input2>
                
                <Input2
                    
                    v-model="titel"
                    type="textsmall"
                    mode="feedback"
                    tekst="Title(unique assignment for each student)*"
                ></Input2>
                
                <Input2
                title="Input AI"
                    v-model="onderwerp"
                    type="text"
                    mode="feedback"
                    tekst="Subject of the essay"
                ></Input2>
                
                <Input2
                title="Input AI"
                    v-model="doel"
                    type="text"
                    mode="feedback"
                    tekst="Student's most important learning objective, for example 'Developing an essay topic from a sociological and economic point of view.'"
                ></Input2>
                <!-- <Input2
                    title="Complexity of the feedback*"
                    v-model="moeilijkheid"
                    type="select"
                    :options="options2.moeilijkheid2"
                    mode="feedback"
                   
                ></Input2> -->
                <div>
                    <Input2
                        
                        v-model="opdracht"
                        type="file"
                        filetype="application/pdf"
                        mode="feedback"
                    ></Input2>
                </div >


                

                <!-- <div>
                    <Input2
                        
                        v-model="vormvereisten"
                        type="file"
                        filetype="application/pdf"
                        mode="vormvereisten"
                    ></Input2>
                </div> -->
    

              

                <div class="selectfilebeoordeling" @click="popupBeoordeling" v-if="this.selectedBeoordeling==''">
                    
                        Select Rubric
                </div>
                <div class="selectfilebeoordeling2" @click="popupBeoordeling" v-if="this.selectedBeoordeling!=''" >
                    
                    {{ this.selectedBeoordeling }}
            </div>
        
                

                <div class="selectfile" @click="bevestig" >
                   
                        Confirm
                </div>

               
            </div>
            


            
        </div>
    </div>
</template>
<script>
import Input from '@/components/Form/BetterInput2.vue'

import Input2 from '@/components/Form/BetterInput.vue'
import Sidebar from '@/components/Dashboard/SidebarLeraar.vue'
import DashboardHeader from '@/components/Dashboard/DashboardHeader.vue'
import DashboardHeading from '@/components/Dashboard/DashboardHeading.vue'
import BetterButton from '@/components/Widgets/BetterButton.vue'
import Popup from '@/components/Widgets/Popup.vue'
import getBase64 from '../utils/getBase64.js'
export default{
    data(){
        return {
            opdrachten: [null],
            leerlingen_anoniem: [""],
            leerling_anoniem: "",
            beoordelingpopup: false,
            buttonText: "Upload opdracht(pdf)",
            buttonColor: "gray",
            doel: "",
            fileExtension: "",
            onderwerp: "",
            search: "",
            naamStudent: "",
            verwijderen: false,
            rubric: "",
            opdracht: "",
            beoordelingsformuliernaam: "",
            vak: "",
            moeilijkheid: "",
            omschrijving: "",
            opdracht: null,
            vormvereisten: null,
            showPopup: false,
            selectedItem: null, // Variable to store the selected item index
            selectedItemId: null,
            selectedBeoordeling: "",
            beoordelingtitel: false,
            student_id: "122",
            titel: "",
            taal: "",
            language: "",
            isSelected: false,
            files: [],
            file: "",
            fileExtensions: [],
            options: {
                talen: {
                    "Engels": "en",
                    "Nederlands": "nl",
                   
                },
                languages: {
                    "English": "en",
                    "Dutch": "nl",
                   
                },
               
            },
            options2: {
                moeilijkheid: {
                    "Simpel": "simple",
                    "Normaal": "normal",
                    "Complex": "advanced",
                },
                moeilijkheid2: {
                    "Simple": "simple",
                    "Normal": "normal",
                    "Advanced": "advanced",
                },
               
            },
          
            
        }
        
    },
    mounted() {
        document.title = "Eduface | Feedback toevoegen";    },
  
    components: {Sidebar,  DashboardHeader, DashboardHeading, Input, BetterButton, Popup, Input2},
    computed: {
        fileExtension() {
      if (this.files.length > 0 && this.files[0].name) {
        const name = this.files[0].name;
        const extension = name.split('.').pop();
        return `.${extension}`;
      }
      return '';
    },
            getSelectedStudentText() {
        const selectedOption = this.userOptions.find(option => option.value === this.naamStudent);
        return selectedOption ? selectedOption.text : 'Not found'; // Change 'Not found' to your default message
        },
        beoordelingsformulier(){
            return this.$store.state.beoordelingsformulieren_leraar
        },
        toggledSidebar(){
            return this.$store.state.mobileExtend
        },
        showFinalPopup(){
            return this.$store.state.popuppl
        },
        popupBeoordeling(){
            console.log('hallo')
            this.beoordelingpopup = true;
            console.log(this.beoordelingpopup)
        },
        showWaitPopup(){
            return this.showPopup && !this.$store.state.popuppl
        },
        bevestigBeoordeling(){
            

            this.selectedBeoordeling = this.beoordelingsformulier.find(item => item.id === this.selectedItemId)?.titel || null;
            this.beoordelingpopup= false
        },
        users_module(){
            return this.$store.state.users_module
        },
        userOptions() {
      return this.users_module.map(user => {
        return {
          text: `${user.voornaam} ${user.achternaam}`, // Display both first name and last name
          value: user.id // Use a unique identifier as the value for each option
        };
      });
    },
    },
  
    async created(){
        await this.$store.dispatch("get_beoordelingsformulieren_leraar", this.$route.params.id)
        await this.$store.dispatch("get_users_module")
    },
    methods:{
        fileSelectedAllFiles(e, index) {
        const files = e.target.files;
        if (files.length > 0) {
            const file = files[0];
            const name = file.name;
            const extension = name.split('.').pop();
            const opdracht = { name, extension, content: '' };

            this.fileExtensions[index] = extension;

            if (extension === "pdf" || extension === "docx") {
                this.getBase64(file, base64 => {
                    opdracht.content = base64;
                    this.$setOpdracht(index, opdracht);
                });
            } else if (extension === "xlsx" || extension === "csv") {
                const reader = new FileReader();
                reader.onload = (loadEvent) => {
                    const arrayBuffer = loadEvent.target.result;
                    const base64String = this.arrayBufferToBase64(arrayBuffer);
                    if (extension === "xlsx") {
                        opdracht.content = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;
                    } else if (extension === "csv") {
                        opdracht.content = base64String;
                    }
                    this.$setOpdracht(index, opdracht);
                };
                reader.readAsArrayBuffer(file);
            } else {
                const reader = new FileReader();
                reader.onload = (loadEvent) => {
                    const fileContent = loadEvent.target.result;
                    opdracht.content = fileContent;
                    this.$setOpdracht(index, opdracht);
                };
                reader.readAsText(file);
            }
        }
    },
    $setOpdracht(index, opdracht) {
        this.$data.opdrachten[index] = opdracht;
    },
    getBase64(file, callback) {
        const reader = new FileReader();
        reader.onload = function(event) {
            callback(event.target.result);
        };
        reader.readAsDataURL(file);
    },
    arrayBufferToBase64(buffer) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    },
    openFileInput(index) {
        this.$refs['fileInput' + index][0].click();
    },
    extraWeek() {
        this.opdrachten.push(null);
        this.leerlingen_anoniem.push("");
        this.fileExtensions.push("");
    },
    minderWeek() {
        this.opdrachten.pop();
        this.leerlingen_anoniem.pop();
        this.fileExtensions.pop();
    },
        validateAndSendData() {
           
           // Check if all arrays have at least one item
           for(let i = 0; i < this.opdrachten.length; i++){
               if (this.opdrachten[i]==null || this.leerlingen_anoniem[i]=="") {
               this.$store.commit("show_snackbar", {
                           text: "Geen opdracht of leerling anoniem ingevuld, of de informatie in de weken is niet gelijk aan elkaar!",
                           type: "warning"
                       })
             
             return false;
           }
           }
           
       
           // Check if the lengths of the arrays match
         
       
           // Check if any of the slides is empty (assuming you can check for a non-empty file)
           for (let i = 0; i < this.opdrachten.length; i++) {
             if (!this.opdrachten[i]) { // Depending on how you're handling file inputs, you might need a more robust check here
               
               this.$store.commit("show_snackbar", {
                           text: "Geen pdf geselecteerd",
                           type: "warning"
                       })
               return false;
             }
           }
       console.log(this.leerlingen_anoniem[0])
           // If all validations pass, proceed to send the data to the backend
           this.bevestig();
         },
//          extraWeek(){
//             this.opdrachten.push(null)
//             this.leerlingen_anoniem.push("")
           
        
//         },
//         minderWeek(){
        
//             this.opdrachten.pop()
       
           
//         },
//         openFileInput() {
//       this.$refs.fileInput.click();
//     },
//     openFileInput1(index) {
//       const fileInputRef = `fileInput${index}`;
//       this.$refs[fileInputRef][0].click();
//     },
//     fileSelectedAllFiles1(index, event) {
//       const file = event.target.files[0];
//       if (file) {
//         this.$set(this.files, index, file);
//         const name = file.name;
//         const extension = name.split('.').pop();
//         this.file = `.${extension}`;

//         if (extension == "pdf" || extension == "docx") {
//           getBase64(file, base64 => {
//             this.opdrachten[index] = base64;
//           });
//         } else if (extension == "xlsx" || extension == "csv") {
//           const reader = new FileReader();
//           reader.onload = (loadEvent) => {
//             const arrayBuffer = loadEvent.target.result;
//             const base64String = this.arrayBufferToBase64(arrayBuffer);
//             if (extension === "xlsx") {
//               this.opdrachten[index] = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;
//             } else if (extension === "csv") {
//               this.opdrachten[index] = base64String;
//             }
//           };
//           reader.readAsArrayBuffer(file);
//         } else {
//           const reader = new FileReader();
//           reader.onload = (loadEvent) => {
//             this.opdrachten[index] = loadEvent.target.result;
//           };
//           reader.readAsText(file);
//         }
//       }
//     },
//     fileSelectedAllFiles(e) {
//             this.files = e.target.files
//             const name = this.files[0].name;
//             const extension = name.split('.').pop();
//             this.file = `.${extension}`
            
//             if(extension == "pdf"  || extension == "docx" ){
//                 getBase64(e.target.files[0], base64 => {
//                     console.log('pdf')  
//                     this.opdracht = base64;
           
//             })
//             }else if(extension == "xlsx"|| extension == "csv"){
//                 const file = this.files[0];
//         const reader = new FileReader();

//         reader.onload = (loadEvent) => {
//             const arrayBuffer = loadEvent.target.result;
//             const base64String = this.arrayBufferToBase64(arrayBuffer);
            
//             if (extension === "xlsx") {
//         this.opdracht = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;
//     } else if (extension === "csv") {
//         this.opdracht = base64String;
//     }
//         };

//         reader.readAsArrayBuffer(file);
//             }
//             else{
//                 const file = this.files[0];
//                 console.log('anders')
//                 // Create a FileReader to read the file's content
//                 const reader = new FileReader();
                
//                 // Set up what happens when the reading operation is completed
//                 reader.onload = (loadEvent) => {
//                     // Now the file's content is available as a string
//                     const fileContent = loadEvent.target.result;
                    
//                     // Emitting the file content (or any other detail you wish to include)
//                     this.opdracht = fileContent
//                 };

//                 // Start reading the file's content as a text string
//                 reader.readAsText(file);
//             }
            

//         },
//         arrayBufferToBase64(buffer) {
//     let binary = '';
//     const bytes = new Uint8Array(buffer);
//     const len = bytes.byteLength;
//     for (let i = 0; i < len; i++) {
//         binary += String.fromCharCode(bytes[i]);
//     }
//     return window.btoa(binary);  // Use window.btoa instead of btoa
// },
//     fileSelected(e) {
//             this.files = e.target.files
//             getBase64(e.target.files[0], base64 => {
//                 this.$emit('update:modelValue', base64)
//             })

//         },
//     handleFileChange(event) {
//       const file = event.target.files[0];
//       if (file && file.type === 'application/pdf') {
//         // File is a PDF, change button color to orange
//         this.buttonColor = 'orange';
//         this.opdracht = file;
//         console.log(this.opdracht)
//         this.buttonText = file.name;
//         // You can handle the PDF file here, e.g., upload it to a server or process it in any way you want.
//         // For this example, we'll just alert the user that the PDF was uploaded.
        
//       } else {
//         // File is not a PDF, reset button color to gray
//         this.buttonColor = 'gray';
        
//       }
//     },
    closePopup(){
            this.beoordelingpopup = false
        },
        actieVerwijderen(){
            this.verwijderen = true;
        },
        async annuleerverwijderen(){
            this.verwijderen = false;
            await this.$store.dispatch("get_modules_leraar")
        },
        // bevestig(){
        //     console.log(this.selectedItem.titel)
        //     // this.showPopup = true
        //     // this.$store.dispatch("nieuwBeoordelingsformulier", {
        //     //     beoordelingsformuliernaam: this.beoordelingsformuliernaam,
        //     //     beoordelingsformulieropleiding: this.beoordelingsformulieropleiding,
        //     //     beoordelingsformulier: this.beoordelingsformulier
        //     // })
        // },
        saveSelectedItemId(itemId) {
      this.selectedItemId = itemId;
      console.log(this.selectedItemId)
    },
    async bevestig() {
        
      
        // const name = this.files[0].name;
        // const extension = name.split('.').pop();
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

for (let index = 0; index < this.opdrachten.length; index++) {
    if (["pdf", "docx", "xlsx", "csv"].includes(this.fileExtensions[index])) {
        
        this.showPopup = true;
        let payload = {
            leerling_anoniem: this.leerlingen_anoniem[index],
            opdracht: this.opdrachten[index].content,
            opdracht_id: this.$route.params.id2,
            vak_id: this.$route.params.id,
            type: this.fileExtensions[index],
            counter: this.opdrachten.length,
            position: index
        };

   

        await this.$store.dispatch("nieuweFeedbackOp", payload);
    } else {
        this.showPopup = true;
        let payload = {
            leerling_anoniem: "opdracht testttt",
            titel: "opdracht",
            opdracht: this.opdrachten[index].content,
            beoordelingsformulier_id: 160,
            taal: "en",
            onderwerp: "programming",
            doel: "programming",
            model: "mbo",
            vak_id: this.$route.params.id,
            type: this.fileExtensions[index]
        };

   

        await this.$store.dispatch("nieuweFeedbackCode", payload);
    }

    // Delay for 5 seconds before the next iteration
    await delay(5000);
}

       
            
      

      
    },
    sendDataToFunction(itemId) {
      // Implement your logic to send the data to a function or API
      console.log("Sending Selected Item ID:", itemId);
      // You can perform your action to send the data here
    },
    afronden(){
            this.$store.commit("showPopupPl", false)
     
                this.$router.push({"name": "opdracht"})
          
            
        }
    }
    
    
    
}
</script>
<style lang="scss">
.feedback{
    .file-feedback-upload{
        display: flex;
        flex-direction: column;

        .file-feedback{
        margin-top: 40px;
        width: 300px;
            height: 60px;
            background-color: #eeeff8;
            cursor: pointer;
            border-radius: 8px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 20px;
            margin-right: 20px;
            border: 1px solid black;
            
    }
    .file-feedback1{
        margin-top: 40px;
        width: 300px;
            height: 60px;
            background-color: $secondary-color;
            cursor: pointer;
            border-radius: 8px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 15px;
            margin-right: 20px;
            
    }
    .file-feedback2{
        width: 300px;
            height: 60px;
            background-color: #eeeff8;
            cursor: pointer;
            border-radius: 8px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 15px;
            margin-right: 20px;
            
    }
    .file-feedback3{
        width: 300px;
            height: 60px;
            background-color: $secondary-color;
            cursor: pointer;
            border-radius: 8px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 15px;
            margin-right: 20px;
            
    }
    }


    .bevestig-beoordeling{
        width: 300px;
            height: 60px;
            cursor: pointer;
            border-radius: 8px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 20px;
            background-color: $base-color;
            color: white;
            margin-top: 20px;
        }

        .nieuwe-beoordeling{
        display: flex;
    }

    .nieuwe-button{
            margin-left: 20px;
            background-color: $secondary-color;
            color: #fff;
            border-radius: 20px;
            cursor: pointer;
            &:hover{
                opacity: 0.5;
            }

        }
        .nieuwe{
            padding: 5px 15px;
            background-color: $secondary-color;
            color: #fff;
            border-radius: 20px;
            cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
        }
    .content{
        min-height: 100vh;
        background-color: #F4F6FC;
        justify-content: space-between;
        position: absolute;
        width: calc(100% - 300px);
        padding: 0px 50px;
        top: 0px;
        left: 200px;

        .verwijderen{
            cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
        }
        
        .selectfile{
            width: 300px;
            height: 60px;
            background-color: #eeeff8;
            cursor: pointer;
            border-radius: 8px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 20px;
            margin-top: 40px;
            border: 1px solid black;
        }
        .selectfilebeoordeling{
            width: 400px;
            height: 60px;
            background-color: #eeeff8;
            cursor: pointer;
            border-radius: 8px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 20px;
            margin-top: 20px;

            border: 1px solid black;
        }
        .selectfilebeoordeling2{
            width: 400px;
            height: 60px;
            background-color: $secondary-color;
            cursor: pointer;
            border-radius: 8px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 20px;
            margin-top: 20px;
        }
        .selectfile2{
            width: 300px;
            height: 60px;
            background-color: $secondary-color;
            cursor: pointer;
            border-radius: 8px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 20px;
            margin-top: 20px;
        }
        .modules {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .module-test {
                display: flex;
                flex-wrap: wrap;
                
                
                height: 250px;
            }
        }

        .nieuwe-module-wrapper{
            display: flex;
            justify-content: center;
            width: 70px;
            height: 250px;
            cursor: pointer;

            &:hover{
                opacity: 0.8;
            }

            .nieuwe-module-img{
                height: 60px;
                margin-top: 50px;
                
            }
        }
        


        

        .storten{
            display: block;
            font-size: 20px;
            margin-bottom: 40px;
        }

        .balans{
            font-size: 50px;
            color: $secondary-color;
            font-weight: bold;
            line-height: 30px;
            margin-bottom: 15px;
        }
    }
    .popup{
        display: flex !important;
        align-items: center;
        flex-direction: column;
        text-align: center;

        .invite-code{
            font-size: 30px;
            font-weight: bold;
            color: $base-color;
            margin: 20px 0px;
        }

    }
    
    
    .books{
        display: flex;
        flex-wrap: wrap;
        
        .boek-plus{
            margin: 5px;

            &:first-child{
                margin-left: 0px;
            }
        }
    }

    @media (max-width: 900px){
        .content{
            left: 0px;
            width: calc(100% - 100px);
            .module-test{
                display: flex;
                flex-wrap: wrap;
            }
            .search{
                    display: none;
                }
            &.mobile-extend{
                display: block;
                left: 200px;
                width: calc(100% - 300px);

                
            }
        }
    }

    @media (max-width: 600px){
        .content{
            padding: 0px 10px;
            width: calc(100% - 20px);
            &.mobile-extend{
                left: calc(100% - 70px);
                width: 70px;
                .scrollable-content{
                    display: none;
                }
            }

            .recent-books{
                margin-bottom: 50px;
                .boek-plus{
                    margin: 5px;

                    &:first-child{
                        margin-left: 0px;
                    }

                    
                }
            }

            
        }
    }
}
</style>
