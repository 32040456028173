import { createWebHistory, createRouter } from "vue-router"
import { i18n } from '../main'; 
// import Home from "@/views/Home.vue"
// import Books from "@/views/BooksOverview.vue"
// import About from "@/views/About.vue"
// import TodoPage from "@/views/TodoPage.vue"
// import RegistrerenStudent from "@/views/RegistrerenStudent.vue"
// import RegistrerenUitgever from "@/views/RegistrerenUitgever.vue"
// import RegistrerenLeraar from "@/views/RegistrerenLeraar.vue"
// import Student from "@/views/Student.vue"
// import Teacher from "@/views/Teacher.vue"
// import Uitgever from "@/views/Uitgever.vue"
// import ProfielStudent from "@/views/ProfielStudent.vue"
// import Wallet from "@/views/Wallet"
// import Login from "@/views/Login.vue"
// import NieuwBoek from "@/views/NieuwBoek.vue"
// import Winkel from "@/views/Winkel.vue"
// import OflineWinkel from "@/views/OflineWinkel.vue"
// import Details from "@/views/Details.vue"
// import OflineDetails from "@/views/OflineDetails.vue"
// import Kopen from "@/views/Kopen.vue"
// import Reader from "@/views/Reader"
// import PersonaliseringStudent from "@/views/PersonaliseringStudent"
// import PersonaliseringLeraar from "@/views/PersonaliseringLeraar"
// import PersonaliseringUitgever from "@/views/PersonaliseringUitgever"
// import CheckUitgever from "@/views/checkUitgever"
// import wiezijnwij from "@/views/WieZijnWij"
// import werkenbij from "@/views/WerkenBij"
// import AiBeta from "@/views/AiBeta"
// import PersoonlijkeToets from "@/views/PersoonlijkeToets.vue"
// import Eindtoets from "@/views/Eindtoets.vue"
// import WeekInformatie from "@/views/WeekInformatie"
// import WeekToets from "@/views/WeekToets.vue"
// import ModulesLeraar from "@/views/ModuleOverviewLeraar.vue"
// import ModulesStudent from "@/views/ModuleOverviewStudent.vue"
// import Leerlingen from "@/views/Leerlingen.vue"
// import NieuweLeerling from "@/views/NieuweLeerling.vue"
// import FeedbackVormModule from "@/views/FeedbackVormModule.vue"
// import FeedbackBulkModule from "@/views/FeedbackModuleBulk.vue"
// import FeedbackCodeModule from "@/views/FeedbackModuleCode.vue"
// import FeedbackStudent from "@/views/FeedbackOverviewStudent.vue"
// import HomeView from "@/views/HomeView.vue"
// import Prijs from "@/views/HomeView3.vue"
// import BookOnline from "@/views/HomeView2.vue"
// import TestFeedback from "@/views/testfeedback.vue"
// import Informatie from "@/views/Informatie.vue"
// import test from "@/views/test.vue"
// import PersonalisedLearning from "@/views/PersonalisedLearning.vue"
//Eduface
import RegistrerenLeraarEduface from "@/views/EduRegistrerenLeraar.vue"
import verifyEmail from "@/views/verifyEmail.vue"
import LoginEduFace from "@/views/Log-in.vue"
import TeacherAccount from "@/views/TeacherAccount.vue"
import Vakken from "@/views/vakken.vue"
import Dashboard from "@/views/Dashboard.vue"
import OnderwijsAssistent from "@/views/OnderwijsAssistent.vue"
import ModulesOverview from "@/views/LeraarModuleOverview.vue"
import HomePage from "@/views/Homepage.vue"
import Beoordelingsformulier from "@/views/Beoordelingsformulieren.vue"
import StudentOpdrachtAssistent from "@/views/StudentOpdrachtAssistent.vue"
import StudentCodeerAssistent from "@/views/StudentCodeerAssistent.vue"
import HetTeam from "@/views/HetTeam.vue"
import Hoehetwerkt from "@/views/Hoehetwerkt.vue"
import AssistentenpaginaOmar from "@/views/AssistentenpaginaOmar.vue"
import AssistentenpaginaMarlou from "@/views/AssistentpaginaMarlou.vue"
import AssistentenpaginaRuben from "@/views/AssistentpaginaRuben.vue"
import DashboardOpdrachten from "@/views/OpdrachtenDasboard.vue"
import NieuweOpdracht from "@/views/NieuweOpdracht.vue"
import DashboardOpdracht from "@/views/OpdrachtDashboard.vue"
import DashboardOpdrachtPl from "@/views/OpdrachtPlDashboard.vue"
import OpNieuweFeedback from "@/views/OpNieuweFeedback.vue"
import OpFeedbackOverview from "@/views/OpFeedbackOverview.vue"
import BrightspaceIntegratie from "@/views/BrightspaceIntegratie.vue"
import BrightspaceIntegraties from "@/views/BrightspaceIntegraties.vue"
import AuthBrightspace from "@/views/AuthBrightspace.vue"
import ProfielLeraar from "@/views/ProfielLeraar.vue"
import Verification from "@/views/Verification.vue"
import VerificationEN from "@/views/VerificationEN.vue"
import Logout from "@/views/Logout.vue"
import WachtwoordVergeten from "@/views/WachtwoordVergeten"
import NieuwWachtwoord from "@/views/NieuwWachtwoord"
import WachtwoordAanpassen from "@/views/WachtwoordAanpassen"
import Error404 from "@/views/404"
import Authenticate from "@/views/Authenticate"
import store from "@/store"
import NieuweModule from "@/views/NieuweModule.vue"
import NieuweBeoordelingsformulier from "@/views/NieuweBeoordelingsformulier.vue"
import ModuleOverview from "@/views/ModuleOverview.vue"
import ModuleInformatie from "@/views/ModuleInformatie.vue"
import ModuleOefententamen from "@/views/ModuleOefententamen.vue"
import ModuleToetsOverview from "@/views/ModuleToetsOverview.vue"
import ModuleWeekToets from "@/views/ModuleWeekToets.vue"
import FeedbackModule from "@/views/FeedbackModule.vue"
import FeedbackOverview from "@/views/FeedbackOverview.vue"
import FeedbackLeraar from "@/views/FeedbackOverviewLeraar.vue"






const routes = [
  //eduface
  {
    path: "/",
    name: "Home",
    component: HomePage, 
    meta: {
      ingelogd: true
    },
  },
    //opdrachten systeem
    {
      path: "/leraar/vak/:id/nieuwe-opdrachten",
      name: "nieuwe-opdracht",
      component: NieuweOpdracht,
      meta: {
        requiresAuth: true,
        locale: 'nl'
      },
  
    },
    {
      path: "/leraar/vak/:id/opdrachten",
      name: "dashboard-opdrachten",
      component: DashboardOpdrachten,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/leraar/vak/:id/opdracht/:id2/overview",
      name: "opdracht",
      component: DashboardOpdracht,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/leraar/vak/:id/opdracht-pl/:id2/overview",
      name: "opdracht-pl",
      component: DashboardOpdrachtPl,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/leraar/feedback/vak/:id/opdracht/:id3/overview/:id2",
      name: "op-feedback-overview",
      component: OpFeedbackOverview,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/leraar/vak/:id/opdracht/:id2/nieuwe-feedback",
      name: "op-nieuwe-feedback",
      component: OpNieuweFeedback,
      meta: {
        requiresAuth: true,
      },
    },
    //einde opdrachten systeem

    //brightspace integratie
    {
      path: "/brightspace/feedback",
      name: "brightspace-integratie",
      component: BrightspaceIntegratie,
    },
    {
      path: "/brightspace/feedbacks/:id",
      name: "brightspace-integraties",
      component: BrightspaceIntegraties,
    },
    {
      path: "/auth",
      name: "brightspace-authentication",
      component: AuthBrightspace,
    },
  {
    path: "/onderwijsassistent/omar",
    name: "assistentpaginaOmar",
    component: AssistentenpaginaOmar, 
  },
  {
    path: "/onderwijsassistent/marlou",
    name: "assistentpaginaMarlou",
    component: AssistentenpaginaMarlou, 
  },
  {
    path: "/onderwijsassistent/ruben",
    name: "assistentpaginaRuben",
    component: AssistentenpaginaRuben, 
  },


  {
    path: "/signup",
    name: "signup",
    component: RegistrerenLeraarEduface, 
  },
  {
    path: "/het-team",
    name: "het-team",
    component: HetTeam, 
  },
  {
    path: "/hoe-werkt-het",
    name: "hoe-het-werkt",
    component: Hoehetwerkt, 
  },
  {
    path: "/:id/opdracht-feedback/:token",
    name: "student-opdracht-assistent",
    component: StudentOpdrachtAssistent, 
  },
  {
    path: "/:id/codeer-feedback/:token",
    name: "student-codeer-assistent",
    component: StudentCodeerAssistent, 
  },
  {
    path: "/verify-email/:token",
    name: "verify-email",
    component: verifyEmail
  },
  {
    path: "/log-in/",
    name: "log-in",
    component: LoginEduFace
  },
  {
    path: "/leraar/vakken",
    name: "vakken",
    component: Vakken,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/leraar/vak/:id/beoordelingsformulier",
    name: "dashboard-beoordelingsformulier",
    component: Beoordelingsformulier,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/leraar/vak/:id/onderwijsassistent",
    name: "dashboard-onderwijsassistent",
    component: OnderwijsAssistent,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/leraar/vak/:id/dashboard",
    name: "dashboard-vak",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/leraar/vak/:id/klaslijst",
  //   name: "klaslijst-vak",
  //   component: KlasLijst,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/leraar/vak/:id/personalised-learning",
  //   name: "personalised-learning",
  //   component: PersonalisedLearning,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/leraar/vak/:id/nieuwe-feedback",
    name: "nieuwe-feedback",
    component: FeedbackModule,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/leraar/vak/:id1/module/overview/:id",
    name: "module-overview-leraar",
    component: ModuleOverview,
    meta: {
      requiresAuth: true,
      locale: 'nl'
    }

  },
  {
    path: "/teacher/vak/:id1/module/overview/:id",
    name: "module-overview-teacher",
    component: ModuleOverview,
    meta: {
      requiresAuth: true,
      locale: 'en'
    }

  },
  {
    path: "/leraar/vak/:id1/module/informatie/:id/:weeknummer",
    name: "module-stof-leraar",
    component: ModuleInformatie,
    meta: {
      requiresAuth: true,
      locale: 'nl'
    }

  },
  {
    path: "/teacher/vak/:id1/module/information/:id/:weeknummer",
    name: "module-stof-teacher",
    component: ModuleInformatie,
    meta: {
      requiresAuth: true,
      locale: 'en'
    }

  },
  {
    path: "/leraar/vak/:id1/module/toetsoverview/:id",
    name: "module-toetsoverview-leraar",
    component: ModuleToetsOverview,
    meta: {
      requiresAuth: true,
      locale: 'nl'
    }

  },
  {
    path: "/teacher/vak/:id1/module/testoverview/:id",
    name: "module-testoverview-teacher",
    component: ModuleToetsOverview,
    meta: {
      requiresAuth: true,
      locale: 'en'
    }

  },
  {
    path: "/leraar/vak/:id1/module/toets/:id/:weeknummer",
    name: "module-toets-leraar",
    component: ModuleWeekToets,
    meta: {
      requiresAuth: true,
      locale: 'nl'      
    }

  },
  {
    path: "/teacher/vak/:id1/module/test/:id/:weeknummer",
    name: "module-test-teacher",
    component: ModuleWeekToets,
    meta: {
      requiresAuth: true,
      locale: 'en'

    }

  },
  {
    path: "/leraar/vak/:id1/module/oefententamen/:id",
    name: "module-oefententamen-leraar",
    component: ModuleOefententamen,
    meta: {
      requiresAuth: true,
      locale: 'nl'

    }

  },
  {
    path: "/teacher/vak/:id1/module/practice-exam/:id",
    name: "module-practice-exam-teacher",
    component: ModuleOefententamen,
    meta: {
      requiresAuth: true,
      locale: 'en'

    }

  },
  //oude routers

  // {
  //   path: "/en",
  //   name: "Home-en",
  //   component: HomeView, 
  //   meta: { locale: 'en' },
  // },
  // {
  //   path: "/feedback",
  //   name: "testfeedback",
  //   component: TestFeedback, 
  // },
  // {
  //   path: "/Prijs",
  //   name: "Prijs",
  //   component: Prijs,
  //   meta: { locale: 'nl' }
  // },
  // {
  //   path: "/Price",
  //   name: "Price",
  //   component: Prijs,
  //   meta: { locale: 'en' },
  // },
  // {
  //   path: "/BookOnline",
  //   name: "BookOnline",
  //   component: BookOnline,
  // },
  // {
  //   path: "/aibeta",
  //   name: "ai-beta",
  //   component: AiBeta,
  // },
  // {
  //   path: "/aibeta/weekinformatie/:id",
  //   name: "weekinformatie",
  //   component: WeekInformatie,
  // },
  // {
  //   path: "/aibeta/weektoets/:id",
  //   name: "weektoets",
  //   component: WeekToets,
  // },
  // {
  //   path: "/aibeta/persoonlijketoets",
  //   name: "persoonlijketoets",
  //   component: PersoonlijkeToets,
  // },
  // {
  //   path: "/aibeta/eindtoets",  
  //   name: "eindtoets",
  //   component: Eindtoets,
  // },
  // {
  //   path: "/aibeta/module/overview/samenvatting",
  //   name: "module-samenvatting",
  //   component: Samenvatting,
  // },
  // {
  //   path: "/aibeta/module/eindtoets",
  //   name: "module-eindtoets",
  //   component: Eindtoets,
  //},
  // {
  //   path: "/about",
  //   name: "About",
  //   component: About,
  // },
  // {
  //   path: "/todo",
  //   name: "Todo",
  //   component: TodoPage,
  // },
  // {
  //   path: "/leraar/registreren",
  //   name: "acleraar",
  //   component: RegistrerenLeraar,
  //   meta: { locale: 'nl' },
  // },
  // {
  //   path: "/teacher/register",
  //   name: "acteacher",
  //   component: RegistrerenLeraar,
  //   meta: { locale: 'en' },
  // },
  // {
  //   path: "/student/registreren",
  //   name: "acstudent",
  //   component: RegistrerenStudent,
  // },
  // {
  //   path: "/student/personalisering",
  //   name: "personalisering-student",
  //   component: PersonaliseringStudent,
  // },
  // {
  //   path: "/leraar/personalisering",
  //   name: "personalisering-leraar",
  //   component: PersonaliseringLeraar,
  //   meta: { locale: 'nl' },
  // },
  // {
  //   path: "/teacher/personalise",
  //   name: "personalise-teacher",
  //   component: PersonaliseringLeraar,
  //   meta: { locale: 'en' },
  // },
  // {
  //   path: "/uitgever/personalisering",
  //   name: "personalisering-uitgever",
  //   component: PersonaliseringUitgever,
  // },
  // {
  //   path: "/uitgever/checkuitgever",
  //   name: "check-uitgever",
  //   component: CheckUitgever,
  // },
  // {
  //   path: "/uitgever/registreren",
  //   name: "acuitgever",
  //   component: RegistrerenUitgever,

  // },
  // {
  //   path: "/student/dashboard",
  //   name: "student",
  //   component: Student,
  //   meta: {
  //     requiresAuth: true,
  //   }

  // },
    // {
  //   path: "/leraar/vak/:id/coming-soon",
  //   name: "coming-soon",
  //   component: OnderwijsAssistent,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/leraar/dashboard",
    name: "leraar",
    component: TeacherAccount,
    meta: {
      requiresAuth: true,
      locale: 'nl' 
    }

  },
  // {
  //   path: "/teacher/dashboard",
  //   name: "teacher",
  //   component: Teacher,
  //   meta: {
  //     requiresAuth: true,
  //     locale: 'en'
  //   }

  // },
  {
    path: "/leraar/vak/:id/module-overview",
    name: "modules-leraar",
    component: ModulesOverview,
    meta: {
      requiresAuth: true,
      locale: 'nl'
    }

  },
  // {
  //   path: "/teacher/module-overview",
  //   name: "modules-teacher",
  //   component: ModulesLeraar,
  //   meta: {
  //     requiresAuth: true,
  //     locale: 'en'
  //   }

  // },
  {
    path: "/leraar/vak/:id/feedback-module",
    name: "feedback-module",
    component: FeedbackModule,
    meta: {
      requiresAuth: true,
      locale: 'nl'
    }

  },
  // {
  //   path: "/teacher/feedback-module",
  //   name: "feedback-module-en",
  //   component: FeedbackModule,
  //   meta: {
  //     requiresAuth: true,
  //     locale: 'en'
  //   }

  // },

  // {
  //   path: "/teacher/feedback-bulk-module",
  //   name: "feedback-bulk-module-en",
  //   component: FeedbackBulkModule,
  //   meta: {
  //     requiresAuth: true,
  //     locale: 'en'
  //   }

  // },

  // {
  //   path: "/teacher/feedback-code-module",
  //   name: "feedback-code-module-en",
  //   component: FeedbackCodeModule,
  //   meta: {
  //     requiresAuth: true,
  //     locale: 'en'
  //   }

  // },
  // {
  //   path: "/leraar/feedback-vorm-module",
  //   name: "feedback-vorm-module",
  //   component: FeedbackVormModule,
  //   meta: {
  //     requiresAuth: true,
  //   }

  // },

  // {
  //   path: "/student/module/overview/:id",
  //   name: "module-overview-student",
  //   component: ModuleOverview,
  //   meta: {
  //     requiresAuth: true,
  //   }

  // },
  // {
  //   path: "/student/module/informatie/:id/:weeknummer",
  //   name: "module-stof-student",
  //   component: ModuleInformatie,
  //   meta: {
  //     requiresAuth: true,
  //   }

  // },
  // {
  //   path: "/student/module/toetsoverview/:id",
  //   name: "module-toetsoverview-student",
  //   component: ModuleToetsOverview,
  //   meta: {
  //     requiresAuth: true,
  //   }

  // },
  // {
  //   path: "/student/module/toets/:id/:weeknummer",
  //   name: "module-toets-student",
  //   component: ModuleWeekToets,
  //   meta: {
  //     requiresAuth: true,
  //   }

  // },
  // {
  //   path: "/student/module/oefententamen/:id",
  //   name: "module-oefententamen-student",
  //   component: ModuleOefententamen,
  //   meta: {
  //     requiresAuth: true,
  //   }

  // },
  // {
  //   path: "/student/module-overview",
  //   name: "modules-student",
  //   component: ModulesStudent,
  //   meta: {
  //     requiresAuth: true,
  //   }

  // },
  // {
  //   path: "/leraar/leerlingen",
  //   name: "leerlingen",
  //   component: Leerlingen,
  //   meta: {
  //     requiresAuth: true,
  //   }

  // },
  {
    path: "/leraar/vak/:id/nieuwe-module",
    name: "nieuwe-module",
    component: NieuweModule,
    meta: {
      requiresAuth: true,
      locale: 'nl'
    },

  },
  // {
  //   path: "/teacher/new-module",
  //   name: "new-module",
  //   component: NieuweModule,
  //   meta: {
  //     requiresAuth: true,
  //     locale: 'en'
  //   },

  // },
  // {
  //   path: "/leraar/nieuwe-leerling",
  //   name: "nieuwe-leerling",
  //   component: NieuweLeerling,
  //   meta: {
  //     requiresAuth: true,
  //   },

  // },
  {
    path: "/leraar/vak/:id/nieuwe-beoordelingsformulier",
    name: "nieuwe-beoordelingsformulier",
    component: NieuweBeoordelingsformulier,
    meta: {
      requiresAuth: true,
      locale: 'nl'
    },

  },

  // {
  //   path: "/teacher/new-rubric",
  //   name: "new-rubric",
  //   component: NieuweBeoordelingsformulier,
  //   meta: {
  //     requiresAuth: true,
  //     locale: 'en'
  //   },

  // },
  {
    path: "/leraar/vak/:id/feedback-overview",
    name: "feedback-leraar",
    component: FeedbackLeraar,
    meta: {
      requiresAuth: true,
      locale: 'nl' 
    }

  },
  // {
  //   path: "/teacher/feedback-overview",
  //   name: "feedback-teacher",
  //   component: FeedbackLeraar,
  //   meta: {
  //     requiresAuth: true,
  //     locale: 'en' 
  //   }

  // },
  {
    path: "/leraar/feedback/vak/:id/overview/:id2",
    name: "feedback-overview-leraar",
    component: FeedbackOverview,
    meta: {
      requiresAuth: true,
      locale: 'nl' 
    }

  },
  // {
  //   path: "/teacher/feedback/overview/:id",
  //   name: "feedback-overview-teacher",
  //   component: FeedbackOverview,
  //   meta: {
  //     requiresAuth: true,
  //     locale: 'en' 
  //   }

  // },
  // {
  //   path: "/student/feedback-overview",
  //   name: "feedback-student",
  //   component: FeedbackStudent,
  //   meta: {
  //     requiresAuth: true,
  //   }

  // },
  // {
  //   path: "/student/feedback/overview/:id",
  //   name: "feedback-overview-student",
  //   component: FeedbackOverview,
  //   meta: {
  //     requiresAuth: true,
  //   }

  // },

  // {
  //   path: "/student/profiel",
  //   name: "profiel-student",
  //   component: ProfielStudent,
  //   meta: {
  //     requiresAuth: true,
  //   }

  // },
  {
    path: "/leraar/profiel",
    name: "profiel-leraar",
    component: ProfielLeraar,
    meta: {
      requiresAuth: true,
      locale: 'nl'
    }

  },
  // {
  //   path: "/teacher/profile",
  //   name: "profile-teacher",
  //   component: ProfielLeraar,
  //   meta: {
  //     requiresAuth: true,
  //     locale: 'en'
  //   }

  // },
  // {
  //   path: "/student/winkel",
  //   name: "winkelen",
  //   component: Winkel,
  //   meta: {
  //     requiresAuth: true,
  //   }

  // },
  // {
  //   path: "/studieboeken",
  //   name: "ofline-winkel",
  //   component: OflineWinkel,
  // },
  // {
  //   path: "/student/boeken",
  //   name: "boeken",
  //   component: Books,
  //   meta: {
  //     requiresAuth: true,
  //   }

  // },
  // {
  //   path: "/student/wallet",
  //   name: "student-wallet",
  //   component: Wallet,
  //   meta: {
  //     requiresAuth: true,
  //   }
  // },
  // {
  //   path: "/uitgever/dashboard",
  //   name: "uitgever",
  //   component: Uitgever,
  //   meta: {
  //     requiresAuth: true,
  //   },
    

  // },
  // {
  //   path: "/uitgever/nieuw-boek",
  //   name: "nieuw_boek",
  //   component: NieuwBoek,
  //   meta: {
  //     requiresAuth: true,
  //   },

  // },
  // {
  //   path: "/uitgever/wallet",
  //   name: "uitgever-wallet",
  //   component: Wallet,
  //   meta: {
  //     requiresAuth: true,
  //   }
  // },
  {
    path: "/verify/:token",
    name: "verify email",
    component: Verification,
    meta: { locale: 'nl' },

  },
  {
    path: "/en/verify/:token",
    name: "verify email en",
    component: VerificationEN,
    meta: { locale: 'en' },

  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/login",
  //   name: "login",
  //   component: Login,
  //   meta: { locale: 'nl' },
  // },
  // {
  //   path: "/en/login",
  //   name: "en-login",
  //   component: Login,
  //   meta: { locale: 'en' },
  // },
  // {
  //   path: "/wiezijnwij",
  //   name: "wiezijnwij",
  //   component: wiezijnwij,
  //   meta: { locale: 'nl' },
  // },
  // {
  //   path: "/whoarewe",
  //   name: "whoarewe",
  //   component: wiezijnwij,
  //   meta: { locale: 'en' },
  // },
  // {
  //   path: "/werkenbij",
  //   name: "werkenbij",
  //   component: werkenbij,
  //   meta: { locale: 'nl' },
  // },
  // {
  //   path: "/careerat",
  //   name: "careerat",
  //   component: werkenbij,
  //   meta: { locale: 'en' },
  // },
  {
    path: "/autoriseren",
    name: "autoriseren",
    component: Authenticate,
  },
  {
    path: "/wachtwoord-vergeten",
    name: "wachtwoordVergeten",
    component: WachtwoordVergeten,
    meta: { locale: 'nl' },
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: WachtwoordVergeten,
    meta: { locale: 'en' },
  },
  {
    path: "/nieuw-wachtwoord/:token",
    name: "nieuwWachtwoord",
    component: NieuwWachtwoord,
    meta: { locale: 'nl' },
  },
  {
    path: "/new-password/:token",
    name: "newPassword",
    component: NieuwWachtwoord,
    meta: { locale: 'en' },
  },
  {
    path: "/wachtwoord-aanpassen",
    name: "wachtwoordAanpassen",
    component: WachtwoordAanpassen,
    meta: {
      requiresAuth: true,
      locale: 'nl'
    },
  },
  {
    path: "/adjust-password",
    name: "adjustPassword",
    component: WachtwoordAanpassen,
    meta: {
      requiresAuth: true,
      locale: 'en'
    },
  },
  // {
  //   path: "/student/boek_details/:titel/:id",
  //   name: "details",
  //   component: Details,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/boek_details/:titel/:id",
  //   name: "ofline-details",
  //   component: OflineDetails,
  // },
  // {
  //   path: "/student/boek_kopen/:id",
  //   name: "kopen",
  //   component: Kopen,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/student/reader/:id",
  //   name: "reader",
  //   component: Reader,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },

  // Root routes:
  // {
  //   path: "/uitgever",
  //   name: "uitgever_red",
  //   redirect: {name: "uitgever"}

  // },
  {
    path: "/student",
    name: "student_red",
    redirect: {name: "student"}

  },
  {
    path: "/leraar",
    name: "leraar_red",
    redirect: {name: "leraar"}

  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: Error404,
  },

];


const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Add this global logger BEFORE the existing `beforeEach` hook
router.beforeEach((to, from, next) => {
  console.log(`Navigating to: ${to.name}`, to); // Logs every route being navigated to
  next(); // Allow navigation to continue
});

router.beforeEach(async (to, from) => {
  window.scrollTo(0, 0)
  store.state.mobileExtend = false;
  const locale = to.meta.locale;
  if (locale) {
    i18n.global.locale.value = locale; // Correct way to set locale in Vue 3 i18n
  }

  if (to.query.updateIngelogd === 'false') {
    // Dynamically update the route meta property
    to.meta.ingelogd = false;
  }

  if (to.name !== "log-in" && to.name !== "Home" && to.name !== "signup") {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!store.state.logged_in) {
        await store.dispatch("check_login");
      }
      
      if (!localStorage.getItem("token") || (localStorage.getItem("token") && !store.state.logged_in)) {
        return {
          name: "login",
          query: {
            snackbar: "Log eerst in om toegang te krijgen tot deze pagina",
            snackbarType: "error"
          }
        };
      } else {
        if (to.fullPath.includes("/student/") && localStorage.getItem('role') != "student") {
          return {
            name: "uitgever",
            query: {
              snackbar: "Om toegang te krijgen tot deze pagina moet u inloggen als student",
              snackbarType: "error"
            }
          };
        } else if (to.fullPath.includes("/uitgever/") && localStorage.getItem('role') != "uitgever") {
          return {
            name: "student",
            query: {
              snackbar: "Om toegang te krijgen tot deze pagina moet je inloggen als uitgever",
              snackbarType: "error"
            }
          };
        }
      }
    }
  } else {
    // New logic for handling "Home" route based on meta.ingelogd
    if (to.name === "Home") {
      if (to.meta.ingelogd) {
        if (localStorage.getItem("token") && localStorage.getItem('role')) {
          // If meta.ingelogd is true and user is logged in, redirect to their role-based page
          return { name: localStorage.getItem('role') };
        } else {
          return true;
        }
      } else {
        return true;
      }
    }

    if (localStorage.getItem("token") && localStorage.getItem('role')) {
      return { name: localStorage.getItem('role') };
    }
  }
  return true;
});

export default router;
