<template>
  <div class="brightspace-integratie">
      <div class="content" v-if="!bezig">
        <h1>De onderwijsassistenten van Eduface staan klaar om aan de slag te gaan met uw taak</h1>
        <h2>Informatie wordt opgehaald, authenticatie process begint...</h2>
        <v-progress-circular
            indeterminate
            class="loading-spinner"
          ></v-progress-circular>
        </div>    
  </div>
</template>
<script>
import OnboardingPage from '@/components/Onboarding/OnboardingPage.vue'
import Form from '@/components/Form/Form.vue'
import Input2 from '@/components/Form/BetterInput.vue'
import BetterButton from "@/components/Widgets/BetterButton.vue"
import Popup from '@/components/Widgets/Popup.vue'
import getBase64 from '../utils/getBase64.js'
import Input from '@/components/Form/BetterInput2.vue'
import axios from 'axios';




export default{
  components: {OnboardingPage, Form, Input, BetterButton, Input2, Popup},
  data(){
      return {
          user_id: "123",
          naam_docent: "pietje",
          beoordelingsformulieren: [null],
          beoordelingsformulier: "",
          beoordelingsformulier: null,
          bezig: false,
          niveau: "",
          opdrachten: [null],
          leerlingen_anoniem: [""],
          leerling_anoniem: "",
          beoordelingpopup: false,
          buttonText: "Upload opdracht(pdf)",
          buttonColor: "gray",
          doel: "",
          fileExtension: "",
          onderwerp: "",
          search: "",
          naamStudent: "",
          verwijderen: false,
          rubric: "",
          opdracht: "",
          beoordelingsformuliernaam: "",
          vak: "",
          moeilijkheid: "",
          omschrijving: "",
          opdracht: null,
          vormvereisten: null,
          showPopup: false,
          selectedItem: null, // Variable to store the selected item index
          selectedItemId: null,
          selectedBeoordeling: "",
          beoordelingtitel: false,
          student_id: "122",
          titel: "",
          titel2: "",
          taal: "",
          language: "",
          isSelected: false,
          files: [],
          file: "",
          fileExtensions: [],
          fileExtensionBeoordelingsformulier: "",
          options: {
              talen: {
                  "Engels": "en",
                  "Nederlands": "nl",
                 
              },
              languages: {
                  "English": "en",
                  "Dutch": "nl",
                 
              },
              niveau:{
                  "Omar-mbo": "mbo",
                  "Marlou-hbo": "hbo",
                  "Ruben-wo": "uni",
              }
             
          },
          pageTitle: '',
    isDeepLinkLaunch: false,
    launchData: {},
    launchId: '',
    currUserName: '',
    currDiff: '',
    userId: '',
    userName: '',
    userId: null,
    username: null,
    deploymentId: null,
    teller: 1,


      }
  },
  async created () {
   
            await this.fetchFeedback();
    // Set up interval to periodically fetch feedback
    if(this.feedback.feedback==""){








            console.log('feedback call wordt gemaakt')
            this.feedbackInterval = setInterval(async () => {
      await this.fetchFeedback();
    }, 10000);




    }
    this.extractUserInfo();




    await this.$store.dispatch("get_beoordelingsformulier_integratie_leraar", this.userId)
  },
  computed: {
    beoordelingsformulier(){
          return this.$store.state.beoordelingsformulier_integratie
      },
    feedback(){
          return this.$store.state.feedbackk
      },
      feedbackk(){
        return this.$store.state.feedback
      },
      feedback_id(){
          return this.$store.state.feedback_id
      },
      fileExtension() {
        if (this.files.length > 0 && this.files[0].name) {
          const name = this.files[0].name;
          const extension = name.split('.').pop();
          return `.${extension}`;
        }
        return '';
      },
      fileExtensionB() {
        if (this.files.length > 0 && this.files[0].name) {
          const name = this.files[0].name;
          const extension = name.split('.').pop();
          return `.${extension}`;
        }
        return '';
      },
  },
  watch: {
  feedback_id(newValue) {
      if (newValue !== "") {
          console.log('Feedback ID is set:', newValue);
          // Perform further actions here based on the new value
      } else {
          console.log('Feedback ID is empty');
          // Handle cases where feedback ID might be empty
      }
  },
  feedback(newValue2) {
      if (newValue2 !== "") {
          console.log('Feedback is set:', newValue2);
          console.log(newValue2.feedback)
          if(this.teller === 1 && newValue2.feedback != ''){
            
            console.log('kom ik hier')
            this.teller +=1 
            this.sendFeedbackToBrightspace(newValue2.feedback);
          }
          // Perform further actions here based on the new value
      } else {
          console.log('Feedback is empty');
          // Handle cases where feedback ID might be empty
      }
  }
},
  methods: {
    async sendFeedbackToBrightspace(feedbackText) {
    const url = 'https://eduface.d2l-partners.brightspace.com/d2l/api/le/1.28/6666/dropbox/folders/24/feedback/User/187';
    const bearerToken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImYzY2YzNzEyLTlmNDQtNDM4Yy1iYTA0LTRiOTIzMzQxMmZiZCIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE3MjU0MzYzNDYsImV4cCI6MTcyNTQzOTk0NiwiaXNzIjoiaHR0cHM6Ly9hcGkuYnJpZ2h0c3BhY2UuY29tL2F1dGgiLCJhdWQiOiJodHRwczovL2FwaS5icmlnaHRzcGFjZS5jb20vYXV0aC90b2tlbiIsInN1YiI6IjE3OCIsInRlbmFudGlkIjoiZmQ4MjBhODMtNDhiMi00NjZiLWFkY2MtYzBiYzQ5NDVmNjQzIiwiYXpwIjoiMTRmODI1N2MtYjkxYi00OTVhLThkMWItNmQzYTQ5YzUwZDU1Iiwic2NvcGUiOiJjb3JlOio6KiIsImp0aSI6ImNjMzA4MTUzLWYyZDMtNDA2MS04NDRkLTUyYjlmOTBkMTU2ZSJ9.0ma7QI7UHVYybpHkXaBN_Xn-zn2V9vhaOT7BGIIJSXIeDX6vqyaUXDCVjJir_AiIEFbzTXBn3zF5W32IZWL7ysOcFcvJ0qGT9AyGKH_YHkiDH12FCt_i8HbcKw7Sk2wdqvaNMzBo-d0UQX6r9K4NNzpKcIvGiwdetEHDBKdj84cbSoc8uvMByanCfOXyDKbf-9o7olGfOzdUUAI2kZa3AR72tcDP8sCn5P7B_d7rjjEXNaHSQCUirWh6Wmr6qrglorSkHH1j6pkcHyUZGUsWBBWk-Lstqz5hMxfbHcZnEUbXBKaKwWcW7QzOpoF9ILiv_FS4r26gXXCxMsUrjT9k1A';

    const feedbackData = {
      "Score": null,
      "Feedback": {
        "Text": feedbackText,
        "Html": `<p>${feedbackText}</p>`
      },
      "RubricAssessments": [],
      "IsGraded": false
    };

    try {
      const response = await axios.post(url, feedbackData, {
        headers: {
          'Authorization': `Bearer ${bearerToken}`,
          'Content-Type': 'application/json'
        }
      });

      console.log('Feedback successfully sent to Brightspace:', response.data);
      this.$store.commit("show_snackbar", {
        text: "Feedback successfully sent to Brightspace.",
        type: "success"
      });
    } catch (error) {
      console.error('Error sending feedback to Brightspace:', error);
      this.$store.commit("show_snackbar", {
        text: "Failed to send feedback to Brightspace. Please try again.",
        type: "error"
      });
    }
  },
    async fetchFileFromBrightspace(index) {
    const url = 'https://eduface.d2l-partners.brightspace.com/d2l/api/le/1.28/6666/dropbox/folders/24/submissions/2/files/15539';
    const bearerToken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImYzY2YzNzEyLTlmNDQtNDM4Yy1iYTA0LTRiOTIzMzQxMmZiZCIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE3MjU0MzYzNDYsImV4cCI6MTcyNTQzOTk0NiwiaXNzIjoiaHR0cHM6Ly9hcGkuYnJpZ2h0c3BhY2UuY29tL2F1dGgiLCJhdWQiOiJodHRwczovL2FwaS5icmlnaHRzcGFjZS5jb20vYXV0aC90b2tlbiIsInN1YiI6IjE3OCIsInRlbmFudGlkIjoiZmQ4MjBhODMtNDhiMi00NjZiLWFkY2MtYzBiYzQ5NDVmNjQzIiwiYXpwIjoiMTRmODI1N2MtYjkxYi00OTVhLThkMWItNmQzYTQ5YzUwZDU1Iiwic2NvcGUiOiJjb3JlOio6KiIsImp0aSI6ImNjMzA4MTUzLWYyZDMtNDA2MS04NDRkLTUyYjlmOTBkMTU2ZSJ9.0ma7QI7UHVYybpHkXaBN_Xn-zn2V9vhaOT7BGIIJSXIeDX6vqyaUXDCVjJir_AiIEFbzTXBn3zF5W32IZWL7ysOcFcvJ0qGT9AyGKH_YHkiDH12FCt_i8HbcKw7Sk2wdqvaNMzBo-d0UQX6r9K4NNzpKcIvGiwdetEHDBKdj84cbSoc8uvMByanCfOXyDKbf-9o7olGfOzdUUAI2kZa3AR72tcDP8sCn5P7B_d7rjjEXNaHSQCUirWh6Wmr6qrglorSkHH1j6pkcHyUZGUsWBBWk-Lstqz5hMxfbHcZnEUbXBKaKwWcW7QzOpoF9ILiv_FS4r26gXXCxMsUrjT9k1A';

    try {
        const response = await axios.get(url, {
            headers: {
                'Authorization': `Bearer ${bearerToken}`
            },
            responseType: 'blob' // Ensures the response is treated as a binary file (like PDFs, etc.)
        });

        if (response.status === 200) {
            // Get the file name and type from the response headers
            const contentDisposition = response.headers['content-disposition'];
            const fileName = contentDisposition ? contentDisposition.split('filename=')[1].replace(/['"]+/g, '') : 'downloadedFile';
            const fileType = response.data.type.split('/')[1]; // Get the file extension (pdf, xlsx, etc.)

            // Create a file object from the response
            const file = new File([response.data], fileName, { type: response.data.type });

            console.log('Fetched file name:', fileName);
            console.log('Fetched file object:', file);

            // Process the file just like user-uploaded files
            this.processFetchedFile(file, index, fileType);
        } else {
            console.error('Unexpected response status:', response.status);
            this.$store.commit("show_snackbar", {
                text: "Unexpected response from Brightspace. Please try again.",
                type: "error"
            });
        }
    } catch (error) {
        console.error('Error fetching the file:', error);
        this.$store.commit("show_snackbar", {
            text: "Failed to fetch the file from Brightspace. Please try again.",
            type: "error"
        });
    }
},

processFetchedFile(file, index, fileType) {
    const name = file.name;
    const extension = fileType;
    const opdracht = { name, extension, content: '' };
    this.fileExtension = extension;
    console.log('Processing file:', name, 'with extension:', extension);

    if (extension === "pdf" || extension === "docx") {
        this.getBase64(file, base64 => {
            opdracht.content = base64;
            this.opdrachten[0] = opdracht
            // this.$setOpdracht(index, opdracht);
            console.log('Base64 content for', name, ':', base64);
        });
    } else if (extension === "xlsx" || extension === "csv") {
        const reader = new FileReader();
        reader.onload = (loadEvent) => {
            const arrayBuffer = loadEvent.target.result;
            const base64String = this.arrayBufferToBase64(arrayBuffer);
            if (extension === "xlsx") {
                opdracht.content = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;
            } else if (extension === "csv") {
                opdracht.content = base64String;
            }
            this.$setOpdracht(index, opdracht);
            console.log('Base64 content for', name, ':', opdracht.content);
        };
        reader.readAsArrayBuffer(file);
    } else {
        const reader = new FileReader();
        reader.onload = (loadEvent) => {
            const fileContent = loadEvent.target.result;
            opdracht.content = fileContent;
            this.$setOpdracht(index, opdracht);
            console.log('Text content for', name, ':', fileContent);
        };
        reader.readAsText(file);
    }
},


extractUserInfo() {
      const url = window.location.href;
      const params = new URLSearchParams(url.split('?')[1]);
  
      // Extract `launch_data` parameter and parse it as JSON
      const launchData = params.get('launch_data');
      if (launchData) {
          // Parse the launch_data from URL
          const decodedLaunchData = decodeURIComponent(launchData);
          const jsonData = JSON.parse(
              decodedLaunchData.replace(/'/g, '"') // Convert single quotes to double quotes for valid JSON
          );
  
          // Extract user_id, username, and deploymentId from the JSON data
          this.userId = jsonData['http://www.brightspace.com'].user_id;
          this.username = jsonData['http://www.brightspace.com'].username;
          this.deploymentId = jsonData['https://purl.imsglobal.org/spec/lti/claim/deployment_id'];
  
          // Extract orgUnitId from the JSON data (example: /orgunit/6666/)
          const orgUnitIdMatch = jsonData['https://purl.imsglobal.org/spec/lti-ags/claim/endpoint'].lineitems.match(/\/orgunit\/(\d+)\//);
          if (orgUnitIdMatch && orgUnitIdMatch[1]) {
              this.orgUnitId = orgUnitIdMatch[1];
          }
  
          // Extract client ID (assuming it exists in the launch_data, adjust the path as necessary)
          if (jsonData['aud']) {
              this.clientId = jsonData['aud'];
          }
  
          console.log('User ID:', this.userId);
          console.log('Username:', this.username);
          console.log('Deployment ID:', this.deploymentId);
          console.log('OrgUnit ID:', this.orgUnitId);
          console.log('Client ID:', this.clientId);

          this.storeUserInfo()
          console.log('called function')
      } else {
          console.error('Launch data not found in URL');
      }
  },
  async storeUserInfo() {
  console.log('storing');

  try {
    // Wait for the store action to complete
    const success = await this.$store.dispatch("storeUserInfo", {
      user_id: this.userId,
      username: this.username,
      org_id: this.orgUnitId,
      client_id: this.clientId
    });

    if (success) {
      const url = 'https://auth.brightspace.com/oauth2/auth';
      const params = {
        response_type: 'code',
        client_id: '14f8257c-b91b-495a-8d1b-6d3a49c50d55',
        scope: 'core:*:*',
        redirect_uri: 'https://eduface.nl/auth/'
      };

      // Construct the full URL with query parameters
      const queryString = new URLSearchParams(params).toString();
      const fullUrl = `${url}?${queryString}`;

      // Redirect the user to the OAuth authorization URL
      window.location.href = fullUrl;

    } else {
      this.$store.commit("show_snackbar", {
        text: "Something went wrong, go back to brightspace and try again!",
        type: "warning"
      });
    }

  } catch (error) {
    console.error('Error storing user info:', error);
    // Handle the error as needed
    this.$store.commit("show_snackbar", {
      text: "Error storing user info. Please try again later.",
      type: "error"
    });
  }
},
// extractUserInfo() {
//     const url = window.location.href;
//     const params = new URLSearchParams(url.split('?')[1]);

//     // Check for state parameter and restore original state
//     const state = params.get('state');
//     if (state) {
//       const stateData = JSON.parse(decodeURIComponent(state));
//       this.userId = stateData.userId;
//       this.username = stateData.username;
//       this.deploymentId = stateData.deploymentId;
//       this.orgUnitId = stateData.orgUnitId;
//       this.returnUrl = stateData.returnUrl;
//     }
//     console.log(orgUnitId)
//     // const code = params.get('code');
//     // if (code) {
//     //   this.authCode = code;
//     //   this.fetchBearerToken();
//     // } else {
//     //   // If no code is present, request authorization code
//     //   this.requestAuthorizationCode();
//     // }
//   },

async fetchBearerToken() {
    const clientId = '14f8257c-b91b-495a-8d1b-6d3a49c50d55';
    const clientSecret = '7kHO4OdCWn0cBxaMRXkxKU7JovBw6KR0aD02m7FNMjY';
    const redirectUri = 'http://localhost:8080/brightspace/feedback/';

    try {
      const response = await axios.post('https://auth.brightspace.com/core/connect/token', new URLSearchParams({
        grant_type: 'authorization_code',
        code: this.authCode,
        client_id: clientId,
        redirect_uri: redirectUri,
        client_secret: clientSecret
      }).toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      this.bearerToken = response.data.access_token;
      console.log('Bearer token retrieved:', this.bearerToken);

      // Redirect back to the original page with the token
      const returnUrl = new URL(this.returnUrl);
      returnUrl.searchParams.set('token', this.bearerToken);
      window.location.href = returnUrl.toString();

    } catch (error) {
      console.error('Error fetching bearer token:', error);
      this.$store.commit("show_snackbar", {
        text: "Failed to authenticate with Brightspace. Please try again.",
        type: "error"
      });
    }
  },

requestAuthorizationCode() {
    const clientId = '14f8257c-b91b-495a-8d1b-6d3a49c50d55';
    const redirectUri = 'http://localhost:8080/brightspace/feedback/';
    const scope = 'core:*:*';

    // Prepare state parameter to retain original query parameters
    const state = JSON.stringify({
      userId: this.userId,
      username: this.username,
      deploymentId: this.deploymentId,
      orgUnitId: this.orgUnitId,
      returnUrl: window.location.href.split('?')[0] // Save the base URL to return to
    });

    // Construct the authorization URL
    const authUrl = `https://auth.brightspace.com/oauth2/auth?response_type=code&client_id=${clientId}&scope=${encodeURIComponent(scope)}&redirect_uri=${encodeURIComponent(redirectUri)}&state=${encodeURIComponent(state)}`;

    // Redirect the user to Brightspace's OAuth2 authorization endpoint
    window.location.href = authUrl;
},

  // async fetchBearerToken() {
  //   const clientId = '14f8257c-b91b-495a-8d1b-6d3a49c50d55';
  //   const clientSecret = '7kHO4OdCWn0cBxaMRXkxKU7JovBw6KR0aD02m7FNMjY';
  //   const redirectUri = 'https://upld.knower.app/auth/';

  //   try {
  //     const response = await axios.post('https://auth.brightspace.com/core/connect/token', new URLSearchParams({
  //       grant_type: 'authorization_code',
  //       code: this.authCode,
  //       client_id: clientId,
  //       redirect_uri: redirectUri,
  //       client_secret: clientSecret
  //     }).toString(), {
  //       headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded'
  //       }
  //     });

  //     this.bearerToken = response.data.access_token;
  //     console.log('Bearer token retrieved:', this.bearerToken);
  //   } catch (error) {
  //     console.error('Error fetching bearer token:', error);
  //     this.$store.commit("show_snackbar", {
  //       text: "Failed to authenticate with Brightspace. Please try again.",
  //       type: "error"
  //     });
  //   }
  // },
    async fetchFeedback() {
    // Fetch feedback from the backend using your Vuex store dispatch
    if(this.feedback_id==""){
      console.log('waiting')
    }else{
      await this.$store.dispatch("get_feedback_brightspace", this.feedback_id);
     
    }
  },
      async bevestig() {
 
     
          if(this.taal == "" && this.language == ""){
            this.$store.commit("show_snackbar", {
                        text: "Kies een taal!",
                        type: "warning"
                    })
          }else if(this.titel == ""){
            this.$store.commit("show_snackbar", {
                        text: "Kies een titel voor de opdracgt!",
                        type: "warning"
                    })
          }else if(this.titel2 == ""){
            this.$store.commit("show_snackbar", {
                        text: "Kies een titel voor het beoordelingsformulier!",
                        type: "warning"
                    })
          }else if(this.onderwerp == ""){
            this.$store.commit("show_snackbar", {
                        text: "Kies een onderwerp!",
                        type: "warning"
                    })
          }else if(this.doel == ""){
            this.$store.commit("show_snackbar", {
                        text: "Kies een leerdoel!",
                        type: "warning"
                    })
          }else if(this.niveau == ""){
            this.$store.commit("show_snackbar", {
                        text: "Kies een niveau!",
                        type: "warning"
                    })
          }else if(this.opdrachten.length == 0){
            this.$store.commit("show_snackbar", {
                        text: "Selecteer een opdracht!",
                        type: "warning"
                    })
          }else if(this.beoordelingsformulieren.length == 0){
            this.$store.commit("show_snackbar", {
                        text: "Selecteer een beoordelingsformulier!",
                        type: "warning"
                    })
          }
          else{
            this.bezig = true
            // const name = this.files[0].name;
            // const extension = name.split('.').pop();
            const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));




            for (let index = 0; index < this.opdrachten.length; index++) {
                if (["pdf", "docx", "xlsx", "csv"].includes(this.fileExtension)) {
                    this.showPopup = true;
                    let payload = {
                        opdracht: this.opdrachten[index].content,
                        taal: this.options.talen[this.taal],
                        titel: this.titel,
                        titel2: this.titel2,
                        onderwerp: this.onderwerp,
                        doel: this.doel,
                        model: this.options.niveau[this.niveau],
                        type: this.fileExtension,
                        typeB: this.fileExtensionBeoordelingsformulier,
                        beoordelingsformulier: this.beoordelingsformulieren[0].content,
                        user_id: this.userId,
                        naam_docent: this.username,
                        token: this.deploymentId,




                    };




                    if (this.taal == "") {
                        payload.taal = this.options.languages[this.language];
                    } else {
                        payload.taal = this.options.talen[this.taal];
                    }




                    await this.$store.dispatch("nieuweFeedbackBrightspace", payload);
                } else {
                  this.$store.commit("show_snackbar", {
                        text: "Daar ging iets mis, probeer het opnieuw",
                        type: "warning"
                    })








                    // this.showPopup = true;
                    // let payload = {
                    //     leerling_anoniem: this.leerlingen_anoniem[index],
                    //     titel: this.titel,
                    //     opdracht: this.opdrachten[index].content,
                    //     beoordelingsformulier_id: this.selectedItemId,
                    //     taal: this.options.talen[this.taal],
                    //     onderwerp: this.onderwerp,
                    //     doel: this.doel,
                    //     model: this.$store.state.opleidingsniveau,
                    //     vak_id: this.$route.params.id,
                    //     type: this.fileExtensions[index]
                    // };




                    // if (this.taal == "") {
                    //     payload.taal = this.options.languages[this.language];
                    // } else {
                    //     payload.taal = this.options.talen[this.taal];
                    // }




                    // await this.$store.dispatch("nieuweFeedbackCodeBrightspace", payload);                
                }




                // Delay for 5 seconds before the next iteration
                await delay(5000);
            }




     
         
    }




   
  },
  // opdracht deel file upload
  fileSelectedAllFiles(e, index) {
      const files = e.target.files;
      if (files.length > 0) {
          const file = files[0];
          const name = file.name;
          const extension = name.split('.').pop();
          const opdracht = { name, extension, content: '' };




          this.fileExtensions[index] = extension;




          if (extension === "pdf" || extension === "docx") {
              this.getBase64(file, base64 => {
                  opdracht.content = base64;
                  this.$setOpdracht(index, opdracht);
              });
          } else if (extension === "xlsx" || extension === "csv") {
              const reader = new FileReader();
              reader.onload = (loadEvent) => {
                  const arrayBuffer = loadEvent.target.result;
                  const base64String = this.arrayBufferToBase64(arrayBuffer);
                  if (extension === "xlsx") {
                      opdracht.content = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;
                  } else if (extension === "csv") {
                      opdracht.content = base64String;
                  }
                  this.$setOpdracht(index, opdracht);
              };
              reader.readAsArrayBuffer(file);
          } else {
              const reader = new FileReader();
              reader.onload = (loadEvent) => {
                  const fileContent = loadEvent.target.result;
                  opdracht.content = fileContent;
                  this.$setOpdracht(index, opdracht);
              };
              reader.readAsText(file);
          }
      }
  },
  $setOpdracht(index, opdracht) {
      this.$data.opdrachten[index] = opdracht;
  },
  getBase64(file, callback) {
      const reader = new FileReader();
      reader.onload = function(event) {
          callback(event.target.result);
      };
      reader.readAsDataURL(file);
  },
  arrayBufferToBase64(buffer) {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
  },
  openFileInput(index) {
      this.$refs['fileInput' + index][0].click();
  },
  //beoordelingsformulier deel file upload
  fileSelectedAllFilesB(e, index) {
      const files = e.target.files;
      if (files.length > 0) {
          const file = files[0];
          const name = file.name;
          const extension = name.split('.').pop();
          const beoordelingsformulier = { name, extension, content: '' };




          this.fileExtensionBeoordelingsformulier = extension;




          if (extension === "pdf" || extension === "docx") {
              this.getBase64(file, base64 => {
                  beoordelingsformulier.content = base64;
                  this.$setBeoordelingsformulier(index, beoordelingsformulier);
              });
          } else if (extension === "xlsx" || extension === "csv") {
              const reader = new FileReader();
              reader.onload = (loadEvent) => {
                  const arrayBuffer = loadEvent.target.result;
                  const base64String = this.arrayBufferToBase64(arrayBuffer);
                  if (extension === "xlsx") {
                      beoordelingsformulier.content = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;
                  } else if (extension === "csv") {
                      beoordelingsformulier.content = base64String;
                  }
                  this.$setBeoordelingsformulier(index, beoordelingsformulier);
              };
              reader.readAsArrayBuffer(file);
          } else {
              const reader = new FileReader();
              reader.onload = (loadEvent) => {
                  const fileContent = loadEvent.target.result;
                  beoordelingsformulier.content = fileContent;
                  this.$setBeoordelingsformulier(index, beoordelingsformulier);
              };
              reader.readAsText(file);
          }
      }
  },
  $setBeoordelingsformulier(index, beoordelingsformulier) {
      this.$data.beoordelingsformulieren[index] = beoordelingsformulier;
  },
  openFileInputB(index) {
      this.$refs['fileInput' + index][0].click();
  },












  extraWeek() {
      this.opdrachten.push(null);
      this.leerlingen_anoniem.push("");
      this.fileExtensions.push("");
  },
  minderWeek() {
      this.opdrachten.pop();
      this.leerlingen_anoniem.pop();
      this.fileExtensions.pop();
  },
      validateAndSendData() {
         this.bezig = false
         // Check if all arrays have at least one item
         for(let i = 0; i < this.opdrachten.length; i++){
             if (this.opdrachten[i]==null || this.leerlingen_anoniem[i]=="") {
             this.$store.commit("show_snackbar", {
                         text: "Geen opdracht of leerling anoniem ingevuld, of de informatie in de weken is niet gelijk aan elkaar!",
                         type: "warning"
                     })
           
           return false;
         }
         }
         
     
         // Check if the lengths of the arrays match
       
     
         // Check if any of the slides is empty (assuming you can check for a non-empty file)
         for (let i = 0; i < this.opdrachten.length; i++) {
           if (!this.opdrachten[i]) { // Depending on how you're handling file inputs, you might need a more robust check here
             
             this.$store.commit("show_snackbar", {
                         text: "Geen pdf geselecteerd",
                         type: "warning"
                     })
             return false;
           }
         }
     console.log(this.leerlingen_anoniem[0])
         // If all validations pass, proceed to send the data to the backend
         this.bevestig();
       },





     
   
  },
  mounted() {
      document.title = "Eduface | Brightspace";    },

}
</script>
<style lang="scss">
.brightspace-integratie {
.content {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers content vertically */
  align-items: center; /* Centers content horizontally */
  text-align: center;
  min-height: 100vh; /* Ensures the content takes up the full height of the viewport */
  padding: 0 50px;
  margin: 0 auto; /* Centers the content horizontally */
}

.file-feedback-upload {
  display: flex;
  flex-direction: column;
  align-items: center; /* Ensure the upload button is centered */
    width: 100%;




  .file-feedback,
  .file-feedback1 {
    margin-top: 40px;
    width: 300px;
    height: 60px;
    background-color: #eeeff8;
    cursor: pointer;
    border-radius: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
    margin-right: 20px;
    border: 1px solid black;
  }




  .file-feedback1 {
    background-color: $secondary-color;
    font-size: 15px;
  }
}




.bevestig-beoordeling {
  width: 300px;
  height: 60px;
  cursor: pointer;
  border-radius: 8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  background-color: $base-color;
  color: white;
  margin-top: 20px;
}




.nieuwe-beoordeling {
  display: flex;
}




.nieuwe-button {
  margin-left: 20px;
  background-color: $secondary-color;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;




  &:hover {
    opacity: 0.5;
  }
}




.selectfile,
.selectfilebeoordeling,
.selectfilebeoordeling2,
.selectfile2 {
  width: 300px;
  height: 60px;
  background-color: #eeeff8;
  cursor: pointer;
  border-radius: 8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  margin-top: 20px;
  border: 1px solid black;
}




.selectfilebeoordeling2,
.selectfile2 {
  background-color: $secondary-color;
}

// Additional styles as needed...




@media (max-width: 900px) {
  .content {
    padding: 0 10px;
    width: calc(100% - 20px);
  }
}




@media (max-width: 600px) {
  .content {
    padding: 0 10px;
    width: calc(100% - 20px);
  }
}
}




</style>