<script>

export default {
  
  data: () => ({
    tab: 'one',
      images: {
        one: '/assets/IMG_7255.jpeg',
        two: '/assets/IMG_7254.jpeg',
        three: '/assets/IMG_7260.png'
      },
    tab2: null,
    files: [],
    opdracht: null,
    dowload: false,
    counter: 1,
    geupload: false,
    leerdoel: "",
    feedbackklaar: false,
    menuActive: false,
    windowWidth: window.innerWidth,
     metaDescription: "Genereer praktische feedback met jouw AI onderwijsassistent gemaakt voor het mbo, hbo en wo."
  }),
  methods: {
    updateImage() {
      this.currentImage = this.images[this.tab];
    },
    handleResize() {
        this.windowWidth = window.innerWidth;
    },
    toggleMenu() {
        this.menuActive = !this.menuActive;
        console.log(this.menuActive)
    },
    fileSelected(event) {
        const file = event.target.files[0];
        
        if (file && file.type !== 'application/pdf') {
            alert('Please select a PDF file.');
            // Optionally, clear the input field
            this.$refs.fileInput.value = '';
            return;
        }
        
        // If a PDF file is selected, proceed with handling it
        if (file) {
            getBase64(file, base64 => {
                this.opdracht = base64;
            });
            
        }
    },
    openFileInput() {
        // Do nothing, let the default behavior of file input handle opening the file selector
    },
    bevestig(){
      console.log(this.opdracht)
      if(this.opdracht == null){
        this.$store.commit("show_snackbar", {
                    text: "Kies een opdracht!",
                    type: "warning"
                })
      }
      else if(this.counter == 2){
        this.$store.commit("show_snackbar", {
                    text: "Deze opdracht heb je al geupload, refresh!",
                    type: "warning"
                })
      }else if(this.leerdoel == ""){
        this.$store.commit("show_snackbar", {
                    text: "Geef een leerdoel aan!",
                    type: "warning"
                })
      }
      else{
        this.showPopup = true
        
            this.$store.dispatch("nieuweFeedbackOffline", {
                leerling_anoniem: "1",
                titel: "1",
                opdracht: this.opdracht,
                beoordelingsformulier_id: 118,
                taal: "nl",
                onderwerp: this.leerdoel,
                doel: this.leerdoel,
                model: "mbo",
                vak_id: "1",
                leraar: 213,
            })
            this.geupload=true
            this.leerling_anoniem = ""
            this.opdracht = null
            this.taal = ""
            this.counter = 2
            this.leerdoel = ""
        
            
      }

      
    
    },
    bevestig1(){
      console.log(this.opdracht)
      if(this.opdracht == null){
        this.$store.commit("show_snackbar", {
                    text: "Kies een opdracht!",
                    type: "warning"
                })
      }
      else if(this.counter == 2){
        this.$store.commit("show_snackbar", {
                    text: "Deze opdracht heb je al geupload, refresh!",
                    type: "warning"
                })
      }else if(this.leerdoel == ""){
        this.$store.commit("show_snackbar", {
                    text: "Geef een leerdoel aan!",
                    type: "warning"
                })
      }
      else{
        this.showPopup = true
        
            this.$store.dispatch("nieuweFeedbackOffline", {
                leerling_anoniem: "1",
                titel: "1",
                opdracht: this.opdracht,
                beoordelingsformulier_id: 118,
                taal: "nl",
                onderwerp: this.leerdoel,
                doel: this.leerdoel,
                model: "hbo",
                vak_id: "1",
                leraar: 213,
            })
            this.geupload=true
            this.leerling_anoniem = ""
            this.opdracht = null
            this.taal = ""
            this.counter = 2
            this.leerdoel = ""
          
        
            
      }

      
    
    },bevestig2(){
      console.log(this.opdracht)
      if(this.opdracht == null){
        this.$store.commit("show_snackbar", {
                    text: "Kies een opdracht!",
                    type: "warning"
                })
      }
      else if(this.counter == 2){
        this.$store.commit("show_snackbar", {
                    text: "Deze opdracht heb je al geupload, refresh!",
                    type: "warning"
                })
      }else if(this.leerdoel == ""){
        this.$store.commit("show_snackbar", {
                    text: "Geef een leerdoel aan!",
                    type: "warning"
                })
      }
      else{
        this.showPopup = true
        
            this.$store.dispatch("nieuweFeedbackOffline", {
                leerling_anoniem: "1",
                titel: "1",
                opdracht: this.opdracht,
                beoordelingsformulier_id: 118,
                taal: "nl",
                onderwerp: this.leerdoel,
                doel: this.leerdoel,
                model: "uni",
                vak_id: "1",
                leraar: 213,
            })
            this.geupload=true
            this.leerling_anoniem = ""
            this.opdracht = null
            this.taal = ""
            this.counter = 2
            this.leerdoel = ""
        
            
      }

      
    
    },
          async exporteerFeedback() {
  const doc = new jsPDF();
  const maxWidth = 190;
  const imageWidth = 50;
  // Initial settings for the image
  const startY = 10; // Starting Y position for the image
  const imageHeight = 16; // Height for the image
  const spaceAfterImage = 10; // Space between the image and text

  // Image URLs or Base64 strings
  const topRightImageUrl = '/assets/feedbackgroen2.png';
  const bottomLeftImageUrl = '/assets/feedbackgrijs2.png';
  const imageUrl = '/assets/logo.png';

  // Placeholder for image loading logic
  const loadImgData = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  // Load images
  const topRightImgData = await loadImgData(topRightImageUrl);
  const bottomLeftImgData = await loadImgData(bottomLeftImageUrl);
  const imgData = await loadImgData(imageUrl);

  // Function to add images to the background of the current page
  const addBackgroundImages = () => {
  // For the top right image, set x as the page width minus the image width and y to 0 (or close to it for a slight margin)
  doc.addImage(topRightImgData, 'PNG', doc.internal.pageSize.getWidth() - 30, 0, 30, 30); // Adjust size as needed

  // For the bottom left image, position it at the bottom left corner of the page
  doc.addImage(bottomLeftImgData, 'PNG', 0, doc.internal.pageSize.getHeight() - 20, 20, 20); // Adjust size as needed

  // Add the third image as before
  doc.addImage(imgData, 'PNG', 10, startY, imageWidth, imageHeight);
};

  // Initial call to add images to the first page
  addBackgroundImages();

  // Adjust starting Y position for text to be below the non-overlapping image
  let textStartY = startY + imageHeight + spaceAfterImage;

  // Add text and manage pages
  const textLines = doc.splitTextToSize(this.feedback?.feedback || "No feedback yet", maxWidth);

  textLines.forEach((line) => {
    if (textStartY > 280) { // Check if a new page is needed
      doc.addPage();
      addBackgroundImages(); // Add background images to the new page
      textStartY = startY + imageHeight + spaceAfterImage; // Ensure text starts below the image on new pages
    }
    doc.text(line, 10, textStartY);
    textStartY += 10; // Adjust for line height
  });

  // Save the PDF
  doc.save('feedback.pdf');
},
async fetchFeedback() {
      // Fetch feedback from the backend using your Vuex store dispatch
      if(this.feedback_id==""){
        console.log('waiting')
      }else{
        await this.$store.dispatch("get_feedback_offline", this.feedback_id);
        this.dowload = true
      }
    },
    goToAssistentPage(name) {
    this.$router.push({ name: 'assistentpagina', params: { name: name } });
  }
  },
  computed: {
    currentImage() {
      return this.images[this.tab];
    },
      feedback(){
            return this.$store.state.feedbackk
        },
        
        feedbackk(){
          return this.$store.state.feedback
        },
        feedback_id(){
            return this.$store.state.feedback_id
        },
        feedback_klaar(){
            return this.$store.state.feedback_klaar
        },
     },
     mounted() {
      document.title = "AI onderwijsassistent | AI feedback tool | Eduface";  
      window.addEventListener('resize', this.handleResize);
      },

beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
},
  watch: {
    feedback_id(newValue) {
        if (newValue !== "") {
            console.log('Feedback ID is set:', newValue);
            // Perform further actions here based on the new value
        } else {
            console.log('Feedback ID is empty');
            // Handle cases where feedback ID might be empty
        }
    },
    
    feedback(newValue2) {
        if (newValue2 !== "") {
            console.log('Feedback is set:', newValue2);
            
            // Perform further actions here based on the new value
        } else {
            console.log('Feedback is empty');
            // Handle cases where feedback ID might be empty
        }
    }
},
async created(){
                  // Initial call to fetch feedback

    await this.fetchFeedback();
// Set up interval to periodically fetch feedback
if(this.feedback.feedback==""){


        console.log('feedback call wordt gemaakt')
        this.feedbackInterval = setInterval(async () => {
  await this.fetchFeedback();
}, 10000); // Adjust the interval as needed (currently set to 1 minute)
  
      
  
}
    



    }
}

</script>

<script setup>


import Footer1 from "@/components/Frontpage/Footer2.vue";
import getBase64 from '../utils/getBase64.js'
import jsPDF from 'jspdf'


</script>
<template>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&family=Roboto:wght@400;700&display=swap"
    rel="stylesheet"
  />

  <div class="edHome">
    <div class="fixedMenu">
      <header class="header">
        <div v-if="!menuActive" class="hamburger-lines" @click="toggleMenu">
            <span class="line line1"></span>
            <span class="line line2"></span>
            <span class="line line3"></span>
        </div>
        <v-btn v-if="!menuActive && windowWidth < 992"
       flat
       class="rounded-pill border loginBtn3"
       @click="$router.push({ name: 'signup' })">
    Sign up
</v-btn>
        <div class="eContainer banner mb-0" :class="{ 'is-active': menuActive }">
          <div class="eRow mt-2 mb-0">
            <div class="col-sm-5 col-xl-2 banner1 mb-0">
              <img
                @click="$router.push({ name: 'Home' })"
                src="/assets/logo.png"
                class="logo"
                alt="logo"
                width="190"
              />
              <img @click="toggleMenu"  v-if="menuActive && windowWidth < 992" src="/assets/cross.svg" width="30" class="cross" />
            </div>
            <!-- <input class="checkbox" type="checkbox" name="" id="" v-model="$store.state.mobileExtend"/> -->
            <div class="col-xl-5 col-sm-7  text-sm-end order-xl-2 mt-lg-2">
              <v-btn
                flat
                class="rounded-pill border text-capitalize loginBtn2 mt-2"
                @click="$router.push({ name: 'signup' })"
                >Sign up</v-btn
              >
              <v-btn
                flat
                class="rounded-pill border text-capitalize loginBtn mt-2"
                @click="$router.push({ name: 'log-in' })"
                >Login</v-btn
              >
            </div>
            <div class="col-12 col-xl-5  text-lg-end order-xl-1 mb-0">
              <ul>
                <li>
                  <div @click="$router.push({ name: 'hoe-het-werkt' })" class="links">
                    Hoe werkt het
                  </div>
                </li>
                <li>
                  <div @click="$router.push({ name: 'het-team' })" class="links">Over ons</div>
                </li>
                <li>
                  <v-menu transition="scale-transition">
                    <template v-slot:activator="{ props }">
                      <div class="links dropdown" v-bind="props">Onderwijsassistent</div>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title
                          @click="$router.push({ name: 'assistentpaginaOmar' })"
                          class="assistentpage"
                          >Omar</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title
                          @click="$router.push({ name: 'assistentpaginaMarlou' })"
                          class="assistentpage"
                          >Marlou</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title
                          @click="$router.push({ name: 'assistentpaginaRuben' })"
                          class="assistentpage"
                          >Ruben</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </div>
    

    <div class="eContainer mt-4 space-between">
      <div class="eRow">
        <div class="col-lg-6-1 ">
          <h1 class="mb-8 text-h5 text-sm-h4 text-md-h3 mainHeading">
            Geef feedback met jouw <br/>
            <span class="textGreen">AI Onderwijsassistent</span>
          </h1>
          <p class="mb-14" style="font-size: 19px">
            Dé
            <a class="textGreen">onderwijsassistent</a> gemaakt voor  <a class="textGreen">docenten</a> van het mbo, hbo en wo.          </p>
          <div class="eRow">
            <div class="col-md-6">
              <!-- <v-btn class="mb-4 btn rounded-pill text-h6" @click="$router.push({name:'signup'})">Gratis proberen</v-btn> -->
              <div class="semibold textGreen iconText"> <img
                                    
                                    src="/assets/Icon verified.svg"
                            
                                    alt="logo"
                                    width="25"
                                />Gefinancieerd door Universiteit Leiden</div>
              <div class="semibold textGreen mb-3 iconText"><img
                                    
                                    src="/assets/Icon verified.svg"
                            
                                    alt="logo"
                                    width="25"
                                />Geen betaalgegevens nodig</div>
              
              <div class="semibold textGreen iconText"><img
                                    
                                    src="/assets/Icon verified.svg"
                            
                                    alt="logo"
                                    width="25"
                                />Slaat geen gegevens op</div>
            </div>
            <div class="col-md-6 text-center text-lg-end image-gone">
              <img :src="currentImage"  alt="image" class="Image1" />
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <h1 class="mb-8 text-h5 text-sm-h4 text-md-h3 mainHeading headingTest">
            Probeer de
            <span class="textGreen">Onderwijsassistent</span>
          </h1>
          <v-card class="pa-4 rounded-xl topCard">
            <div class="eRow">
              <div class="col-md-5">
                <h6 class="ms-md-4 text-h6 mt-2 dikgedrukt">Ik ben docent op het...</h6>
              </div>
              <div class="col-md-7 ">
                <v-tabs v-model="tab" align-tabs="lef" @change="updateImage">
                  <v-tab class="dikgedrukt" value="one">Mbo</v-tab>
                  <v-tab value="two">Hbo</v-tab>
                  <v-tab value="three">Wo</v-tab>
                </v-tabs>
              </div>
            </div>
            <v-window v-model="tab">
              <v-window-item value="one">
                <h4 class="mt-2 text-h4 mb-8">mbo assistent Omar</h4>
                <v-form ref="form">
                  <div class="mt-4">
                    <label v-if="!opdracht">Upload opdracht student (pdf)</label>
                    <label  v-if="opdracht"  class="fileSelected">PDF geupload </label>
                    <br/>
                    <img  v-if="opdracht" src="/assets/check.svg" width="30" />
                    <label class="fileUpload" v-if="!opdracht">
                      <input ref="fileInput" type="file" :accept="'application/pdf'" style="display: none" @change="fileSelected" />
                      <div class="d-flex">
                        <svg
                          class="mx-3"
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="40"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M20 6a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h6l2 2zm-9.25 7H14v4h2v-4h3.25L15 8.75"
                          />
                        </svg>
                        <div>
                          Upload hier de opdracht van een student, wij slaan deze opdracht niet op.
                        </div>
                      </div>
                    </label>
                  </div>

                  <div class="mt-4">
                    <label>Belangrijkste leerdoel</label>
                    <textarea
                    v-model = leerdoel
                      class="w-100 border"
                      Rows="4"
                      placeholder="Beschrijf het belangrijkste leerdoel dat de student uit de opdracht haalt. Bijvoorbeeld 'het vergelijken van Nederlandse taal met de Engelse taal'."
                    ></textarea>
                  </div>
                  <div class="mt-5">
                    <v-btn v-if="!geupload || feedback.error=='failed' || feedback.error=='file_download_error'" @click="bevestig" class="btn2 rounded-pill shadow-none">Creëer feedback</v-btn>
                    <div v-if="!feedback_klaar && geupload && feedback.error!='failed' && feedback.error!='file_download_error'">Bedankt voor je opdracht, niet refreshen, Omar is bezig met je opdracht! </div>
                    <v-progress-circular
      v-if="!feedback_klaar && geupload && feedback.error!='failed' &&  feedback.error!='file_download_error'"
      indeterminate

      class="loading-spinner"
    ></v-progress-circular>
    <div v-if="feedback.error=='failed' || feedback.error=='file_download_error'">Er is iets mis gegaan met het maken van de feedback, probeer het opnieuw!</div>
                    <div v-if="feedback_klaar && dowload==true">Referesh als je nog een opdracht wilt uploaden</div>
               <v-btn v-if="feedback_klaar && dowload==true"  @click="exporteerFeedback" class="btn2 rounded-pill shadow-none">Exporteer feedback</v-btn>
                  </div>
                </v-form>
              </v-window-item>
              <v-window-item value="two">
                <h4 class="mt-2 text-h4 mb-8">hbo assistent Marlou</h4>
                <v-form ref="form">
                  <div class="mt-4">
                    <label v-if="!opdracht">Upload opdracht student (pdf)</label>
                    <label  v-if="opdracht"  class="fileSelected">PDF geupload </label>
                    <br/>
                    <img  v-if="opdracht" src="/assets/check.svg" width="30" />
                    <label class="fileUpload" v-if="!opdracht">
                      <input ref="fileInput" type="file" :accept="'application/pdf'" style="display: none" @change="fileSelected" />
                      <div class="d-flex">
                        <svg
                          class="mx-3"
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="40"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M20 6a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h6l2 2zm-9.25 7H14v4h2v-4h3.25L15 8.75"
                          />
                        </svg>
                        <div>
                          Upload hier de opdracht van een student, wij slaan deze opdracht niet op.
                        </div>
                      </div>
                    </label>
                  </div>

                  <div class="mt-4">
                    <label>Belangrijkste leerdoel</label>
                    <textarea
                    v-model = leerdoel
                      class="w-100 border"
                      Rows="4"
                      placeholder="Beschrijf het belangrijkste leerdoel dat de student uit de opdracht haalt. Bijvoorbeeld 'het vergelijken van Nederlandse taal met de Engelse taal'."
                    ></textarea>
                  </div>
                  <div class="mt-5">
                    <v-btn v-if="!geupload || feedback.error=='failed' || feedback.error=='file_download_error'" @click="bevestig" class="btn2 rounded-pill shadow-none">Creëer feedback</v-btn>
                    <div v-if="!feedback_klaar && geupload && feedback.error!='failed' && feedback.error!='file_download_error'">Bedankt voor je opdracht, niet refreshen, Marlou is bezig met je opdracht! </div>
                    <v-progress-circular
      v-if="!feedback_klaar && geupload && feedback.error!='failed' &&  feedback.error!='file_download_error'"
      indeterminate

      class="loading-spinner"
    ></v-progress-circular>
    <div v-if="feedback.error=='failed' || feedback.error=='file_download_error'">Er is iets mis gegaan met het maken van de feedback, probeer het opnieuw!</div>
     
    <div v-if="feedback_klaar && dowload==true">Referesh als je nog een opdracht wilt uploaden</div>
               <v-btn v-if="feedback_klaar && dowload==true"  @click="exporteerFeedback" class="btn2 rounded-pill shadow-none">Exporteer feedback</v-btn>
                  </div>
                </v-form>
              </v-window-item>
              <v-window-item value="three">
                <h4 class="mt-2 text-h4 mb-8">wo assistent Ruben</h4>
                <v-form ref="form">
                  <div class="mt-4">
                    <label v-if="!opdracht">Upload opdracht student (pdf)</label>
                    <label  v-if="opdracht"  class="fileSelected">PDF geupload </label>
                    <br/>
                    <img  v-if="opdracht" src="/assets/check.svg" width="30" />
                    <label class="fileUpload" v-if="!opdracht">
                      <input ref="fileInput" type="file" :accept="'application/pdf'" style="display: none" @change="fileSelected" />
                      <div class="d-flex">
                        <svg
                          class="mx-3"
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="40"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M20 6a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h6l2 2zm-9.25 7H14v4h2v-4h3.25L15 8.75"
                          />
                        </svg>
                        <div>
                          Upload hier de opdracht van een student, wij slaan deze opdracht niet op.
                        </div>
                      </div>
                    </label>
                  </div>

                  <div class="mt-4">
                    <label>Belangrijkste leerdoel</label>
                    <textarea
                    v-model = leerdoel
                      class="w-100 border"
                      Rows="4"
                      placeholder="Beschrijf het belangrijkste leerdoel dat de student uit de opdracht haalt. Bijvoorbeeld 'het vergelijken van Nederlandse taal met de Engelse taal'."
                    ></textarea>
                  </div>
                  <div class="mt-5">
                    <v-btn v-if="!geupload || feedback.error=='failed' || feedback.error=='file_download_error'" @click="bevestig" class="btn2 rounded-pill shadow-none">Creëer feedback</v-btn>
                    <div v-if="!feedback_klaar && geupload && feedback.error!='failed' && feedback.error!='file_download_error'">Bedankt voor je opdracht, niet refreshen, Ruben is bezig met je opdracht! </div>
                    <v-progress-circular
      v-if="!feedback_klaar && geupload && feedback.error!='failed' &&  feedback.error!='file_download_error'"
      indeterminate

      class="loading-spinner"
    ></v-progress-circular>
    <div v-if="feedback.error=='failed' || feedback.error=='file_download_error'">Er is iets mis gegaan met het maken van de feedback, probeer het opnieuw!</div>
     
    <div v-if="feedback_klaar && dowload==true">Referesh als je nog een opdracht wilt uploaden</div>
               <v-btn v-if="feedback_klaar && dowload==true"  @click="exporteerFeedback" class="btn2 rounded-pill shadow-none">Exporteer feedback</v-btn>
                  </div>
                </v-form>
              </v-window-item>
            </v-window>
          </v-card>
        </div>
      </div>
    </div>



    <div class="eContainer">
      <div class="eRow my-16">
        <div class="col text-end">
          <img src="/assets/logos.png" class="logo1" alt="logo" />
        </div>
        <div class="col">
          <h3 class="text-h4 mt-16 titelHeading">Wij werken samen met</h3>
          <p class="mt-5 text-subtitle-1">
            Deze partners helpen ons op onze missie om technologie toegankelijk te maken in het
            onderwijs. Geloof jij ook in de kracht van technologie en help jij ons ook?
          </p>
        </div>
        
        
      </div>
    </div>

    <div class="eContainer my-4 pt-16 stappen">
      <div class="eRow mt-4 mt-lg-0 gCard">
        <div class="col-md-4 my-3">
          <div class="cCard">
            <img src="/assets/Omar profiel transparant.png" class="w-100" width="50" />
            <h5 class="text-h5">Omar</h5>
            <p class="">
              Omar is gecreëerd voor het mbo. Buiten de normale feedback let Omar altijd extra goed op het
              taalgebruik van de student en geeft hierbij tips.
            </p>
          </div>
        </div>
        <div class="col-md-4 my-3">
          <div class="cCard">
            <img src="/assets/Marlouprofiel.png" class="w-100" />
            <h5 class="text-h5">Marlou</h5>
            <p class="">
              Marlou is gecreëerd voor het hbo. Ze is praktisch ingesteld en zorgt ervoor dat studenten de leerstof in de opdrachten toepassen. 
            </p>
          </div>
        </div>
        <div class="col-md-4 my-3">
          <div class="cCard">
            <img src="/assets/Rubenprofiel.png" class="w-100" />
            <h5 class="text-h5">Ruben</h5>
            <p class="">
              Ruben richt zich op het universitair onderwijs en let in de feedback die hij geeft meer op de academische schrijfvaardigheid en beargumentatie. 
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="eContainer my-4 spacing extra-space">
      <div class="eRow">
        <div class="col-md-6 text-center text-md-end mb-4 mb-md-0">
          <h3 class="text-h4 mt-2">Ik ben docent op het..</h3>
        </div>
        <div class="col-md-6 text-center text-md-start mb-4 mb-md-0">
          <v-tabs v-model="tab2" align-tabs="center" class="d-inline-block m-auto">
            <v-tab value="Mbo">Mbo</v-tab>
            <v-tab value="Hbo">Hbo</v-tab>
            <v-tab value="Wo">Wo</v-tab>
          </v-tabs>
        </div>
      </div>
    </div>
    <v-window v-model="tab2">
      <v-window-item value="Mbo">
        <div>
          <div class="eContainer spacing">
            <div class="eRow bottom-space">
              <div class="col-sm-7">
                <h4 class="text-h4 mb-3">
                  Ontmoet <a class="textGreen">onderwijsassistent</a> Omar
                </h4>
                <p class="mb-6" style="line-height: 1">
                  <small class="font-weight-bold text-subtitle-1"
                    >Hallo, ik ben Omar. Als jouw nieuwe assistent
ben ik er om je te ondersteunen bij al je taken.</small
                  >
                </p>
                <v-btn @click="$router.push({name:'assistentpaginaOmar'})"  class="btn rounded-0 cShadow normal-text">Leer meer over mij!</v-btn>
              </div>
              <div class="col-md-5 text-center text-md-end mt-4 mt-md-0">
                <img src="/assets/Omar profiel transparant.png" alt="image" class="Image2" />
              </div>
            </div>
            <div class="eRow mt-4 mt-lg-0 gCard">
              <div class="col-md-4 my-3">
                <div class="cCard">
                  <img src="/assets/star.svg" />
                  <h2>10 uur per week</h2>
                  <p>Docenten geven aan dat ze gemiddeld 10 uur per week besparen met de hulp van Omar</p>
                </div>
              </div>
              <div class="col-md-4 my-3">
                <div class="cCard">
                  <img src="/assets/star.svg" />
                  <h2>1.000+</h2>
                  <p>Studenten ontvangen al feedback op hun opdrachten door Omar.</p>
                </div>
              </div>
              <div class="col-md-4 my-3">
                <div class="cCard">
                  <img src="/assets/star.svg" />
                  <h2>95%</h2>
                  <p>van de docenten die Ruben hebben uitgeprobeerd, blijven met hem samenwerken</p>
                </div>
              </div>
            </div>
            <div class="mt-8 eRow">
              <div class="col-md-6 text-end">
                <img src="/assets/Image3.png" class="Image3" />
              </div>
              <div class="col-md-6 mt-sm-4 mb-8 laatMij">
                <div class="mx-auto" style="max-width: 550px">
                  <h3 class="mt-sm-6 mt-md-14 mt-lg-16 text-h6 mb-6">
                    Laat mij je ondersteunen bij je onderwijstaken! Dit is wat ik voor je kan betekenen:
                  </h3>
                  <p>
                    <img src="/assets/file.svg" class="me-1" /> Ik kan
                    <span class="textGreen">formatieve feedback</span> geven op de opdrachten van
                    jouw studenten.
                  </p>
                  <p>
                    <img src="/assets/box.svg" class="me-1" /> Ik kan onbeperkt 
                    <span class="textGreen">oefenvragen</span> maken binnen jouw digitale
                    leeromgeving.
                  </p>
                  <p>
                    <img src="/assets/people.svg" class="me-1" /> Ik ben helemaal
                    <span class="textGreen">AVG</span> proof gebouwd en ik sla geen informatie op!
                  </p>
                  <div class="cCard uploadBox">
                    <div><img src="/assets/upload.svg" /></div>
                    Laat mij feedback geven op het werk <br />van jouw studenten.
                  </div>
                </div>
              </div>
            </div>
            <div class="eRow">
              <!-- <div class="col-md-5 mb-4 mb-md-0">
                <div class="reviewLeft">
                  <div class="eRow">
                    <div class="txt col-7">
                      Tamara Metz - software development docent
                      <div class="mt-4 txt">MboRijnIand</div>
                    </div>
                    <div class="text-center col-5">
                      <img src="/assets/Image4.png" />
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-7 reviewRight">
                <h5 class="text-h5 font-weight-bold">
                  "Omar heeft mij geholpen om meer dan IO uur per week te besparen op het maken van
                  oefenmateriaal en op het nakijken en voorzien van feedback voor mijn studenten'
                </h5>
                <div class="starts">
                  <img src="/assets/star.svg" />
                  <img src="/assets/star.svg" />
                  <img src="/assets/star.svg" />
                  <img src="/assets/star.svg" />
                  <img src="/assets/star.svg" />
                </div>
              </div> -->
            </div>
          </div>
          <div class="greenBg">
            <div class="py-8 eContainer">
              <div class="eRow">
                <div class="col-sm-6"><img src="/assets/Image5.png" /></div>
                <div class="col-sm-6">
                  <div class="ps-0 ps-sm-4 ps-md-6 ps-lg-8">
                    <h4 class="text-h4 font-weight-bold mb-4">
                      Ik kan niet wachten om samen te werken!
                    </h4>
                    <p class="mb-6 text-subtitle-1">
                      Ik heb goed nieuws! Ik sla geen informatie op, want ik ben volledig AVG proof
                      gebouwd.
                    </p>
                    <v-btn class="btn rounded-0" @click="$router.push({name:'signup'})">Samenwerken met Omar</v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-window-item>
      <v-window-item value="Hbo">
        <div>
          <div class="eContainer">
            <div class="eRow">
              <div class="col-sm-7">
                <h4 class="text-h4 mb-3">
                  Ontmoet <span class="textGreen">onderwijsassistent</span> Marlou
                </h4>
                <p class="mb-6" style="line-height: 1">
                  <small class="font-weight-bold text-subtitle-1"
                    >Hallo, ik ben Marlou. Als jouw nieuwe assistent
ben ik er om je te ondersteunen bij al je taken.</small
                  >
                </p>
                <v-btn @click="$router.push({name:'assistentpaginaMarlou'})" class="btn rounded-0 cShadow normal-text">Leer meer over mij!</v-btn>
              </div>
              <div class="col-md-5 text-center text-md-end mt-4 mt-md-0">
                <img src="/assets/Marlouprofiel.png" alt="image" class="Image2" />
              </div>
            </div>
            <div class="eRow mt-4 mt-lg-0 gCard">
              <div class="col-md-4 my-3">
                <div class="cCard">
                  <img src="/assets/star.svg" />
                  <h2>10 uur per week</h2>
                  <p>Docenten geven aan dat ze gemiddeld 10 uur per week besparen met de hulp van Marlou</p>
                </div>
              </div>
              <div class="col-md-4 my-3">
                <div class="cCard">
                  <img src="/assets/star.svg" />
                  <h2>1.000+</h2>
                  <p>Studenten ontvangen al feedback op hun opdrachten door Marlou</p>
                </div>
              </div>
              <div class="col-md-4 my-3">
                <div class="cCard">
                  <img src="/assets/star.svg" />
                  <h2>95%</h2>
                  <p>van de docenten die Marlou hebben uitgeprobeerd, blijven met hem samenwerken</p>
                </div>
              </div>
            </div>
            <div class="mt-8 eRow">
              <div class="col-md-6 text-end">
                <img src="/assets/Image3.png" class="Image3" />
              </div>
              <div class="col-md-6 mt-sm-4 mb-8 laatMij">
                <div class="mx-auto" style="max-width: 550px">
                  <h3 class="mt-sm-6 mt-md-14 mt-lg-16 text-h6 mb-6">
                    Laat mij je ondersteunen bij je onderwijstaken! Dit is wat ik voor je kan betekenen:
                  </h3>
                  <p>
                    <img src="/assets/file.svg" class="me-1" /> Ik kan
                    <span class="textGreen">formatieve feedback</span> geven op de opdrachten van
                    jouw studenten
                  </p>
                  <p>
                    <img src="/assets/box.svg" class="me-1" /> Ik kan onbeperkt 
                    <span class="textGreen">oefenvragen</span> maken binnen jouw digitale
                    leeromgeving.
                  </p>
                  <p>
                    <img src="/assets/people.svg" class="me-1" /> Ik ben helemaal
                    <span class="textGreen">AVG</span> proof gebouwd en ik sla geen informatie op!
                  </p>
                  <div class="cCard uploadBox">
                    <div><img src="/assets/upload.svg" /></div>
                    Laat mij feedback geven op het werk <br />van jouw studenten
                  </div>
                </div>
              </div>
            </div>
            <div class="eRow">
              <!-- <div class="col-md-5 mb-4 mb-md-0">
                <div class="reviewLeft">
                  <div class="eRow">
                    <div class="txt col-7">
                      Tamara Metz - software development docent
                      <div class="mt-4 txt">MboRijnIand</div>
                    </div>
                    <div class="text-center col-5">
                      <img src="/assets/Image4.png" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-7 reviewRight">
                <h5 class="text-h5 font-weight-bold">
                  "Omar heeft mij geholpen om meer dan IO uur per week te besparen op het maken van
                  oefenmateriaal en op het nakijken en voorzien van feedback voor mijn studenten'
                </h5>
                <div class="starts">
                  <img src="/assets/star.svg" />
                  <img src="/assets/star.svg" />
                  <img src="/assets/star.svg" />
                  <img src="/assets/star.svg" />
                  <img src="/assets/star.svg" />
                </div>
              </div> -->
            </div>
          </div>
          <div class="greenBg">
            <div class="py-8 eContainer">
              <div class="eRow">
                <div class="col-sm-6"><img src="/assets/Image5.png" /></div>
                <div class="col-sm-6">
                  <div class="ps-0 ps-sm-4 ps-md-6 ps-lg-8">
                    <h4 class="text-h4 font-weight-bold mb-4">
                      Ik kan niet wachten om samen te werken!
                    </h4>
                    <p class="mb-6 text-subtitle-1">
                      Ik heb goed nieuws! Ik sla geen informatie op, want ik ben volledig AVG proof
                      gebouwd.
                    </p>
                    <v-btn class="btn rounded-0" @click="$router.push({name:'signup'})">Samenwerken met Marlou</v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-window-item>
      <v-window-item value="Wo">
        <div>
          <div class="eContainer">
            <div class="eRow">
              <div class="col-sm-7">
                <h4 class="text-h4 mb-3">
                  Ontmoet <span class="textGreen">onderwijsassistent</span> Ruben
                </h4>
                <p class="mb-6" style="line-height: 1">
                  <small class="font-weight-bold text-subtitle-1"
                    >Hallo, ik ben Ruben. Als jouw nieuwe assistent
ben ik er om je te ondersteunen bij al je taken.</small
                  >
                </p>
                <v-btn @click="$router.push({name:'assistentpaginaRuben'})" class="btn rounded-0 cShadow normal-text">Leer meer over mij!</v-btn>
              </div>
              <div class="col-md-5 text-center text-md-end mt-4 mt-md-0">
                <img src="/assets/Rubenprofiel.png" alt="image" class="Image2" />
              </div>
            </div>
            <div class="eRow mt-4 mt-lg-0 gCard">
              <div class="col-md-4 my-3">
                <div class="cCard">
                  <img src="/assets/star.svg" />
                  <h2>10 uur per week</h2>
                  <p>Docenten geven aan dat ze gemiddeld 10 uur per week besparen met de hulp van Ruben</p>
                </div>
              </div>
              <div class="col-md-4 my-3">
                <div class="cCard">
                  <img src="/assets/star.svg" />
                  <h2>1.000+</h2>
                  <p>Studenten ontvangen al feedback op hun opdrachten door Ruben</p>
                </div>
              </div>
              <div class="col-md-4 my-3">
                <div class="cCard">
                  <img src="/assets/star.svg" />
                  <h2>95%</h2>
                  <p>van de docenten die Ruben hebben uitgeprobeerd, blijven met hem samenwerken</p>
                </div>
              </div>
            </div>
            <div class="mt-8 eRow">
              <div class="col-md-6 text-end">
                <img src="/assets/Image3.png" class="Image3" />
              </div>
              <div class="col-md-6 mt-sm-4 mb-8 laatMij">
                <div class="mx-auto" style="max-width: 550px">
                  <h3 class="mt-sm-6 mt-md-14 mt-lg-16 text-h6 mb-6">
                    Laat mij je ondersteunen bij je onderwijstaken! Dit is wat ik voor je kan betekenen:
                  </h3>
                  <p>
                    <img src="/assets/file.svg" class="me-1" /> Ik kan
                    <span class="textGreen">formatieve feedback</span> geven op de opdrachten van
                    jouw studenten
                  </p>
                  <p>
                    <img src="/assets/box.svg" class="me-1" /> Ik kan onbeperkt 
                    <span class="textGreen">oefenvragen</span> maken binnen jouw digitale
                    leeromgeving.
                  </p>
                  <p>
                    <img src="/assets/people.svg" class="me-1" /> Ik ben helemaal
                    <span class="textGreen">AVG</span> proof gebouwd en ik sla geen informatie op!
                  </p>
                  <div class="cCard uploadBox">
                    <div><img src="/assets/upload.svg" /></div>
                    Laat mij feedback geven op het werk <br />van jouw studenten
                  </div>
                </div>
              </div>
            </div>
            <div class="eRow">
              <!-- <div class="col-md-5 mb-4 mb-md-0">
                <div class="reviewLeft">
                  <div class="eRow">
                    <div class="txt col-7">
                      Tamara Metz - software development docent
                      <div class="mt-4 txt">MboRijnIand</div>
                    </div>
                    <div class="text-center col-5">
                      <img src="/assets/Image4.png" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-7 reviewRight">
                <h5 class="text-h5 font-weight-bold">
                  "Omar heeft mij geholpen om meer dan IO uur per week te besparen op het maken van
                  oefenmateriaal en op het nakijken en voorzien van feedback voor mijn studenten'
                </h5>
                <div class="starts">
                  <img src="/assets/star.svg" />
                  <img src="/assets/star.svg" />
                  <img src="/assets/star.svg" />
                  <img src="/assets/star.svg" />
                  <img src="/assets/star.svg" />
                </div>
              </div> -->
            </div>
          </div>
          <div class="greenBg">
            <div class="py-8 eContainer">
              <div class="eRow">
                <div class="col-sm-6"><img src="/assets/Image5.png" /></div>
                <div class="col-sm-6">
                  <div class="ps-0 ps-sm-4 ps-md-6 ps-lg-8">
                    <h4 class="text-h4 font-weight-bold mb-4">
                      Ik kan niet wachten om samen te werken!
                    </h4>
                    <p class="mb-6 text-subtitle-1">
                      Ik heb goed nieuws! Ik sla geen informatie op, want ik ben volledig AVG proof
                      gebouwd.
                    </p>
                    <v-btn class="btn rounded-0" @click="$router.push({name:'signup'})">Samenwerken met Ruben</v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-window-item>
    </v-window>

    <div class="eContainer py-6">
      <h3>FAQs</h3>
      <v-expansion-panels variant="inset" class="mt-4">
        <v-expansion-panel
          class="padded-panel"
          title="Mag ik opdrachten van studenten uploaden?"
          text="‘Ja, we gebruiken de opdracht enkel om de feedback te genereren. Na het lezen ervan verwijderen we de opdracht weer. We slaan dus niks op en er wordt niet mee getraind.’"
        >
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels variant="inset" class="mt-4">
        <v-expansion-panel
          class="padded-panel"
          title="Welke informatie heeft mijn assistent nodig om lesmateriaal te maken en feedback te genereren?"
          text="‘Om lesmateriaal te maken kun je jouw PowerPoints, hand outs, vak omschrijvingen of andere uitgeschreven documenten over het vak uploaden. Om feedback te genereren heeft jouw AI onderwijsassistent een beoordelingsformulier en een studenten opdracht nodig.’ "
        >
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels variant="inset" class="mt-4">
        <v-expansion-panel
          class="padded-panel"
          title="Kunnen studenten meerdere keren feedback ontvangen?"
          text="‘Ja, studenten kunnen meerdere keren feedback ontvangen van de assistent.’"
        >
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>

  <Footer1></Footer1>
</template>

<style>

.v-slide-group__container {
  display: block !important;
  width: 100%;
  text-align: center;
}
.v-slide-group__content {
  display: inline-block !important;
  margin: auto !important;
  background-color: #f2f2f2;
  border-radius: 45px;
  padding: 5px;
  height: 48px !important;
}
.v-slide-group__content button {
  border-radius: 45px !important;
  height: 38px !important;
}
.v-tab__slider {
  display: none;
}
.v-slide-group__content .v-btn {
  background-color: #f2f2f2 !important;
  color: #000 !important;
}
.v-btn.v-slide-group-item--active {
  background-color: #fff !important;
}
button.v-expansion-panel-title {
  background-color: #fff !important;
  color: #000 !important;
  padding: 0 0 0 15px !important;
  margin: 0 !important;
  display: block;
  width: calc(100% - 15px);
  min-height: 38px !important;
  font-weight: bold;
  font-size: 18px !important;
  line-height: 2;
}
v-expansion-panels {
  padding: 0 !important;
}
.v-expansion-panel {
  border-radius: 15px !important;
}

.v-expansion-panels--variant-inset > .v-expansion-panel--active {
  max-width: 100% !important;
}
/* header css */
.fixedMenu {
  padding: 36px 0 !important;
  margin-bottom: 60px !important;
}
.fixedMenu .header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  padding: 5px 0;
  position: fixed;
}
.header {
  height: auto !important;
}
.header ul {
  list-style: none;
  margin-top: 10px;
  margin-bottom: 0;
}
.header ul li {
  display: inline-block;
  padding: 5px;
}
.header ul li .links,
.dropdownBtn button {
  display: block;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 18px !important;
  font-weight: bold !important;
  margin: 0;
}
.header .v-btn__overlay {
  background-color: transparent !important;
}
.header .v-btn__content {
  padding: 5px 10px;
  cursor: pointer;
  font-size: 18px !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
}
.v-btn__content{
  font-weight: bold;

}
.loginBtn2,
.loginBtn {
  letter-spacing: normal !important;
}
.bottom-space{
  margin-bottom: 80px !important;
}
.dropdown {
  position: relative;
  padding-right: 20px !important;
}
.dropdown:after {
  position: absolute;
  content: '❮';
  right: 0;
  font-size: 16px;
  transform: rotate(-90deg);
  color: #414042 !important;
}
@media (max-width: 576px) {
  .logo {
    width: 150px;
  }
  .logo1{
    width: 400px;
  }
  .loginBtn2,
  .loginBtn {
    padding: 0px 5px !important;
    font-size: 10px !important;
  }
}
.cross{
  cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
}
/* header css end */
@media (max-width: 992px) {
  .image-gone{
    display: none;
  }
  .banner.mb-0 {
        display: none;
    }

    .is-active .banner.mb-0 {
        display: block;
    }
    .hamburger-lines {
    margin-left: 10px;
        display: block;
        cursor: pointer;
        padding: 10px 0;
        position: fixed;
    }

    .line {
        display: block;
        width: 25px;
        height: 2px;
        background-color: black;
        margin: 5px auto;
        transition: all 0.3s ease-in-out;
    }

    .line1 {
        transform-origin: top left;
        margin-top: 0.6em;
    }

    .line2 {
        transition-duration: 0.2s;
    }

    .line3 {
        transform-origin: bottom left;
        margin-bottom: 0.6em;
    }

    .is-active .line1 {
        transform: rotate(45deg) translate(0.5px, 0px);
    }

    .is-active .line2 {
        opacity: 0;
    }

    .is-active .line3 {
        transform: rotate(-45deg) translate(0.5px, -1px);
    }
}
@media (max-width: 992px) {
    .banner.mb-0 {
        display: none;
    }

    .banner.mb-0.is-active {
        display: block !important;
    }

}

.normal-text {
    text-transform: none;
}
</style>

<style scoped>
.padded-panel .v-expansion-panel__header,
.padded-panel .v-expansion-panel__content {
  font-size: 15px !important; /* Adjust the value as needed */
}
.normal-text {
    text-transform: none !important;
}
.loading-spinner .v-progress-circular__overlay {
  border-color: #00E075; /* Replace with your desired color */
}
@import '../../public/assets/grid.css';


*,
.banner{
  margin-bottom: 10px;
  .banner1{
    display: flex;
    align-items: center;

    .overons{
      align-items: center;
      justify-content: center;
      margin-left: 150px;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
    }
    .hoehetwerkt{
      align-items: center;
      justify-content: center;
      margin-left: 170px;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
    }
  }
}
@media (max-width: 767px) {
	.overons,.hoehetwerkt  {
    margin-left: 15px !important;
        font-size: 14px !important;
        line-height: 1;
  }

  .logo{

    width: 150px;
        height: auto;
  }
}
.spacing{
  margin-top: 30px !important;
}
.space-between{
  margin-top: -60px !important;
}
@media (max-width: 1200px) {
	.space-between{
  margin-top: 16px !important;
}
}
.headingTest{
  margin-top: 50px;
}
.logo{
  cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
}
.dikgedrukt{
  font-weight: bold;
}
.assistentpage{
  cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
    
}
.iconText{
  display: flex;
  align-items: center;
  gap: 10px;

}
span {
  font-family: 'League Spartan', sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #414042;
}
@media (max-width: 576px) {
  .edHome {
    margin: 0 15px;
  }
}

button {
  margin-top: 0 !important;
}
.mainHeading {
  letter-spacing: 2px;
  font-family: 'Spartan', sans-serif !important;
  font-size: 30px !important;
  line-height: 1.1;
  font-weight: bold;
}
@media (max-width: 576px) {
  .mainHeading {
    
  }
}
.btn {
  background-color: #00e075 !important;
  box-shadow: none;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 17px;
  padding: 15px 25px;
  height: auto !important;
  color: #000 !important;
}
.btn2 {
  background-color: #00e075 !important;
  box-shadow: none;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 20px;
  padding: 15px 25px;
  height: auto !important;
  color: #000 !important;
}
.loginBtn {
  background: transparent !important;
  color: #000 !important;
}
.loginBtn2 {

color: #fff !important;
background-color: 00e075;
margin-right: 10px;


}
.loginBtn3 {

color: #fff !important;
background-color: 00e075;
margin-right: 80px;
margin-top: 60px !important;


}
.btn:hover {
  box-shadow: none;
}
.semibold {
  font-weight: 600;
}
.textGray {
  color: #8a8a8a;
}
.textGreen {
  color: #008f5a;
  font-weight: bold !important;
}

.topCard {
  background-color: #ebebeb;
  border: 1px solid #686868;
  box-shadow: rgb(48 63 79) 50px 50px 56px -9px !important;
}

.radioInput {
  background: #fff;
  padding: 4px 8px 2px 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  letter-spacing: -0.4px;
  display: inline-block;
  font-size: 14px;
  border-radius: 15px;
  cursor: pointer;
}
.extra-space{
  margin-top: 100px !important;
}
.radioInput input {
  display: none;
}
.fileUpload {
  display: block;
  cursor: pointer;
  background: #fff;
  color: #adadad;
  padding: 8px 10px;
  border-radius: 15px;
  margin-top: 5px;
  font-size: 19px
}
.fileUpload input {
  display: none;
}
textarea {
  background: #fff;
  border-radius: 15px;
  padding: 8px 10px;
}
textarea::placeholder {
  font-size:19px;
}
@media (max-width: 576px) {
  textarea::placeholder {
  font-size:14px;
}
}

.titelHeading{
  font-family: 'Spartan', sans-serif !important;
}
.Image1 {
  max-width: 100%;
  width: 150px;
}
.cShadow {
  box-shadow: #414142 5px 5px 0px !important;
}
.Image2 {
  max-width: 100%;
  width: 230px;
}
.cCard {
  background-color: #f6f2f7;
  padding: 15px 25px;
  border-radius: 8px;
}

.cCard img {
  max-width: 100%;
  width: 25px;
  margin-right: 10px;
}
.cCard h2 {
  font-size: 43px;
  font-weight: 400 !important;
}
.cCard p {
  font-size: 18px;
  font-weight: 700 !important;
}
.gCard img {
  width: 40px;
  margin-bottom: 20px;
}
.gCard h2 {
  margin-bottom: 20px;
}
.Image3 {
  max-width: 100%;
}
.laatMij p {
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 10px;
}
.laatMij img {
  vertical-align: middle;
}
.uploadBox {
  margin-top: 15px;
  text-align: center;
  font-weight: 700;
  border: 2px solid #8bebad;
}
.uploadBox img {
  width: 50px !important;
}
.reviewLeft {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 10px;
  padding: 15px 25px;
}
.reviewLeft .txt {
  font-weight: 700;
  font-size: 20px;
}
.reviewLeft img {
  width: 100%;
  max-width: 140px;
}
.reviewRight h3 {
  font-weight: 600;
}
.starts {
  display: inline-block;
  margin-top: 40px;
  border-bottom: 5px solid #000;
}
.starts img {
  width: 50px;
}

.greenBg {
  margin-top: 15px;
  background-color: #bfe3d6;
}
.greenBg img {
  max-width: 100%;
  width: 500px;
}
.greenBg h2 {
  margin-top: 50px;
  font-weight: 600;
}
.greenBg p {
  font-size: 14px;
  line-height: 1;
  margin-bottom: 20px;
}
</style>