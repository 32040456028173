<template>
    <div class="leraar-module-overview">
        <Popup class="module-popup" :show="verwijderPopup" @close="stopVerwijderen">
            <h2>{{ $t('confirmDeleteAssignment') }}</h2>
            <BetterButton 
                :text="$t('delete')"
                @click="moduleVerwijderen(verwijderfeedback)"
            ></BetterButton>
        </Popup>
        <Sidebar mode="teacher" active="Modules"></Sidebar>
        <div class="content" :class="{'mobile-extend': toggledSidebar}">
            <DashboardHeader mode="feedback" @search="filterBooks"></DashboardHeader>
            
            <div class="scrollable-content">
                <div class="nieuwe-feedback">
                    <h2>Module overzicht</h2>
                    <div class="nieuwe-button">
                        <div v-if="$i18n.locale === 'nl'" class="nieuwe" @click="$router.push({name:'nieuwe-module'})">Nieuwe Module</div>
                        <div v-if="$i18n.locale === 'en'" class="nieuwe" @click="$router.push({name:'nieuwe-module-en'})">{{ $t('newFeedback') }}</div>
                    </div>
                </div>

                <div class="table-wrapper">
                    <table>
                        <thead>
                            <tr>
                                <th>Module</th>
                                <th>Leraar</th>
                                <th>{{ $t('title') }}</th>
                                <th>{{ $t('delete') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr  class="table-margin" v-for="feed in filtered_modules" :key="feed.titel" >
                                    <td v-if="$i18n.locale === 'en' && feed.klaar=='done' && feed.klaar_weekvragen=='done'  && feed.klaar_examenvragen=='done'" class="informatie" @click="openModule(feed.id)">Open module</td>
                                    <td v-if="$i18n.locale === 'nl' && feed.klaar=='done' && feed.klaar_weekvragen=='done'  && feed.klaar_examenvragen=='done'" class="informatie" @click="openModule(feed.id)">Open module</td>
                                    <td v-if="feed.klaar=='' || feed.klaar_weekvragen=='' || feed.klaar_examenvragen==''" class="informatie" ><v-progress-circular

      indeterminate

      class="loading-spinner"
    ></v-progress-circular></td>
                                    <td v-if="feed.klaar=='failed' || feed.klaar_weekvragen=='failed' || feed.klaar_examenvragen=='failed'" class="informatie" ><div v-if="!showPopup">
                                        <div v-if="!showPopup">Er is iets mis gegaan, probeer het opnieuw</div>
                                        <button @click="refreshModule(feed.id)">{{ $t('refresh') }}</button>
                                        
                                    </div>
                                    <div v-if="showPopup">
                                        {{ $t('beingSaved') }}
                                    </div></td>
                                    <!-- <td v-if="feed.feedback==''" class="informatie" ><img src="/assets/laadbar.png" width="150" /></td> -->
                                    <td >{{ feed.voornaam_leraar }} {{ feed.achternaam_leraar }} </td>
                                    <td >{{ feed.titel }}</td>
                                    <td v-if="feed.taal=='nl'" class="verwijderen" @click="verwijderFeedback(feed.id)">Verwijder module</td>

    
                                    <td v-if="feed.taal=='en'" class="verwijderen" @click="verwijderFeedback(feed.id)">Delete module</td>
                                    
                                </tr>
                            </tbody>
                        </table>


                </div>
                
            </div>
            


            
        </div>
    </div>
</template>
<script>
import Sidebar from '@/components/Dashboard/SidebarLeraar.vue'
import DashboardHeader from '@/components/Dashboard/DashboardHeader.vue'
import DashboardHeading from '@/components/Dashboard/DashboardHeading.vue'
import Popup from '@/components/Widgets/Popup.vue'
import BetterButton from '@/components/Widgets/BetterButton.vue'
export default{
    data(){
        return {
            search: "",
            verwijderen: false,
            verwijderPopup: false,
            verwijderfeedback: 0,
            showPopup: false,
            dummyModules: [
                {
                    id: 1,
                    titel: "Finance 1",
                    cover: "https://blockbook-backend-hotylusf3q-ez.a.run.app//books/cover/114",
                    opleiding: "Business economics",
                    leraar: "Firoz Firozzaman",
                },
                {
                    id: 2,
                    titel: "Finance 2",
                    cover: "/assets/dummy_covers/physical_education.jpg",
                    opleiding: "Business economics",
                    leraar: "Dr. Rakesh Verma",
                },
                {
                    id: 3,
                    titel: "Finance 3",
                    cover: "/assets/dummy_covers/educational_technology.jpg",
                    opleiding: "Business economics",
                    leraar: "Epifania V. Tabbada",
                    
                }
            ],
            checkFeedback: true,
            feedbackInterval: null,
            isComponentMounted: true
        }
        
    },
    components: {Sidebar, DashboardHeader, DashboardHeading, Popup, BetterButton},
    computed: {
        // checkFeedback() {
        //     let allFeedbackNotEmpty = true; // Assume initially that all feedback is not empty
        //     for (let i = 0; i < this.feedback.length; i++) {
        //         if (this.feedback[i].feedback === "") {
        //             allFeedbackNotEmpty = false; // If any feedback is empty, set the flag to false
        //             break; // No need to continue checking, we already found an empty feedback
        //         }
        //     }
        //     this.checkFeedback = !allFeedbackNotEmpty; // Set checkFeedback to true if any feedback is empty, false otherwise
        //     console.log('setting', this.checkFeedback);
        // },

        modules(){
            return this.$store.state.modules_leraar
        },
        toggledSidebar(){
            return this.$store.state.mobileExtend
        },
        filtered_modules() {
            const vm = this;
            return vm.modules
            .filter(module => {
                return (
                module.titel.toLowerCase().includes(vm.search.toLowerCase()) ||
                module.vak.toLowerCase().includes(vm.search.toLowerCase())
                );
            })
            .sort((a, b) => {
                // Sort alphabetically by module title
                if (a.titel.toLowerCase() < b.titel.toLowerCase()) {
                return -1;
                }
                if (a.titel.toLowerCase() > b.titel.toLowerCase()) {
                return 1;
                }
                return 0;
            });
        },
        
    },
    async created() {
    // Initial call to fetch feedback
    await this.fetchFeedback();

    // Set up interval to periodically fetch feedback
    // for(let i=0; i < this.feedback.length; i++){
    //     if(this.feedback[i].feedback==""){
    //         this.feedbackInterval = setInterval(async () => {
    //             if(this.feedback[i].feedback=="" && this.checkFeedback==true){
    //                 await this.fetchFeedback();
    //                 console.log('checken')
    //             }else{
    //                 console.log('stop checken')
    //             }
                
    // }, 10000); // Adjust the interval as needed (currently set to 1 minute)
    //     }
    // }
    // this.feedbackInterval = setInterval(async () => {
    //         if (this.isComponentMounted) {
    //             for (let i = 0; i < this.feedback.length; i++) {
    //                 if (this.feedback[i].feedback === "" && this.checkFeedback === true) {
    //                     await this.fetchFeedback();
    //                     console.log('checken')
    //                     break; // Exit the loop after the first check
    //                 } else {
    //                     console.log('stop checken')
    //                 }
    //             }
    //         }
    //     }, 10000);
   
  },
  mounted() {
        document.title = "Eduface | Modules";    },
  
  beforeDestroy() {
    this.isComponentMounted = false;
        // Clear the interval when the component is destroyed
        clearInterval(this.feedbackInterval);
    },
    watch: {
    modules: {
        handler: function(newVal, oldVal) {
            this.handleFeedbackChange(newVal);
        },
        deep: true // To watch changes inside the feedback array
    }
},
    methods:{
        async refreshModule(module_id){
this.showPopup = true

await this.$store.dispatch("refreshModule", {
                module_id: module_id,
                vak_id: this.$route.params.id,
               
            })


await this.$store.dispatch("get_modules_leraar", this.$route.params.id)
},
        async handleFeedbackChange(newVal) {
        let allFeedbackNotEmpty = true;
        for (let i = 0; i < newVal.length; i++) {
          
            if (newVal[i].klaar === "" || newVal[i].klaar_examenvragen === "" || newVal[i].klaar_weekvragen === "" || newVal[i].klaar === "failed" || newVal[i].klaar_weekvragen === "failed" || newVal[i].klaar_examenvragen === "failed") {
                allFeedbackNotEmpty = false;
                break;
            }
        }
        this.checkFeedback = !allFeedbackNotEmpty;
        console.log('setting', this.checkFeedback);
        if (this.checkFeedback) {
            setTimeout(async () => {
        await this.$store.dispatch("get_modules_leraar", this.$route.params.id);
    }, 5000);
        }
    },
        async fetchFeedback() {
      // Fetch feedback from the backend using your Vuex store dispatch
      await this.$store.dispatch("get_modules_leraar", this.$route.params.id);
    },
        verwijderFeedback(module_id){
            this.verwijderfeedback = module_id
            this.verwijderPopup = true;
          
        },
        stopVerwijderen(){
            this.verwijderPopup = false;
        },
        async moduleVerwijderen(module_id){
            
    
            await this.$store.dispatch("verwijderModule", module_id)
            
            await this.$store.dispatch("get_modules_leraar", this.$route.params.id)
            this.verwijderPopup = false;
        },
        openModule(id){
            console.log('test info')
            this.$router.push({
                name: "module-overview-leraar",
                params: {
                    id: id,
                    id1: this.$route.params.id
                }
            })
        },
    //     async fetchModule() {
    //   // Fetch feedback from the backend using your Vuex store dispatch
    //   await this.$store.dispatch("get_modules_leraar", this.$route.params.id);
    // },
        actieVerwijderen(){
            this.verwijderen = true;
        },
        async annuleerverwijderen(){
            this.verwijderen = false;
            await this.$store.dispatch("get_modules_leraar", this.$route.params.id)
        },
        filterBooks(query){
            
            
            this.search = query
        
        },
        async moduleVerwijderen(module_id){
            await this.$store.dispatch("verwijderModule", module_id)
            
            await this.$store.dispatch("get_modules_leraar", this.$route.params.id)
        },
    }
    
    
    
}
</script>
<style lang="scss">
.leraar-module-overview{
    .content{
        background-color: #F4F6FC;
        height: 100vh;
        justify-content: space-between;
        position: absolute;
        width: calc(100% - 300px);
        padding: 0px 50px;
        top: 0px;
        left: 200px;


        .scrollable-content{
            .nieuwe-feedback{
        display: flex;
    }

    .nieuwe-button{
            margin-left: 20px;
            background-color: $base-color;
            color: #fff;
            border-radius: 20px;
            cursor: pointer;
            &:hover{
                opacity: 0.5;
            }

        }
        .nieuwe{
            padding: 5px 15px;
            background-color: $base-color;
            color: #fff;
            border-radius: 20px;
            cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
        }

            .informatie{
                color: $base-color;
                cursor: pointer;

                &:hover{
                    opacity: 0.8;
                }
            }
            .table-margin table tbody tr {
                    display: block;
                    margin-bottom: 10px;
                    border-bottom: 1px solid transparent;
                }
                
                .table-margin table tbody tr:last-child {
                    margin-bottom: 0; /* Remove margin-bottom for the last row */
                }
            .table-wrapper {
                width: 100%;

                
                /* Adjust padding, margin, and other styles as needed */
                
            }
            .verwijderen{
                color: #004C4C;
                cursor: pointer;
                        &:hover{
                            opacity: 0.5;
                            
                        }
                
            }
                .table {
                width: 100%;
                border-collapse: collapse;
                margin-top: 20px;
                
            }

                .th, td {
                border: 1px solid #dddddd;
                
                height: 40px;
                width: 400px;
            
            }

            .th {
                background-color: #f2f2f2;
                text-align: left;
            }
            thead th {
                text-align: left;
            }
            

            td {
                text-align: left;
            }
        }

        .nieuwe-module{
            display: flex;
        }

        .verwijder-button{
            margin-left: 20px;
            background-color: $base-color;
            color: #fff;
            border-radius: 20px;
            cursor: pointer;
            &:hover{
                opacity: 0.5;
            }

        }

        .modules {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .module-test {
                display: flex;
                flex-wrap: wrap;
                
                
                height: 250px;
            }
        }

        .nieuwe-module-wrapper{
            display: flex;
            justify-content: center;
            width: 70px;
            height: 250px;
            cursor: pointer;

            &:hover{
                opacity: 0.8;
            }

            .nieuwe-module-img{
                height: 60px;
                margin-top: 50px;
                
            }
        }
        


        

        .storten{
            display: block;
            font-size: 20px;
            margin-bottom: 40px;
        }

        .balans{
            font-size: 50px;
            color: $secondary-color;
            font-weight: bold;
            line-height: 30px;
            margin-bottom: 15px;
        }
    }
    .books{
        display: flex;
        flex-wrap: wrap;
        
        .boek-plus{
            margin: 5px;

            &:first-child{
                margin-left: 0px;
            }
        }
    }

    @media (max-width: 900px){
        .content{
            left: 0px;
            width: calc(100% - 100px);
            .module-test{
                display: flex;
                flex-wrap: wrap;
            }
            .search{
                    display: none;
                }
            &.mobile-extend{
                display: block;
                left: 200px;
                width: calc(100% - 300px);

                
            }
        }
    }

    @media (max-width: 600px){
        .content{
            padding: 0px 10px;
            width: calc(100% - 20px);
            &.mobile-extend{
                left: calc(100% - 70px);
                width: 70px;
                .scrollable-content{
                    display: none;
                }
            }

            .recent-books{
                margin-bottom: 50px;
                .boek-plus{
                    margin: 5px;

                    &:first-child{
                        margin-left: 0px;
                    }

                    
                }
            }

            
        }
    }
}
</style>