<template>
    <div class="vak">
        
        
        <div @click="openModule"  class="informatie">
            <CoverModule class="cover" :cover="this.dummyModules[modules.cover].cover"></CoverModule>
            <div class="les">{{ modules.titel }}</div>
    
            <div class="leraar">{{ modules.username_leraar}}</div>

            
            
        </div> 

         
        <div>
            <button class="verwijderen" v-if="$store.state.role=='leraar' && verwijderen" @click="moduleVerwijderen(modules.id)">
                Vak verwijderen
            </button> 
        </div>
          
    </div>
</template>
<script>
import CoverModule from '@/components/Module/CoverModule.vue'
import Popup from '@/components/Widgets/Popup.vue'
import BetterButton from '@/components/Widgets/BetterButton.vue'
export default {
    props: ["modules","showoverlay", "extra", "mode", "verwijderen"],
    components: {CoverModule, Popup, BetterButton},
    data(){
        return {
            showPopup: false,
            dummyModules: [
                {

                    cover: "/assets/educatie1.png",

                },
                {

                    cover: "/assets/educatie1.png",

                },
                {

                    cover: "/assets/educatie2.png",

                    
                },
                {

                    cover: "/assets/educatie3.png",

                },
                {

                    cover: "/assets/educatie4.png",

                },
                {
                    
                    cover: "/assets/educatie5.png",

                },
            ]
        }
    },
    methods: {
        openModule(){
            console.log('test info')
            this.$router.push({
                name: "dashboard-vak",
                params: {
                    id: this.modules.id,
                }
            })
        },
        async moduleVerwijderen(vak_id){
            console.log(vak_id)
            await this.$store.dispatch("verwijderVak", vak_id)
            
            await this.$store.dispatch("get_vakken_leraar")
        },

    
    },
   
}
</script>

<style lang="scss">
.vak{
    position: relative;
    
    justify-content: column;
    width: 180px;
    margin: 10px;
    margin-bottom: 30px;
    
    
    .informatie{
        cursor: pointer;

    &:hover{
        opacity: 0.8;
    }
    }
    .cover{
        width: 180px;
        height: 100px;
        border: 1px solid #000;
    }

    div{
      
        color: black  !important;
    }

}
</style>