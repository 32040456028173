<template>
    <div class="profiel-leraar-page">
        <Popup class="module-popup" :show="showFinalPopup">
            <h2>{{ $t('pleaseWait') }}</h2>
            <p>Wij zijn uw account aan het verwijderen</p>
            <p>{{ $t('thisMayTakeAWhile') }}</p>
        </Popup>
        <Popup class="module-popup" :show="showWaitPopup" @close="afronden()">
            <h2>Weet u zeker dat u uw account wil verwijderen?</h2>
            <BetterButton 
                :text="'Nee'"
                @click="afronden()"
            ></BetterButton>
            <BetterButton class="deletebutton"
                :text="'Ja zeker'"
                @click="nextStep()"
            ></BetterButton>
        </Popup>
        <Sidebar mode="leraar" active="Profiel"></Sidebar>
        <div class="content" :class="{'mobile-extend': toggledSidebar}">
            <DashboardHeader mode="module" :hideSearch="true"></DashboardHeader>
            <div class="scrollable-content">
                <DashboardHeading>{{ $t('personalInformation') }}</DashboardHeading>
                <div class="all-information">
                    <div class="info-wrapper">
                        <div class="information">Naam:</div>
                        <div v-if="!bewerkModus" class="data">{{userInformation.username}}</div>
                        <Input
                            v-if="bewerkModus"
                            class="data"
                            type="text"
                            v-model="userInformation.username"
                            :placeholder="$t('typeHereYour') + ' ' + $t('username').toLowerCase() + ' ' + $t('here')"
                        ></Input>
                    </div>
                    <div class="info-wrapper">
                        <div class="information">{{ $t('email') }}:</div>
                        <div v-if="!bewerkModus" class="data">{{userInformation.email}}</div>
                        <Input
                            v-if="bewerkModus"
                            class="data"
                            type="text"
                            v-model="userInformation.email"
                            :placeholder="$t('typeHereYour') + ' ' + $t('email').toLowerCase() + ' ' + $t('here')"
                        ></Input>
                    </div>
                     
                </div>
                <DashboardHeading>{{ $t('schoolInformation') }}</DashboardHeading>
                <div class="all-information">
                    <div class="info-wrapper">
                        <div class="information">{{ $t('school') }}:</div>
                        <div v-if="!bewerkModus" class="data">{{userInformation.school}}</div>
                        <Input
                            v-if="bewerkModus"
                            class="data"
                            type="text"
                            v-model="userInformation.school"
                            :placeholder="$t('typeHereYour') + ' ' + $t('school').toLowerCase() + ' ' + $t('here')"
                        ></Input>
                    </div>
                    <div class="info-wrapper">
                        <div class="information">{{ $t('education') }}:</div>
                        <div v-if="!bewerkModus" class="data">{{userInformation.opleiding}}</div>
                        <Input
                            v-if="bewerkModus"
                            class="data"
                            type="text"
                            v-model="userInformation.opleiding"
                            :placeholder="$t('typeHereYour') + ' ' + $t('education').toLowerCase()"
                        ></Input>
                    </div>
                    
                </div>
                <button v-if="!bewerkModus" class="webshop-button" @click="bewerken()">{{ $t('editProfile') }}</button>
                <button v-if="bewerkModus" class="webshop-button" @click="annuleerBewerken()">{{ $t('cancelEdit') }}</button>
                <button v-if="bewerkModus" class="webshop-button" @click="slaOpBewerken()">Aanpassingen opslaan</button>
                
                <router-link v-if="$i18n.locale === 'nl'" :to="{name: 'wachtwoordAanpassen'}" class="verander-ww"><a class="webshop-button">{{ $t('changePassword') }}</a></router-link>
                <router-link v-if="$i18n.locale === 'en'" :to="{name: 'adjustPassword'}" class="verander-ww"><a class="webshop-button">{{ $t('changePassword') }}</a></router-link>
                <button @click="accountVerwijderen()" class="webshop-button">Account verwijderen</button>
            </div>
            
           






            <!-- <h1>Welkom bij uw account {{$store.state.username }}</h1>
            <router-link :to="{name: 'winkelen'}"><a>Naar de webshop</a></router-link>
             -->
            
        </div>
    </div>
</template>
<script>
import Sidebar from '@/components/Dashboard/SidebarLeraar.vue'
import DashboardHeader from '@/components/Dashboard/DashboardHeader.vue'
import DashboardHeading from '@/components/Dashboard/DashboardHeading.vue'

import Input from '@/components/Form/BetterInput.vue'
import Popup from '@/components/Widgets/Popup.vue'
import BetterButton from '@/components/Widgets/BetterButton.vue'
export default{
    data(){
        return{
            bewerkModus: false,
            showWaitPopup: false,
            showFinalPopup: false,
        }
        
    },
    components: {Sidebar, DashboardHeader, DashboardHeading, Input, Popup, BetterButton},
    computed: {

        windowWidth(){
            return this.$store.state.windowSize.width
        },
        toggledSidebar(){
            return this.$store.state.mobileExtend
        },
        userInformation(){
            return this.$store.state.user
        },
        
    },
    methods: {
        async nextStep(){
            this.showWaitPopup = false
            this.showFinalPopup = true
            await this.$store.dispatch("verwijderAccount")
            
        },
        afronden(){
            this.showWaitPopup = false
        },
        accountVerwijderen(){
            this.showWaitPopup = true
        },
        bewerken(){
            this.bewerkModus = true
        },
        async slaOpBewerken(){      
            if(this.userInformation.email==""){
                this.$store.commit("show_snackbar", {
                    text: "De email is nog niet ingevuld.",
                    type: "warning"
                })
            } else if(this.userInformation.username==""){
                this.$store.commit("show_snackbar", {
                    text: "De username is nog niet ingevuld.",
                    type: "warning"
                })
            }else if(this.userInformation.school==""){
                this.$store.commit("show_snackbar", {
                    text: "De school is nog niet ingevuld.",
                    type: "warning"
                })
            }else if(this.userInformation.opleiding==""){
                this.$store.commit("show_snackbar", {
                    text: "De opleiding is nog niet ingevuld.",
                    type: "warning"
                })
            }else {
                
                await this.$store.dispatch("profielAanpassen", this.$store.state.user)
            setTimeout(() => {this.bewerkModus = false}, 1)
            await this.$store.dispatch("get_user_information")
                
            }      
            
        },
        async annuleerBewerken(){
            await this.$store.dispatch("get_user_information")
            this.bewerkModus = false
        },
    },
    async created(){
        this.$store.dispatch("get_user_information")
        
    }

    
}

</script>

<style lang="scss">
.profiel-leraar-page{
    background-color: #F4F6FC;
    min-height: 100vh;

    .deletebutton{
        margin-top: 20px;
    }    
    .popup{
        display: flex !important;
        align-items: center;
        flex-direction: column;
        text-align: center;

      

    }
    .content{
        justify-content: space-between;
        position: absolute;
        width: calc(100% - 300px);
        top: 0px;
        left: 200px;
        padding: 0px 50px;
        overflow-x: hidden;

        .scrollable-content{
            padding-bottom: 50px;
        }

        .webshop-button{
            color: white;
            margin-top: 20px !important;
            background-color: $secondary-color;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            width: 250px;
            padding: 5px 15px;
            border-radius: 20px;
            margin-right: 5px;
            cursor: pointer;
            &:hover{
                opacity: 0.5;
                
            }
        }
        

        .all-information{
            display: flex;
            margin-bottom: 20px;
            width: calc(100%-20px);
            flex-wrap: wrap;
            

            .information{
            font-size: 20px;
            font-weight: bold;
            margin-right: 80px;
            
            }

            .info-wrapper{
                width: 300px;

                .data{
                    word-wrap: break-word;
                }
            }
        }
        
       
    }

    @media (max-width: 900px){
        .content{
            left: 0px;
            width: calc(100% - 100px);
            &.mobile-extend{
                display: block;
                left: 200px;
                width: calc(100% - 300px);

            }
        }
    }

    @media (max-width: 600px){
        .content{
            padding: 0px 10px;
            width: calc(100% - 20px);
            &.mobile-extend{
                left: calc(100% - 70px);
                width: 70px;
                .scrollable-content{
                    display: none;
                }
            }            
        }

    }
}

</style>