<template>
    <div class="login-leraar">
        <div v-if="role=='leraar'">
            <div class="inlog-header" >
                <div @click="changeRole" class="inlog-options"><span class="line-through">Student</span></div>
                <div class="inlog-options "><span class="optie-benik">Log hier in als </span></div>
                <div class="inlog-options">Docent</div>
              

            </div>



            <div class="login-information">
                <div class="foto-kant">
                    <img src="/assets/registreren.png"  class="plaatje-inlog" />
                </div>
                <div class="informatie-kant" @keyup.enter="login">
              
                    <Input 
                        
                        v-model="Email"
                        type="textsmall"
                        mode="login"
                        tekst="E-mail"
                    ></Input>
                    <Input title="Wachtwoord" type="passwordd" mode="feedback" v-model="password"></Input>
                    <RouterLink :to="{name: 'wachtwoordVergeten'}"><a>{{ $t('forgotPassword') }}</a></RouterLink>
                    <BetterButton v-if="!inlog" @click="login" class="button">Login</BetterButton>

                    <v-progress-circular
                    v-if="inlog"
      indeterminate

      class="loading-spinner"
    ></v-progress-circular>
                </div>
            </div>

        </div>
        <div v-if="role=='student'" >
            <div class="inlog-header" >
                <div @click="changeRole" class="inlog-options"><span class="line-through">Docent</span></div>
                <div class="inlog-options "><span class="optie-benik">Log hier in als </span></div>
                <div class="inlog-options">Student</div>
              

            </div>



            <div class="login-information">
                <div class="foto-kant">
                    <img src="/assets/registreren.png"  class="plaatje-inlog" />
                </div>
                <div class="informatie-kant" @keyup.enter="login">
              
                    <Input 
                        
                        v-model="Email"
                        type="textsmall"
                        mode="login"
                        tekst="E-mail"
                    ></Input>
                    <Input title="Wachtwoord" type="passwordd" mode="feedback" v-model="password"></Input>
                    <RouterLink :to="{name: 'wachtwoordVergeten'}"><a>{{ $t('forgotPassword') }}</a></RouterLink>
                    <BetterButton @click="login" class="button">Login</BetterButton>
                    
                </div>
            </div>

        </div>

      
        
        
    </div>
</template>
<script>
import OnboardingPage from '@/components/Onboarding/OnboardingPage.vue'
import Form from '@/components/Form/Form.vue'
import Input from '@/components/Form/BetterInput.vue'
import BetterButton from "@/components/Widgets/BetterButton.vue"
export default{
    components: {OnboardingPage, Form, Input, BetterButton},
    data(){
        return {
            inlog: false,
            password: "",
            herhaalPassword: "",
            Email: "",
            Naam: "",
            Studie: "",
            School: "",
            onderwijsniveau: "",
            username: "",
            email: "",
            success: false,
            button: false,
            failed: false,
            role: "leraar",
            options: {
                talen: {
                    "Engels": "en",
                    "Nederlands": "nl",
                   
                },
                scholen: {
                    "MBO": "mbo",
                    "HBO": "hbo",
                    "Universitair": "uni",
                   
                },
               
            },
            selectedOption: '',
            opties: ["Collega's", "Google", "LinkedIn", "Instagram", "Anders"],
            selectedIndex: null,    
            selectedOptions: [],

        }
    },
    mounted() {
        document.title = "Eduface | Login";    },
  
    methods: {
        async login(){
            this.inlog=true
            await this.$store.dispatch("loginEdu",{
                password: this.password,
                email: this.Email,
                loginType: this.role
            }).then(success => {
                this.email = ""
                this.wachtwoord = ""
                this.inlog=false
            })

        },
        changeRole(){
            if(this.role=="leraar"){
                this.role="student"
            }else{
                this.role="leraar"
            }
            
        },
        async naarDashboard(){
            if (this.selectedOptions.length === 0) {
            this.$store.commit("show_snackbar", {
                text: "Kies minimaal 1 optie.",
                type: "warning"
            });                                         
        }else{
            await this.$store.dispatch("signupPersonalised",{
                    email: this.Email,
                    password: this.password,
                    naam: this.Naam,
                    role: "leraar",
                    onderwijsniveau: this.options.scholen[this.onderwijsniveau],
                    studie: this.Studie,
                    school: this.School,
                    gevonden: this.selectedOptions,

                })
        }
        },
        verder(){
            if(this.password!=this.herhaalPassword || this.password==""){
                this.$store.commit("show_snackbar", {
                    text: "De wachtwoorden matchen niet of zijn nog niet ingevuld.",
                    type: "warning"
                })
                this.password = ""
                this.herhaalPassword = ""
                
            }else if (this.Email == "") {
            this.$store.commit("show_snackbar", {
                        text: "Vul je email in.",
                        type: "warning"
                    })
                    
        }else if(this.stap==1){
            this.stap = 2

        }else if(this.Naam == ""){
            this.$store.commit("show_snackbar", {
                        text: "Vul je naam in.",
                        type: "warning"
                    }) 

        }
        else if(this.onderwijsniveau == ""){
            this.$store.commit("show_snackbar", {
                        text: "Vul je onderwijsnivea in.",
                        type: "warning"
                    }) 

        }
        else if(this.Studie == ""){
            this.$store.commit("show_snackbar", {
                        text: "Vul je studie in.",
                        type: "warning"
                    }) 

        }
        else if(this.School == ""){
            this.$store.commit("show_snackbar", {
                        text: "Vul je school in.",
                        type: "warning"
                    }) 

        }else if(this.stap==2){
            this.stap = 3

        }
        },
        toggleOption(option) {
        if (this.isSelected(option)) {
            // If option is already selected, remove it from the array
            this.selectedOptions.splice(this.selectedOptions.indexOf(option), 1);
        } else {
            // If option is not selected, add it to the array
            this.selectedOptions.push(option);
        }
        console.log("Selected options:", this.selectedOptions);
    },
    isSelected(option) {
        // Check if the option is selected by checking if it exists in the array
        return this.selectedOptions.includes(option);
    },
        buttonPress(){
            this.button = true
        },
        async signupEN(){
            this.button = true
            if(this.password==this.herhaalPassword){
                
                await this.$store.dispatch("signup",{
                    username: this.username,
                    password: this.password,
                    email: this.email,
                    role: "leraar",
                    language: "en"
                })
                
                this.success = this.$store.state.signup_success
                this.failed = this.$store.state.signup_failed
                if(this.failed){
                    this.button = false
                }
            }else{
                this.$store.commit("show_snackbar", {
                    text: "De wachtwoorden matchen niet.",
                    type: "warning"
                })
                this.password = ""
                this.herhaalPassword = ""
                this.button = false
            }
            
        },
        async signup(){
            this.button = true
            if(this.password==this.herhaalPassword){
                await this.$store.dispatch("signup",{
                    username: this.username,
                    password: this.password,
                    email: this.email,
                    role: "leraar",
                })
                this.success = this.$store.state.signup_success
            }else{
                this.$store.commit("show_snackbar", {
                    text: "De wachtwoorden matchen niet.",
                    type: "warning"
                })
                this.password = ""
                this.herhaalPassword = ""
                this.button = false
            }
            
        }
    }
}
</script>
<style lang="scss">
.login-leraar{

    .inlog-header{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        font-size: 25px;

        .inlog-options{
            margin: 0px 30px;
         

            .optie-benik{
                font-weight: bold;
            }

            .line-through{
                text-decoration: line-through;
                cursor: pointer;
                &:hover{
                    opacity: 0.6;
                }
      
            }
        }
        
    }

    .login-information {
  display: flex;
  justify-content: center;
  height: 550px;


  .onsgevonden{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .button{
    margin-top: 40px;
    align-items: center;
    text-align: center;
    width: 200px;
    height: 45px;
    
  }

    .option {
    border: 2px solid black;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    width: 200px;
    height: 30px;
    font-size: 25px;
    border-radius: 40px;
    text-align: center;
    align-items: center;
  }
  .selected {
    border-color: orange;
  }

    .gevonden{
    font-size: 30px;
    font-weight: bold;
    margin-top: 40px;

    .blue{
        color: $base-color;

    }
    .oranje{
        color: $secondary-color;
    }

  }
  }
  
}

.foto-kant {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .plaatje-inlog{
    width: 600px;
  }
}

.informatie-kant {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;


  flex-direction: column;

  .input{
    margin-left: -30px;
  }
  .button{
    margin-top: 40px;
    align-items: center;
    text-align: center;
    width: 200px;
    height: 45px;
    
  }
}
.percentage{
    display: flex;
        align-items: center;
        justify-content: center;

        .progress{
            width: 300px;
        }
}
    h1{
        color: $base-color;
    }

    .next-content{
        height: calc(100% - 80px);
        padding: 40px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .normal-title{
            font-family: "Roboto";
            font-weight: 700;
            font-size: 25px;
        }

        .normal-subtitle{
            font-family: "Roboto";
            font-size: 20px;
        }

        .normal-tip{
            font-family: "Roboto";
            font-size: 15px;
            line-height: 60px;
        }

        .row2{
            display: flex;

            img{
                width: 50%;
            }
        }
    }

    .title{
        font-family: "Roboto";
        font-weight: 700;
        font-size: 35px;
        line-height: 56px;
    }

    

    .top-text{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .onboarding-nav{
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        margin-top: 10px;

        div{
            margin-right: 10px;
            text-decoration: underline;
            cursor: pointer;
            color: #000;
            &:hover{
                opacity: 0.6;
            }

            &.active{
                text-decoration: none;
                font-weight: bold;
                cursor: default;

                &:hover{
                    opacity: 1;
                }
            }
        }
    }
}
@media (max-width: 900px){
    .login-leraar{
        .inlog-header{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        font-size: 15px;

        .inlog-options{
            margin: 0px 30px;
         

            .optie-benik{
                font-weight: bold;
            }

            .line-through{
                text-decoration: line-through;
            }
        }
        
    }

    .login-information {
  display: flex;
  justify-content: center;
  height: 550px;


  .onsgevonden{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .button{
    margin-top: 40px;
    align-items: center;
    text-align: center;
    width: 200px;
    height: 45px;
    
  }

    .option {
    border: 2px solid black;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    width: 200px;
    height: 30px;
    font-size: 25px;
    border-radius: 40px;
    text-align: center;
    align-items: center;
  }
  .selected {
    border-color: orange;
  }

    .gevonden{
    font-size: 30px;
    font-weight: bold;
    margin-top: 40px;

    .blue{
        color: $base-color;

    }
    .oranje{
        color: $secondary-color;
    }

  }
  }
  
}

.foto-kant {
display: none;
}

.informatie-kant {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;


  flex-direction: column;

  .input{
    margin-left: -30px;
  }
  .button{
    margin-top: 40px;
    align-items: center;
    text-align: center;
    width: 100px;
    height: 45px;
    
  }
}
.percentage{
    display: flex;
        align-items: center;
        justify-content: center;

        .progress{
            width: 200px;
        }
}
    }

    }

    @media (max-width: 600px){
        .login-leraar{
        .inlog-header{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        font-size: 15px;

        .inlog-options{
            margin: 0px 30px;
         

            .optie-benik{
                font-weight: bold;
            }

            .line-through{
                text-decoration: line-through;
            }
        }
        
    }

    .login-information {
  display: flex;
  justify-content: center;
  height: 550px;


  .onsgevonden{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .button{
    margin-top: 40px;
    align-items: center;
    text-align: center;
    width: 200px;
    height: 45px;
    
  }

    .option {
    border: 2px solid black;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    width: 200px;
    height: 30px;
    font-size: 25px;
    border-radius: 40px;
    text-align: center;
    align-items: center;
  }
  .selected {
    border-color: orange;
  }

    .gevonden{
    font-size: 30px;
    font-weight: bold;
    margin-top: 40px;

    .blue{
        color: $base-color;

    }
    .oranje{
        color: $secondary-color;
    }

  }
  }
  
}

.foto-kant {
display: none;
}

.informatie-kant {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;


  flex-direction: column;

  .input{
    margin-left: -30px;
  }
  .button{
    margin-top: 40px;
    align-items: center;
    text-align: center;
    width: 100px;
    height: 45px;
    
  }
}
.percentage{
    display: flex;
        align-items: center;
        justify-content: center;

        .progress{
            width: 200px;
        }
}
    }
    }
</style>