<template>
    <div class="wachtwoord-aanpassen">
   
        <OnboardingPage v-if="$i18n.locale === 'nl'"
            banner="login-student" 
            title="Zo weer online!"
            bannerText="Wachtwoord aanpassen" 
            bannerBold=""
            :magNietTerug="false"
        >
            <Form v-if="$i18n.locale === 'nl'" action="wachtwoordAanpassen" @submit="wachtwoordAanpassen" @keyup.enter="wachtwoordAanpassen">
                <div class="top-text">
                    <div class="title">Wachtwoord aanpassen</div>
                    <div class="sub-title">vul je nieuwe wachtwoord in</div>
                </div>
                
                <Input title="Oud wachtwoord" type="password" v-model="oldpassword"></Input>
                <Input title="Wachtwoord" type="password" v-model="password"></Input>
                <Input title="Herhaal wachtwoord" type="password" v-model="password1"></Input>

                
            </Form>
            
        </OnboardingPage>
        <OnboardingPage v-if="$i18n.locale === 'en'"
            banner="login-student" 
            title="Straight back online!"
            bannerText="Adjust password" 
            bannerBold=""
            :magNietTerug="false"
        >
            
            <Form v-if="$i18n.locale === 'en'" action="wachtwoordAanpassenEN" @submit="wachtwoordAanpassenEN" @keyup.enter="wachtwoordAanpassenEN">
                <div class="top-text">
                    <div class="title">Adjust password</div>
                    <div class="sub-title">Fill in your new password</div>
                </div>
                
                <Input title="Old password" type="password" v-model="oldpassword"></Input>
                <Input title="Password" type="password" v-model="password"></Input>
                <Input title="New password" type="password" v-model="password1"></Input>

                
            </Form>
        </OnboardingPage>
    </div>
</template>
<script>
import OnboardingPage from '@/components/Onboarding/OnboardingPage.vue'
import Form from '@/components/Form/Form.vue'
import Input from '@/components/Form/BetterInput.vue'
import NavItem from '@/components/Dashboard/NavItem.vue'

export default{
    components: {OnboardingPage, Form, Input, NavItem},
    data(){
        return {
            password: "",
            password1: "",
        }
    },
    methods: {
        async wachtwoordAanpassen(){
            if(this.password==""){
                this.$store.commit("show_snackbar", {
                    text: "Het wachtwoord is nog niet ingevuld.",
                    type: "warning"
                })
            } else if(this.password1==""){
                this.$store.commit("show_snackbar", {
                    text: "Het herhaal wachtwoord is nog niet ingevuld.",
                    type: "warning"
                })
            } else if(this.oldpassword==""){
                this.$store.commit("show_snackbar", {
                    text: "Het oude wachtwoord is nog niet ingevuld.",
                    type: "warning"
                })
            }else if(this.password1!=this.password){
                this.$store.commit("show_snackbar", {
                    text: "De nieuwe wachtwoorden zijn niet gelijk aan elkaar.",
                    type: "warning"
                })
            }else{
                await this.$store.dispatch("wachtwoordAanpassen",{
                    password: this.password1,
                    oldpassword: this.oldpassword,
                })
            }

        },
        async wachtwoordAanpassenEN(){
            if(this.password==""){
                this.$store.commit("show_snackbar", {
                    text: "Het wachtwoord is nog niet ingevuld.",
                    type: "warning"
                })
            } else if(this.password1==""){
                this.$store.commit("show_snackbar", {
                    text: "Het herhaal wachtwoord is nog niet ingevuld.",
                    type: "warning"
                })
            } else if(this.oldpassword==""){
                this.$store.commit("show_snackbar", {
                    text: "Het oude wachtwoord is nog niet ingevuld.",
                    type: "warning"
                })
            }else if(this.password1!=this.password){
                this.$store.commit("show_snackbar", {
                    text: "De nieuwe wachtwoorden zijn niet gelijk aan elkaar.",
                    type: "warning"
                })
            }else{
                await this.$store.dispatch("wachtwoordAanpassen",{
                    password: this.password1,
                    oldpassword: this.oldpassword,
                    taal: "en",
                })
            }

        },
        
    },
    async created(){
        this.$store.dispatch("get_user_information")
    }
}

</script>
<style lang="scss">
.wachtwoord-aanpassen{
    h1{
        color: $base-color;
    }
    

    

    .next-content{
        height: calc(100% - 80px);
        padding: 40px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .normal-title{
            font-family: "Roboto";
            font-weight: 700;
            font-size: 25px;
        }

        .normal-subtitle{
            font-family: "Roboto";
            font-size: 20px;
        }

        .normal-tip{
            font-family: "Roboto";
            font-size: 15px;
            line-height: 60px;
        }

        .row2{
            display: flex;

            img{
                width: 50%;
            }
        }
    }

    .title{
        font-family: "Roboto";
        font-weight: 700;
        font-size: 35px;
        line-height: 35px;
    }

    .subtitle{
        font-family: "Roboto";
        font-size: 25px;
    }

    .onboarding-nav{
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        margin-top: 10px;

        div{
            margin-right: 10px;
            text-decoration: underline;
            color: #000;
            cursor: pointer;
            &:hover{
                opacity: 0.6;
            }

            &.active{
                text-decoration: none;
                font-weight: bold;
                cursor: default;

                &:hover{
                    opacity: 1;
                }
            }
        }
    }
}
</style>