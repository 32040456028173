import { checkCompatEnabled } from '@vue/compiler-core';
import { createStore, createLogger } from 'vuex'
import router from '../router/index'
import { i18n } from '../main'; 

const Buffer = require('buffer').Buffer


const axios = require("axios").default
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL

// axios.defaults.baseURL = "https://blockbook-backend-hotylusf3q-ez.a.run.app"
axios.defaults.timeout = 10000

export default createStore({
    plugins: [createLogger()],
    state () {
      return {
        user: {},
        signup_success: false,
        signup_failed: false,
        token: null,
        email: null,
        role: null,
        onderwijsassistent: null,
        opleidingsniveau: null,
        username: null,
        voornaam: null,
        tussenvoegsel: null,
        achternaam: null, 
        geboortedatum: null, 
        school: null, 
        opleidingsniveau: null, 
        startjaar: null,
        email_contactpersoon: null, 
        kvk_nummer: null, 
        aantal_werknemers: null, 
        straatnaam: null, 
        postcode: null, 
        huisnummer: null, 
        stad: null, 
        logged_in: false,
        books: [],
        all_books: [],
        current_book: {},
        buy_succes: false,
        reader_pages: [null,"",""],
        totalPages: 0,
        informatie_docent: 0,
        overview: [],
        marking_data: {},
        notes_data: {},
        showSnackbar: false,
        snackbarText: "Er is iets fout gegaan",
        snackbarType: null,
        snackbarTimeout: 2000,
        snackbarColor: "#ff0000",
        errorMessage: "",
        uitgeverContact: {},
        feedback: {},
        feedbackk: { feedback: "" },
        transacties: [],
        balans: 0,
        books_uitgever: [],
        windowsSize: 10000,
        recent_books: [],
        mobileExtend: false,
        accessCode: "",
        feedback_id: "",
        auth_id: "",
        popup: false,
        popuppl: false,
        users_module: [],
        users: [],
        modules: [],
        modules_leraar: [],
        module_overviews: [],
        module_weekinformatie: [],
        module_eindtoets: [],
        module_weektoets: [],
        beoordelingsformulieren_leraar: [],
        feedback_leraar: [],
        feedback_student: [],
        vakken_leraar: [],
        logboek_leraar: [],
        logboek_studenten: [],
        beoordelingsformulier_leraar: [],
        beoordelingsforumlier: 0,
        feedback_klaar: false,
        opdrachten_leraar: [],
        opdrachten_leraar_pl: [],
        opdracht_leraar_pl: {},
        user_pl_process: [],
        beoordelingsformulier_integratie: [],
        info_integratie: [],
        authentication_integratie: [],
      }
    },
    mutations: {
        windowSize(state, size){
            state.windowSize = size
        },
        username(state, username){
            state.username = username
        },
        balans(state, balans){
            state.balans = balans
        },
        user(state, user){
            state.user = user
        },
        transacties(state, transacties){
            state.transacties = transacties
        },
        token(state, token){
            state.token = token
        },
        email(state, email){
            state.email = email
        },
        opleidingsniveau(state, opleidingsniveau){
            state.opleidingsniveau = opleidingsniveau
        },
        onderwijsassistent(state, onderwijsassistent){
            state.onderwijsassistent = onderwijsassistent
        },
        signup_success (state) {
            state.signup_success = true
        },
        signup_failed (state) {
            state.signup_failed = true
        },
        log_in (state, data){
            state.logged_in = data
        },
        role(state, role){
            state.role = role
        },
        books(state, books){
            state.books = books
        },
        books_uitgever(state, books_uitgever){
            state.books_uitgever = books_uitgever
        },
        all_books(state, books){
            state.all_books = books
        },
        current_book(state, book){
            state.current_book = book
        },
        buy_success (state) {
            state.buy_success = true
        },
        buy_reset (state) {
            state.buy_success = false
        },
        reader_pages (state, {view_page, base64}){
            state.reader_pages[view_page] = base64
        },
        totalPages(state, num){
            state.totalPages = num
        },
        overview(state, overview){
            state.overview = overview
        },
        marking_data(state, marking_data){
            state.marking_data = marking_data
        },
        notes_data(state, notes_data){
            state.notes_data = notes_data
        },
        show_snackbar(state, {text, type}){
            state.showSnackbar = true
            console.log(text)
            state.snackbarText = decodeURIComponent(text.replace(/\+/g, " "))
            state.snackbarType = type
            state.snackbarColor = type == "success" ? "#02e26b" : (type == "warning" ? "#d3b51d" : "#ff0000")
        
        },
        modify_snackbar_show(state, show){
            state.showSnackbar = show
        },
        errorMessage(state, message){
            state.errorMessage = message
        },
        uitgeverContact(state, uitgeverContact){
            state.uitgeverContact = uitgeverContact
        },
        recent_books(state, books){
            state.recent_books = books
        },
        toggleSidebar(state){
            state.mobileExtend = !state.mobileExtend
        },
        accessCode(state, code){
            state.accessCode = code
        },
        feedback_id(state, feedback_id){
            state.feedback_id = feedback_id
        },
        auth_id(state, auth_id){
            state.auth_id = auth_id
        },
        showPopup(state, val){
            state.popup = val
        },
        showPopupPl(state, val){
            state.popuppl = val
        },
        users_module(state, users){
            state.users_module = users
        },
        users(state, users){
            state.users = users
        },
        modules(state, modules){
            state.modules = modules
        },
        modules_leraar(state, modules_leraar){
            state.modules_leraar = modules_leraar
        },
        beoordelingsformulieren_leraar(state, beoordelingsformulieren_leraar){
            state.beoordelingsformulieren_leraar = beoordelingsformulieren_leraar
        },
        module_overviews(state, module_overviews){
            state.module_overviews = module_overviews
        },
        module_weekinformatie(state, module_weekinformatie){
            state.module_weekinformatie = module_weekinformatie
        },
        module_eindtoets(state, module_eindtoets){
            state.module_eindtoets = module_eindtoets
        },
        module_weektoets(state, module_weektoets){
            state.module_weektoets = module_weektoets
        },
        feedback_leraar(state, feedback_leraar){
            state.feedback_leraar = feedback_leraar
        },
        feedback_student(state, feedback_student){
            state.feedback_student = feedback_student
        },
        feedback(state, feedback){
            state.feedback = feedback
        },
        feedbackk(state, feedbackk){
            state.feedbackk = feedbackk
        },
        informatie_docent(state, informatie_docent){
            state.informatie_docent = informatie_docent
        },
        vakken_leraar(state, vakken_leraar){
            state.vakken_leraar = vakken_leraar
        },
        logboek_leraar(state, logboek_leraar){
            state.logboek_leraar = logboek_leraar
        },
        logboek_studenten(state, logboek_studenten){
            state.logboek_studenten = logboek_studenten
        },
        beoordelingsformulier_leraar(state, beoordelingsformulier_leraar){
            state.beoordelingsformulier_leraar = beoordelingsformulier_leraar
        },
        beoordelingsformulier(state, beoordelingsformulier){
            state.beoordelingsformulier = beoordelingsformulier
        },
        feedback_klaar(state, feedback_klaar){
            state.feedback_klaar = feedback_klaar
        },
        opdrachten_leraar(state, opdrachten_leraar){
            state.opdrachten_leraar = opdrachten_leraar
        },
        opdrachten_leraar_pl(state, opdrachten_leraar_pl){
            state.opdrachten_leraar_pl = opdrachten_leraar_pl
        },
        opdracht_leraar_pl(state, opdracht_leraar_pl){
            state.opdracht_leraar_pl = opdracht_leraar_pl
        },
        user_pl_process(state, user_pl_process){
            state.user_pl_process = user_pl_process
        },
        beoordelingsformulier_integratie(state, beoordelingsformulier_integratie){
            state.beoordelingsformulier_integratie = beoordelingsformulier_integratie
        },
        info_integratie(state, info_integratie){
            state.info_integratie = info_integratie
        },
        authentication_integratie(state, authentication_integratie){
            state.authentication_integratie = authentication_integratie
        },

    },
    getters: {
        role(state){
            return state.role
        },
        books(state){
            return books
        },
        all_books(state){
            return all_books
        }
    },
    actions: {
        
        async get_user_information({commit, state}) {
            const token = state.token;
            const res = await axios.get("/users/get-user-information", {
                headers: {
                    'Authorization': `Bearer ${token}` // Send token in Authorization header
                }
            });
        
            commit("user", res.data);
        },
        
        async get_user_pl_process({commit, state}){
            const token = state.token
            const res = await axios.get("/users/get-user-pl-process/" + token)
            console.log(res.data)
            commit("user_pl_process", res.data)

            
        },
        async informatie_docent({commit, state}){
            const token = state.token
            const res = await axios.get("/users/informatie-docent/" + token)
            commit("informatie_docent", res.data)

            
        },
      
        async get_vakken_leraar({commit}, id){
            
            const token = localStorage.getItem('token')
            const res = await axios.get("/users/vakken-leraar/" + token);
            console.log(res.data)
            commit("vakken_leraar", res.data)
        },
        async get_modules_leraar({commit}, id){
            
            const token = localStorage.getItem('token')
            const res = await axios.get("/users/modules-leraar/" + token+ "/" + id);
            console.log(res.data)
            commit("modules_leraar", res.data)
        },
        async get_opdrachten_leraar({commit}, id){
            console.log(id)
            const token = localStorage.getItem('token')
            const res = await axios.get("/users/opdrachten-leraar/" + token+ "/" + id);
            console.log(res.data)
            commit("opdrachten_leraar", res.data)
        },
        async get_opdrachten_leraar_pl({commit}, id){
            console.log(id)
            const token = localStorage.getItem('token')
            const res = await axios.get("/users/opdrachten-leraar-pl/" + token+ "/" + id);
            console.log(res.data)
            commit("opdrachten_leraar_pl", res.data)
        },
        async get_opdracht_leraar_pl({commit}, id){
            console.log(id)
            const res = await axios.get("/users/opdracht-leraar-pl/" + id);
            console.log(res.data)
            commit("opdracht_leraar_pl", res.data)
        },
        async get_feedback_leraar({commit}, id){
            console.log(id)
            const token = localStorage.getItem('token')
            const res = await axios.get("/users/feedback-leraar/" + token+ "/" + id);
            console.log(res.data)
            commit("feedback_leraar", res.data)
        },
        async get_feedback_leraar_op({commit}, {id, id2}){
            console.log(id)
            console.log(id2)
            const token = localStorage.getItem('token')
            const res = await axios.get("/users/feedback-leraar/" + token+ "/" + id+ "/" + id2);
            console.log(res.data)
            commit("feedback_leraar", res.data)
        },
        async get_beoordelingsformulier_leraar({commit}, id){
            console.log(id)
            const token = localStorage.getItem('token')
            const res = await axios.get("/users/beoordelingsformulier-leraar/" + token+ "/" + id);
            console.log(res.data)
            commit("beoordelingsformulier_leraar", res.data)
        },
        async get_beoordelingsformulier({commit}, {id, token}){
            console.log(id)
            console.log(token)
            const res = await axios.get("/users/beoordelingsformulier/" + token+ "/" + id);
            console.log(res.data)
            commit("beoordelingsformulier", res.data)
        },
        async get_beoordelingsformulier_integratie_leraar({state, commit, dispatch}, id){
            
            console.log(id)
            const res = await axios.get('/modules/beoordelingsformulier-integratie/' + id)
            console.log(res.data)
            commit("beoordelingsformulier_integratie",res.data)
        },
        async get_info_integratie({state, commit, dispatch}, id){
            
            console.log(id)
            const res = await axios.get('/modules/info-integratie/' + id)
            console.log(res.data)
            commit("info_integratie", res.data)
        },
        async get_authentication_integratie({state, commit, dispatch}, id){
            
            console.log(id)
            const res = await axios.get('/modules/authentication-integratie/' + id)
            console.log(res.data)
            commit("authentication_integratie", res.data)
        },

        async get_logboek_leraar({commit}, id){
            console.log(id)
            const token = localStorage.getItem('token')
            const res = await axios.get("/users/logboek-leraar/" + token+ "/" + id);
            console.log(res.data)
            commit("logboek_leraar", res.data)
        },
        async get_logboek_studenten({commit}, id){
            console.log(id)
            const token = localStorage.getItem('token')
            const res = await axios.get("/users/logboek-leraar-studenten/" + token+ "/" + id);
            console.log(res.data)
            commit("logboek_studenten", res.data)
        },
        
        async get_beoordelingsformulieren_leraar({commit}, id){
            
            const token = localStorage.getItem('token')
            const res = await axios.get("/users/beoordelingsformulieren-leraar/" + token + "/" + id);
            commit("beoordelingsformulieren_leraar", res.data)
        },
        async get_modules({state, commit, dispatch}){
            const token = localStorage.getItem('token')
            const res = await axios.get("/users/modules/" + token);
            console.log(res.data)
            commit("modules", res.data)
        },
        async signup({ commit }, { username, password, email, role, language }) {
            try {
                const res = await axios.post("/users/signup", {
                    // Send data in the body instead of params
                    username: username,
                    password: password,
                    email: email,
                    role: role,
                    language: language
                }, {
                    timeout: 120000
                });
        
                if (res.data.message != null) {
                    commit('signup_success');
                }
            } catch (err) {
                commit('signup_failed');
                commit("show_snackbar", {
                    text: err.response.data.error,
                    type: "error"
                });
            }
        },
        
        async signupPersonalised({commit, dispatch, state}, {email, password, naam, role, onderwijsniveau, studie, school, gevonden}){
            try{
                const res = await axios.post("/users/signup-personalised", {
                    password: password,
                    email: email,
                    username: naam,
                    role: role,
                    onderwijsniveau: onderwijsniveau,
                    studie: studie,
                    school: school,
                    gevonden: gevonden,
                });
                console.log("kom ik hier")
                if (res.data.token != null){
                    
                    localStorage.setItem('token', res.data.token)
                    localStorage.setItem('username', res.data.username)
                    localStorage.setItem('email', res.data.email)
                    localStorage.setItem('role', res.data.role)
                    await dispatch("check_login")
                    router.push({name: state.role})
                   
                    console.log(" of kom ik hier")
                }
                
            }catch(err){
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            }
            
            
        },
        
        async verifyMail({commit, dispatch, state}, token){
            try{
                const res = await axios.get("/users/verify-mail/" + token);
                
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('username', res.data.username)
                localStorage.setItem('email', res.data.email)
                localStorage.setItem('role', res.data.role)
                await dispatch("check_login")
                router.push({name: state.role})
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
                
            }catch(err){
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
                router.push("/")
            }
            
        },
        async verify({commit, dispatch}, token){
            try{
                const res = await axios.get("/users/verify/" + token);
            
                localStorage.setItem('username', res.data.username)
                commit("username", res.data.username)
                localStorage.setItem('email', res.data.email)
                commit("email", res.data.email)
                localStorage.setItem('token', res.data.token)
                commit("token", res.data.token)
                localStorage.setItem('role', res.data.role)
                commit("role", res.data.role)
                
                await dispatch('check_login')
                router.push({name: "personalisering-" + res.data.role})
            }catch(err){
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
                router.push("/")
            }
            
        },
        
        async verifyEN({commit, dispatch}, token){
            try{
                const res = await axios.get("/users/verify/" + token);
            
                localStorage.setItem('username', res.data.username)
                commit("username", res.data.username)
                localStorage.setItem('email', res.data.email)
                commit("email", res.data.email)
                localStorage.setItem('token', res.data.token)
                commit("token", res.data.token)
                localStorage.setItem('role', res.data.role)
                commit("role", res.data.role)
                
                await dispatch('check_login')
                router.push({name: "personalise-teacher"})
            }catch(err){
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
                router.push("/en")
            }
            
        },
        async check_login({commit, dispatch}){
            const token = localStorage.getItem('token')
            
            if (token !== null){
                try{
                    const res = await axios.get("/users/role/" + token);
                    commit('role', res.data.role)
                    commit('username', localStorage.getItem('username'))
                    commit('email', localStorage.getItem('email'))
                    commit('token', localStorage.getItem('token'))
                    commit('opleidingsniveau', localStorage.getItem('opleidingsniveau'))
                    if(localStorage.getItem('opleidingsniveau')==='uni'){
                        commit('onderwijsassistent', 'Ruben')
                    }
                    if(localStorage.getItem('opleidingsniveau')==='hbo'){
                        commit('onderwijsassistent', 'Marlou')
                    }
                    if(localStorage.getItem('opleidingsniveau')==='mbo'){
                        commit('onderwijsassistent', 'Omar')
                    }
                    // commit('opleidingsniveau', localStorage.getItem('opleidingsniveau'))
                    commit('log_in', true)


                }catch(err){
                    console.log("test")
                    dispatch('logout')
                }
                
                
            }else{
                commit('log_in', false)
            }
            
        },
        
        async loginEdu({state, commit, dispatch}, {password, email, loginType}){
 

            try{
                
                const res = await axios.post("/users/loginEdu", {
                    password: password,
                    email: email,
                    loginType: loginType
                });
                if (res.data.token != null){
                    console.log(res.data.opleidingsniveau)
                    localStorage.setItem('token', res.data.token)
                    localStorage.setItem('publicToken', res.data.public_token)
                    localStorage.setItem('username', res.data.username)
                    localStorage.setItem('email', res.data.email)
                    localStorage.setItem('role', res.data.role)
                    localStorage.setItem('opleidingsniveau', res.data.opleidingsniveau)
                    await dispatch('check_login')
                    console.log(state.role)
                    console.log("hello world")

                  
                            
                            router.push({name: state.role})
                  
                    
                }
                else{
                    return new Promise((resolve, reject) => {
                        // return false to the login page
                        resolve(false)
                    })
                }
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            }
            

        },
        async wachtwoordVergeten({state, commit, dispatch}, {emailUsername, taal}){
            console.log(taal)
            try{
                const res = await axios.post("/users/wachtwoord-vergeten/", {
                    emailUsername: emailUsername,
                    taal: taal,
                });
                if (res.data.message != null){
                    return new Promise((resolve, reject) => {
                        resolve(true)
                    })   
                }
                else{
                    return new Promise((resolve, reject) => {
                        // return false to the wachtwoord vergeten page
                        resolve(false)
                    })
                }
            }catch(err){
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
                return new Promise((resolve, reject)=>{
                    resolve(false)
                })
            }
            

        },
        async nieuwWachtwoord({state, commit, dispatch}, {password, verifyToken}){
            const currentLocale = i18n.global.locale.value;
            try{
                const res = await axios.post("/users/nieuw-wachtwoord", {
                    password: password,
                    verifyToken: verifyToken,
                });
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
               
                    router.push({name: "log-in"})

                
            
            }catch(err){
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
            

        },
        async wachtwoordAanpassen({state, commit, dispatch}, {password, oldpassword, taal}){
            const language = taal;
            try{
                const token = localStorage.getItem("token")
                const res = await axios.post("/users/wachtwoord-veranderen", {
                    password: password,
                    token: token,
                    oldpassword: oldpassword,
                });
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
                if(taal == "en" && state.role=="leraar"){
                    router.push({name: "teacher"})
                }else{
                    router.push({name: state.role})
                }
                
            
            }catch(err){
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
            

        },
        async storeUserInfo({state, commit, dispatch}, {user_id, username, org_id, client_id}){
            
            try{
                
                const res = await axios.post("/modules/store-user-info", {
                    user_id: user_id,
                    username: username,
                    org_id: org_id,
                    client_id: client_id,
                });
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
                if (res.data.message != null){
                    return new Promise((resolve, reject) => {
                        resolve(true)
                    })   
                }
                
                
            
            }catch(err){
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
            

        },
        async newAuthentication({state, commit, dispatch}, {user_id, token}){
            
            try{
                
                const res = await axios.post("/modules/nieuwe-authentication", {
                    user_id: user_id,
                    token: token,
                });
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
                commit("auth_id", res.data.id)
                if (res.data.message != null){
                    return new Promise((resolve, reject) => {
                        resolve(true)
                    })   
                }
                
                
            
            }catch(err){
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
            

        },
        async logout({commit, dispatch}){
            const currentLocale = i18n.global.locale.value;
            const token = localStorage.getItem("token")
            const res = await axios.get("/users/logout/" + token);
            localStorage.removeItem('username')
            localStorage.removeItem('email')
            localStorage.removeItem('token')
            localStorage.removeItem('publicToken')
            localStorage.removeItem('role')
            localStorage.removeItem('voornaam')
            commit('username', null)
            commit('email', null)
            commit('token', null)
            commit('role', null)
            if(currentLocale=='nl'){
                router.push('/') 
            }else{
                router.push('/en')
            }
            
            

        },
        
        async get_users_module({state, commit, dispatch}){
            const token = localStorage.getItem('token')
            console.log(token)
            const res = await axios.get("/users/get-users-module/"+ token);
            console.log('hallo')
            console.log(res.data)
            commit("users_module", res.data)
        },
        async get_users({state, commit, dispatch}){
            const res = await axios.get("/users/get-users");
            
            console.log(res.data)
            commit("users", res.data)
        },
        

       
        async get_information_feedback({state, commit, dispatch}){
            const res = await axios.get('users/get-information-feedback' )
            commit("information_feedback", res.data)
            console.log(res.data)
        },
        
        async nieuwVak({state, commit, dispatch}, {titel, cover}){
            
            try{
                const token = state.token

                const res = await axios.post("/modules/nieuw-vak/", {
                    params: {
                        "token": token,
                        "titel": titel,  
                        "cover": cover,
                    },
                    timeout: 120000,
                    
                })
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        async verwijderVak({state, commit, dispatch},  module_id){
            console.log(module_id)
            try{
                const res = await axios.post("/modules/vak-verwijderen/", {
                    "vak_id": module_id,
                })
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        async verwijderAccount({state, commit, dispatch}){
            const token = state.token
            console.log(token)
            try{
                const res = await axios.post("/users/account-verwijderen/", {
                    token: token,
                })
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
                dispatch('logout')
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        async nieuweModule({state, commit, dispatch}, {moduleTitle, vak, moduleOnderwerp, taal, weekdoelen, vragen, slides, aantalVragen, aantalMeerkeuze, aantalOpen, typeVragen, mode}) {
            console.log(mode);
            try {
                const token = state.token;
        
                // Move everything into the body
                const res = await axios.post("/modules/nieuwe-module/", {
                    token: token,
                    moduleTitel: moduleTitle,  
                    vak: vak,
                    type: "eindopdracht",
                    taal: taal,
                    moduleOnderwerp: moduleOnderwerp,
                    weekdoelen: weekdoelen,
                    vragen: vragen,
                    aantalVragen: aantalVragen,
                    aantalMeerkeuze: aantalMeerkeuze,
                    aantalOpen: aantalOpen,
                    typeVragen: typeVragen,
                    mode: mode,
                    slides: slides
                }, {
                    timeout: 120000 // Timeout stays as it was
                });
        
                commit("show_snackbar", {
                    text: res.data.message,
                    type: "success"
                });
                commit("accessCode", res.data.accessCode);
                commit("showPopup", true);
                
            } catch (err) {
                console.log(err);
                commit("show_snackbar", {
                    text: err.response.data.error,
                    type: "error"
                });
            }
        },
       
       
        async nieuwBeoordelingsformulier({state, commit, dispatch}, {beoordelingsformuliernaam, beoordelingsformulier, vak_id}){
            
            try{
                const token = state.token

                const res = await axios.post("/modules/nieuw-beoordelingsformulier/", {
                    "beoordelingsformulier": beoordelingsformulier,
                    "token": token,
                    "beoordelingsformuliernaam": beoordelingsformuliernaam,  
                    "vak_id": vak_id,
                })
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
                commit("showPopup", true)
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        }, 
        async nieuwePersonalisedOpdracht({state, commit, dispatch}, {opdracht_id, vak_id}){
            console.log(opdracht_id)
            console.log(vak_id)
            try{
                const token = state.token

                const res = await axios.post("/modules/nieuwe-personalised-opdracht/", {
                    params: {
                        "token": token,
                        "opdracht_id": opdracht_id,  
                        "vak_id": vak_id,
                    },
                    timeout: 120000,
                    
                })
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
                commit("showPopup", true)
                            }catch(err){
                                console.log(err)
                                commit("show_snackbar", {
                                    text: err.response.data.error, 
                                    type: "error"
                                })
                            
                            }
                        },

        async nieuweOpdracht({state, commit, dispatch}, {titel, opdracht, taal, onderwerp, doelen, doel, model, vak_id, type}){
            console.log(model)
            try{
                const token = state.token

                const res = await axios.post("/modules/nieuwe-opdracht/", {
                    "opdracht": opdracht,
                    "token": token,
                    "titel": titel,  
                    "taal": taal,
                    "onderwerp": onderwerp,
                    "doel": doel,
                    "doelen": doelen,
                    "model": model,
                    "vak_id": vak_id,
                    "type": type,
                }, {
                    timeout: 120000 // Timeout stays as it was
                });
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
                
                commit("showPopup", true)
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        async nieuweFeedbackOp({state, commit, dispatch}, {leerling_anoniem, opdracht, opdracht_id, vak_id, type, counter, position}){
       
            try{
                const token = state.token

                const res = await axios.post("/modules/nieuwe-feedback-op/", {
                    "opdracht": opdracht,
                    "token": token,
                    "leerling_anoniem": leerling_anoniem,
                    "opdracht_id": opdracht_id,
                    "vak_id": vak_id,
                    "type": type,
                    "counter": position,
                    
                }, {
                    timeout: 120000 // Timeout stays as it was
                });
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
                if(counter - 1 === position){
                    commit("showPopupPl", true)
                }
                
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        async nieuweFeedback({state, commit, dispatch}, {leerling_anoniem, titel, opdracht, beoordelingsformulier_id, taal, onderwerp, doel, model, vak_id, type}){
            console.log(model)
            try{
                const token = state.token

                const res = await axios.post("/modules/nieuwe-feedback/", {
                    "opdracht": opdracht,
                    "token": token,
                    "leerling_anoniem": leerling_anoniem,
                    "titel": titel,  
                    "beoordelingsformulier_id": beoordelingsformulier_id,
                    "taal": taal,
                    "onderwerp": onderwerp,
                    "doel": doel,
                    "model": model,
                    "vak_id": vak_id,
                    "type": type,
                }, {
                    timeout: 120000 // Timeout stays as it was
                });
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
                
                commit("showPopup", true)
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        async nieuweFeedbackBrightspace({state, commit, dispatch}, {opdracht, taal, titel, titel2, onderwerp, doel, model, type, typeB, beoordelingsformulier, user_id, naam_docent, token}){
            try{
               


                const res = await axios.post("/modules/nieuwe-brightspace-feedback/", {
                    "opdracht": opdracht,
                    "beoordelingsformulier": beoordelingsformulier,
                    "taal": taal,
                    "titel": titel,  
                    "titel2": titel2, 
                    "onderwerp": onderwerp,
                    "doel": doel,
                    "model": model,
                    "type": type,
                    "typeB": typeB,
                    "user_id": user_id,
                    "naam_docent": naam_docent,
                    "token": token,
                }, {
                    timeout: 120000 // Timeout stays as it was
                });
                commit("show_snackbar", {
                    text: res.data.message,
                    type: "success"
                })
               
                commit("feedback_id", res.data.id)
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error,
                    type: "error"
                })
           
            }
        },
        async nieuweFeedbackBrightspaceBeoordelingsformulier({state, commit, dispatch}, {opdracht, taal, titel, titel2, onderwerp, doel, model, type, user_id, naam_docent, token, beoordelingsformulier_id}){
            try{
               


                const res = await axios.post("/modules/nieuwe-brightspace-feedback-beoordelingsformulier/", {
                    "opdracht": opdracht,
                    "taal": taal,
                    "titel": titel,  
                    "titel2": titel2, 
                    "onderwerp": onderwerp,
                    "doel": doel,
                    "model": model,
                    "type": type,
                    "user_id": user_id,
                    "naam_docent": naam_docent,
                    "token": token,
                    "beoordelingsformulier_id": beoordelingsformulier_id
                }, {
                    timeout: 120000 // Timeout stays as it was
                });
                commit("show_snackbar", {
                    text: res.data.message,
                    type: "success"
                })
               
                commit("feedback_id", res.data.id)
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error,
                    type: "error"
                })
           
            }
        },

        async nieuweFeedbackOffline({state, commit, dispatch}, {leerling_anoniem, titel, opdracht, beoordelingsformulier_id, taal, onderwerp, doel, model, vak_id, leraar}){
            console.log(model)
            try{
               

                const res = await axios.post("/modules/nieuwe-feedback-offline/", {
                    "opdracht": opdracht,
                    "leraar": leraar,
                    "leerling_anoniem": leerling_anoniem,
                    "titel": titel,  
                    "beoordelingsformulier_id": beoordelingsformulier_id,
                    "taal": taal,
                    "onderwerp": onderwerp,
                    "doel": doel,
                    "model": model,
                    "vak_id": vak_id
                }, {
                    timeout: 120000 // Timeout stays as it was
                });
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
                commit("feedback_id", res.data.id)
                commit("showPopup", true)
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        async nieuweFeedbackCode({state, commit, dispatch}, {leerling_anoniem, titel, opdracht, beoordelingsformulier_id, taal, onderwerp, doel, model, vak_id, type}){
          
            try{
                const token = state.token

                const res = await axios.post("/modules/nieuwe-feedback-code/", {
                    "opdracht": opdracht,
                    "token": token,
                    "leerling_anoniem": leerling_anoniem,
                    "titel": titel,  
                    "beoordelingsformulier_id": beoordelingsformulier_id,
                    "taal": taal,
                    "onderwerp": onderwerp,
                    "doel": doel,
                    "model": model,
                    "type": type,
                    "vak_id": vak_id
                }, {
                    timeout: 120000 // Timeout stays as it was
                });
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
                commit("showPopup", true)
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        async nieuweFeedbackCodeOffline({state, commit, dispatch}, {leerling_anoniem, titel, opdracht, beoordelingsformulier_id, taal, onderwerp, doel, model, codetaal, vak_id, leraar}){
          
            try{
               

                const res = await axios.post("/modules/nieuwe-feedback-code-offline/", {
                    "opdracht": opdracht,
                    "leraar": leraar,
                    "leerling_anoniem": leerling_anoniem,
                    "titel": titel,  
                    "beoordelingsformulier_id": beoordelingsformulier_id,
                    "taal": taal,
                    "onderwerp": onderwerp,
                    "doel": doel,
                    "model": model,
                    "codetaal": codetaal,
                    "vak_id": vak_id
                }, {
                    timeout: 120000 // Timeout stays as it was
                });
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
                commit("feedback_id", res.data.id)
                commit("showPopup", true)
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        
        async moduleWeekOverviewWijzigen({state, commit, dispatch}, wijzigingen){  
                   
            try{
                const token = state.token
                console.log(wijzigingen)
                const res = await axios.post("/modules/module-week-overview-bewerken/", {
                    "token": token, 
                    "overview": wijzigingen
                })
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        async moduleOverviewWijzigen({state, commit, dispatch}, wijzigingen){
            
            try{
                const token = state.token

                const res = await axios.post("/modules/module-overview-bewerken/", {
                    "token": token, 
                    "overview": wijzigingen
                })
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        async feedbackWijzigen({state, commit, dispatch}, wijzigingen){
            
            try{

                const res = await axios.post("/modules/feedback-bewerken/", {
                    "feedback": wijzigingen
                })
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        async moduleWeektoetsOpslaan({state, commit, dispatch}, {module_weektoets, groupedQuestions, teVerwijderenOpdrachten, teVerwijderenVragen, teVerwijderenAntwoordOpties}){
            try{
                const token = state.token
                console.log(module_weektoets)
                const res = await axios.post("/modules/module-weektoets-bewerken/", {
                    "token": token, 
                    "weektoets": module_weektoets,
                    "grouped_questions": groupedQuestions,
                    "teVerwijderenOpdrachten": teVerwijderenOpdrachten,
                    "teVerwijderenVragen": teVerwijderenVragen,
                    "teVerwijderenAntwoordOpties": teVerwijderenAntwoordOpties,
                })
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        async profielAanpassen({state, commit, dispatch}, wijzigingen){
            console.log(wijzigingen)
            try{
                const token = state.token

                const res = await axios.post("/users/profiel-bewerken/", {
                    "token": token, 
                    "wijzigingen": wijzigingen
                })
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        
        async verwijderFeedback({state, commit, dispatch}, {feedback_id}){
            console.log(feedback_id)
            try{
                const res = await axios.post("/modules/feedback-verwijderen/", {
                    "feedback_id": feedback_id,
                })
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        async verwijderOpdracht({state, commit, dispatch}, {feedback_id}){
            console.log(feedback_id)
            try{
                const res = await axios.post("/modules/opdracht-verwijderen/", {
                    "feedback_id": feedback_id,
                })
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        async verwijderOpdrachtPl({state, commit, dispatch}, {feedback_id}){
            console.log(feedback_id)
            console.log('kom ik hier')
            try{
                const res = await axios.post("/modules/opdracht-pl-verwijderen/", {
                    "feedback_id": feedback_id,
                })
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        async verwijderBeoordelingsformulier({state, commit, dispatch}, {beoordelingsformulier_id}){
            console.log('hallo')
            console.log(beoordelingsformulier_id)
            try{
                const res = await axios.post("/modules/beoordelingsformulier-verwijderen/", {
                    "beoordelingsformulier_id": beoordelingsformulier_id,
                })
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        async verwijderModule({state, commit, dispatch},  module_id){
            console.log(module_id)
            try{
                const res = await axios.post("/modules/module-verwijderen/", {
                    "module_id": module_id,
                })
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        async refreshModule({state, commit, dispatch},  {module_id, vak_id}){
            console.log(module_id)
            try {
                const res = await axios.post("/modules/module-refresh/", {
                    "module_id": module_id,
                }, {
                    timeout: 120000, // Set timeout to 120000 milliseconds (2 minutes)
                });
        
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                });
                commit("showPopup", false)
                router.push("/leraar/vak/"+vak_id+ "/module-overview")
                
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        async refreshFeedback({state, commit, dispatch},  {feedback_id, vak_id}){
            console.log(feedback_id)
            try {
                const res = await axios.post("/modules/refresh-feedback", {
                    feedback_id: feedback_id // Pass feedback_id in the body
                }, {
                    timeout: 120000 // Set timeout to 120000 milliseconds (2 minutes)
                });
        
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                });
             
                
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        async refreshFeedbackOp({state, commit, dispatch},  {feedback_id, opdracht_id, vak_id}){
            console.log(feedback_id)
            try {
                const res = await axios.post("/modules/refresh-feedback-op", {
                    feedback_id: feedback_id, // Pass feedback_id in the body
                    opdracht_id: opdracht_id   // Pass opdracht_id in the body
                }, {
                    timeout: 120000 // Set timeout to 120000 milliseconds (2 minutes)
                });
        
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                });
              
                router.push("/leraar/vak/"+vak_id+ "/opdracht/" + opdracht_id +  "/overview/")
                
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        async refreshOpdracht({state, commit, dispatch},  {feedback_id, vak_id}){
            console.log(feedback_id)
            try {
                const res = await axios.post("/modules/refresh-opdracht", {
                    feedback_id: feedback_id // Pass feedback_id in the body
                }, {
                    timeout: 120000 // Set timeout to 120000 milliseconds (2 minutes)
                });
                
        
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                });
             
                
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },

        async moduleTentamenwijzigingen({state, commit, dispatch}, {module_eindtoets, groupedQuestions, teVerwijderenOpdrachten, teVerwijderenVragen, teVerwijderenAntwoordOpties}){
            
            try{
                const token = state.token

                const res = await axios.post("/modules/module-tentamen-bewerken/", {
                    "token": token, 
                    "tentamen": module_eindtoets,
                    "grouped_questions": groupedQuestions,
                    "teVerwijderenOpdrachten": teVerwijderenOpdrachten,
                    "teVerwijderenVragen": teVerwijderenVragen,
                    "teVerwijderenAntwoordOpties": teVerwijderenAntwoordOpties,
                })
                commit("show_snackbar", {
                    text: res.data.message, 
                    type: "success"
                })
            }catch(err){
                console.log(err)
                commit("show_snackbar", {
                    text: err.response.data.error, 
                    type: "error"
                })
            
            }
        },
        
        async get_module_weekinformatie({state, commit, dispatch}, {id, weeknummer}){
            
            console.log(id)
            const res = await axios.get('/modules/module-weekinformatie/', {
                params: {
                    "id": id,
                    "weeknummer": weeknummer,
                }
                
            })
            console.log(res.data)
            commit("module_weekinformatie",res.data)

        },
        async get_module_overviews({state, commit, dispatch}, id){
            
            console.log(id)
            const res = await axios.get('/modules/module-overviews/' + id)
            console.log(res.data)
            commit("module_overviews",res.data)
        },
        async get_feedback({state, commit, dispatch}, id){
            
            console.log(id)
            const res = await axios.get('/modules/feedback/' + id)
            console.log(res.data)
            commit("feedback",res.data)
        },
        async get_feedback_offline({state, commit, dispatch}, id){
            
            console.log(id)
            const res = await axios.get('/modules/feedback-offline/' + id)
            console.log(res.data)
            commit("feedbackk",res.data)
            console.log("data" + res.data)
            console.log("data" + res.data.feedback)
            if(res.data.feedback != ""){
                commit("feedback_klaar", true)
            }

        },
        async get_feedback_brightspace({state, commit, dispatch}, id){
           
            console.log(id)
            const res = await axios.get('/modules/feedback-brightspace/' + id)
            console.log(res.data)
            commit("feedbackk",res.data)
            console.log("data" + res.data)
            console.log("data" + res.data.feedback)
            if(res.data.feedback != ""){
                commit("feedback_klaar", true)
            }


        },

        async get_module_eindtoets({state, commit, dispatch}, id){
            
            console.log(id)
            const res = await axios.get('/modules/module-eindtoets/' + id)
            console.log('hallo')
            console.log(res.data)
            commit("module_eindtoets",res.data)
        },
        async get_module_weektoets({state, commit, dispatch}, {id, weeknummer}){
            
            console.log(id)
            const res = await axios.get('/modules/module-weektoets/', {
                params: {
                    "id": id,
                    "weeknummer": weeknummer,
                }
                
            })
            console.log(res.data)
            commit("module_weektoets",res.data)
        },
        //old functions:
         // async get_book({state, commit, dispatch}, id){
        //     console.log("getting book" + id)
        //     const res = await axios.get("/books/" + id);
        //     console.log(res.data)
        //     commit("current_book", res.data)
        // },
        // async get_recent_books({state, commit, dispatch}){
        //     const token = localStorage.getItem('token')
        //     const res = await axios.get("/books/recent/" + token);
        //     console.log(res.data)
        //     commit("recent_books", res.data)
        // },
        // async get_page({state, commit, dispatch}, {id, page, view_page}){
        //     if(page > 0){
        //         const token = localStorage.getItem("token")
        //         const res = await axios.get('/books/page/' + id, {
        //             responseType: 'arraybuffer',
        //             params: {
        //                 token: token,
        //                 page: page 
        //             }
        //         }).catch(err => {
        //             if(err.response.status == 400){
        //                 router.push({name: state.role, query: {
        //                     snackbar: "Je hebt geen toegang tot het boek dat je wilde openen!",
        //                     snackbarType: "error"
        //                 }})
        //             }
        //         })
        //         try{
        //             const base64 = Buffer.from(res.data, 'binary').toString('base64')
        //             commit("reader_pages", {
        //                 view_page,
        //                 base64
        //             })
        //         } catch(e){}
        //     }
        // },
        // async previousReaderPage({state, commit, dispatch}, {id, current_page}){
        //     state.reader_pages[2] = state.reader_pages[1]
        //     await dispatch("get_page", {
        //         id,
        //         page: current_page-1,
        //         view_page: 1,
        //     })
        // },
        // async nextReaderPage({state, commit, dispatch}, {id, current_page, max_view}){
        //     state.reader_pages[1] = state.reader_pages[2]
        //     await dispatch("get_page", {
        //         id,
        //         page: current_page + max_view,
        //         view_page: 2,
        //     })
        // },
        // async totalPages({state, commit, dispatch}, id){
        //     const res = await axios.get('/books/number-of-pages/' + id)
        //     commit("totalPages",res.data.numberOfPages)
        // },
        // async getOverview({state, commit, dispatch}, id){
        //     const token = localStorage.getItem("token")
        //     const res = await axios.get('/books/overview/' + id, {
        //         params: {
        //             token: token
        //         },
        //         timeout: 60000,
        //     })
        //     commit("overview",res.data.overview)
        // },
        // async add_book({state, commit, dispatch}, {prijs, titel, jaar_uitgave, editie, omschrijving, volledige_omschrijving, taal, isbn, aantal_paginas,  auteurs, epub_base64, cover_base64}){
        //     console.log(cover_base64)
        //     const token = state.token
        //     const uitgever = state.username
        //     const res = await axios.post("/books/add-book", {
        //         "epub": epub_base64,  
        //         "cover_afbeelding": cover_base64,
        //     }, {
        //         params: {
        //             "uitgever": uitgever,
        //             "token": token,
        //             "titel": titel,
        //             "jaar_uitgave": jaar_uitgave,
        //             "editie": editie,
        //             "omschrijving": omschrijving,
        //             "volledige_omschrijving": volledige_omschrijving,
        //             "taal": taal,
        //             "isbn": isbn,
        //             "aantal_paginas": aantal_paginas,
        //             "auteurs": auteurs,   
        //             "prijs": prijs, 
                                  
        //         },
        //         timeout: 300000
        //     });
        // },
        // async doorverkopen({state, commit, dispatch}, boek_id){
        //     try{
        //         const token = localStorage.getItem('token')
        
        //         const res = await axios.post("/books/doorverkopen", {}, {
        //             params: {
        //                 "token": token,
        //                 "boek_id": boek_id,
                        
        //             }
        //         });
        //         commit("show_snackbar", {
        //             text: res.data.message, 
        //             type: "success"
        //         })
        //     }catch(err){
        //         commit("show_snackbar", {
        //             text: err.response.data.error, 
        //             type: "error"
        //         })
            
        //     }
        
            
        // },
        // async doorverkopenAnnuleren({state, commit, dispatch}, boek_id){
        //     try{
        //         const token = localStorage.getItem('token')
        
        //         const res = await axios.post("/books/doorverkopen-annuleren", {}, {
        //             params: {
        //                 "token": token,
        //                 "boek_id": boek_id,
                        
        //             }
        //         });
        //         commit("show_snackbar", {
        //             text: res.data.message, 
        //             type: "success"
        //         })
        //     }catch(err){
        //         commit("show_snackbar", {
        //             text: err.response.data.error, 
        //             type: "error"
        //         })
            
        //     }
        
            
        // },
        // async get_uitgever_books({commit}, id){
        //     console.log("the id:")
        //     console.log(id)
        //     const res = await axios.get("/books/books-uitgever/" + id);
        //     commit("books_uitgever", res.data)
        // },
        // async get_books({state, commit, dispatch}){
        //     const token = localStorage.getItem('token')
        //     const res = await axios.get("/users/books/" + token);
        //     console.log(res.data)
        //     commit("books", res.data)
        // },
        // async get_all_books({state, commit, dispatch}){
        //     const res = await axios.get("/books/all");
        //     console.log(res.data)
        //     commit("all_books", res.data)
        // },
        // async login({state, commit, dispatch}, {password, email, loginType, redirect, language}){
        //     const taal = language;
        //     const currentLocale = i18n.global.locale.value;
        //     console.log(currentLocale)
        //     try{
        //         console.log("test")
        //         const res = await axios.post("/users/login", {}, {
        //         params: {
        //             "password": password,
        //             "email": email,
        //             "loginType": loginType,
                    
        //         }
        //         });console.log("test1")
        //         if (res.data.token != null){
        //             console.log("hallo")
        //             localStorage.setItem('token', res.data.token)
        //             localStorage.setItem('publicToken', res.data.public_token)
        //             localStorage.setItem('username', res.data.username)
        //             localStorage.setItem('email', res.data.email)
        //             localStorage.setItem('role', res.data.role)
        //             localStorage.setItem('voornaam', res.data.voornaam)
        //             await dispatch('check_login')
        //             console.log(state.role)
        //             console.log("hello world")

        //             if(redirect != null && redirect != ""){
        //                 redirect = new URL(redirect)
        //                 redirect.searchParams.append("publicToken",res.data.public_token)
        //                 open(redirect, "_self")
        //             }else{
        //                 if(res.data.isapproved==false){
        //                     router.push({name: "personalisering-"+res.data.role})
        //                 }else if(taal === 'en' && state.role=='leraar'){
        //                     console.log("dit werkt")
        //                     router.push({name: "teacher"})
                        
        //                 }else{
                            
        //                     router.push({name: state.role})
        //                 }
                       
        //             }
                    
        //         }
        //         else{
        //             return new Promise((resolve, reject) => {
        //                 // return false to the login page
        //                 resolve(false)
        //             })
        //         }
        //     }catch(err){
        //         console.log(err)
        //         commit("show_snackbar", {
        //             text: err.response.data.error, 
        //             type: "error"
        //         })
        //     }
            

        // },
        // async personaliseren({commit, dispatch, state}, {voornaam,tussenvoegsel,achternaam, geboortedatum, school, opleidingsniveau, startjaar, opleiding}){
        //     try{
        //         if(state.role == null){
        //             await dispatch("check_login")
        //         }

        //         const res = await axios.post("/users/personaliseren", {}, {
        //             params: {
        //                 "voornaam": voornaam,
        //                 "tussenvoegsel": tussenvoegsel,
        //                 "achternaam": achternaam,
        //                 "geboortedatum": geboortedatum,
        //                 "school": school,
        //                 "opleidingsniveau": opleidingsniveau,
        //                 "startjaar": startjaar,
        //                 "token": state.token,
        //                 "opleiding": opleiding, 

        //             }
        //         })
                
        //         router.push({name: state.role})
        //     }catch(err){
        //         commit("show_snackbar", {
        //             text: err.response.data.error, 
        //             type: "error"
        //         })
        //     }
            

            

            

            

            
        // },
        // async personaliserenLeraar({commit, dispatch, state}, {voornaam,tussenvoegsel,achternaam, geboortedatum, school,opleiding, language}){
        //     try{
        //         if(state.role == null){
        //             await dispatch("check_login")
        //         }

        //         const res = await axios.post("/users/personaliseren-leraar", {}, {
        //             params: {
        //                 "voornaam": voornaam,
        //                 "tussenvoegsel": tussenvoegsel,
        //                 "achternaam": achternaam,
        //                 "geboortedatum": geboortedatum,
        //                 "school": school,
        //                 "token": state.token,
        //                 "opleiding": opleiding, 

        //             }
        //         })
        //         if(language=='en'){
                    
        //             router.push({name: "teacher"})
                   
                    
        //         }else{
        //             router.push({name: state.role})
              
        //         }
                
        //     }catch(err){
        //         commit("show_snackbar", {
        //             text: err.response.data.error, 
        //             type: "error"
        //         })
        //     }
            

            

            

            

            
        // },
        // async personaliserenUitgever({ dispatch, state}, {voornaam,tussenvoegsel,achternaam, email_contactpersoon, kvk_nummer, btwnummer, straatnaam, postcode, huisnummer, stad}){
        //     const res = await axios.post("/users/personaliseren-uitgever", {}, {
        //         params: {
        //             "voornaam": voornaam,
        //             "tussenvoegsel": tussenvoegsel,
        //             "achternaam": achternaam,
        //             "email_contactpersoon": email_contactpersoon,
        //             "kvk_nummer": kvk_nummer,
        //             "straatnaam": straatnaam,
        //             "postcode": postcode,
        //             "huisnummer": huisnummer,
        //             "stad": stad,
        //             "token": state.token,
        //             "btwnummer": btwnummer,

        //         }
        //     })

        //     if(state.role == null){
        //         await dispatch("check_login")
        //     }
        //     if(state.role == "student"){
        //         router.push({name: state.role})
        //     }else{
        //         router.push({name: "check-uitgever"})
        //     }
            

            
        // },
        // async get_feedback_student({commit}, id){
            
        //     const token = localStorage.getItem('token')
        //     const res = await axios.get("/users/feedback-student/" + token);
        //     commit("feedback_student", res.data)
        // },
        // async get_notes_data({commit, state}, {boek_id}){
        //     const token = state.token

        //     const res = await axios.get("/users/get-notes-data/" + token, {
        //         params: {
        //             "boek_id": boek_id,  
        //         }
        //     })
            
        //     commit("notes_data", JSON.parse(res.data["notes_data"]))
        // },
        // async save_notes_data({commit, state}, {boek_id, notes_data}){
        //     const token = state.token

        //     const res = await axios.post("/users/save-notes-data/" + token, {}, {
        //         params: {
        //             "boek_id": boek_id,  
        //             "notes_data": JSON.stringify(notes_data)
        //         }
                
        //     })
        // },
        // async get_marking_data({commit, state}, {boek_id}){
        //     const token = state.token

        //     const res = await axios.get("/users/get-marking-data/" + token, {
        //         params: {
        //             "boek_id": boek_id,  
        //         }
        //     })
            
        //     commit("marking_data", JSON.parse(res.data["marking_data"]))
        // },
        // async get_transaction_history({commit, state}){
        //     const token = state.token

        //     const res = await axios.get("/users/transacties/" + token)

        //     const transacties = res.data
            
        //     commit("transacties", transacties)
        // },
        // async save_marking_data({commit, state}, {boek_id, marking_data}){
        //     const token = state.token

        //     const res = await axios.post("/users/save-marking-data/" + token, {}, {
        //         params: {
        //             "boek_id": boek_id,  
        //             "marking_data": JSON.stringify(marking_data)
        //         }
                
        //     })
        // },
        // async buy_book({commit, state}, {boek_id, payment_method}){
        //     commit('buy_reset')
        //     const token = state.token

        //     const res = await axios.post("/users/buy-book/" + token, {}, {
        //         params: {
        //             "boek_id": boek_id,  
        //             payment_method
        //         }
        //     })
        //     console.log(res.data)
        //     if (res.data.redirect_url != null){
        //         open(res.data.redirect_url, "_self")

                
        //         // commit('buy_success')
        //         // router.push({name: "winkelen", query: {
        //         //     snackbar: "Het boek is succesvol toegevoegd aan jouw account!",
        //         //     snackbarType: "success"
        //         // }})

        //     }
        // },
        // async get_balans({commit, state}){
        //     const token = state.token
        //     const res = await axios.get("/users/get-user-information/" + token)
        //     const balans = res.data.balans

        //     commit("balans", balans)
        // },
        // async getUitgeverContact({state, commit, dispatch}, username){
        //     console.log("UI=itgever: " + username)
        //     const res = await axios.get('/uitgever-details/?username='+username)
        //     commit("uitgeverContact",res.data)
        // },
         // async nieuweLeerling({state, commit, dispatch}, {student, vak}){
            
        //     try{
        //         const token = state.token
                
        //         const res = await axios.post("/modules/nieuwe-leerling/", {
                    
        //                 "token": token,
        //                 "student": student,  
        //                 "vak": vak,
                    
                    
                    
        //         })
        //         router.push({name: "leerlingen"})
        //         commit("show_snackbar", {
        //             text: res.data.message, 
        //             type: "success"
        //         })
               
        //     }catch(err){
        //         console.log(err)
        //         commit("show_snackbar", {
        //             text: err.response.data.error, 
        //             type: "error"
        //         })
            
        //     }
        // },
        // async nieuweFeedbackBulk({state, commit, dispatch}, {leerlingen_anoniem, titel, opdrachten, beoordelingsformulier_id, taal, onderwerp, doel, model, vak_id}){
          
        //     try{
        //         const token = state.token

        //         const res = await axios.post("/modules/nieuwe-feedback-bulk/", {
        //             "opdrachten": opdrachten
        //         }, {
        //             params: {
        //                 "token": token,
        //                 "leerlingen_anoniem": leerlingen_anoniem,
        //                 "titel": titel,  
        //                 "beoordelingsformulier_id": beoordelingsformulier_id,
        //                 "taal": taal,
        //                 "onderwerp": onderwerp,
        //                 "doel": doel,
        //                 "model": model,
        //                 "vak_id": vak_id,

        //             },
        //             timeout: 120000,
                    
        //         })
        //         commit("show_snackbar", {
        //             text: res.data.message, 
        //             type: "success"
        //         })
        //         commit("showPopup", true)
        //     }catch(err){
        //         console.log(err)
        //         commit("show_snackbar", {
        //             text: err.response.data.error, 
        //             type: "error"
        //         })
            
        //     }
        // },
        // async nieuweFeedbackVormvereisten({state, commit, dispatch}, {student_id, titel, vak, omschrijving, opdracht, vormvereisten, beoordelingsformulier_id, taal, onderwerp}){
        //     console.log(student_id)
        //     try{
        //         const token = state.token

        //         const res = await axios.post("/modules/nieuwe-feedback-vormvereisten/", {
        //             "opdracht": opdracht,
        //             "vormvereisten": vormvereisten
        //         }, {
        //             params: {
        //                 "token": token,
        //                 "student_id": student_id,
        //                 "titel": titel,  
        //                 "vak": vak,
        //                 "omschrijving": omschrijving,
        //                 "beoordelingsformulier_id": beoordelingsformulier_id,
        //                 "taal": taal,
        //                 "onderwerp": onderwerp

        //             },
        //             timeout: 120000,
                    
        //         })
        //         commit("show_snackbar", {
        //             text: res.data.message, 
        //             type: "success"
        //         })
        //         commit("showPopup", true)
        //     }catch(err){
        //         console.log(err)
        //         commit("show_snackbar", {
        //             text: err.response.data.error, 
        //             type: "error"
        //         })
            
        //     }
        // },
// async verwijderLeerling({state, commit, dispatch}, {user_id, module_id}){
        //     console.log(user_id)
        //     console.log(module_id)
        //     try{
        //         const token = state.token

        //         const res = await axios.post("/modules/module-leerling-verwijderen/", {
        //             "id": user_id, 
        //             "module_id": module_id,
        //             "token": token,
        //         })
        //         commit("show_snackbar", {
        //             text: res.data.message, 
        //             type: "success"
        //         })
        //     }catch(err){
        //         console.log(err)
        //         commit("show_snackbar", {
        //             text: err.response.data.error, 
        //             type: "error"
        //         })
            
        //     }
        // },
          
        // async add_module({commit, dispatch, state}, {moduleCode}){
        //     try{
        //         const token = state.token

        //         const res = await axios.post("/users/add-module/" + token, {}, {
        //             params: {
        //                 "module_code": moduleCode,  
                        
        //             }
        //         })
        //         commit("show_snackbar", {
        //             text: res.data.message, 
        //             type: "success"
        //         })
        //         dispatch("get_modules")
        //     }catch(err){
        //         console.log(err)
        //         commit("show_snackbar", {
        //             text: err.response.data.error, 
        //             type: "error"
        //         })
        //     }
            
        // },
        
    }
})
