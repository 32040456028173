<template>
    <div class="dashboard-header" :class="{'mobile-extend': mobileExtend}">
        <div class="fixed dashboard-header-content" :class="{'mobile-extend': mobileExtend}" v-if="mode!='ofline-winkel'">
            <div class="header-left">
                <div class="container nav-container">
                    <input class="checkbox" type="checkbox" name="" id="" v-model="$store.state.mobileExtend"/>
                    <div class="hamburger-lines1">
                        <span class="line line1"></span>
                        <span class="line line2"></span>
                        <span class="line line3"></span>
                    </div> 
                </div>
                <div class="blockbook-text" :class="{'has-search': mode != 'boekInformatie' && !hideSearch}">
  <a href="/" @click.prevent="handleLogoClick">
    <img src="/assets/logo.png" />
  </a>
</div>            </div>
            
            
            <Search mode="boeken" @search="event => $emit('search', event)" v-if="mode !='boekInformatie'  && mode!='module' && mode!='moduleEN' && mode!='feedback' && mode!='feedbackEN' && mode!='leerlingen' && !hideSearch"></Search>
            <Search mode="module" @search="event => $emit('search', event)" v-if="mode =='module' && !hideSearch"></Search>
            <Search mode="moduleEN" @search="event => $emit('search', event)" v-if="mode =='moduleEN' && !hideSearch"></Search>
            <Search mode="feedback" @search="event => $emit('search', event)" v-if="mode =='feedback' && !hideSearch"></Search>
            <Search mode="feedbackEN" @search="event => $emit('search', event)" v-if="mode =='feedbackEN' && !hideSearch"></Search>
            <Search mode="leerlingen" @search="event => $emit('search', event)" v-if="mode =='leerlingen' && !hideSearch"></Search>
            <NavItem v-if="mode == 'dashboard'" href="winkelen" class="webshop-link" :inverted="true" text="Webshop">
                <img src="/assets/shopping-bag.svg" />
            </NavItem>
            <NavItem v-if="mode == 'winkel'" href="student" class="webshop-link" :inverted="true" text="Dashboard">
                <img src="/assets/dashboard.svg" />    
            </NavItem>
            <NavItem v-if="mode == 'module'"  class="webshop-link" :inverted="true" >
                
            </NavItem>
            <NavItem v-if="mode == 'leerlingen'"  class="webshop-link" :inverted="true" >
                
            </NavItem>
           
            <NavItem v-if="mode == 'boekInformatie'" @click="$router.go(-1)" class="webshop-link" :inverted="true" text="Terug">
                <img src="/assets/arrowback_black.svg" />
            </NavItem>   
            
        </div>
        <div class="fixed dashboard-header-content-ofline" v-if="mode=='ofline-winkel'">
            <NavItem href="Home" class="homebutton" mode="logo">
                <div class="logo" ><img src="/assets/logo-klein.png"/></div>
            </NavItem>
            
            <!-- <Search @search="event => $emit('search', event)" v-if="mode !='boekInformatie' && !hideSearch"></Search> -->
            <!-- <div class="arrow-back">
                <NavItem v-if="mode == 'ofline-winkel'" @click="$router.go(-1)" class="webshop-link" :inverted="true" text="Terug">
                    <img src="/assets/arrowback_black.svg" />
                </NavItem>  
            </div>
            
            <NavItem v-if="mode == 'ofline-winkel'" href="login" class="webshop-ofline-link" :inverted="true" text="Inloggen" mode="blue-bold">
            </NavItem> -->
        </div>
        <div class="header-position">

        </div>
        
    </div>
</template>


<script>
import Search from '@/components/Widgets/Search.vue'
import NavItem from '@/components/Dashboard/NavItem.vue'
export default{
    components: {Search, NavItem},
    props: ["mode", "hideSearch"],
    methods: {
        toggleSidebar(){
            this.$store.commit("toggleSidebar")
        }
    },
    computed: {
        mobileExtend(){
            return this.$store.state.mobileExtend
        },
    },
    methods: {
    handleLogoClick() {
      // Navigate to Home with the query parameter to set meta.ingelogd to false
      this.$router.push({ name: 'Home', query: { updateIngelogd: 'true' } });
    }
  }
}
</script>


<style lang="scss">
.dashboard-header{
    background-color: #F4F6FC;
    position: relative;
    width: 100%;
    height: 140px;
    z-index: 10;

    .nav-container {
        display: none;
        position: relative;
        height: 60px;
        
        .checkbox {
            position: absolute;
            display: block;
            height: 32px;
            width: 32px;
            top: 13px;
            left: 0px;
            z-index: 5;
            opacity: 0;
            cursor: pointer;
        }

        .hamburger-lines1 {
            display: block !important;
            height: 26px;
            width: 32px;
            position: absolute;
            top: 19px;
            left: 10px;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .line {
                display: block;
                height: 4px;
                width: 100%;
                border-radius: 10px;
                background: #0e2431;
            }

            .line1 {
                transform-origin: 0% 0%;
                transition: transform 0.4s ease-in-out;
            }

            .line2 {
                transition: transform 0.2s ease-in-out;
            }

            .line3 {
                transform-origin: 0% 100%;
                transition: transform 0.4s ease-in-out;
            }
        }
    }

    .nav-container input[type="checkbox"]:checked ~ .hamburger-lines1 .line1 {
        transform: rotate(45deg);
    }

    .nav-container input[type="checkbox"]:checked ~ .hamburger-lines1 .line2 {
        transform: scaleY(0);
    }

    .nav-container input[type="checkbox"]:checked ~ .hamburger-lines1 .line3 {
        transform: rotate(-45deg);
    }

    .header-left{
        display: flex;
        align-items: center;
        width: 150px;
        
        img{
            margin-top: 10px;
            margin-left: 0px;
            width: 140px;
        }
    }

    .dashboard-header-content{
        left: 230px;
        display: flex;
        justify-content: space-between;
        height: 140px;
        width: calc(100% - 300px);
        align-items: center;
        background-color: #F4F6FC;
        padding-right: 50px;

        .nav-item{
            padding-top: 0px;
        }
    }

    .blockbook-text{
        font-style: normal;
        font-weight: 800;
        font-size: 26px;
        line-height: 38px;
        width: 250px;
        margin-left: 20px;

        color: $base-color;

        .oranje{
            color: $secondary-color;
        }
    }

    .webshop-link{
        display: flex;
        justify-content: flex-end;
        width: 150px;
        padding-right: 0px;
        
    }
    .header-position{
        height: 150px;
        width: calc(100% - 100px);
    }
    .dashboard-header-content-ofline{
        left: 0px;
        display: flex;
        justify-content: space-between;
        height: 140px;
        width: 100%;
        align-items: center;
        background-color: $white;

        .homebutton{
            width: 200px;
        }
        .arrow-back{
                .webshop-link{
                    position: absolute;
                    left: 100px;
                    width: 80px;
                } 
                
            }

        .nav-item{
            padding-top: 0px;
            
            
            
        }
    }
    .logo{
        margin-right: 20px;
        width: 1000px;
    }
    .webshop-ofline-link{
        display: flex;
        justify-content: flex-end;
        width: 150px;
        padding-right: 0px;

    }


    @media (max-width: 900px){
        .header-left{
            width: 260px;
        }

        .nav-container{
            display: block;
        }

        .webshop-link{
            display: none;
        }

        .blockbook-text{
            margin-left: 80px;
        }

        .dashboard-header-content{
            left: 40px;
            width: calc(100% - 100px);
            &.mobile-extend{
                left: 240px;
                width: calc(100% - 300px);
                overflow: hidden;
            }
        }
    }

    @media (max-width: 600px){
        .dashboard-header-content{
            left: 0px;
            width: calc(100% - 10px);
            &.mobile-extend{
                width: 100px;
                left: calc(100% - 57px);
            }
        }

        .blockbook-text{
            &.has-search{
                display: none;
            }
            
        }

        .header-left{
            width: 65px;
        }

        .search{
            width: 100%;
        }
    }
    

}

@media (max-width: 600px){
    .dashboard-header{
        left: 0px;
        width: calc(100% - 40px);
        &.mobile-extend{
            width: 100px;
            left: calc(100% - 57px);
            
        }
        .header-position{
            display: none;
        }
        .webshop-link{
            display: none;
        }
    }
}
</style>