<template>
    <div class="brightspace-integratie">
      <!-- <div class="inlog-header" >
                <div class="inlog-options">1. <span class="line-through">Account</span></div>
                <div class="inlog-options "><span class="optie-benik">2. E-mail</span></div>
                <div class="inlog-options">3. Personaliseer</div>

            </div> -->
            <div class="logo-integratie">
              <img 
                src="/assets/logo.png"
                alt="logo"
                width="190"
              />
            </div>
            
      <div v-if="error" class="content">
        <h1>Er is iets misgegaan, keer terug naar Brightspace en probeer het vanuit daar opnieuw!</h1>
      </div>
      <div v-if="!error" class="content"> 
        
        <div v-if="step === 1">
          <div class="onsgevonden">
            <h1>Volg deze stappen om de opdracht te kiezen waar u feedback op wilt geven.</h1>
            <h1 class="text-information">Kies een assignment/opdracht uit uw vak:</h1>
            <div v-if="folders.length==0"><v-progress-circular
            indeterminate
            class="loading-spinner"
          ></v-progress-circular></div>
    <div class="option" 
        v-for="folder in folders" 
        :key="folder.Id" 
        @click="onFolderSelect(folder.Id)" 
        :class="{ 'selected': folder.Id === folderId }"
    >
        {{ folder.Name }}
    </div>

    <!-- Next Step Button -->

    <div @click="fetchSubmissions" v-if="folderId" :disabled="!folderId" class="button-brightspace">
        Verder
    </div>
</div>
      

    </div>

    <!-- Step 2: Display submissions for the selected folder -->
    <div v-if="step === 2">
    <div class="onsgevonden">
        <h1 class="text-information">Kies de student aan wie u feedback wilt geven</h1>

        <div v-if="submissions.length === 0">
            <v-progress-circular indeterminate class="loading-spinner"></v-progress-circular>
        </div>

        <div 
            class="option" 
            v-for="submission in submissions" 
            :key="submission.Entity.EntityId" 
            @click="onSubmissionSelect(submission)" 
            :class="{ 'selected': submission.Entity.EntityId === selectedSubmissionId }"
        >
            {{ submission.Entity.DisplayName }}
        </div>

        <!-- Back and Next Step Buttons -->
        <div @click="proceedToStep3" v-if="selectedSubmissionId" :disabled="!selectedSubmissionId" class="button-brightspace">
        Verder
    </div>
          <div @click="goBackToStep(1)" class="button-brightspace">
        Terug
    </div>
   
        
        
        
    </div>
</div>
    <!-- Step 3.1: Choose a specific submission if there are multiple submissions -->
    <div v-if="step === 3.1">
    <div class="onsgevonden">
        <h1 class="text-information">Deze student heeft meerdere submissions gedaan, kies degene waarop je feedback wilt geven!</h1>

        <div v-if="selectedSubmission.Submissions.length === 0">
            <v-progress-circular indeterminate class="loading-spinner"></v-progress-circular>
        </div>

        <div 
            class="option" 
            v-for="submission in selectedSubmission.Submissions" 
            :key="submission.Id" 
            @click="onSpecificSubmissionSelect(submission.Id)" 
            :class="{ 'selected': submission.Id === specificSubmissionId }"
        >
           {{ submission.SubmittedBy.DisplayName }} - {{ formatDateTime(submission.SubmissionDate) }}
        </div>

        <!-- Back and Next Step Buttons -->
        <div @click="goToStep3_2" v-if="specificSubmissionId" :disabled="!specificSubmissionId" class="button-brightspace">
        Verder
    </div>
          <div @click="goBackToStep(2)" class="button-brightspace">
        Terug
    </div>
        
      
    </div>
</div>

    <!-- Step 3.2: Display files in the selected submission -->
    <div v-if="step === 3.2">
    <div class="onsgevonden">
        <h1 class="text-information">Kies de opdracht waarop u feedback wilt genereren (max 1), dit kan alleen Word of PDF zijn op dit moment</h1>

        <div v-if="selectedSpecificSubmission.Files.length === 0">
            <v-progress-circular indeterminate class="loading-spinner"></v-progress-circular>
        </div>

        <div 
            class="option" 
            v-for="file in selectedSpecificSubmission.Files" 
            :key="file.FileId" 
            @click="onFileSelect(file.FileId, file.FileName)" 
            :class="{ 'selected': file.FileId === selectedFileId }"
        >
            {{ file.FileName }}
        </div>

        <!-- Back and Finish Buttons -->
        <div @click="fetchFileFromBrightspace" v-if="selectedFileId" :disabled="!selectedFileId" class="button-brightspace">
        Verder
    </div>
          <div @click="goBackToStep(selectedSubmission.Submissions.length > 1 ? 3.1 : 2)" class="button-brightspace">
        Terug
    </div>
    </div>
</div>
<div class="content2" v-if="step=== 4">
  <div class="inlog-header" >
                <div class="inlog-options "><span class="optie-benik">Ik heb nog geen beoordelingsformulier</span></div>
                <div @click="changeOption(4)" class="inlog-options"><span class="optie-naartoe">Ik heb al een beoordelingsformulier</span></div>

            </div>
          <div >
            <h1>Geef hier feedback met een van onze onderwijsassistenten</h1>
          </div>
          <Input2
            title="Taal*"
            v-model="taal"
            type="select"
            :options="options.talen"
            mode="feedback"        
          ></Input2>
  
  
  
  
          <Input2
            title="Niveau*"
            v-model="niveau"
            type="select"
            :options="options.niveau"
            mode="feedback"            
          ></Input2>
  
  
  
  
    
  
  
          <Input2    
            v-model="titel2"
            type="textsmall"
            mode="feedback"
            tekst="Titel beoordelingsformulier(om later terug te vinden voor hergebruik)"
          ></Input2>
  
  
          <Input2
            title="Input AI"
            v-model="onderwerp"
            type="text"
            mode="feedback"
            tekst="Onderwerp van het essay"
          ></Input2>
               
          <Input2
            title="Input AI"
            v-model="doel"
            type="text"
            mode="feedback"
            tekst="Belangrijkste leerdoel student, bijvoorbeeld ‘Vanuit sociologisch en economisch oogpunt een essay onderwerp uitwerken.’"
          ></Input2>
  
  
         
               
          <!-- <div v-for="(opdracht, index) in opdrachten" :key="index" class="file-feedback-upload">
            <div class="file-feedback" v-if="!opdracht" @click="openFileInput(index)">
              <input
                class="file-upload"
                :ref="'fileInput' + index"
                type="file"
                :accept="'.pdf, .xlsx, .csv, .docx'"
                style="display: none"
                @change="fileSelectedAllFiles($event, index)"
              >
              Upload opdracht
            </div>
            <div class="file-feedback1" v-else @click="openFileInput(index)">
              <input
                class="file-upload"
                :ref="'fileInput' + index"
                type="file"
                :accept="'.pdf, .xlsx, .csv, .docx'"
                style="display: none"
                @change="fileSelectedAllFiles($event, index)"
              >
              {{ opdracht.name }}
            </div>
  
  
          </div> -->
  
            <!-- <Input2                    
              v-model="leerlingen_anoniem[index]"
              type="textsmall"
              mode="feedback"
              tekst="Leerling(uniek per student)*"
            ></Input2> -->
       
  
  
  
  
  
  
  
  
          <!-- <div class="slides-options">
              <a class="extra-week" href="javascript:void(0)" @click="extraWeek">Nog een student toevoegen</a>
              <a v-if="opdrachten.length > 1" class="extra-week" href="javascript:void(0)" @click="minderWeek">Een student verwijderen</a>
          </div>     -->
  
  
  
  
          <div v-for="(beoordelingsformulier, index) in beoordelingsformulieren" :key="index" class="file-feedback-upload">
            <div class="file-feedback" v-if="!beoordelingsformulier" @click="openFileInputB(index)">
              <input
                class="file-upload"
                :ref="'fileInput' + index"
                type="file"
                :accept="'.pdf, .xlsx, .csv, .docx'"
                style="display: none"
                @change="fileSelectedAllFilesB($event, index)"
              >
              Upload Beoordelingsformulier
            </div>
            <div class="file-feedback1" v-else @click="openFileInputB(index)">
              <input
                class="file-upload"
                :ref="'fileInput' + index"
                type="file"
                :accept="'.pdf, .xlsx, .csv, .docx'"
                style="display: none"
                @change="fileSelectedAllFilesB($event, index)"
              >
              {{ beoordelingsformulier.name }}
            </div>
  
          </div>
  
  
  
  
          <div class="selectfile" @click="bevestig" >
              <!-- validateAndSendData -->
                  Bevestigen
          </div>
        </div>
        <div class="content2" v-if="step=== 4.1">
  <div class="inlog-header" >
                <div @click="changeOption(4.1)" class="inlog-options "><span class="optie-naartoe">Ik heb nog geen beoordelingsformulier</span></div>
                <div class="inlog-options"><span class="optie-benik">Ik heb al een beoordelingsformulier</span></div>

            </div>
          <div >
            <h1>Geef hier feedback met een van onze onderwijsassistenten</h1>
          </div>
          <div>
            <h2>Selecteer hieronder 1 van uw eerder gebruikte beoordelingsformulieren</h2>
          </div>
  
          <div class="table-wrapper">
  <table>
    <thead>
      <tr>
        <th>Selecteer</th>
        <th>{{ $t('title') }}</th>
        <th>{{ $t('date') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr class="table-margin" v-for="feed in filtered_feedback" :key="feed.titel">
        <td>
          <input
            type="radio"
            name="selectedFeed"
            v-model="selectedItem"
            :value="feed"
            @change="updateSelectedFeed"
          />
        </td>
        <td>{{ feed.titel }}</td>
        <td>{{ feed.datum }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div v-if="selectedItem">
  <h3>Informatie bij dit beoordelingsformulier:</h3>
  <div>Onderwerp: {{ selectedItem.onderwerp }}</div>
  <div>Doel: {{ selectedItem.doel }}</div>
</div>
          <div v-if="selectedItem" class="selectfile" @click="bevestig1" >
              <!-- validateAndSendData -->
                  Bevestigen
          </div>
        </div>
        <div v-if="step === 5 && this.feedback.feedback==''">
          <h1>We zijn bezig met uw opdracht even geduld...</h1>
          <v-progress-circular
            indeterminate
            class="loading-spinner"
          ></v-progress-circular>
        </div>
        <div  v-if="this.feedback.feedback!=''">
            <h1>Opdracht geupload naar brightspace, sluit dit venster!</h1>
        </div> 

      </div>
        



        
  
        
  
  
           
    </div>
  </template>
  <script>
  import OnboardingPage from '@/components/Onboarding/OnboardingPage.vue'
  import Form from '@/components/Form/Form.vue'
  import Input2 from '@/components/Form/BetterInput.vue'
  import BetterButton from "@/components/Widgets/BetterButton.vue"
  import Popup from '@/components/Widgets/Popup.vue'
  import getBase64 from '../utils/getBase64.js'
  import Input from '@/components/Form/BetterInput2.vue'
  import axios from 'axios';
  
  
  
  
  export default{
    components: {OnboardingPage, Form, Input, BetterButton, Input2, Popup},
    data(){
        return {
          selectedRubric: null,
            step: 1,
            user_id: 178,
            naam_docent: "pietje",
            beoordelingsformulieren: [null],
            beoordelingsformulier: "",
            beoordelingsformulier: null,
            bezig: false,
            niveau: "",
            opdrachten: [null],
            leerlingen_anoniem: [""],
            leerling_anoniem: "",
            beoordelingpopup: false,
            buttonText: "Upload opdracht(pdf)",
            buttonColor: "gray",
            doel: "",
            fileExtension: "",
            onderwerp: "",
            search: "",
            naamStudent: "",
            verwijderen: false,
            rubric: "",
            opdracht: "",
            beoordelingsformuliernaam: "",
            vak: "",
            moeilijkheid: "",
            omschrijving: "",
            opdracht: null,
            vormvereisten: null,
            showPopup: false,
            selectedItem: null, // Variable to store the selected item index
            selectedItemId: null,
            selectedBeoordeling: "",
            beoordelingtitel: false,
            student_id: "122",
            titel: "",
            titel2: "",
            taal: "",
            language: "",
            isSelected: false,
            files: [],
            file: "",
            fileExtensions: [],
            fileExtensionBeoordelingsformulier: "",
            options: {
                talen: {
                    "Engels": "en",
                    "Nederlands": "nl",
                   
                },
                languages: {
                    "English": "en",
                    "Dutch": "nl",
                   
                },
                niveau:{
                    "Omar-mbo": "mbo",
                    "Marlou-hbo": "hbo",
                    "Ruben-wo": "uni",
                }
               
            },
            pageTitle: '',
      isDeepLinkLaunch: false,
      launchData: {},
      launchId: '',
      currUserName: '',
      currDiff: '',
      userId: '',
      userName: '',
      userId: null,
      username: null,
      deploymentId: "hjshsgbhidfbjsngvjkdsbgvjksfngjksf jk",
      teller: 1,
      folderId: null,
      folders: [],
      bearerToken: "eyJhbGciOiJSUzI1NiIsImtpZCI6ImYzY2YzNzEyLTlmNDQtNDM4Yy1iYTA0LTRiOTIzMzQxMmZiZCIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE3MjU1MTgwNTQsImV4cCI6MTcyNTUyMTY1NCwiaXNzIjoiaHR0cHM6Ly9hcGkuYnJpZ2h0c3BhY2UuY29tL2F1dGgiLCJhdWQiOiJodHRwczovL2FwaS5icmlnaHRzcGFjZS5jb20vYXV0aC90b2tlbiIsInN1YiI6IjE3OCIsInRlbmFudGlkIjoiZmQ4MjBhODMtNDhiMi00NjZiLWFkY2MtYzBiYzQ5NDVmNjQzIiwiYXpwIjoiMTRmODI1N2MtYjkxYi00OTVhLThkMWItNmQzYTQ5YzUwZDU1Iiwic2NvcGUiOiJjb3JlOio6KiIsImp0aSI6IjY4NzAzYTAyLWI3ZmQtNDA0OC1iMDFmLTAzZjk4ZTFiMDgwYyJ9.IkU3Avw0RPYv_LjA7Cn13XoUWKgJY53ayblBPOaJvqs2V1GyxTLXHGjscH9-8rnLm0Ey6JjlenUcf0kcWWWsAuu9EQyAU2i5is-XJbWJiXDtxZsvzPx73aZE-3oXDb_ugyBViqtENdXIcBVqQXzimhYGggrdNwNbSesDlI3b2UF4kqkZ53myXoMGux6PLPE5fliccMj0BwXpIYsIXWToRlhTFdDgD0n1m1Uzz91FmOrHoc3ID4cHez2VrxW210wsM-sEHeXPQirajTfLUcALgSfl5sWZsbH3rKqcd7kviskV7Xh_jg_fgu_oBFLAAuZGGTJWLGjl8Z5f8C3ymixBLw",
      submissions: [], // This will store submissions for the selected folder
      selectedSubmission: null, // This stores the selected submission object
      selectedSubmissionId: null, // ID of the selected submission entity
      specificSubmissionId: null, // ID of the specific submission selected in step 3.1
      selectedSpecificSubmission: null,  
      selectedFileId: null,
      fileName: null,    
      info: null,
      error: false,
        }
    },
    async created () {
     
              await this.fetchFeedback();
      // Set up interval to periodically fetch feedback
      if(this.feedback.feedback==""){
  
  
  
  
  
  
  
  
              console.log('feedback call wordt gemaakt')
              this.feedbackInterval = setInterval(async () => {
        await this.fetchFeedback();
      }, 10000);
  
  
  
  
      }
      this.whoAMI();
      // this.fetchFolders();
  
  
  
      await this.$store.dispatch("get_beoordelingsformulier_integratie_leraar", this.user_id)
    },
    computed: {
      feedbackkk(){
            return this.$store.state.beoordelingsformulier_integratie
        },
        info_integratie(){
            return this.$store.state.info_integratie
        },
        authentication_integratie(){
            return this.$store.state.authentication_integratie
        },
      feedback(){
            return this.$store.state.feedbackk
        },
        feedbackk(){
          return this.$store.state.feedback
        },
        feedback_id(){
            return this.$store.state.feedback_id
        },
        fileExtension() {
          if (this.files.length > 0 && this.files[0].name) {
            const name = this.files[0].name;
            const extension = name.split('.').pop();
            return `.${extension}`;
          }
          return '';
        },
        fileExtensionB() {
          if (this.files.length > 0 && this.files[0].name) {
            const name = this.files[0].name;
            const extension = name.split('.').pop();
            return `.${extension}`;
          }
          return '';
        },
        filtered_feedback(){
            const vm = this
            return vm.feedbackkk.filter(feedback => {
                return (
                    
                    (feedback.titel.toLowerCase().includes(vm.search.toLowerCase())) ||
                    (feedback.onderwerp.toLowerCase().includes(vm.search.toLowerCase())) 
                   
                )
            })
        },
    },
    watch: {
    feedback_id(newValue) {
        if (newValue !== "") {
            console.log('Feedback ID is set:', newValue);
            // Perform further actions here based on the new value
        } else {
            console.log('Feedback ID is empty');
            // Handle cases where feedback ID might be empty
        }
    },
    feedback(newValue2) {
        if (newValue2 !== "") {
            console.log('Feedback is set:', newValue2);
            console.log(newValue2.feedback)
            if(this.teller === 1 && newValue2.feedback != ''){
              
              console.log('kom ik hier')
              this.teller +=1 
              this.sendFeedbackToBrightspace(newValue2.feedback);
            }
            // Perform further actions here based on the new value
        } else {
            console.log('Feedback is empty');
            // Handle cases where feedback ID might be empty
        }
    }
  },
    methods: {
      changeOption(option){
        if(option===4){
          this.step = 4.1
        }
        if(option===4.1){
          this.step = 4
        }
      },
      async whoAMI() {
  try {
    // Retrieve the authorization token from the route parameters
    const id = this.$route.params.id;

    if (id) {
      // Set up the config for the Axios request, including the Authorization header

      await this.$store.dispatch("get_authentication_integratie", id);

      console.log(this.authentication_integratie[0].user_id)
      // Use the Identifier (userId) to dispatch the store action
      await this.$store.dispatch("get_info_integratie", this.authentication_integratie[0].user_id);

      // Log user_id from the fetched info_integratie data
      console.log(this.info_integratie[0].user_id);
      this.orgUnitId = this.info_integratie[0].org_id
      this.userId = this.authentication_integratie[0].user_id
      this.userName = this.info_integratie[0].username
      this.clientId =  this.info_integratie[0].client_id
      this.bearerToken = this.authentication_integratie[0].token
      // Call fetchFolders if you need to proceed with other actions
      this.fetchFolders(); // Ensure fetchFolders is called as a function
    } else {
      console.error('No token found in route parameters.');
    }
  } catch (error) {
    console.error('Error fetching user information:', error);
  }
},

        goBackToStep(step) {
      this.step = step;
    },
        async fetchFolders() {
        
        try {
            const response = await axios.get(`https://eduface.d2l-partners.brightspace.com/d2l/api/le/1.28/${this.orgUnitId}/dropbox/folders/`, {
                headers: {
                    'Authorization': `Bearer ${this.bearerToken}`
                }
            });
            
            this.folders = response.data;
            console.log('Folders fetched:', this.folders);
        } catch (error) {
          this.error=true
            console.error('Error fetching folders:', error);
            this.$store.commit("show_snackbar", {
                text: "Hier gaat iets mis met het ophalen van de informatie!",
                type: "error"
            });
        }
    },

    onFolderSelect(folderId) {
        this.folderId = folderId;
        console.log('Selected folder ID:', this.folderId);
    },
    async nextStep() {
      if (!this.folderId) return;

      try {
        const response = await axios.get(`https://eduface.d2l-partners.brightspace.com/d2l/api/le/1.28/${this.orgUnitId}/dropbox/folders/${this.folderId}/submissions/`, {
          headers: {
            'Authorization': `Bearer ${this.bearerToken}`
          }
        });
        
        this.submissions = response.data;
        console.log('Submissions fetched:', this.submissions);

        // Move to the next step
        this.step = 2;
      } catch (error) {
        console.error('Error fetching submissions:', error);
        this.$store.commit("show_snackbar", {
          text: "Hier gaat iets mis met het ophalen van de informatie!",
          type: "error"
        });
      }
    },
    onSubmissionSelect(submissionId) {
      this.selectedSubmissionId = submissionId;
      console.log('Selected submission ID:', this.selectedSubmissionId);
    },
      async sendFeedbackToBrightspace(feedbackText) {
        const url = `https://eduface.d2l-partners.brightspace.com/d2l/api/le/1.28/${this.orgUnitId}/dropbox/folders/${this.folderId}/feedback/${this.selectedSubmission.Entity.EntityType}/${this.selectedSubmission.Entity.EntityId}`;
      const bearerToken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImYzY2YzNzEyLTlmNDQtNDM4Yy1iYTA0LTRiOTIzMzQxMmZiZCIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE3MjU0NDc1NjMsImV4cCI6MTcyNTQ1MTE2MywiaXNzIjoiaHR0cHM6Ly9hcGkuYnJpZ2h0c3BhY2UuY29tL2F1dGgiLCJhdWQiOiJodHRwczovL2FwaS5icmlnaHRzcGFjZS5jb20vYXV0aC90b2tlbiIsInN1YiI6IjE3OCIsInRlbmFudGlkIjoiZmQ4MjBhODMtNDhiMi00NjZiLWFkY2MtYzBiYzQ5NDVmNjQzIiwiYXpwIjoiMTRmODI1N2MtYjkxYi00OTVhLThkMWItNmQzYTQ5YzUwZDU1Iiwic2NvcGUiOiJjb3JlOio6KiIsImp0aSI6ImVjNTVmM2UzLWMzMTMtNDBjOS1iY2ExLTk4ZmZhMDU2N2ZiZCJ9.NXMdy5Z8isfBtgIWswBnrUut5hUk5IR6EkCZvXTsOsLDxLReFm8A3th46Dj0GKefcjpS95-v3oE_WFQ3erYLplLIxrK7-OFodhW8wPeZlnmzSelHUQYLkKOtYzoVkpSOCzQe00RoSnQO1flMqZRgVFClr2wl7R-jwcWbXXuak0FxHR6Ys42RHsXUteR8SGZMCYXcGI2eTcjYFRTkfT4S-WFQVGUhzawCISWsfnQjcQxvv_5GHEUkyJ5LABYrgGFPsGvXV7y-42MnEAXnfLh91ooCdwqek-I94G_64wcMdtc2CeX72lduzGzwLyM4p6hWqlSjGqLryrPRZ8JCWXYS0Q';
  
      const feedbackData = {
        "Score": null,
        "Feedback": {
          "Text": feedbackText,
          "Html": `<p>${feedbackText}</p>`
        },
        "RubricAssessments": [],
        "IsGraded": false
      };
  
      try {
        const response = await axios.post(url, feedbackData, {
          headers: {
            'Authorization': `Bearer ${this.bearerToken}`,
            'Content-Type': 'application/json'
          }
        });
  
        console.log('Feedback successfully sent to Brightspace:', response.data);
        this.$store.commit("show_snackbar", {
          text: "Feedback successfully sent to Brightspace.",
          type: "success"
        });
      } catch (error) {
        console.error('Error sending feedback to Brightspace:', error);
        this.$store.commit("show_snackbar", {
          text: "Failed to send feedback to Brightspace. Please try again.",
          type: "error"
        });
      }
    },
    async fetchSubmissions() {
      if (!this.folderId) return;

      try {
        const response = await axios.get(`https://eduface.d2l-partners.brightspace.com/d2l/api/le/1.28/${this.orgUnitId}/dropbox/folders/${this.folderId}/submissions/`, {
          headers: {
            'Authorization': `Bearer ${this.bearerToken}`
          }
        });

        this.submissions = response.data;
        console.log('Submissions fetched:', this.submissions);

        // Move to the next step
        this.step = 2;
      } catch (error) {
        console.error('Error fetching submissions:', error);
        this.$store.commit("show_snackbar", {
          text: "Failed to fetch submissions. Please try again.",
          type: "error"
        });
      }
    },
    onSubmissionSelect(submission) {
      this.selectedSubmission = submission;
      this.selectedSubmissionId = submission.Entity.EntityId;
      console.log('Selected submission ID:', this.selectedSubmissionId);
    },
    proceedToStep3() {
      if (this.selectedSubmission.Submissions.length > 1) {
        // Multiple submissions: proceed to step 3.1
        this.step = 3.1;
      } else {
        // Single submission: directly proceed to step 3.2
        this.specificSubmissionId = this.selectedSubmission.Submissions[0].Id;
        this.selectedSpecificSubmission = this.selectedSubmission.Submissions[0];
        this.step = 3.2;
      }
    },
    onSpecificSubmissionSelect(submissionId) {
      this.specificSubmissionId = submissionId;
      this.selectedSpecificSubmission = this.selectedSubmission.Submissions.find(sub => sub.Id === submissionId);
      console.log('Selected specific submission ID:', this.specificSubmissionId);
    },
    goToStep3_2() {
      if (!this.specificSubmissionId) return;

      this.step = 3.2;
    },
    onFileSelect(fileId, fileName) {
      this.selectedFileId = fileId;
      this.fileName = fileName;
      console.log('Selected file ID:', this.selectedFileId);
      console.log('Selected file name:', this.fileName);

    },
    finishSelection() {
  console.log('OrgUnitId:', this.orgUnitId);
  console.log('FolderId:', this.folderId);
  console.log('SubmissionId:', this.specificSubmissionId); // Corrected to use specificSubmissionId
  console.log('EntityId:', this.selectedSubmission.Entity.EntityId);
  console.log('EntityType:', this.selectedSubmission.Entity.EntityType);
  console.log('FileId:', this.selectedFileId);
},
    formatDateTime(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
      async fetchFileFromBrightspace(index) {
       
        if(this.fileName.split('.').pop() === 'pdf' || this.fileName.split('.').pop() === 'docx'){
            const url = `https://eduface.d2l-partners.brightspace.com/d2l/api/le/1.28/${this.orgUnitId}/dropbox/folders/${this.folderId}/submissions/${this.specificSubmissionId}/files/${this.selectedFileId}`;
        const bearerToken = this.bearerToken  

      try {
          const response = await axios.get(url, {
              headers: {
                  'Authorization': `Bearer ${bearerToken}`
              },
              responseType: 'blob' // Ensures the response is treated as a binary file (like PDFs, etc.)
          });
  
          if (response.status === 200) {
              // Get the file name and type from the response headers
              const contentDisposition = response.headers['content-disposition'];
              const fileName = contentDisposition ? contentDisposition.split('filename=')[1].replace(/['"]+/g, '') : 'downloadedFile';
              const fileType = response.data.type.split('/')[1]; // Get the file extension (pdf, xlsx, etc.)
  
              // Create a file object from the response
              const file = new File([response.data], fileName, { type: response.data.type });
  
              console.log('Fetched file name:', fileName);
              console.log('Fetched file object:', file);
  
              // Process the file just like user-uploaded files
              this.processFetchedFile(file, index, fileType);
              this.step = 4
          } else {
              console.error('Unexpected response status:', response.status);
              this.$store.commit("show_snackbar", {
                  text: "Unexpected response from Brightspace. Please try again.",
                  type: "error"
              });
          }
      } catch (error) {
          console.error('Error fetching the file:', error);
          this.$store.commit("show_snackbar", {
              text: "Failed to fetch the file from Brightspace. Please try again.",
              type: "error"
          });
      }
        }else{
            this.$store.commit("show_snackbar", {
                          text: "Wij accepteren alleen pdfs en docxs voor nu!",
                          type: "warning"
                      })
        }
        
  },
  
  processFetchedFile(file, index, fileType) {
      const name = file.name;
      const extension = fileType;
      const opdracht = { name, extension, content: '' };
      this.fileExtension = extension;
      console.log('Processing file:', name, 'with extension:', extension);
  
      if (extension === "pdf" || extension === "docx") {
          this.getBase64(file, base64 => {
              opdracht.content = base64;
              this.opdrachten[0] = opdracht
              // this.$setOpdracht(index, opdracht);
              console.log('Base64 content for', name, ':', base64);
          });
      } else if (extension === "xlsx" || extension === "csv") {
          const reader = new FileReader();
          reader.onload = (loadEvent) => {
              const arrayBuffer = loadEvent.target.result;
              const base64String = this.arrayBufferToBase64(arrayBuffer);
              if (extension === "xlsx") {
                  opdracht.content = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;
              } else if (extension === "csv") {
                  opdracht.content = base64String;
              }
              this.$setOpdracht(index, opdracht);
              console.log('Base64 content for', name, ':', opdracht.content);
          };
          reader.readAsArrayBuffer(file);
      } else {
          const reader = new FileReader();
          reader.onload = (loadEvent) => {
              const fileContent = loadEvent.target.result;
              opdracht.content = fileContent;
              this.$setOpdracht(index, opdracht);
              console.log('Text content for', name, ':', fileContent);
          };
          reader.readAsText(file);
      }
  },
  
  
  extractUserInfo() {
      const url = window.location.href;
      const params = new URLSearchParams(url.split('?')[1]);
  
      // Extract `launch_data` parameter and parse it as JSON
      const launchData = params.get('launch_data');
      if (launchData) {
          // Parse the launch_data from URL
          const decodedLaunchData = decodeURIComponent(launchData);
          const jsonData = JSON.parse(
              decodedLaunchData.replace(/'/g, '"') // Convert single quotes to double quotes for valid JSON
          );
  
          // Extract user_id, username, and deploymentId from the JSON data
          this.userId = jsonData['http://www.brightspace.com'].user_id;
          this.username = jsonData['http://www.brightspace.com'].username;
          this.deploymentId = jsonData['https://purl.imsglobal.org/spec/lti/claim/deployment_id'];
  
          // Extract orgUnitId from the JSON data (example: /orgunit/6666/)
          const orgUnitIdMatch = jsonData['https://purl.imsglobal.org/spec/lti-ags/claim/endpoint'].lineitems.match(/\/orgunit\/(\d+)\//);
          if (orgUnitIdMatch && orgUnitIdMatch[1]) {
              this.orgUnitId = orgUnitIdMatch[1];
          }
  
          // Extract client ID (assuming it exists in the launch_data, adjust the path as necessary)
          if (jsonData['aud']) {
              this.clientId = jsonData['aud'];
          }
  
          console.log('User ID:', this.userId);
          console.log('Username:', this.username);
          console.log('Deployment ID:', this.deploymentId);
          console.log('OrgUnit ID:', this.orgUnitId);
          console.log('Client ID:', this.clientId);
      } else {
          console.error('Launch data not found in URL');
      }
  },
  // extractUserInfo() {
  //     const url = window.location.href;
  //     const params = new URLSearchParams(url.split('?')[1]);
  
  //     // Check for state parameter and restore original state
  //     const state = params.get('state');
  //     if (state) {
  //       const stateData = JSON.parse(decodeURIComponent(state));
  //       this.userId = stateData.userId;
  //       this.username = stateData.username;
  //       this.deploymentId = stateData.deploymentId;
  //       this.orgUnitId = stateData.orgUnitId;
  //       this.returnUrl = stateData.returnUrl;
  //     }
  //     console.log(orgUnitId)
  //     // const code = params.get('code');
  //     // if (code) {
  //     //   this.authCode = code;
  //     //   this.fetchBearerToken();
  //     // } else {
  //     //   // If no code is present, request authorization code
  //     //   this.requestAuthorizationCode();
  //     // }
  //   },
  
  async fetchBearerToken() {
      const clientId = '14f8257c-b91b-495a-8d1b-6d3a49c50d55';
      const clientSecret = '7kHO4OdCWn0cBxaMRXkxKU7JovBw6KR0aD02m7FNMjY';
      const redirectUri = 'http://localhost:8080/brightspace/feedback/';
  
      try {
        const response = await axios.post('https://auth.brightspace.com/core/connect/token', new URLSearchParams({
          grant_type: 'authorization_code',
          code: this.authCode,
          client_id: clientId,
          redirect_uri: redirectUri,
          client_secret: clientSecret
        }).toString(), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
  
        this.bearerToken = response.data.access_token;
        console.log('Bearer token retrieved:', this.bearerToken);
  
        // Redirect back to the original page with the token
        const returnUrl = new URL(this.returnUrl);
        returnUrl.searchParams.set('token', this.bearerToken);
        window.location.href = returnUrl.toString();
  
      } catch (error) {
        console.error('Error fetching bearer token:', error);
        this.$store.commit("show_snackbar", {
          text: "Failed to authenticate with Brightspace. Please try again.",
          type: "error"
        });
      }
    },
  
  requestAuthorizationCode() {
      const clientId = '14f8257c-b91b-495a-8d1b-6d3a49c50d55';
      const redirectUri = 'http://localhost:8080/brightspace/feedback/';
      const scope = 'core:*:*';
  
      // Prepare state parameter to retain original query parameters
      const state = JSON.stringify({
        userId: this.userId,
        username: this.username,
        deploymentId: this.deploymentId,
        orgUnitId: this.orgUnitId,
        returnUrl: window.location.href.split('?')[0] // Save the base URL to return to
      });
  
      // Construct the authorization URL
      const authUrl = `https://auth.brightspace.com/oauth2/auth?response_type=code&client_id=${clientId}&scope=${encodeURIComponent(scope)}&redirect_uri=${encodeURIComponent(redirectUri)}&state=${encodeURIComponent(state)}`;
  
      // Redirect the user to Brightspace's OAuth2 authorization endpoint
      window.location.href = authUrl;
  },
  
    // async fetchBearerToken() {
    //   const clientId = '14f8257c-b91b-495a-8d1b-6d3a49c50d55';
    //   const clientSecret = '7kHO4OdCWn0cBxaMRXkxKU7JovBw6KR0aD02m7FNMjY';
    //   const redirectUri = 'https://upld.knower.app/auth/';
  
    //   try {
    //     const response = await axios.post('https://auth.brightspace.com/core/connect/token', new URLSearchParams({
    //       grant_type: 'authorization_code',
    //       code: this.authCode,
    //       client_id: clientId,
    //       redirect_uri: redirectUri,
    //       client_secret: clientSecret
    //     }).toString(), {
    //       headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //       }
    //     });
  
    //     this.bearerToken = response.data.access_token;
    //     console.log('Bearer token retrieved:', this.bearerToken);
    //   } catch (error) {
    //     console.error('Error fetching bearer token:', error);
    //     this.$store.commit("show_snackbar", {
    //       text: "Failed to authenticate with Brightspace. Please try again.",
    //       type: "error"
    //     });
    //   }
    // },
      async fetchFeedback() {
      // Fetch feedback from the backend using your Vuex store dispatch
      if(this.feedback_id==""){
        console.log('waiting')
      }else{
        await this.$store.dispatch("get_feedback_brightspace", this.feedback_id);
       
      }
    },
        async bevestig() {
   
       
            if(this.taal == "" && this.language == ""){
              this.$store.commit("show_snackbar", {
                          text: "Kies een taal!",
                          type: "warning"
                      })
            }else if(this.titel2 == ""){
              this.$store.commit("show_snackbar", {
                          text: "Kies een titel voor het beoordelingsformulier!",
                          type: "warning"
                      })
            }else if(this.onderwerp == ""){
              this.$store.commit("show_snackbar", {
                          text: "Kies een onderwerp!",
                          type: "warning"
                      })
            }else if(this.doel == ""){
              this.$store.commit("show_snackbar", {
                          text: "Kies een leerdoel!",
                          type: "warning"
                      })
            }else if(this.niveau == ""){
              this.$store.commit("show_snackbar", {
                          text: "Kies een niveau!",
                          type: "warning"
                      })
            }else if(this.opdrachten.length == 0){
              this.$store.commit("show_snackbar", {
                          text: "Selecteer een opdracht!",
                          type: "warning"
                      })
            }else if(this.beoordelingsformulieren.length == 0){
              this.$store.commit("show_snackbar", {
                          text: "Selecteer een beoordelingsformulier!",
                          type: "warning"
                      })
            }
            else{
              this.step = 5
              // const name = this.files[0].name;
              // const extension = name.split('.').pop();
              const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  
  
  
  
              for (let index = 0; index < this.opdrachten.length; index++) {
                  if (["pdf", "docx", "xlsx", "csv"].includes(this.fileExtension)) {
                      this.showPopup = true;
                      let payload = {
                          opdracht: this.opdrachten[index].content,
                          taal: this.options.talen[this.taal],
                          titel: this.titel,
                          titel2: this.titel2,
                          onderwerp: this.onderwerp,
                          doel: this.doel,
                          model: this.options.niveau[this.niveau],
                          type: this.fileExtension,
                          typeB: this.fileExtensionBeoordelingsformulier,
                          beoordelingsformulier: this.beoordelingsformulieren[0].content,
                          user_id: this.userId,
                          naam_docent: this.userName,
                          token: this.bearerToken,
  
  
  
  
                      };
  
  
  
  
                      if (this.taal == "") {
                          payload.taal = this.options.languages[this.language];
                      } else {
                          payload.taal = this.options.talen[this.taal];
                      }
  
  
  
  
                      await this.$store.dispatch("nieuweFeedbackBrightspace", payload);
                  } else {
                    this.$store.commit("show_snackbar", {
                          text: "Daar ging iets mis, probeer het opnieuw",
                          type: "warning"
                      })
  
  
  
  
  
  
  
  
                      // this.showPopup = true;
                      // let payload = {
                      //     leerling_anoniem: this.leerlingen_anoniem[index],
                      //     titel: this.titel,
                      //     opdracht: this.opdrachten[index].content,
                      //     beoordelingsformulier_id: this.selectedItemId,
                      //     taal: this.options.talen[this.taal],
                      //     onderwerp: this.onderwerp,
                      //     doel: this.doel,
                      //     model: this.$store.state.opleidingsniveau,
                      //     vak_id: this.$route.params.id,
                      //     type: this.fileExtensions[index]
                      // };
  
  
  
  
                      // if (this.taal == "") {
                      //     payload.taal = this.options.languages[this.language];
                      // } else {
                      //     payload.taal = this.options.talen[this.taal];
                      // }
  
  
  
  
                      // await this.$store.dispatch("nieuweFeedbackCodeBrightspace", payload);                
                  }
  
  
  
  
                  // Delay for 5 seconds before the next iteration
                  await delay(5000);
              }
  
  
  
  
       
           
      }
  
  
  
  
     
    },
    async bevestig1() {
   
       
   
     this.step = 5
     // const name = this.files[0].name;
     // const extension = name.split('.').pop();
     const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));


      console.log(this.selectedItem.taal)

     for (let index = 0; index < this.opdrachten.length; index++) {
         if (["pdf", "docx", "xlsx", "csv"].includes(this.fileExtension)) {
             this.showPopup = true;
             let payload = {
                 opdracht: this.opdrachten[index].content,
                 taal: this.selectedItem.taal,
                 titel: this.titel,
                 titel2: this.selectedItem.titel,
                 onderwerp: this.selectedItem.onderwerp,
                 doel: this.selectedItem.doel,
                 model: this.selectedItem.model,
                 type: this.fileExtension,
                 user_id: this.userId,
                 naam_docent: this.userName,
                 token: this.bearerToken,
                 beoordelingsformulier_id: this.selectedItem.id




             };




             await this.$store.dispatch("nieuweFeedbackBrightspaceBeoordelingsformulier", payload);
         } else {
           this.$store.commit("show_snackbar", {
                 text: "Daar ging iets mis, probeer het opnieuw",
                 type: "warning"
             })








             // this.showPopup = true;
             // let payload = {
             //     leerling_anoniem: this.leerlingen_anoniem[index],
             //     titel: this.titel,
             //     opdracht: this.opdrachten[index].content,
             //     beoordelingsformulier_id: this.selectedItemId,
             //     taal: this.options.talen[this.taal],
             //     onderwerp: this.onderwerp,
             //     doel: this.doel,
             //     model: this.$store.state.opleidingsniveau,
             //     vak_id: this.$route.params.id,
             //     type: this.fileExtensions[index]
             // };




             // if (this.taal == "") {
             //     payload.taal = this.options.languages[this.language];
             // } else {
             //     payload.taal = this.options.talen[this.taal];
             // }




             // await this.$store.dispatch("nieuweFeedbackCodeBrightspace", payload);                
         




         // Delay for 5 seconds before the next iteration
         await delay(5000);
     }





  
}





},
    // opdracht deel file upload
    fileSelectedAllFiles(e, index) {
        const files = e.target.files;
        if (files.length > 0) {
            const file = files[0];
            const name = file.name;
            const extension = name.split('.').pop();
            const opdracht = { name, extension, content: '' };
  
  
  
  
            this.fileExtensions[index] = extension;
  
  
  
  
            if (extension === "pdf" || extension === "docx") {
                this.getBase64(file, base64 => {
                    opdracht.content = base64;
                    this.$setOpdracht(index, opdracht);
                });
            } else if (extension === "xlsx" || extension === "csv") {
                const reader = new FileReader();
                reader.onload = (loadEvent) => {
                    const arrayBuffer = loadEvent.target.result;
                    const base64String = this.arrayBufferToBase64(arrayBuffer);
                    if (extension === "xlsx") {
                        opdracht.content = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;
                    } else if (extension === "csv") {
                        opdracht.content = base64String;
                    }
                    this.$setOpdracht(index, opdracht);
                };
                reader.readAsArrayBuffer(file);
            } else {
                const reader = new FileReader();
                reader.onload = (loadEvent) => {
                    const fileContent = loadEvent.target.result;
                    opdracht.content = fileContent;
                    this.$setOpdracht(index, opdracht);
                };
                reader.readAsText(file);
            }
        }
    },
    $setOpdracht(index, opdracht) {
        this.$data.opdrachten[index] = opdracht;
    },
    getBase64(file, callback) {
        const reader = new FileReader();
        reader.onload = function(event) {
            callback(event.target.result);
        };
        reader.readAsDataURL(file);
    },
    arrayBufferToBase64(buffer) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    },
    openFileInput(index) {
        this.$refs['fileInput' + index][0].click();
    },
    //beoordelingsformulier deel file upload
    fileSelectedAllFilesB(e, index) {
        const files = e.target.files;
        if (files.length > 0) {
            const file = files[0];
            const name = file.name;
            const extension = name.split('.').pop();
            const beoordelingsformulier = { name, extension, content: '' };
  
  
  
  
            this.fileExtensionBeoordelingsformulier = extension;
  
  
  
  
            if (extension === "pdf" || extension === "docx") {
                this.getBase64(file, base64 => {
                    beoordelingsformulier.content = base64;
                    this.$setBeoordelingsformulier(index, beoordelingsformulier);
                });
            } else if (extension === "xlsx" || extension === "csv") {
                const reader = new FileReader();
                reader.onload = (loadEvent) => {
                    const arrayBuffer = loadEvent.target.result;
                    const base64String = this.arrayBufferToBase64(arrayBuffer);
                    if (extension === "xlsx") {
                        beoordelingsformulier.content = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;
                    } else if (extension === "csv") {
                        beoordelingsformulier.content = base64String;
                    }
                    this.$setBeoordelingsformulier(index, beoordelingsformulier);
                };
                reader.readAsArrayBuffer(file);
            } else {
                const reader = new FileReader();
                reader.onload = (loadEvent) => {
                    const fileContent = loadEvent.target.result;
                    beoordelingsformulier.content = fileContent;
                    this.$setBeoordelingsformulier(index, beoordelingsformulier);
                };
                reader.readAsText(file);
            }
        }
    },
    $setBeoordelingsformulier(index, beoordelingsformulier) {
        this.$data.beoordelingsformulieren[index] = beoordelingsformulier;
    },
    openFileInputB(index) {
        this.$refs['fileInput' + index][0].click();
    },
  
  
  
  
  
  
  
  
  
  
  
  
    extraWeek() {
        this.opdrachten.push(null);
        this.leerlingen_anoniem.push("");
        this.fileExtensions.push("");
    },
    minderWeek() {
        this.opdrachten.pop();
        this.leerlingen_anoniem.pop();
        this.fileExtensions.pop();
    },
        validateAndSendData() {
           this.bezig = false
           // Check if all arrays have at least one item
           for(let i = 0; i < this.opdrachten.length; i++){
               if (this.opdrachten[i]==null || this.leerlingen_anoniem[i]=="") {
               this.$store.commit("show_snackbar", {
                           text: "Geen opdracht of leerling anoniem ingevuld, of de informatie in de weken is niet gelijk aan elkaar!",
                           type: "warning"
                       })
             
             return false;
           }
           }
           
       
           // Check if the lengths of the arrays match
         
       
           // Check if any of the slides is empty (assuming you can check for a non-empty file)
           for (let i = 0; i < this.opdrachten.length; i++) {
             if (!this.opdrachten[i]) { // Depending on how you're handling file inputs, you might need a more robust check here
               
               this.$store.commit("show_snackbar", {
                           text: "Geen pdf geselecteerd",
                           type: "warning"
                       })
               return false;
             }
           }
       console.log(this.leerlingen_anoniem[0])
           // If all validations pass, proceed to send the data to the backend
           this.bevestig();
         },
  
  
  
  
  
       
     
    },
    mounted() {
        document.title = "Eduface | Brightspace";    },
  
  }
  </script>
  <style lang="scss">
  
  .brightspace-integratie {
    .table-margin table tbody tr {
        display: block;
        margin-bottom: 10px;
        border-bottom: 1px solid transparent;
    }
    
    .table-margin table tbody tr:last-child {
        margin-bottom: 0; /* Remove margin-bottom for the last row */
    }
    .table-wrapper {
    width: 100%;
    max-height: 220px; /* Adjust this value to match the height of 5 rows */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Disable horizontal scrolling */

    table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;

        thead th {
            background-color: #f2f2f2;
            text-align: left;
            padding: 8px;
        }

        tbody {
            display: table-row-group; /* Ensure rows are grouped properly */
            width: 100%;
        }

        tr {
            display: table-row; /* Keep rows as table rows */
            width: 100%;
        }

        td, th {
            border: 1px solid #dddddd;
            padding: 8px;
            text-align: left;
            width: auto;
        }
    }
}
.table-margin table tbody tr {
        display: block;
        margin-bottom: 10px;
        border-bottom: 1px solid transparent;
    }
    
    .table-margin table tbody tr:last-child {
        margin-bottom: 0; /* Remove margin-bottom for the last row */
    }
    .table-wrapper {
    width: 100%;
    max-height: 220px; /* Adjust this value to match the height of 5 rows */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Disable horizontal scrolling */

    table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;

        thead th {
            background-color: #f2f2f2;
            text-align: left;
            padding: 8px;
        }

        tbody {
            display: table-row-group; /* Ensure rows are grouped properly */
            width: 100%;
        }

        tr {
            display: table-row; /* Keep rows as table rows */
            width: 100%;
        }

        td, th {
            border: 1px solid #dddddd;
            padding: 8px;
            text-align: left;
            width: auto;
        }
    }
}
    .logo-integratie{
      display: flex;
      justify-content: center;
      margin-top: 5px;
    }
    .inlog-header{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        font-size: 25px;

        .inlog-options{
            margin: 0px 30px;
         

            .optie-benik{
                font-weight: bold;
                color: $secondary-color;
            }
            .optie-naartoe{
              color: $base-color;
              cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
            }

            .line-through{
                text-decoration: line-through;
            }
        }
        
    }
    .button-brightspace{
      width: 150px;
      margin: 10px 0px;
      height: 50px;
      background-color: $base-color;
      color: #fff;
      display: flex;
      justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }

    }
    .onsgevonden {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .button:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }

    .button:hover:not(:disabled) {
        background-color: #0056b3;
    }

    .option {
    border: 2px solid black;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    min-width: 200px; /* Minimum width, expands if needed */
    min-height: 40px; /* Minimum height, expands if needed */
    font-size: 20px;
    border-radius: 40px;
    text-align: center;
    display: flex; /* Ensure flexbox is used */
    justify-content: center; /* Horizontally center content */
    align-items: center; /* Vertically center content */
    transition: background-color 0.3s ease, border-color 0.3s ease;
    white-space: nowrap; /* Prevent text from wrapping to multiple lines */
}

    .selected {
        background-color: $secondary-color; /* Blue background when selected */
        color: white;
        border-color: $base-color; /* Darker border when selected */
    }

    .gevonden {
        font-size: 25px;
        font-weight: bold;
        margin-top: 40px;
        text-align: center;
    }

    .blue {
        color: #007BFF; /* Blue text color */
    }

    .oranje {
        color: #FFA500; /* Orange text color */
    }
}
.text-information{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.text-information {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .checkbox-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px auto;
        padding: 10px;
        width: 100%;
        text-align: center;
        border: 1px solid #ccc; /* Optional border for checkbox container */
        border-radius: 10px;
        transition: background-color 0.3s ease;
    }

    .checkbox-container:hover {
        background-color: #f0f0f0; /* Light grey background on hover */
    }

    .styled-checkbox {
        margin-right: 10px;
        transform: scale(1.5); /* Enlarged checkbox */
        cursor: pointer;
    }

    label {
        font-size: 18px;
        cursor: pointer;
        transition: color 0.3s ease;
    }

    label:hover {
        color: #007BFF; /* Highlight label on hover */
    }

    button {
        display: block;
        margin: 20px auto;
        padding: 12px 24px;
        font-size: 16px;
        background-color: #007BFF; /* Blue background */
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    button:disabled {
        background-color: #ccc; /* Grey background when disabled */
        cursor: not-allowed;
    }

    button:hover:not(:disabled) {
        background-color: #0056b3; /* Darker blue on hover */
    }

    .checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto; /* Centers the container */
  width: 100%;
  text-align: center; /* Centers the text */
}

.styled-checkbox {
  margin-right: 10px;
  transform: scale(1.2);
}

label {
  font-size: 16px;
}

button {
  display: block;
  margin: 10px auto;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}



  .content {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers content vertically */
    align-items: center; /* Centers content horizontally */
    text-align: center;
    min-height: 100vh; /* Ensures the content takes up the full height of the viewport */
    padding: 0 50px;
    margin: 0 auto; /* Centers the content horizontally */
    margin-top: -70px;
  }

  .content2 {
    display: flex;
    flex-direction: column;
   /* Centers content vertically */
    align-items: center; /* Centers content horizontally */
    text-align: center;
    min-height: 100vh; /* Ensures the content takes up the full height of the viewport */
    padding: 0 50px;
    margin: 0 auto; /* Centers the content horizontally */
    margin-top: 80px;
  }
  
  .file-feedback-upload {
    display: flex;
    flex-direction: column;
    align-items: center; /* Ensure the upload button is centered */
      width: 100%;
  
  
  
  
    .file-feedback,
    .file-feedback1 {
      margin-top: 40px;
      width: 300px;
      height: 60px;
      background-color: #eeeff8;
      cursor: pointer;
      border-radius: 8px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 20px;
      margin-right: 0px;
      border: 1px solid black;
    }
  
  
  
  
    .file-feedback1 {
      background-color: $secondary-color;
      font-size: 15px;
    }
  }
  
  
  
  
  .bevestig-beoordeling {
    width: 300px;
    height: 60px;
    cursor: pointer;
    border-radius: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
    background-color: $base-color;
    color: white;
    margin-top: 20px;
  }
  
  
  
  
  .nieuwe-beoordeling {
    display: flex;
  }
  
  
  
  
  .nieuwe-button {
    margin-left: 20px;
    background-color: $secondary-color;
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
  
  
  
  
    &:hover {
      opacity: 0.5;
    }
  }
  
  
  
  
  .selectfile,
  .selectfilebeoordeling,
  .selectfilebeoordeling2,
  .selectfile2 {
    width: 300px;
    height: 60px;
    background-color: #eeeff8;
    cursor: pointer;
    border-radius: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
    border: 1px solid black;
  }
  
  
  
  
  .selectfilebeoordeling2,
  .selectfile2 {
    background-color: $secondary-color;
  }
  
  // Additional styles as needed...
  
  
  
  
  @media (max-width: 900px) {
    .content {
      padding: 0 10px;
      width: calc(100% - 20px);
    }
  }
  
  
  
  
  @media (max-width: 600px) {
    .content {
      padding: 0 10px;
      width: calc(100% - 20px);
    }
  }
  }
  @media (min-width: 768px) {
  .checkbox-container {
    width: 50%;
  }

  .styled-checkbox {
    transform: scale(1.2);
  }

  button {
    padding: 12px 24px;
  }
}
</style>