<template>


</template>
<script>
const axios = require("axios").default;
import router from "@/router"
export default {
    async created(){
        await this.$store.dispatch('logout')
        
    }
}


</script>
