<template>
    <div class="books-overview">
        <Popup class="module-popup" :show="popup" @close="afronden()">
            <h2>Vak toevoegen</h2>
            <p>Titel vak:</p>
            <Input v-model="titel"></Input>
            <BetterButton 
                :text="'Toevoegen'"
                @click="vakToevoegen"
            ></BetterButton>
        </Popup>
        <Popup class="module-popup" :show="popup2" @close="afronden()">
            <h2>Vak toegevoegd</h2>

            <BetterButton 
                :text="$t('complete')"
                @click="afronden"
            ></BetterButton>
        </Popup>
        <Sidebar mode="leraar" active="Vakken"></Sidebar>
        <div class="content" :class="{'mobile-extend': toggledSidebar}">
            <DashboardHeader  mode="module" @search="filterBooks"></DashboardHeader>
          
            <div class="scrollable-content">
                <div class="nieuwe-module">
                    <h2>Vakken</h2>
                    <div class="verwijder-button">   
                        <div v-if="!verwijderen" @click="actieVerwijderen()" class="verwijderen">
                            Verwijder vak 
                        </div>
                        <div v-else @click="annuleerverwijderen()" class="verwijderen">
                        {{ $t('cancelDelete') }}
                        </div>
                    </div>
                   
                </div>
                 

             
                
                
                
                <div class="modules">
                    <div class="module-test">
                        <div></div>
                        <Module v-if="$i18n.locale === 'nl'"  v-for="(mod, index) in modules" :modules="mod" :key="index" :verwijderen="this.verwijderen"></Module>
                        <!-- <Module v-if="$i18n.locale === 'en'" mode="teacher" v-for="(mod, index) in filtered_modules" :modules="mod" :key="index" :verwijderen="this.verwijderen"></Module> -->
                        <div v-if="$i18n.locale === 'nl'" class="nieuwe-module-wrapper" @click="this.popup=true">
                           
                            <img class="nieuwe-module-img" src="/assets/plus-circle.svg" />
                        </div>
                        <div v-if="$i18n.locale === 'en'" class="nieuwe-module-wrapper" @click="$router.push({name:'new-module'})">
                           
                           <img class="nieuwe-module-img" src="/assets/plus-circle.svg" />
                       </div>
                       
                    </div>
                </div>
               
            </div>
            


            
        </div>
    </div>
</template>
<script>
import Sidebar from '@/components/Dashboard/SidebarLeraar.vue'
import DashboardHeader from '@/components/Dashboard/DashboardHeader.vue'
import DashboardHeading from '@/components/Dashboard/DashboardHeading.vue'
import Module from '@/components/Eduface/Vak.vue'
import Popup from '@/components/Widgets/Popup.vue'
import BetterButton from '@/components/Widgets/BetterButton.vue'
import Input from '@/components/Form/BetterInput.vue'
export default{
    data(){
        return {
            search: "",
            verwijderen: false,
            dummyModules: [
                {
                    id: 1,
                    titel: "Finance 1",
                    cover: "https://blockbook-backend-hotylusf3q-ez.a.run.app//books/cover/114",
                    opleiding: "Business economics",
                    leraar: "Firoz Firozzaman",
                },
                {
                    id: 2,
                    titel: "Finance 2",
                    cover: "/assets/dummy_covers/physical_education.jpg",
                    opleiding: "Business economics",
                    leraar: "Dr. Rakesh Verma",
                },
                {
                    id: 3,
                    titel: "Finance 3",
                    cover: "/assets/dummy_covers/educational_technology.jpg",
                    opleiding: "Business economics",
                    leraar: "Epifania V. Tabbada",
                    
                }
            ],
            popup: false,
            popup2: false,
            titel: "",

        }
        
    },
    mounted() {
        document.title = "Eduface | Vakken";    },
    components: {Module, Sidebar, DashboardHeader, DashboardHeading, Popup, BetterButton, Input},
    computed: {
        modules(){
            return this.$store.state.vakken_leraar
        },
        toggledSidebar(){
            return this.$store.state.mobileExtend
        },
        filtered_modules(){
            const vm = this
            return vm.modules.filter(module => {
                return (
                    (module.titel.toLowerCase().includes(vm.search.toLowerCase())) 
                )
            })
        },
        
    },
  
    async created() {
    // Initial call to fetch feedback
    await this.fetchModule();
  
    for(let i=0; i < this.modules.length; i++){
        if(this.modules[i].klaar=="" || this.modules[i].klaar_examenvragen=="" || this.modules[i].klaar_weekvragen=="" || this.modules[i].klaar=="failed" || this.modules[i].klaar_examenvragen=="failed" || this.modules[i].klaar_weekvragen=="failed"){
              // Set up interval to periodically fetch feedback
                this.moduleInterval = setInterval(async () => {
                await this.fetchModule();
                }, 20000); // Adjust the interval as needed (currently set to 1 minute)
        }
    }
  
  },
    methods:{
        async vakToevoegen(){
            if(this.titel == ""){
                this.$store.commit("show_snackbar", {
                            text: "Noteer een titel!",
                            type: "warning"
                        })
            }
            else{
              

               
                    this.$store.dispatch("nieuwVak", {
                        titel: this.titel,
                        cover: Math.floor(Math.random() * 5) + 1
                    })
                    this.popup = false
                    this.popup2 = true
                    this.$router.push({"name": "vakken"})
                    await this.$store.dispatch("get_vakken_leraar");
            }
            
        },
        async afronden(){
            this.popup = false
            this.popup2 = false
            await this.$store.dispatch("get_vakken_leraar");
           
            
            
        },
        async fetchModule() {
      // Fetch feedback from the backend using your Vuex store dispatch
      await this.$store.dispatch("get_vakken_leraar");
    },
        actieVerwijderen(){
            this.verwijderen = true;
            console.log(this.$store.state.role)
        },
        async annuleerverwijderen(){
            this.verwijderen = false;
            await this.$store.dispatch("get_vakken_leraar")
        },
        filterBooks(query){
            
            
            this.search = query
        
        }
    }
    
    
    
}
</script>
<style lang="scss">
.books-overview{
    background-color: #F4F6FC;
    min-height: 100vh;
    .popup{
        display: flex !important;
        align-items: center;
        flex-direction: column;
        text-align: center;

        .invite-code{
            font-size: 30px;
            font-weight: bold;
            color: $base-color;
            margin: 20px 0px;
        }

    }
    .content{
        justify-content: space-between;
        position: absolute;
        width: calc(100% - 300px);
        padding: 0px 50px;
        top: 0px;
        left: 200px;

        .nieuwe-module{
            display: flex;
        }

        .verwijder-button{
            margin-left: 20px;
            background-color: $base-color;
            color: #fff;
            border-radius: 20px;
            cursor: pointer;
            &:hover{
                opacity: 0.5;
            }

        }
        .verwijderen{
            padding: 5px 15px;
            background-color: $base-color;
            color: #fff;
            border-radius: 20px;
            cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
        }
        .modules {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .module-test {
                display: flex;
                flex-wrap: wrap;
                
                
                height: 250px;
            }
        }

        .nieuwe-module-wrapper{
            display: flex;
            justify-content: center;
            width: 70px;
            height: 250px;
            cursor: pointer;

            &:hover{
                opacity: 0.8;
            }

            .nieuwe-module-img{
                height: 60px;
                margin-top: 50px;
                
            }
        }
        


        

        .storten{
            display: block;
            font-size: 20px;
            margin-bottom: 40px;
        }

        .balans{
            font-size: 50px;
            color: $secondary-color;
            font-weight: bold;
            line-height: 30px;
            margin-bottom: 15px;
        }
    }
    .books{
        display: flex;
        flex-wrap: wrap;
        
        .boek-plus{
            margin: 5px;

            &:first-child{
                margin-left: 0px;
            }
        }
    }

    @media (max-width: 900px){
        .content{
            left: 0px;
            width: calc(100% - 100px);
            .module-test{
                display: flex;
                flex-wrap: wrap;
            }
            .search{
                    display: none;
                }
            &.mobile-extend{
                display: block;
                left: 200px;
                width: calc(100% - 300px);

                
            }
        }
    }

    @media (max-width: 600px){
        .content{
            padding: 0px 10px;
            width: calc(100% - 20px);
            &.mobile-extend{
                left: calc(100% - 70px);
                width: 70px;
                .scrollable-content{
                    display: none;
                }
            }

            .recent-books{
                margin-bottom: 50px;
                .boek-plus{
                    margin: 5px;

                    &:first-child{
                        margin-left: 0px;
                    }

                    
                }
            }

            
        }
    }
}
</style>