<template>
    <div class="teacher-page">
        <Sidebar mode="leraar" active="dashboard" ></Sidebar>
        <div class="content" :class="{'mobile-extend': toggledSidebar}">
            <DashboardHeader mode="uitgever" :hideSearch="true"></DashboardHeader>
            <div class="scrollable-content">
                <div class="feedback-data">
                    <div class="minuten">
                        <img src="/assets/Dashboard1.png"/>
                        <div class="bespaard">{{ $t('savedMinutesPart1') }}<span class="oranje">{{ Math.round(informatie_docent.aantal_minuten / 500) * 20 }}</span>{{ $t('savedMinutesPart2') }}<span class="blauw">{{ $t('blockbook') }}</span></div>
                    </div>
                    <div class="feedback">
                        <img src="/assets/Dashboard2.png"/>
                        <div class="bespaard">{{ $t('studentsReceived') }}<span class="blauw">"{{ informatie_docent.aantal_feedback }}{{ $t('times') }}"</span><span class="oranje">AI</span> <span class="blauw">{{ $t('aiFeedback') }}</span></div>
                    </div>                   
                </div>
                <div class="onderwijs">                   
                    <div class="technologie">
                        <div>{{ $t('withYou') }}<a class="blauw"> {{ $t('education') }} </a>{{ $t('toANewLevel') }}<span class="oranje">{{ $t('technology') }}</span></div>
                    </div>
                </div>
            </div>
            
            
           

            <div>
                
            </div>
            
            
            
        </div>
    </div>
</template>
<script>
import Sidebar from '@/components/Dashboard/SidebarLeraar.vue'
import DashboardHeader from '@/components/Dashboard/DashboardHeader.vue'
import DashboardHeading from '@/components/Dashboard/DashboardHeading.vue'
export default{
    components: {Sidebar, DashboardHeader, DashboardHeading},
     computed: {
        toggledSidebar(){
            return this.$store.state.mobileExtend
        },
        informatie_docent(){
            return this.$store.state.informatie_docent
        },
     },
    async created(){
        await this.$store.dispatch("informatie_docent")
    }
}

</script>

<style lang="scss">
.teacher-page{
    background-color: #F4F6FC;
    .content{
        justify-content: space-between;
        position: absolute;
        width: calc(100% - 300px);
        top: 0px;
        left: 200px;
        padding: 0px 50px;
        overflow-x: hidden;
        .onderwijs{
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .technologie{
            
            background-color: #eeeff8;
            width: 700px;
            height: 150px;
            margin-left: 150px;
            margin-top: 50px;
            border-radius: 4px;
            text-align: center;
            font-weight: bold;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            /* Horizontally center the content */
       

            .oranje{
            color: $secondary-color;
        }
            .blauw{
                color: $base-color;
            }
        }
        }
        
        .feedback-data{
            display: flex;
            width: 100%;
            margin-left: 150px;
            font-weight: bold;
            border-radius: 4px;
            font-size: 20px;
            flex-wrap: wrap;
            

            .oranje{
                color: $secondary-color;
            }
            .blauw{
                color: $base-color;
            }
            

            .minuten{
                display: flex;
                 
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: #eeeff8;
                width: 300px;
                height: 320px;
                margin-right: 100px;
                border-radius: 10px;
                text-align: center;
                .bespaard{
                    margin-top: 10px;
                    margin-left: 10px;
                }
                img{
                    margin-left: 25px;
                    width: 250px;
                }
            }
            .feedback{
                display: flex;

                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: #eeeff8;
                width: 300px;
                height: 320px;
                border-radius: 10px;
                margin-right: 200px;
                text-align: center;
                .bespaard{
                    margin-top: 10px;
                    margin-left: 10px;
                }
                img{
                    margin-left: 25px;
                    width: 215px;
                }
            }
        }
    }
}
@media (max-width: 900px){
    .teacher-page{
        .content{
            left: 0px;
            width: calc(100% - 100px);
            &.mobile-extend{
                display: block;
                left: 200px;
                width: calc(100% - 300px);

                
            }
            
        }
    }
        
    }
@media (max-width: 600px){
    .teacher-page{
        .content{
            padding: 0px 10px;
            width: calc(100% - 20px);
            
            &.mobile-extend{
                left: calc(100% - 70px);
                width: 70px;
                .scrollable-content{
                    display: none;
                }
            }

            
            .feedback-data{
                margin-left: 20px;
            }
            .onderwijs{
                .technologie{
                margin-left: 20px;
            }
            }
            
        }
    }
        
    }

</style>