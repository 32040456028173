<template>
    <div class="uitgever">
        <TeacherPage></TeacherPage>
    </div>
</template>
<script>
import TeacherPage from '@/components/Eduface/TeacherAccount.vue';
export default{
    components: {TeacherPage},
    mounted() {
        document.title = "Eduface | Dashboard";    },
}
</script>
<style lang="scss">
.uitgever{
    h1{
        color: $base-color;
    }
}
</style>