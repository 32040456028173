<template>
    <div class="onboarding-page">
        
        
        
        <div v-if="windowWidth > 1300">
            <div class="mark-container">
                <img class="mark" src="/assets/onboarding/mark.png"/>
            </div>
            <div class="banner" :style="'background: linear-gradient(46.57deg, #666565 0.88%, #F8F7F7 87.12%), url(' + bannerURL + '); background-blend-mode: multiply, normal; background-size: cover; background-position: center;'">
                <div class="top" @click="$router.go(-1)">
                    <img v-if="!magNietTerug" src="/assets/arrowback.svg"/>
                    <span v-if="!magNietTerug" class="banner-back">{{ $t('back') }}</span>
                </div>
                <div class="bottom">
                    <h1>{{ title }}</h1>
                    <h2>{{ bannerText }} <b>{{ bannerBold}}</b></h2>
                </div>
            </div>
            <div class="bg" :style="'background: linear-gradient(46.57deg, #666565 0.88%, #F8F7F7 87.12%), url(' + bgURL + '); background-blend-mode: multiply, normal; background-size: cover; background-position: center;'">
                <div class="onboarding-form">
                    <div class="form-content">
                        <slot></slot>
                    </div>
                    
                </div>
            </div>
        </div>
        <div v-else>
            <div class="banner" :style="'background: linear-gradient(46.57deg, #666565 0.88%, #F8F7F7 87.12%), url(' + bannerURL + '); background-blend-mode: multiply, normal; background-size: cover; background-position: center;'">
                <div class="banner-left-items">
                    <div class="top" @click="$router.go(-1)">
                        <img v-if="!magNietTerug" src="/assets/arrowback.svg"/>
                        <span v-if="!magNietTerug" class="banner-back">{{ $t('back') }}</span>
                    </div>
                    <div class="bottom">
                        <h1>{{ title }}</h1>
                        <h2>{{ bannerText }} <b>{{ bannerBold}}</b></h2>
                    </div>
                </div>
                
                <div class="onboarding-form">
                    <div class="form-content">
                        <slot></slot>
                    </div>
                    
                </div>
            </div>
        </div>
        
        




        <!-- <div class="content">
            <h1>Login met uw account:</h1>
            <input name="email" placeholder="Email" v-model="email"/>
            <input name="wachtwoord" placeholder="Wachtwoord" v-model="wachtwoord" type="password"/>
            <button @click="login">log in</button>
            <router-link :to="{name: 'wachtwoordVergeten'}"><button>Wachtwoord Vergeten</button></router-link>
        </div> -->
    </div>
</template>
<script>
export default{
    props: ["banner", "bannerText", "bannerBold", "title", "magNietTerug"],
    data(){
        return {
            email: "",
            wachtwoord: "",
            message: "",
            bgURL: "/assets/onboarding/background.jpg"
            
        }
    },
    computed: {
        bannerURL(){
            if(this.banner == "login-student"){
                return "/assets/onboarding/login-student.jpg"
            }else if(this.banner == "login-uitgever"){
                return "/assets/onboarding/login-uitgever.jpg"
            }else if(this.banner == "registreren-student"){
                return "/assets/onboarding/registreren-student.jpg"
            }else if(this.banner == "registreren-uitgever"){
                return "/assets/onboarding/registreren-uitgever.jpg"
            }else if(this.banner == "personaliseren-student"){
                return "/assets/onboarding/personaliseren-student.jpg"
            }
            else if(this.banner == "login-leraar"){
                return "/assets/onboarding/teacher.jpg"
            }
            else if(this.banner == "registreren-leraar"){
                return "/assets/onboarding/leraar1.jpg"
            }
            else if(this.banner == "personaliseren-leraar"){
                return "/assets/onboarding/leraar1.jpg"
            }


        },
        windowWidth(){
            return this.$store.state.windowSize.width
        }
    },
    methods: {
        async login(){
            await this.$store.dispatch("login",{
                password: this.wachtwoord,
                email: this.email,
            }).then(success => {
                this.email = ""
                this.wachtwoord = ""
            })

        },
        enterClicked(){
            
        }

        
    }

    
}

</script>

<style lang="scss">
.onboarding-page{
    // .content{
    //     justify-content: space-between;
    // }

    .banner{
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        top: 0px;
        left: 0px;
        width: 50vw;
        height: 100vh;

        .top{
            display: flex;
            align-items: center;
            color: $white;
            font-size: 22px;
            padding: 50px;
            cursor: pointer;

            &:hover{
                opacity: 0.6;
            }

            img{
                width: 40px;
                height: 40px;
                margin-right: 10px;
            }
        }

        .bottom{
            padding: 50px;
            h1{
                font-family: "roboto";
                font-weight: 400;
                font-size: 50px;
                color: $white;
                margin-bottom: 50px;
            }
            h2{
                color: $white;
                font-size: 35px;
                line-height: 50px;
            }
        }
    }

    .bg{
        display: flex;
        align-items: center;
        position: absolute;
        top: 0px;
        right: 0px;
        width: 50vw;
        height: 100vh;
    }

    .onboarding-form{
        position: relative;
        display: flex;
        align-items: center;
        width: calc(100% - 210px);
        max-height: calc(100% - 100px);
        height: 700px;
        background: $white;
        border-radius: 0px 20px 20px 0px;
        padding: 20px 80px;

        .form-content{
            display: flex;
            justify-content: stretch;
            align-items: center;
            width: 100%;
            height: 100%;
        }
    }

    .mark-container{
        display: flex;
        position: absolute;
        z-index: 2;
        width: 100px;
        height: 100vh;
        align-items: center;
        left: calc(50vw - 65px)
    }

    .mark{
        
        width: 130px;
        
    }
  
    
    @media (max-width: 1300px){
        .banner{
            width: 100%;
            flex-direction: row;
            align-items: center;
            background-position: right;

            .banner-left-items{
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: space-between;

                .bottom{
                    h1{
                        font-size: 30px;
                        margin-bottom: 0px;
                    }

                    h2{
                        font-size: 25px;
                    }
                }
            }

            .onboarding-form{
                width: 500px;
                border-radius: 20px;
                margin-right: 40px;
            }
        }
    }

    @media (max-width: 900px){
        .banner{
            .banner-left-items{
                display: none;
            }

            .onboarding-form{
                width: calc(100% - 80px);
                border-radius: 20px;
                margin: 0px 40px;
                height: auto;
            }

            .top-text{
                flex-direction: column;
                align-items: flex-start;
            }
        }

        
    }

    @media (max-width: 600px){
        .banner{

            .onboarding-form{
                width: calc(100% - 20px);
                border-radius: 20px;
                margin: 0px 10px;
                padding: 30px 40px;
                
            }

            .form{
                padding: 0px 0px 40px 0px;
            }
        }

        
    }
}




</style>