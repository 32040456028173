export const messages = {
    en: {
    test1: "hello does this work",
    test2: "i think so",
    test3: "yes it works",
    headerTitle: "Upload.<br />Generate. Save.",
    headerSubtitle: "Save time with an AI tool built for educators.",
    scheduleDemoButton: "Schedule a Demo",
    reviewingAndFeedback: "Reviewing &<br />Giving Feedback",
    developingCaseStudies: "Developing Case<br />Studies",
    practiceTestsAndExamQuestions: "Practice Tests and<br />Exam Questions",
    believeInTechInnovation: "We believe in technology & innovation for education!",
    techImpactOnEducation: "New technologies have an increasingly significant impact on education. We are here to make new technologies accessible for teachers and students, thus believing in better education.",
    whatWeDo: "What we do",
    aiSupportDescription: "We use AI to assist teachers in developing practice questions, case studies, reviewing, giving feedback, and monitoring students' results.",
    forEducationByStudents: "For education, by students",
    blockbookOrigin: "Blockbook originated because two students believed that new technologies should be more utilized in education. With Blockbook, new technologies are made accessible in education.",
    allInOneHelp: "All-in-one assistance",
    basedOnCourseMaterial: "Based on course material",
    madeForEducation: "Made for education",
    learnMoreAboutOurProduct: "Learn more about our product",
    uploadLearningMaterial: "Upload learning material",
    uploadLearningMaterialDescription: "Upload the course material to create a learning environment. You can upload PowerPoints, PDFs, grading forms, past exams, and other text documents to create your course's learning environment.",
    automatedDigitalEnvironment: "Automated digital learning environment",
    automatedDigitalEnvironmentDescription: "After uploading the material, we create a digital learning environment in 10 minutes. Adjust what needs to be changed and publish the digital learning environment with practice material to students.",
    createContentFaster: "Create content faster for students",
    createContentFasterDescription: "Generate practice content for students automatically. Students can find practice tests that match their learning goals and are personalized to their learning process.",
    provideFeedbackQuickly: "Provide feedback quickly",
    provideFeedbackQuicklyDescription: "Let the grading tool check interim formative assignments and provide students with feedback based on the learning objectives and a grading form.",
    educationByStudents: "For education, by students",
    educationByStudentsDescription: "Blockbook was founded because two students believed new technologies should be more utilized in education. With Blockbook, new technologies are made accessible in education.",
    allInOneAssistance: "All-in-one assistance",
    basedOnCourseMaterial: "Based on course material",
    madeForEducation: "Made for education",
    weCollaborateWith: "We collaborate with",
    partnersHelpingMission: "These partners help us on our mission to make technology accessible in education. Do you also believe in the power of technology and will you help us too?",
    moreAboutourproduct: "Learn more about our product",
    weCollaborateWith: "We collaborate with",
    partnersHelpingMission: "These partners help us on our mission to make technology accessible in education. Do you also believe in the power of technology and will you help us too?",
    whatDoesItCost: "What does it cost",
    costExplanation: "We charge annual licenses based on the number of students who will use the tool. Currently, we offer two options, a free option and a paid option.",
    viewPrices: "View prices",
    mostAdvantageous: "Most advantageous",
    schoolAccount: "School account",
    pricePerStudentPerYear: "€20 per student per year",
    aiToolForSchools: "The AI tool built for a school.",
    billedAnnually: "Billed annually",
    unlimitedTeacherAccounts: "Unlimited number of teacher accounts",
    customizedForSchool: "Customized for the school",
    infoSecurityForSchool: "Information security suitable for the school",
    maximizeYourProductivity: "Maximize your productivity and provide better education!",
    bookADemo: "Book a demo",
    contactUs: "Contact us",
    jeroenStoryPart1: "In his first year of studying, founder Jeroen bought all his study books for more than",
    euroAmount1: "€450,-",
    jeroenStoryPart2: "At the end of the year, Jeroen wanted to sell these books to get back a large part of his money. However, he only got back",
    euroAmount2: "€70,-",
    jeroenStoryPart3: ", making his study books cost more than",
    euroAmount3: "€350,-",
    jeroenStoryPart4: "in one year.",
    jeroenAction: "Jeroen decided to do something about this. He sought a solution to resell textbooks more easily and retain more of the proceeds. Together with co-founder Menno,",
    resell: "",
    andKeepMore: "",
    blockbookOrigin: "Blockbook was born!",
    ourGoal: "Our",
    goal: "Goal",
    ourGoalDescription: "The ideal online personalized learning experience for all students worldwide",
    textbooks: "Textbooks",
    buyYourBooksIntro: "Buy your",
    saveMoney: "save!",
    ourMission: "Our",
    mission: "Mission",
    ourMissionDescription: "To offer the ideal personalized learning experience in a fully automated way by developing new technologies",
    ourPrefix: "Our",
    goalWord: "Goal",
    idealOnline: "The ideal online",
    personalizedLearning: "personalized",
    learningExperience: "learning experience for all",
    studentsWorldwide: "students worldwide",
    textbooksWord: "Textbooks",
    buyNowYour: "Buy now your",
    textbooksOnBlockbook: "textbooks",
    onBlockbookAnd: "on Blockbook and",
    saveWord: "save",
    buyBooksButton: "Buy your books",
    ourMissionPrefix: "Our",
    missionWord: "Mission",
    offerTheIdeal: "To offer the ideal",
    personalizedLearningExperience: "personalized",
    learningExperienceThrough: "learning experience in a fully",
    automatedWay: "automated",
    wayByDeveloping: "way by developing new",
    technologiesWord: "technologies",
    blockbookMissionIntro: "Blockbook is on a",
    missionWord: "mission",
    toImproveEducation: "to improve education and needs your help!",
    withBlockbook: "With Blockbook, founders Jeroen and Menno make textbooks",
    cheaper: "cheaper",
    faster: "faster",
    andEasier: "and easier",
    lookingForPassionatePeople: "We are always looking for passionate people who want to join Blockbook's mission!",
    ourGoalPhrase: "Our",
    goalWord: "Goal",
    idealOnlinePersonalizedLearningExperience: "The ideal online",
    personalized: "personalized",
    learningExperienceForAll: "learning experience for all",
    studentsWorldwide: "students worldwide",
    ourMissionPhrase: "Our",
    missionWord: "Mission",
    offerIdealPersonalizedLearning: "To offer the ideal",
    learningExperienceFullyAutomated: "learning experience in a fully",
    automated: "automated",
    wayByDevelopingNew: "way by developing new",
    technologies: "technologies",
    whoAre: "Who are ",
    we: "we",
    blockbookOriginStory: "Blockbook originated from the",
    personal: "personal",
    frustrations: "frustrations",
    ofTwoStudents: "of two students who understood all too well the challenges of the textbook market. We know the",
    pain: "pain",
    ofHighPrices: "of high prices and the",
    hassle: "hassle",
    aroundResellingTextbooks: "around reselling textbooks. That's why we decided to",
    bringARevolution: "bring a revolution",
    inTheWayStudents: "in the way students",
    buy: "buy",
    andSell: "and sell",
    theirTextbooks: "their textbooks.",
    challengingStatusQuo: "At Blockbook, we believe it's time to challenge the status quo. We've created a platform that is not only",
    cheaper: "cheaper",
    butAlsoEasier: "but also easier",
    andFaster: "and faster",
    weAreHere: "We are here",
    toElevate: "to elevate",
    theResellingOfTextbooks: "the reselling of textbooks to a whole new level, where",
    convenience: "convenience",
    andSavings: "and savings",
    goHandInHand: "go hand in hand.",
    withBlockbookStudentsCan: "With Blockbook, students can",
    effortlessly: "effortlessly",
    offerAndSellTheirDigitalTextbooks: "offer and sell their digital textbooks to fellow students who are looking for",
    costEffective: "cost-effective",
    options: "options.",
    joinTheGrowing: "Join the",
    growing: "growing",
    groupOfStudents: "group of students who buy their books through Blockbook! Discover Blockbook today and experience the",
    future: "future",
    ofEducation: "of education.",
    options: "Our options",
    places: "Only a limited amount of places available.",
    pilotUser: "Pilot User",
    pricePrefix: "Euro",
    priceFree: "€0",
    month: "",
    pilotTesting: "Pilot testing at your school via Blockbook.",
    createYourAccount: "Create your account",
    signUp: "Sign Up",
    automatedPracticeMaterial: "Automated practice material",
    automatedFeedbackTool: "Automated feedback tool",
    teacherAccount: "Teacher Account",
    price: "€20",
    perMonth: "per month",
    aiToolForTeachers: "The AI tool built for teachers.",
    monthlyPayment: "Monthly payment",
    signUp: "Sign Up",
    automatedTeachingMaterial: "Automated teaching material",
    automatedFeedback: "Automated feedback giving",
    sharingWithStudents: "Sharing with students",
    mostAdvantageous: "Most Advantageous",
    schoolAccount: "School Account",
    price: "€20",
    perStudentPerYear: "Per student per year",
    aiToolBuiltForASchool: "The AI tool built for a school.",
    billedAnnually: "Billed annually",
    contactUs: "Contact Us",
    unlimitedNumberOfTeacherAccounts: "Unlimited number of teacher accounts",
    customizedForTheSchool: "Customized for the school",
    dataSecurityCompliantWithTheSchool: "Data security compliant with the school",
    loginTitle: "Login",
    noAccountQuestion: "Don't have an account?",
    register: "Register",
    student: "Student",
    publisher: "Publisher",
    teacher: "Teacher",
    usernameOrEmail: "Username or Email",
    password: "Password",
    forgotPassword: "Forgot Password",
    noAccountQuestionPublisher: "Don't have an account?",
    companyNameOrEmail: "Company Name or Email",
    back: "Back",
    savedMinutesPart1: "You've saved ",
    savedMinutesPart2: " minutes with ",
    blockbook: "Eduface",
    studentsReceived: "Students have received ",
    times: " times ",
    aiFeedback: "AI feedback",
    withYou: "Together with you, we elevate ",
    education: "education",
    toANewLevel: " to a new level through ",
    technology: "technology!",
    deleteModule: "Delete Module",
    cancelDelete: "Cancel Delete",
    feedbackOverview: "Feedback Overview",
    newFeedback: "New Feedback",
    feedback: "Feedback",
    student: "Student",
    title: "Title",
    date: "Date",
    category: "Rubric",
    delete: "Delete ",
    somethingWentWrong: "Something went wrong, please try again:",
    refresh: "Refresh",
    beingSaved: "Being saved, one moment...",
    deleteModule: "Delete Module",
    moduleAddedSuccess: "Module successfully added!",
    saveInviteCode: "Save the code below to invite students",
    complete: "Complete",
    pleaseWait: "Please wait...",
    processingModule: "We are processing your new module...",
    thisMayTakeAWhile: "This may take a while",
    assessmentFormAddedSuccess: "Assessment form successfully added!",
    complete: "Complete",
    pleaseWait: "Please wait...",
    processingAssessmentForm: "We are processing your new assessment form...",
    thisMayTakeAWhile: "This may take a while",
    assignmentAddedSuccess: "Assignment successfully added, feedback is being generated!",
    complete: "Complete",
    pleaseWait: "Please wait...",
    processingAssignment: "We are processing your new assignment...",
    thisMayTakeAWhile: "This may take a while",
    assessmentForms: "Assessment Forms",
    confirm: "Confirm",
    confirmDeleteAssignment: "Are you sure you want to delete the assignment?",
    delete: "Delete",
    assignmentsAddedSuccess: "Assignments successfully added, feedback is being generated!",
    complete: "Complete",
    pleaseWait: "Please wait...",
    processingAssignments: "We are processing your new assignments...",
    thisMayTakeAWhile: "This may take a while",
    personalInformation: "Personal Information",
    schoolInformation: "School Information",
    username: "Username",
    email: "Email",
    firstName: "First Name",
    insertion: "Insertion",
    lastName: "Last Name",
    birthDate: "Birth Date",
    school: "School",
    education: "Education",
    typeHereYour: "Type your",
    here: "here!",
    orLeaveEmpty: "or leave it empty!",
    editProfile: "Edit Profile",
    cancelEdit: "Cancel Edit",
    saveChanges: "Save Changes",
    changePassword: "Change Password",
    testYourself: "Test Yourself",
    testYourselfDescription: "Take the chapter tests to test yourself!",
    makeTest: "Take the Test",
    view: "View",
    viewTest: "View the Test",
    verder: "Further",
    assignmentTitlePlaceholder: "Type the title of your assignment here or leave it blank...",
    deleteAssignment: "Delete assignment",
    questionTitlePlaceholder: "Type the title of your question here...",
    deleteQuestion: "Delete question",
    selectQuestionType: "Select a question type...",
    answerOptionPlaceholder: "Type your answer option...",
    deleteAnswerOption: "Delete answer option",
    correctAnswer: "Correct answer",
    addExtraAnswerOption: "Add extra answer option",
    addExtraQuestion: "Add extra question",
    addExtraAssignment: "Add extra assignment",
    moveDown: "Move down",
    moveUp: "Move up",

   
      // Additional translations...
    },
    nl: {
    moveDown: "Naar beneden",
    moveUp: "Naar boven",  
    assignmentTitlePlaceholder: "Type hier de titel van jouw opdracht of laat deze leeg...",
    deleteAssignment: "Verwijder opdracht",
    questionTitlePlaceholder: "Type hier de titel van jouw vraag...",
    deleteQuestion: "Verwijder vraag",
    selectQuestionType: "Selecteer een vraagtype...",
    answerOptionPlaceholder: "Type jouw antwoordoptie...",
    deleteAnswerOption: "Verwijder antwoordoptie",
    correctAnswer: "Correct antwoord",
    addExtraAnswerOption: "Voeg extra antwoord optie toe",
    addExtraQuestion: "Voeg extra vraag toe",
    addExtraAssignment: "Voeg extra opdracht toe",  
    verder: "Verder",
    testYourself: "Toets jezelf",
    testYourselfDescription: "Maak de hoofdstuktoetsen om jezelf te testen!",
    makeTest: "Maak de toets",
    viewTest: "Bekijk de toets",
    view: "Bekijk",
    personalInformation: "Persoonlijke informatie",
    schoolInformation: "School informatie",
    username: "Gebruikersnaam",
    email: "Email",
    firstName: "Voornaam",
    insertion: "Tussenvoegsel",
    lastName: "Achternaam",
    birthDate: "Geboortedatum",
    school: "School",
    education: "Opleiding/opleidingen",
    typeHereYour: "Type hier jouw",
    here: "!",
    orLeaveEmpty: "of laat leeg!",
    editProfile: "Profiel aanpassen",
    cancelEdit: "Aanpassing annuleren",
    saveChanges: "Aanpassing opslaan",
    changePassword: "Wachtwoord aanpassen",
    assignmentsAddedSuccess: "Opdrachten succesvol toegevoegd, feedback wordt gegenereerd!",
    complete: "Afronden",
    pleaseWait: "Moment geduld a.u.b...",
    processingAssignments: "Wij zijn uw nieuwe opdrachten aan het verwerken...",
    thisMayTakeAWhile: "Dit kan even duren",   
    confirmDeleteAssignment: "Weet je zeker dat je opdracht wilt verwijderen?",
    delete: "Verwijderen ",
    assessmentForms: "Beoordelingsformulieren",
    confirm: "Bevestigen",
    assignmentAddedSuccess: "Opdracht succesvol toegevoegd, feedback wordt gegenereerd!",
    complete: "Afronden",
    pleaseWait: "Moment geduld a.u.b...",
    processingAssignment: "Wij zijn uw nieuwe opdracht aan het verwerken...",
    thisMayTakeAWhile: "Dit kan even duren",
    assessmentFormAddedSuccess: "Beoordelingsformulier succesvol toegevoegd!",
    complete: "Afronden",
    pleaseWait: "Moment geduld a.u.b...",
    processingAssessmentForm: "Wij zijn uw nieuwe beoordelingsformulier aan het verwerken...",
    thisMayTakeAWhile: "Dit kan even duren",  
    moduleAddedSuccess: "Module succesvol toegevoegd!",
    saveInviteCode: "Sla onderstaande code op om leerlingen uit te nodigen",
    complete: "Afronden",
    pleaseWait: "Moment geduld a.u.b...",
    processingModule: "Wij zijn uw nieuwe module aan het verwerken...",
    thisMayTakeAWhile: "Dit kan even duren",
    somethingWentWrong: "Er is iets misgegaan, probeer nog een keer:",
    refresh: "Vernieuwen",
    beingSaved: "Wordt opgeslagen, een moment...",
    deleteModule: "Module verwijderen",
    feedbackOverview: "Feedback overzicht",
    newFeedback: "Nieuwe feedback",
    feedback: "Feedback",
    student: "Student",
    title: "Titel",
    date: "Datum",
    category: "Rubriek",
    delete: "Verwijderen",
    test1: "hallo werkt dit",
    test2: "denk het wel",
    test3: "ja het werkt",
    headerTitle: "Upload.<br />Genereer. Bespaar.",
    headerSubtitle: "Bespaar tijd met een AI tool gebouwd<br />voor docenten.",
    scheduleDemoButton: "Plan een Demo",
    reviewingAndFeedback: "Nakijken &<br />Feedback geven",
    developingCaseStudies: "Casussen<br />ontwikkelen",
    practiceTestsAndExamQuestions: "Oefentoetsen en<br />tentamenvragen",
    believeInTechInnovation: "Wij geloven in technologie & innovatie voor het onderwijs!",
    techImpactOnEducation: "Nieuwe technologieën hebben een steeds grotere impact op het onderwijs. Wij zijn er om de nieuwe technologieën toegankelijk te maken voor docenten en studenten. Daarmee geloven wij in beter onderwijs.",
    whatWeDo: "Wat wij doen",
    aiSupportDescription: "Wij zetten AI in om een docent te ondersteunen bij het ontwikkelen van oefenvragen, casussen, nakijken, feedback geven en het bijhouden van resultaten van studenten.",
    forEducationByStudents: "Voor het onderwijs, door studenten",
    blockbookOrigin: "Blockbook is ontstaan omdat twee studenten geloven dat nieuwe technologieën meer gebruikt moeten worden in het onderwijs. Met Blockbook worden nieuwe technologieën toegankelijk gemaakt in het onderwijs.",
    allInOneHelp: "Alles-in-een hulp",
    basedOnCourseMaterial: "Op basis van lesmateriaal",
    madeForEducation: "Gemaakt voor het onderwijs",
    learnMoreAboutOurProduct: "Leer meer over ons product",
    uploadLearningMaterial: "Upload lesmateriaal",
    uploadLearningMaterialDescription: "Upload het lesmateriaal voor jouw vak en maak hiermee een leeromgeving. Je kan PowerPoints, PDFs, beoordelingsformulieren, oude toetsen en andere tekstbestanden uploaden om de leeromgeving van jouw vak te maken.",
    automatedDigitalEnvironment: "Geautomatiseerde digitale leeromgeving",
    automatedDigitalEnvironmentDescription: "Na het uploaden van het leermateriaal maken wij in 10 min een digitale leeromgeving. Pas hierin aan wat aangepast moet worden en publiceer de digitale leeromgeving met oefenmateriaal naar studenten.",
    createContentFaster: "Maak sneller content voor student",
    createContentFasterDescription: "Genereer geautomatiseerd oefencontent voor studenten. Studenten kunnen oefentoetsen vinden die passen bij hun leerdoelen en gepersonaliseerd op hun leerproces.",
    provideFeedbackQuickly: "Geef feedback in een handomdraai",
    provideFeedbackQuicklyDescription: "Laat de nakijktool de tussentijdse formatieve opdrachten nakijken en studenten voorzien met feedback op basis van de leerdoelen en een beoordelingsformulier.",
    educationByStudents: "Voor het onderwijs, door studenten",
    educationByStudentsDescription: "Blockbook is ontstaan omdat twee studenten geloven dat nieuwe technologieën meer gebruikt moeten worden in het onderwijs. Met Blockbook worden nieuwe technologieën toegankelijk gemaakt in het onderwijs.",
    allInOneAssistance: "Alles-in-een hulp",
    basedOnCourseMaterial: "Op basis van lesmateriaal",
    madeForEducation: "Gemaakt voor het onderwijs",
    weCollaborateWith: "Wij werken samen met",
    partnersHelpingMission: "Deze partners helpen ons op onze missie om technologie toegankelijk te maken in het onderwijs. Geloof jij ook in de kracht van technologie en help jij ons ook?",
    moreAboutourproduct: "Leer meer over ons product",
    weCollaborateWith: "Wij werken samen met",
    partnersHelpingMission: "Deze partners helpen ons op onze missie om technologie toegankelijk te maken in het onderwijs. Geloof jij ook in de kracht van technologie en help jij ons ook?",
    whatDoesItCost: "Wat kost het",
    costExplanation: "Wij rekenen in studiejaar licenties op basis van het aantal studenten die gebruik gaan maken van de tool. Wij bieden op dit moment twee opties, een gratis optie en een betaalde optie.",
    viewPrices: "Bekijk prijzen",
    mostAdvantageous: "Voordeligst",
    schoolAccount: "School account",
    pricePerStudentPerYear: "€20 per student per jaar",
    aiToolForSchools: "De AI tool gebouwd voor een school.",
    billedAnnually: "Jaarlijks gefactureerd",
    unlimitedTeacherAccounts: "Onbeperkt aantal docentenaccounts",
    customizedForSchool: "Op maat gemaakt voor de school",
    infoSecurityForSchool: "Informatiebeveiliging passend bij de school",
    maximizeYourProductivity: "Maximaliseer jouw productiviteit en geef beter onderwijs!",
    bookADemo: "Boek een demo",
    contactUs: "Contacteer ons",
    jeroenStoryPart1: "In zijn eerste jaar studeren kocht oprichter Jeroen al zijn studieboeken voor meer dan",
    euroAmount1: "€450,-",
    jeroenStoryPart2: "Aan het einde van het jaar wilde Jeroen deze boeken weer verkopen om zo een groot gedeelte van zijn geld terug te verdienen. Jeroen kreeg echter maar slechts",
    euroAmount2: "€70,-",
    jeroenStoryPart3: "terug, waardoor in één jaar zijn studieboeken meer dan",
    euroAmount3: "€350,-",
    jeroenStoryPart4: "hebben gekost.",
    jeroenAction: "Hier ging Jeroen iets aan doen. Hij ging op zoek naar een oplossing om studieboeken makkelijker te kunnen",
    resell: "doorverkopen",
    andKeepMore: "en hier ook meer aan over te houden. Samen met oprichter Menno is toen",
    blockbookOrigin: "Blockbook ontstaan!",
    ourGoal: "Ons",
    goal: "Doel",
    ourGoalDescription: "De ideale online gepersonaliseerde leerervaring voor alle studenten wereldwijd",
    textbooks: "Studieboeken",
    buyYourBooksIntro: "Koop nu jouw",
    saveMoney: "bespaar",
    ourMission: "Onze",
    mission: "Missie",
    ourMissionDescription: "De ideale gepersonaliseerde leerervaring op een volledig geautomatiseerde manier aanbieden door nieuwe",
    technologies: "technologieën",
    toDevelop: "te ontwikkelen",
    ourPrefix: "Ons",
    goalWord: "Doel",
    idealOnline: "De ideale online",
    personalizedLearning: "gepersonaliseerde",
    learningExperience: "leerervaring voor alle",
    studentsWorldwide: "studenten wereldwijd",
    textbooksWord: "Studieboeken",
    buyNowYour: "Koop nu jouw",
    textbooksOnBlockbook: "studieboeken",
    onBlockbookAnd: "op Blockbook en",
    saveWord: "bespaar",
    buyBooksButton: "Koop je boeken",
    ourMissionPrefix: "Onze",
    missionWord: "Missie",
    offerTheIdeal: "De ideale",
    personalizedLearningExperience: "gepersonaliseerde",
    learningExperienceThrough: "leerervaring op een volledig",
    automatedWay: "geautomatiseerde",
    wayByDeveloping: "manier aanbieden door nieuwe",
    technologiesWord: "technologieën",
    toDevelop: "te ontwikkelen",
    blockbookMissionIntro: "Blockbook is op een",
    missionWord: "missie",
    toImproveEducation: "om het onderwijs te verbeteren en heeft hierbij jouw hulp nodig!",
    withBlockbook: "Met Blockbook maken oprichters Jeroen en Menno studieboeken",
    cheaper: "goedkoper",
    faster: "sneller",
    andEasier: "en makkelijker",
    lookingForPassionatePeople: "Wij zijn altijd op zoek naar gepassioneerde mensen die zich willen aansluiten bij de missie van Blockbook!",
    ourGoalPhrase: "Ons",
    goalWord: "Doel",
    idealOnlinePersonalizedLearningExperience: "De ideale online",
    personalized: "gepersonaliseerde",
    learningExperienceForAll: "leerervaring voor alle",
    studentsWorldwide: "studenten wereldwijd",
    ourMissionPhrase: "Onze",
    missionWord: "Missie",
    offerIdealPersonalizedLearning: "De ideale",
    learningExperienceFullyAutomated: "leerervaring op een volledig",
    automated: "geautomatiseerde",
    wayByDevelopingNew: "manier aanbieden door nieuwe",
    technologies: "technologieën",
    whoAre: "Wie zijn ",
    we: "wij",
    blockbookOriginStory: "Blockbook is ontstaan uit de",
    personal: "persoonlijke",
    frustrations: "frustraties",
    ofTwoStudents: "van twee studenten die de uitdagingen van de studieboekenmarkt maar al te goed begrepen. We kennen de",
    pain: "pijn",
    ofHighPrices: "van hoge prijzen en het",
    hassle: "gedoe",
    aroundResellingTextbooks: "rondom het doorverkopen van studieboeken. Daarom hebben we besloten om een",
    bringARevolution: "revolutie",
    inTheWayStudents: "teweeg te brengen in de manier waarop studenten hun studieboeken",
    buy: "kopen",
    andSell: "en verkopen",
    theirTextbooks: ".",
    challengingStatusQuo: "Bij Blockbook geloven we dat het tijd is om de status quo uit te dagen. We hebben een platform gecreëerd dat niet alleen",
    cheaper: "goedkoper",
    butAlsoEasier: "is, maar ook",
    andFaster: "gemakkelijker en sneller",
    weAreHere: "Wij zijn er",
    toElevate: "om het doorverkopen van studieboeken naar een geheel nieuw niveau te tillen, waarbij",
    convenience: "gemak",
    andSavings: "en besparingen",
    goHandInHand: "hand in hand gaan",
    withBlockbookStudentsCan: "Door Blockbook kunnen studenten",
    effortlessly: "moeiteloos",
    offerAndSellTheirDigitalTextbooks: "hun digitale studieboeken aanbieden en deze verkopen aan medestudenten die op zoek zijn naar",
    costEffective: "voordelige",
    options: "opties.",
    joinTheGrowing: "Sluit je aan bij de",
    growing: "groeiende",
    groupOfStudents: "groep studenten die hun boeken bij Blockbook kopen! Ontdek vandaag nog Blockbook en ervaar de",
    future: "toekomst",
    ofEducation: "van het onderwijs.",
    options: "Onze opties",
    places: "Maar een beperkt aantal plekken beschikbaar.",
    pilotUser: "Pilot Gebruiker",
    pricePrefix: "Euro",
    priceFree: "€0",
    month: "",
    pilotTesting: "Pilot testen bij jouw school via Blockbook.",
    createYourAccount: "Maak je account aan",
    signUp: "Meld me aan",
    automatedPracticeMaterial: "Geautomatiseerd oefenmateriaal",
    automatedFeedbackTool: "Geautomatiseerde feedbacktool",
    teacherAccount: "Docentaccount",
    price: "€20",
    perMonth: "per maand",
    aiToolForTeachers: "De AI tool gebouwd voor docenten.",
    monthlyPayment: "Maandelijkse betaling",
    signUp: "Meld me aan",
    automatedTeachingMaterial: "Geautomatiseerd lesmateriaal",
    automatedFeedback: "Geautomatiseerd feedback geven",
    sharingWithStudents: "Delen met studenten",
    mostAdvantageous: "Voordeligst",
    schoolAccount: "School account",
    price: "€20",
    perStudentPerYear: "Per student per jaar",
    aiToolBuiltForASchool: "De AI tool gebouwd voor een school.",
    billedAnnually: "Jaarlijks gefactureerd",
    contactUs: "Contact opnemen",
    unlimitedNumberOfTeacherAccounts: "Onbeperkt aantal docentenaccounts",
    customizedForTheSchool: "Op maat gemaakt voor de school",
    dataSecurityCompliantWithTheSchool: "Informatiebeveiliging passend bij de school",
    loginTitle: "Inloggen",
    noAccountQuestion: "Heb je nog geen account?",
    register: "Registreren",
    student: "Student",
    publisher: "Uitgever",
    teacher: "Leraar",
    usernameOrEmail: "Gebruikersnaam of E-mail",
    password: "Wachtwoord",
    forgotPassword: "Wachtwoord vergeten",
    noAccountQuestionPublisher: "Heeft u nog geen account?",
    companyNameOrEmail: "Bedrijfsnaam of E-mail",
    back: "Terug",
    savedMinutesPart1: "Je hebt ",
    savedMinutesPart2: " minuten bespaard met ",
    blockbook: "Eduface",
    studentsReceived: "Studenten hebben ",
    times: "x ",
    aiFeedback: "AI feedback",
    withYou: "Samen met jou tillen we het ",
    education: "onderwijs",
    toANewLevel: " naar een hoger niveau door ",
    technology: "technologie!",
    deleteModule: "Module verwijderen",
    cancelDelete: "Annuleer verwijderen",

      // Add more translations here...
    },
    // Add more languages here...
  };
  