<template>
    <div class="form">
        
        <slot></slot>
        
        
        <div class="action">
            <BetterButton @click="$emit('submit')" v-if="action=='inloggen'" text="Log in">
                <img src="/assets/key.svg"/>
            </BetterButton>
            <BetterButton @click="$emit('submit')" v-if="action=='registreren'" text="Gratis registreren"></BetterButton>
            <BetterButton @click="$emit('submit')" v-if="action=='registrerenEN'" text="Register for free"></BetterButton>
            <BetterButton @click="$emit('submit')" v-if="action=='verder'" text="Verder"></BetterButton>
            <BetterButton @click="$emit('submit')" v-if="action=='verderEN'" text="Further"></BetterButton>
            <BetterButton @click="$emit('submit')" v-if="action=='personaliseren'" text="Naar dashboard"></BetterButton>
            <BetterButton @click="$emit('submit')" v-if="action=='personaliserenEN'" text="To dashboard"></BetterButton>
            <BetterButton @click="$emit('submit')" v-if="action=='accountAanvragen'" text="Account aanvragen"></BetterButton>
            <BetterButton @click="$emit('submit')" v-if="action=='nieuwWachtwoord'" text="Wachtwoord aanpassen"></BetterButton>
            <BetterButton @click="$emit('submit')" v-if="action=='nieuwWachtwoordEN'" text="Adjust password"></BetterButton>
            <BetterButton @click="$emit('submit')" v-if="action=='wachtwoordAanpassen'" text="Wachtwoord aanpassen"></BetterButton>
            <BetterButton @click="$emit('submit')" v-if="action=='wachtwoordAanpassenEN'" text="Adjust password"></BetterButton>
        </div>
    </div>
</template>
<script>
import BetterButton from "@/components/Widgets/BetterButton.vue"
export default{
    props: ["action"],
    components: {BetterButton},
}
</script>

<style lang="scss">
.form{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    // height: calc(100% - 120px);
    width: 100%;
    padding: 60px 0px;

    .form-content{
        width: 100%;
    }
    .action{
        margin-top: 40px;
    }
}
</style>