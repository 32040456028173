<template>
    <div class="hoe-het-werkt">
      <div class="fixedMenu">
      <header class="header">
        <div v-if="!menuActive" class="hamburger-lines" @click="toggleMenu">
            <span class="line line1"></span>
            <span class="line line2"></span>
            <span class="line line3"></span>
        </div>
        <v-btn v-if="!menuActive && windowWidth < 992"
       flat
       class="rounded-pill border loginBtn3"
       @click="$router.push({ name: 'signup' })">
    Signup
</v-btn>
        <div class="eContainer banner mb-0" :class="{ 'is-active': menuActive }">
          <div class="eRow mt-2 mb-0">
            <div class="col-sm-5 col-xl-2 banner1 mb-0">
              <img
                @click="$router.push({ name: 'Home' })"
                src="/assets/logo.png"
                class="logo"
                alt="logo"
                width="190"
              />
              <img @click="toggleMenu"  v-if="menuActive && windowWidth < 576" src="/assets/cross.svg" width="30" class="cross" />
            </div>
            <!-- <input class="checkbox" type="checkbox" name="" id="" v-model="$store.state.mobileExtend"/> -->
            <div class="col-xl-5 col-sm-7  text-sm-end order-xl-2 mt-lg-2">
              <v-btn
                flat
                class="rounded-pill border text-capitalize loginBtn2 mt-2"
                @click="$router.push({ name: 'signup' })"
                >Signup</v-btn
              >
              <v-btn
                flat
                class="rounded-pill border text-capitalize loginBtn mt-2"
                @click="$router.push({ name: 'log-in' })"
                >Login</v-btn
              >
            </div>
            <div class="col-12 col-xl-5  text-lg-end order-xl-1 mb-0">
              <ul>
                <li>
                  <div @click="$router.push({ name: 'hoe-het-werkt' })" class="links">
                    Hoe werkt het
                  </div>
                </li>
                <li>
                  <div @click="$router.push({ name: 'het-team' })" class="links">Over ons</div>
                </li>
                <li>
                  <v-menu transition="scale-transition">
                    <template v-slot:activator="{ props }">
                      <div class="links dropdown" v-bind="props">Onderwijsassistent</div>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title
                          @click="$router.push({ name: 'assistentpaginaOmar' })"
                          class="assistentpage"
                          >Omar</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title
                          @click="$router.push({ name: 'assistentpaginaMarlou' })"
                          class="assistentpage"
                          >Marlou</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title
                          @click="$router.push({ name: 'assistentpaginaRuben' })"
                          class="assistentpage"
                          >Ruben</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </div>
    <div class="eContainer my-4 pb-5 afterheading">
      <div class="eRow md-my-16">
        <div class="col-md-5">
          <h3 class="text-h4 mt-sm-16 mt-md-16 pt-md-16 font-weight-bold">Hoe werkt het?</h3>
          <p class="mt-5 text-h6">
            Eduface is er om jou te ondersteunen in je dagelijkse taken. Benieuwd naar hoe de tool werkt? Volg de stappen op deze pagina!
          </p>

          <div class="pt-4 ">
            <v-btn class="btn rounded-0 normal-text" @click="$router.push({name:'signup'})">Maak direct een account </v-btn>
          </div>
        </div>
        <div class="col-md-7">
          <div class="mt-5 my-md-0 pt-md-4">
            <v-responsive :aspect-ratio="16 / 9" class="video"
              ><iframe
                class="w-100 h-100"
                src="https://app.supademo.com/embed/clvkyla770ut5769d1gnmtpab"
                allow="clipboard-write"
                frameborder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowfullscreen
              ></iframe>
            </v-responsive>
          </div>
        </div>
      </div>
    </div>
        <!-- <div class="top">
            <div class="top-tekst">

               <div class="header1">

                    <div class="info">
                            <h1>Hoe werkt het</h1>

                            <div>Eduface is er om jou te ondersteunen in je dagelijkse taken. Benieuwd naar hoe de tool werkt? Volg de stappen op deze pagina!</div>
                            
                            <br/>
                            <button @click="sendEmail">Maak direct een account aan</button>
                         
                    </div>
                    <div class="plaatje">
                        <img src="/assets/Image2.png" alt="image" class="Image2" />
                    </div>
               </div>
            </div>
            
        </div> -->
       
        <div class="bottom">
  <h1 class="bottom-tekst">Maak gebruik van jouw AI onderwijsassistent binnen een paar stappen!</h1>
  <div class="plaatjes">
    <div class="teamlid1">
      <div class="col-md-7 video-container">
        <v-responsive :aspect-ratio="16 / 9" class="video">
          <iframe
            class="w-100 h-100"
            src="https://app.supademo.com/embed/clw4rr3yw285ckzem493wzgum"
            allow="clipboard-write"
            frameborder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowfullscreen
          ></iframe>
        </v-responsive>
      </div>
      <div class="cijfer">1</div>
      <div class="informatie">
        <h2 class="left">Maak een gratis account aan</h2> <br/>
        <div class="left"> Maak een gratis account aan bij ons om van start te gaan met jouw onderwijsassistent. </div>
      </div>
    </div>
    <div class="teamlid">
      <div class="informatie">
        <h2 class="right">Upload een beoordelingsformulier</h2> <br/>
        <div class="right"> Upload het beoordelingsformulier dat past bij de opdracht van jouw vak. Dit beoordelingsformulier gebruikt de assistent om de opdracht na te kijken. </div>
      </div>
      <div class="cijfer">2</div>
      <div class="col-md-7 video-container">
        <v-responsive :aspect-ratio="16 / 9" class="video">
          <iframe
            class="w-100 h-100"
            src="https://app.supademo.com/embed/clw4rr3yw285ckzem493wzgum"
            allow="clipboard-write"
            frameborder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowfullscreen
          ></iframe>
        </v-responsive>
      </div>
    </div>
    <div class="teamlid1">
      <div class="col-md-7 video-container">
        <v-responsive :aspect-ratio="16 / 9" class="video">
          <iframe
            class="w-100 h-100"
            src="https://app.supademo.com/embed/clw4rr3yw285ckzem493wzgum"
            allow="clipboard-write"
            frameborder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowfullscreen
          ></iframe>
        </v-responsive>
      </div>
      <div class="cijfer">3</div>
      <div class="informatie">
        <h2 class="left">Upload een opdracht</h2> <br/>
        <div class="left"> Vervolgens kun je de opdracht van de student uploaden en op genereer feedback drukken. Na het genereren wordt de studentenopdracht verwijderd om de privacy te waarborgen in overeenstemming met de AVG. Het beoordelingsformulier bewaren we, zodat je het opnieuw kunt gebruiken! </div>
      </div>
    </div>
    <div class="teamlid">
      <div class="informatie">
        <h2 class="right">Deel feedback met de student</h2> <br/>
        <div class="right"> Binnen 1-2 minuten heeft de AI assistent de opdracht gelezen en feedback gegeven. Je kan de feedback openen en indien nodig aanpassen. Zodra de feedback klaar is, kun je deze exporteren en naar de student sturen. </div>
      </div>
      <div class="cijfer">4</div>
      <div class="col-md-7 video-container">
        <v-responsive :aspect-ratio="16 / 9" class="video">
          <iframe
            class="w-100 h-100"
            src="https://app.supademo.com/embed/clw4rr3yw285ckzem493wzgum"
            allow="clipboard-write"
            frameborder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowfullscreen
          ></iframe>
        </v-responsive>
      </div>
    </div>
  </div>
</div>

        
    </div>
    <Footer1></Footer1>
    
</template>
<script>
import NavItem from '@/components/Dashboard/NavItem.vue'
import Footer1 from "@/components/Frontpage/Footer2.vue";
export default{
    components: {NavItem, Footer1},
    data () {
        return{
            menuActive: false,
    windowWidth: window.innerWidth,
        }

    },
    methods: {
        sendEmail() {
    const emailAddress = 'Jeroen.van.gessel@blockbook.nl';
    const subject = 'Contact';
    const body = 'Hier kun je vragen stellen of informatie sturen om met ons in contact te komen.';

    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  },
  handleResize() {
        this.windowWidth = window.innerWidth;
    },
    toggleMenu() {
        this.menuActive = !this.menuActive;
        console.log(this.menuActive)
    },
    },
    mounted() {
      document.title = "Eduface";  
      window.addEventListener('resize', this.handleResize);
      },

beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
},


}

</script>
<style>
.v-slide-group__container {
  display: block !important;
  width: 100%;
  text-align: center;
}
.v-slide-group__content {
  display: inline-block !important;
  margin: auto !important;
  background-color: #f2f2f2;
  border-radius: 45px;
  padding: 5px;
  height: 48px !important;
}
.v-slide-group__content button {
  border-radius: 45px !important;
  height: 38px !important;
}
.v-tab__slider {
  display: none;
}
.v-slide-group__content .v-btn {
  background-color: #f2f2f2 !important;
  color: #000 !important;
}
.v-btn.v-slide-group-item--active {
  background-color: #fff !important;
}
button.v-expansion-panel-title {
  background-color: #fff !important;
  color: #000 !important;
  padding: 0 0 0 15px !important;
  margin: 0 !important;
  display: block;
  width: calc(100% - 15px);
  min-height: 38px !important;
  font-weight: bold;
  font-size: 18px !important;
  line-height: 2;
}
v-expansion-panels {
  padding: 0 !important;
}
.v-expansion-panel {
  border-radius: 15px !important;
}

.v-expansion-panels--variant-inset > .v-expansion-panel--active {
  max-width: 100% !important;
}
/* header css */
.fixedMenu {
  padding: 36px 0 !important;
  margin-bottom: 60px !important;
}
.fixedMenu .header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  padding: 5px 0;
  position: fixed;
}
.header {
  height: auto !important;
}
.header ul {
  list-style: none;
  margin-top: 10px;
  margin-bottom: 0;
}
.header ul li {
  display: inline-block;
  padding: 5px;
}
.header ul li .links,
.dropdownBtn button {
  display: block;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 18px !important;
  font-weight: bold !important;
  margin: 0;
}
.header .v-btn__overlay {
  background-color: transparent !important;
}
.header .v-btn__content {
  padding: 5px 10px;
  cursor: pointer;
  font-size: 18px !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
}
.loginBtn2,
.loginBtn {
  letter-spacing: normal !important;
}
.dropdown {
  position: relative;
  padding-right: 20px !important;
}
.dropdown:after {
  position: absolute;
  content: '❮';
  right: 0;
  font-size: 16px;
  transform: rotate(-90deg);
  color: #414042 !important;
}
@media (max-width: 576px) {
  .logo {
    width: 150px;
  }
  .logo1{
    width: 400px;
  }
  .loginBtn2,
  .loginBtn {
    padding: 0px 5px !important;
    font-size: 10px !important;
  }
}
/* header css end */
.afterheading{
    margin-top: 60px !important;
}
</style>
<style lang="scss">
.assistentpage{
  cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
    
}
.logo{
  cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
}
.banner{
  margin-bottom: 10px;



  .banner1{
    display: flex;
    align-items: center;

    .overons{
      align-items: center;
      justify-content: center;
      margin-left: 150px;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
    }
    .hoehetwerkt{
      align-items: center;
      justify-content: center;
      margin-left: 170px;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
    }
  }
}
@media (max-width: 1100px) {
    .hoe-het-werkt {
      .bottom {
        .cijfer {
          margin-bottom: 10px !important;
        }
        .teamlid1 {
          display: flex;
          flex-direction: column-reverse; /* Reverse the column direction */
          margin-left: 50px;

          .informatie {
            width: 80%;
            text-align: center;
          }
        }
        .teamlid {
          display: flex;
          flex-direction: column; /* Reverse the column direction */
          margin-left: 50px;

          .informatie {
            width: 80%;
            text-align: center;
          }
        }
      }
    }
  }

@media (max-width: 767px) {
	.overons,.hoehetwerkt  {
    margin-left: 15px !important;
        font-size: 14px !important;
        line-height: 1;
  }

  .logo{

    width: 150px;
        height: auto;
  }
  .hoe-het-werkt{
    .bottom{
        .cijfer{
          margin-bottom: 10px !important;
        }
        .teamlid{
          display: flex;
          flex-direction: column;
            margin-left: 50px;
        
        .informatie{
            width: 80%  ;
        }
        }
        .teamlid1 {
          display: flex;
          flex-direction: column-reverse; /* Reverse the column direction */
          margin-left: 50px;

          .informatie {
            width: 80%;
            text-align: center;
          }
        }

    }
  }

}
.fixedMenu {
	padding: 36px 0 !important;
	margin-bottom: 60px !important;
	
  }
  .fixedMenu .header {
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	background: #fff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
	padding: 5px 0;
	position: fixed;
  }
    span {
  font-family: 'League Spartan', sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #414042;
}
@media (max-width: 576px) {
  .edHome {
    margin: 0 15px;
  }
}

button {
  margin-top: 0 !important;
}
.mainHeading {
  letter-spacing: 2px;
}
.btn {
  background-color: #00e075 !important;
  box-shadow: none;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 16px;
  padding: 15px 25px;
  height: auto !important;
  color: #000 !important;
}
.loginBtn {
  background: transparent !important;
  color: #000 !important;
}
.loginBtn2 {

color: #fff !important;
background-color: 00e075;
margin-right: 10px;


}
.loginBtn3 {

color: #fff !important;
background-color: 00e075;
margin-right: 80px;
margin-top: 60px !important;


}
.btn:hover {
  box-shadow: none;
}
.hoe-het-werkt{
    width: 100%;
    // padding: 0 40px;
    .left-aligned-text {
    text-align: left;  /* Ensures text inside the div is aligned to the left */
}
    .button {
        display: inline-block;
        padding: 8px 16px;
        margin: 4px 2px;
        cursor: pointer;
        text-align: center;
        text-decoration: none;
        color: $secondary-color;
        background-color: $base-color;
        border: none;
        border-radius: 8px;
        font-family: Arial, sans-serif;
    }

    .button:hover {
        background-color: #005582;
    }


    .presentatie{
        
        width: 700px;
        margin: 0px 30px;
    }
    .top-picture{

        width: 50%;
        height: 50%;
    }
    .header1 {
    display: flex;

    justify-content: center;
    align-items: center;
    width: 100%; /* Adjust if necessary */
    height: 60vh; /* Set the height of the header to the full viewport height */
    background-color: #f6f2f7;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.1);

    .info{
        margin-left: 40px;
        width: 40%
    }
    .plaatje{
        margin-left: 400px;
        width: 50%;
    }
}

.top {
    margin-top: 40px;
    height: 60vh; /* Set the height of the top container to the full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
}
.normal-text {
    text-transform: none !important;
}

.top-tekst {
    width: 95%; /* Adjust if necessary */
}
.bottom {
  margin-top: 40px;
  height: 100%;

  .bottom-tekst {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .plaatjes {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .teamlid {
    display: flex;

    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    width: 100%;
  }
  .teamlid1 {
    display: flex;

    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    width: 100%;
  }

  .cijfer {
    font-size: 50px;
    color: #00aaff;
    margin-bottom: 20px;
    font-weight: bold;
    font-family: 'Dela Gothic One', sans-serif;
    margin-right: 5px;
    margin-left: 5px;
  }

  .video-container {
    width: 100%;
    max-width: 800px;
  }
  .informatie1 {
    width: 80%;
    text-align: left;
    margin-top: 20px;

    .left{
      text-align: left;
      width: 80%;
    }
    .right{
      text-align: right;
      width: 80%;
    }

  }
  .informatie {
    width: 80%;
    text-align: right;
    margin-top: 20px;

    .left{
      text-align: left;

    }
    .right{
      text-align: right;

    }

  }
}

    .doel{
        height: 600px;
        width: 50%;
        font-size: 40px;
        color: $base-color;

        .oranje{
            font-weight: bold;
            color: $secondary-color;
        }
    }
    .small{
        font-size: 20px;
        color: $base-color;
        margin-top: 50px;

        .oranje{
            font-weight: bold;
            color: $secondary-color;
        }
    }
    .missie{
        height: 600px;
        margin: 40px 80px;
        font-size: 40px;
        color: $base-color;
        width: 50%;

        .oranje{
            font-weight: bold;
            color: $secondary-color;
        }
    }
    .big{
        margin-top: 80px;
    }

    .no-top-margin{
        margin-top: 0px;
    }
    .webshop-but{
        margin-top: 10px;
        color: white;
        background-color: $secondary-color;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        font-weight: bold;
        padding: 10px 15px;
        border-radius: 20px;
        margin-right: 5px;
        width: auto;
        cursor: pointer;
        &:hover{
            opacity: 0.5;
            
        }
    }
    .webshop-link{
            display: flex;
            justify-content: flex-end;
            width: 5px;
            height: 20px;
            color: $base-color;
            margin-top: 50px;

        }



    .pizza{
        width: 70%;
    }
    .profiel-info{
        height: 40px;
        width: 600px;
    }
    .picture-profiel{
        margin-top: 60px;
        height: 400px;
        width: 600px;
    }

    @media (max-width: 1100px){

        .top{
            flex-direction: column;

            .top-tekst{
                width: 100%;

                .big-tekst{
                    margin-top: 40px;
                    font-size: 30px;
                }

                .small-tekst{
                    font-size: 15px;
                }
            }

            .top-picture{
                display: flex;
                justify-content: center;
                width: 100%;
                margin-top: 40px;
            }
        }

        .bottom{
            flex-direction: column;

            .big{
                font-size: 30px;
                margin-top: 8px;
            }

            .small{
                font-size: 15px;
            }

            .doel{
                width: 100%;
                height: auto;
            }

            .missie{
                margin: 0;
                width: 100%;

                .personal-info{
                    margin-top: 20px;
                    .profiel{
                        width: 100%;
                        .profiel-info{
                            width: 100%;
                            img{
                                margin-top: 0px;
                                width: 100%;
                                height: calc(78vw - 160px);
                            }
                        }
                    }
                }
            }


            .small{
                margin-top: 0px;
            }

            .webshop-but{
                padding: 10px 15px;
                font-size: 16px;
            }

        }
    }

    @media (max-width: 500px){
        .bottom{
            .missie{
                .personal-info{
                    .profiel{
                        .profiel-info{
                            img{
                                width: 100%;
                                height: calc(74vw - 40px);
                            }
                        }
                    }
                }
            }
        }

        
    }
}

@media (max-width: 1100px){
    .wiezijnwij{
        padding: 0px 80px;
        width: calc(100% - 160px);
    }

    
}

@media (max-width: 500px){
    .wiezijnwij{
        padding: 0px 20px;
        width: calc(100% - 40px);
    }

    
}

// @media (max-width: 900px){

// }

</style>