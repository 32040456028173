<template>
    <div class="data-page">
        <Sidebar mode="teacher" active="dashboard" :assistent="assistent"></Sidebar>
        <div class="content" :class="{'mobile-extend': toggledSidebar}">
            <DashboardHeader mode="uitgever" :hideSearch="true"></DashboardHeader>
            <div class="scrollable-content">
                <h1>{{ this.$store.state.onderwijsassistent }} zijn laatste activiteit</h1>
                <div class="feedback-data">
                    <div class="minuten">
                        
                        <div class="bespaard">Feedback requests</div>
                        <div class="requests" v-for="(item, index) in filteredItems" :key="item.logboek_id">
                            <p>-{{ item.titel }}, {{ item.student }}, {{ item.date }}</p>
                            </div>
                    </div>
                    <div class="feedback">
                        
                        <div class="bespaard">{{ this.$store.state.onderwijsassistent }} versie {{ this.versie }}</div>
                        <div class="requests" v-for="(item, index) in this.updates" :key="item.id">
                        <p>-{{ item.update }}</p>
                        </div>
                    </div>
                                     
                </div>
                <div class="algemene-data">
                    <div class="minuten">
                        
                        <div class="bespaard">Laatste taken die ik heb uitgevoerd</div>
                        <div class="requests" v-for="(item, index) in limitedLogboek" :key="item.logboek_id">
                        <p>-{{ item.type_tool }}: {{ item.titel }}, {{ item.student }}, {{ item.date }}</p>
                        </div>
                    </div>
                    <div class="feedback">
                        
                        <div class="bespaard">Laatst actieve studenten</div>
                        <div class="requests" v-for="(item, index) in filteredStudentItems" :key="item.logboek_id">
                        <p>-{{ item.student }}, {{ item.date }}</p>
                        </div>
                    </div>
                                     
                </div>
            </div>
            
            
           

            <div>
                
            </div>
            
            
            
        </div>
    </div>
</template>
<script>
import Sidebar from '@/components/Dashboard/SidebarLeraar.vue'
import DashboardHeader from '@/components/Dashboard/DashboardHeader.vue'
import DashboardHeading from '@/components/Dashboard/DashboardHeading.vue'
export default{
    data(){
        return {
            options: {
                talen: {
                    "Engels": "en",
                    "Nederlands": "nl",
                   
                },
                scholen: {
                    "MBO": "mbo",
                    "HBO": "hbo",
                    "Universitair": "uni",
                   
                },
               
            },
            versie: "1.0.2",
            selectedOption: '',
            opties: ["Collega's", "Google", "LinkedIn", "Instagram", "Anders"],
            selectedIndex: null,    
            selectedOptions: [],
            updates: [
                { id: 1, update: this.$store.state.onderwijsassistent+" kan casussen en rubrieken maken d.m.v. personalised learning in het nieuwe opdrachten systeem." },
                { id: 2, update: "Binnenkort komt Rob bij ons werken en " + this.$store.state.onderwijsassistent + " ondersteunen." },
                { id: 3, update: "Krijg extra inzicht in uw studenten en kom erachter welke overeenkomende fouten ze maken." },
                { id: 3, update: this.$store.state.onderwijsassistent+" kan personalised learning uitvoeren." },

            ],

        }
    },
    mounted() {
        document.title = "Eduface | Vak dasboard";    },
    components: {Sidebar, DashboardHeader, DashboardHeading},
     computed: {
        limitedLogboek() {
      // Limit the array to the first 5 items
      return this.logboek.slice(-5);
    },
        filteredItems() {
      // Filter items with non-empty 'student' and get the last 3
      const filtered = this.logboek.filter(item => item.student !== "");
      return filtered.slice(-3);  // Get the last 3 items
    },
    filteredStudentItems() {
        return this.logboek_studenten.slice(-5);
    },
    logboek(){
        return this.$store.state.logboek_leraar
    },
        toggledSidebar(){
            return this.$store.state.mobileExtend
        },
        informatie_docent(){
            return this.$store.state.informatie_docent
        },
        logboek_studenten(){
        return this.$store.state.logboek_studenten
    },
     },
    methods: {
        helloWorld(){
            console.log("test")
        },
    },
    async created(){
        await this.$store.dispatch("informatie_docent")
        await this.$store.dispatch("get_logboek_leraar", this.$route.params.id)
        await this.$store.dispatch("get_logboek_studenten", this.$route.params.id)
    }
}

</script>

<style lang="scss">
.data-page{
    background-color: #F4F6FC;
    min-height: 100vh;
    .content{

        justify-content: space-between;
        position: absolute;
        width: calc(100% - 300px);
        top: 0px;
        left: 200px;
        padding: 0px 50px;
        overflow-x: hidden;
        .books{
            display: flex;
            
            .boek-plus{
                margin: 5px;

                &:first-child{
                    margin-left: 0px;
                }

                
            }
        }
        .onderwijs{
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .technologie{
            
            background-color: #eeeff8;
            width: 700px;
            height: 150px;
            margin-left: 150px;
            margin-top: 50px;
            border-radius: 4px;
            text-align: center;
            font-weight: bold;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            /* Horizontally center the content */
       

            .oranje{
            color: $secondary-color;
        }
            .blauw{
                color: $base-color;
            }
        }
        }
        
        .feedback-data{
            display: flex;
            width: 100%;
            margin-left: 10px;
            font-weight: bold;
            border-radius: 4px;
            font-size: 20px;
            flex-wrap: wrap;
            

            .oranje{
                color: $secondary-color;
            }
            .blauw{
                color: $base-color;
            }
            

            .minuten{
                display: flex;
                 
                flex-direction: column;
                
                border: 1px solid #000;
                background-color: #eeeff8;
                width: 400px;
                height: 220px;
                margin-right: 50px;
                border-radius: 10px;
                margin-bottom: 10px;
        
                .bespaard{
                    margin-top: 10px;
                    margin-left: 10px;
                }
                .requests{
                    font-size: 15px;
                    margin-top: 10px;
                    margin-left: 10px;
                }
                img{
                    margin-left: 25px;
                    width: 250px;
                }
            }
            .feedback{
                display: flex;
                border: 1px solid #000;
                flex-direction: column;
               
                
                background-color: #eeeff8;
                width: 450px;
                height: 220px;
                border-radius: 10px;
                margin-right: 200px;
               
                .bespaard{
                    margin-top: 10px;
                    margin-left: 10px;
                }
                .requests{
                    font-size: 15px;
                    margin-top: 10px;
                    margin-left: 10px;
                }
                img{
                    margin-left: 25px;
                    width: 215px;
                }
            }
        }
        .algemene-data{
            margin-top: 30px;
            display: flex;
            width: 100%;
            margin-left: 10px;
            font-weight: bold;
            border-radius: 4px;
            font-size: 20px;
           
            display: flex;
            

            .oranje{
                color: $secondary-color;
            }
            .blauw{
                color: $base-color;
            }
            

            .minuten{
                border: 1px solid #000;
                display: flex;
                 
                flex-direction: column;
                
                
                background-color: #eeeff8;
                width: 500px;
                height: 100%;
                margin-right: 50px;
                border-radius: 10px;
                margin-bottom: 10px;
        
                .bespaard{
                    margin-top: 10px;
                    margin-left: 10px;
                }
                .requests{
                    font-size: 15px;
                    margin-top: 10px;
                    margin-left: 10px;
                }
                img{
                    margin-left: 25px;
                    width: 250px;
                }
            }
            .feedback{
                border: 1px solid #000;
                display: flex;

                flex-direction: column;
               
                
                background-color: #eeeff8;
                width: 350px;
                height: 100%;
                border-radius: 10px;
                margin-right: 200px;
               
                .bespaard{
                    margin-top: 10px;
                    margin-left: 10px;
                }
                .requests{
                    font-size: 15px;
                    margin-top: 10px;
                    margin-left: 10px;
                }
                img{
                    margin-left: 25px;
                    width: 215px;
                }
            }
        }

        .recent-books{
            position: relative;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            margin-bottom: 80px;

            .recent-book-container{
                width: 400px;
                margin-right: 20px;

                &:last-child{
                    width: 400px;
                    margin-right: 0px;
                }

                .horizontal{
                    justify-content: space-between;
                }
            }
        }
        .omzet{
            height: 50%;
            width: 50%;
        }
        .verkoop{
            height: 50%;
            width: 50%;
        }
        .big-informatie{
            font-size: 25px;
            .bold{
                font-weight: bold;
            }
        }
        .small-informatie{
            font-size: 15px;
            margin-bottom: 10px;
            .bold{
                font-weight: bold;
            }
        }
    }
}
@media (max-width: 900px){
    .data-page{
        .content{
            left: 0px;
            width: calc(100% - 100px);
            &.mobile-extend{
                display: block;
                left: 200px;
                width: calc(100% - 300px);

                
            }
            .feedback-data{
            display: flex;
            width: 100%;
            margin-left: 10px;
            font-weight: bold;
            border-radius: 4px;
            font-size: 20px;
            flex-wrap: wrap;
            

            .oranje{
                color: $secondary-color;
            }
            .blauw{
                color: $base-color;
            }
            

            .minuten{
                display: flex;
                 
                flex-direction: column;
                
                border: 1px solid #000;
                background-color: #eeeff8;
                width: 400px;
                height: 100%;
                margin-right: 50px;
                border-radius: 10px;
        
                .bespaard{
                    margin-top: 10px;
                    margin-left: 10px;
                }
                .requests{
                    font-size: 15px;
                    margin-top: 10px;
                    margin-left: 10px;
                }
                img{
                    margin-left: 25px;
                    width: 250px;
                }
            }
            .feedback{
                display: flex;
                border: 1px solid #000;
                flex-direction: column;
               
                
                background-color: #eeeff8;
                width: 450px;
                height: 100%;
                border-radius: 10px;
                margin-right: 200px;
               
                .bespaard{
                    margin-top: 10px;
                    margin-left: 10px;
                }
                .requests{
                    font-size: 15px;
                    margin-top: 10px;
                    margin-left: 10px;
                }
                img{
                    margin-left: 25px;
                    width: 215px;
                }
            }
        }
        }
    }
        
    }
@media (max-width: 600px){
    .teacher-page{
        .content{
            padding: 0px 10px;
            width: calc(100% - 20px);
            
            &.mobile-extend{
                left: calc(100% - 70px);
                width: 70px;
                .scrollable-content{
                    display: none;
                }
            }

            .recent-books{
                margin-bottom: 50px;
                .boek-plus{
                    margin: 5px;

                    &:first-child{
                        margin-left: 0px;
                    }

                    
                }
            }

            .feedback-data{
                margin-left: 20px;
            }
            .onderwijs{
                .technologie{
                margin-left: 20px;
            }
            }
            
        }
    }
        
    }

</style>