<template>
    <div class="module-oefententamen">
        <div class="module-aanpassen">
            <div class="terug-knop">
                <NavItem v-if="$store.state.role=='student'"  href="modules-student" class="goback" :inverted="true" >
                    <img src="/assets/arrowback_blauw.svg" />
                    </NavItem> 
                    <NavItem v-if="$store.state.role === 'leraar' && $i18n.locale === 'nl'" @click="navigateToFeedbackOverview" class="goback" :inverted="true">
    <img src="/assets/arrowback_blauw.svg" />
</NavItem>
                    <NavItem v-if="$store.state.role=='leraar' && $i18n.locale === 'en'"  href="modules-teacher" class="goback" :inverted="true" >
                    <img src="/assets/arrowback_blauw.svg" />
                    </NavItem>
                    <div v-if="eindtoets[0].taal=='nl'" class="tekst">Terug naar dashboard</div>
                    <div v-if="eindtoets[0].taal=='en'" class="tekst">Back to dashboard</div>
            </div>
            <button v-if="$store.state.role=='leraar' && !bewerkModus && eindtoets[0].taal=='nl'" class="aanpassen" @click="bewerken()">Oefententamen aanpassen</button>
                <button v-if="$store.state.role=='leraar' && bewerkModus && eindtoets[0].taal=='nl'" class="aanpassen" @click="annuleerBewerken()">Aanpassing annuleren</button>
                <button v-if="$store.state.role=='leraar' && bewerkModus && eindtoets[0].taal=='nl'" class="aanpassen" @click="slaOpBewerken()">Aanpassing opslaan</button>
                <button v-if="$store.state.role=='leraar' && !bewerkModus && eindtoets[0].taal=='en'" class="aanpassen" @click="bewerken()">Adjust practice exam</button>
                <button v-if="$store.state.role=='leraar' && bewerkModus && eindtoets[0].taal=='en'" class="aanpassen" @click="annuleerBewerken()">Cancel adjusting</button>
                <button v-if="$store.state.role=='leraar' && bewerkModus && eindtoets[0].taal=='en'" class="aanpassen" @click="slaOpBewerken()">Save adjustment</button>
        </div>
        
        <!-- <div class="pdf">
            <div>
                
                <label for="epub_file" class="btn">Select pdf</label>
                <input id="epub_file" type="file" accept=".pdf" @change="onEpubPicked" ref="pdfInput" />
            </div>
            <div @click="submit_formulier()" v-if="epub_attached" class="bevestigen">Bevestigen</div>
        
        </div> -->
        <div class="overview">
            
            <ZijMenu :id="this.$route.params.id" :vak="this.$route.params.vak" :taal="eindtoets[0].taal"></ZijMenu>
        
            <div class="mainBody">
                <div class="contentBody shadow p-5">
                    
                    <div v-if="!bewerkModus" class="container">
                        <div v-if="!bewerkModus" class="module-blauw"> <img src="/assets/moduleblauw.png"  width="300" /></div>
                        <div class="logo-hoedje" v-if="!bewerkModus">
                            <img src="/assets/Blockbookhoedje.png" alt="blockbook" width="70" />

                        </div>
                        <div class="blockbook-vragen">
                        <div class="blockbook-vraag" v-if="!bewerkModus">
                      <div class="opdracht opdracht1">
                          <div class="vraag-view">
                            
                            <p class="vraagvan">Vraag {{ this.qeustionCounter }}   van {{ eindtoets.length }}</p>
                              <h2 class="vragen">
                                  <!-- <p v-if="opdrachtIndex === 0" style="display: inline">{{ questionIndex + 1 }}.</p> -->
                                   {{ currentOpdrachtIndex + 1 }}.{{ currentQuestionIndex + 1 }}: {{ currentQuestion.vraag }}
                              </h2>
                              <div v-if="currentQuestion.antwoordopties && currentQuestion.type === 'meerkeuze'">
                                  <div class="select" v-if="eindtoets[0].taal=='nl'">
                                      <span v-if="getNumCorrectAnswers(currentQuestion) > 1"><i>Selecteer </i><b>alle</b><i> correcte antwoorden</i></span>
                                      <span v-else>Selecteer 1 correct antwoord</span>
                                  </div>
                                  <div class="select" v-if="eindtoets[0].taal=='en'">
                                      <span v-if="getNumCorrectAnswers(currentQuestion) > 1"><i>Select </i><b>all</b><i> correct answers</i></span>
                                      <span v-else>Select 1 correct answer</span>
                                  </div>
                                 
                                  
                                    <!-- <button v-if="currentQuestionIndex  > 0" class="previous">&lt</button> -->
                                    <div class="antwoordopties" v-for="(option, index) in currentQuestion.antwoordopties" :key="index">
                                        
                                      <label>
                                          <input v-if="getNumCorrectAnswers(currentQuestion) == 1" type="radio" v-model="meerkeuzevraag" :value="option" :name="'question_' + currentOpdrachtIndex + '_' + currentQuestionIndex">
                                          <input v-else type="checkbox" v-model="selectedOptions[index]" :name="'question_' + currentQuestionIndex">
                                          {{convert(index) + ') ' + option.antwoordoptie }}
                                      </label>
                                  </div>
                                  <!-- <button v-if="currentQuestionIndex < currentOpdracht.questions.length - 1" class="next">></button> -->
                                  
                                  
                              </div>
                              <div v-else>
                                  <textarea v-model="openvraag" rows="3" cols="50" :placeholder="'Plaats hier je antwoord voor vraag hierboven ' " class="custom-textarea"></textarea>
                              </div>
                          </div>
                          <div class="verder">
                            <button  class="verder1" v-if="!answersRevealed && meerkeuzevraag!=null || !answersRevealed && openvraag!='' || !answersRevealed && optieselected" @click="toggleAnswersRevealed()">
                              {{ $t('verder') }}
                            </button>
                            <button  class="verder2" v-if=" meerkeuzevraag==null &&  openvraag=='' && !optieselected ">
                              {{ $t('verder') }}
                            </button>
                          </div>
                          
                         
                          
                      
                      </div>
                      
                     
                     
                     
                      <div class="answerRevealed" v-if="answersRevealed">
                        <div v-for="(opdracht, opdrachtIndex) in groupedQuestions" :key="opdrachtIndex">
                          <div v-for="(question, questionIndex) in opdracht.questions" :key="questionIndex">
                            <!-- Check if the current question matches the revealed question -->
                            <div v-if="currentOpdrachtIndex === opdrachtIndex && currentQuestionIndex === questionIndex">
                             
                              <p>
                                <div v-if="eindtoets[0].taal=='nl'">
                                  <strong v-if="getNumCorrectAnswers(currentQuestion) == 0">Geen correct antwoord</strong>
                                  <strong v-else-if="getNumCorrectAnswers(currentQuestion) == 1">Correcte antwoord: </strong>
                                  <strong v-else>Correcte Antwoorden: </strong>
                                </div>
                                <div v-if="eindtoets[0].taal=='en'">
                                  <strong v-if="getNumCorrectAnswers(currentQuestion) == 0">No correct answer</strong>
                                  <strong v-else-if="getNumCorrectAnswers(currentQuestion) == 1">Correct answer: </strong>
                                  <strong v-else>Correct answers: </strong>
                                </div>
                                <ul v-if="getNumCorrectAnswers(currentQuestion) > 1">
                                  <li v-for="(optie, index) in question.antwoordopties.map((optie, index) => [(question.type == 'meerkeuze' ? convert(index) + ') ' : '') + optie.antwoordoptie, optie.correct]).filter(optie => optie[1] == 1)" :key="index">{{ optie[0] }}</li>
                                </ul>
                                <span v-else>{{ question.antwoordopties.map((optie, index) => [(question.type == 'meerkeuze' ? convert(index) + ') ' : '') + optie.antwoordoptie, optie.correct]).filter(optie => optie[1] == 1)[0][0] }}</span>
                              </p>
                              <div v-if="question.explanation!=''">
                                Explanation: {{ question.explanation }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="buttonss">
                            <button class="previous" @click="previousQuestion()" v-if="currentQuestionIndex  > 0">
                                &lt
                            </button>
                            
                            <button class="previous" @click="previousOpdracht()" v-if="currentQuestionIndex === 0 && currentOpdrachtIndex > 0">
                                &lt
                            </button>
                            
                            <!-- <button @click="checkAntwoorden">
                              check
                            </button> -->
                            <button class="next" @click="nextQuestion()" v-if="currentQuestionIndex < currentOpdracht.questions.length - 1">
                            >
                            </button>
                            

                            <button class="next" @click="nextOpdracht()" v-if="currentQuestionIndex === currentOpdracht.questions.length - 1 && currentOpdrachtIndex < groupedQuestions.length - 1">
                           >
                            </button>
                      </div>
                      
                  </div>
                    </div>
                    <div v-if="!bewerkModus" class="module-oranje"> <img src="/assets/moduleoranjeblauw.png"  width="300" /></div>
                    <button v-if="$i18n.locale === 'nl'" @click="exporteerVragen" class="exporteer">Exporteer vragen</button>
                    <button v-if="$i18n.locale === 'en'" @click="exporteerVragenEN" class="exporteer">Export questions</button>
                    </div>
                    
                    
                    
                    <div v-if="bewerkModus && $i18n.locale === 'en'">
                        <div class="opdracht" v-for="(opdracht, opdrachtIndex) in groupedQuestions" :key="opdrachtIndex">
                            <div class="input-opdracht-details">
                                <Input
                                    :title="`Opdracht ${opdrachtIndex+1}: Title`"
                                    placeholder="Type your title here of your assignment or leave this empty..."
                                    class="vragen input-opdracht"
                                    v-model="opdracht.questions[0].opdracht"
                                ></Input>

                                <div v-if="groupedQuestions.length != 1 && !isMobile" class="delete-button" @click="verwijderOpdracht(opdrachtIndex)">
                                    <img src="/assets/trash-o.svg">
                                </div>
                            </div>
                            
                            <div class="alle-vragen">
                                <div class="vraag" v-for="(question, questionIndex) in opdracht.questions" :key="questionIndex">
                                    <div class="vragen input-vraag-details">
                                        <!-- <p v-if="opdrachtIndex === 0" style="display: inline">{{ questionIndex + 1 }}.</p> -->
                                        <Input
                                            :title="`Question ${opdrachtIndex+1}.${questionIndex+1}: Title`"
                                            placeholder="Type the title of your question..."
                                            class="vragen input-vraag"
                                            v-model="question.vraag"
                                        ></Input>
                                        <div v-if="opdracht.questions.length != 1 && isMobile" class="delete-button" @click="verwijderVraag(opdracht, question)">
                                            <img src="/assets/trash-o.svg">
                                        </div>
                                        <Input
                                            :title="`Vraagtype`"
                                            placeholder="Select a question type..."
                                            class="vragen input-vraag-type"
                                            type="select"
                                            :options="options.vraagTypesEN"
                                            :SelectOptionValue="true"
                                            v-model="question.type"
                                        ></Input>
                                        <div v-if="opdracht.questions.length != 1 && !isMobile" class="delete-button" @click="verwijderVraag(opdracht, question)">
                                            <img src="/assets/trash-o.svg">
                                        </div>
                                    </div>
                                    <div class="antwoord-opties">
                                        <div v-for="(option, index) in question.antwoordopties" :key="index">
                                            <div class="input-antwoord-optie-details">
                                                <Input
                                                    :title="`Question ${opdrachtIndex+1}.${questionIndex+1}: ${question.type == 'meerkeuze' ? 'Answeroption ' + convert(index) : 'Correct Answer ' + (index + 1)} `"
                                                    placeholder="Type your answeroption..."
                                                    class="vragen input-antwoord-optie"
                                                    v-model="option.antwoordoptie"
                                                ></Input>
                                                <div v-if="question.antwoordopties.length != 1 && isMobile" class="delete-button" @click="verwijderAntwoordOptie(question, index)">
                                                    <img src="/assets/trash-o.svg">
                                                </div>
                                                <Input
                                                    v-if="question.type == 'meerkeuze'"
                                                    :title="`Correctness`"
                                                    placeholder="Select a correctness..."
                                                    class="vragen input-antwoord-optie-correctheid"
                                                    type="select"
                                                    :options="options.vraagCorrectheid"
                                                    :SelectOptionValue="true"
                                                    v-model="option.correct"
                                                ></Input>
                                                <div v-if="question.antwoordopties.length != 1 && !isMobile" class="delete-button" @click="verwijderAntwoordOptie(question, index)">
                                                    <img src="/assets/trash-o.svg">
                                                </div>
                                            </div>
                                        </div>
                                        <a href="javascript:void(0)" @click="extraAntwoordOptie(question)">{{ $t('addExtraAnswerOption') }}</a>
                                    </div>
                                </div>
                                
                                <a href="javascript:void(0)" @click="extraVraag(opdracht)">{{ $t('addExtraQuestion') }}</a>
                            </div>
                            
                            
                        </div>   
                        <a href="javascript:void(0)" @click="extraOpdracht()">{{ $t('addExtraAssignment') }}</a> <br/>
                    </div>  
                    <div v-if="bewerkModus && $i18n.locale === 'nl'">
                        <div class="opdracht" v-for="(opdracht, opdrachtIndex) in groupedQuestions" :key="opdrachtIndex">
                            <div class="input-opdracht-details">
                                <Input
                                    :title="`Opdracht ${opdrachtIndex+1}: Titel`"
                                    placeholder="Type hier de titel van jouw opdracht of laat deze leeg..."
                                    class="vragen input-opdracht"
                                    v-model="opdracht.questions[0].opdracht"
                                ></Input>

                                <div v-if="groupedQuestions.length != 1 && !isMobile" class="delete-button" @click="verwijderOpdracht(opdrachtIndex)">
                                    <img src="/assets/trash-o.svg">
                                </div>
                            </div>
                            
                            <div class="alle-vragen">
                                <div class="vraag" v-for="(question, questionIndex) in opdracht.questions" :key="questionIndex">
                                    <div class="vragen input-vraag-details">
                                        <!-- <p v-if="opdrachtIndex === 0" style="display: inline">{{ questionIndex + 1 }}.</p> -->
                                        <Input
                                            :title="`Vraag ${opdrachtIndex+1}.${questionIndex+1}: Titel`"
                                            placeholder="Type hier de titel van jouw vraag..."
                                            class="vragen input-vraag"
                                            v-model="question.vraag"
                                        ></Input>
                                        <div v-if="opdracht.questions.length != 1 && isMobile" class="delete-button" @click="verwijderVraag(opdracht, question)">
                                            <img src="/assets/trash-o.svg">
                                        </div>
                                        <Input
                                            :title="`Vraagtype`"
                                            placeholder="Selecteer een vraagtype..."
                                            class="vragen input-vraag-type"
                                            type="select"
                                            :options="options.vraagTypes"
                                            :SelectOptionValue="true"
                                            v-model="question.type"
                                        ></Input>
                                        <div v-if="opdracht.questions.length != 1 && !isMobile" class="delete-button" @click="verwijderVraag(opdracht, question)">
                                            <img src="/assets/trash-o.svg">
                                        </div>
                                    </div>
                                    <div class="antwoord-opties">
                                        <div v-for="(option, index) in question.antwoordopties" :key="index">
                                            <div class="input-antwoord-optie-details">
                                                <Input
                                                    :title="`Vraag ${opdrachtIndex+1}.${questionIndex+1}: ${question.type == 'meerkeuze' ? 'Antwoordoptie ' + convert(index) : 'Correcte antwoord ' + (index + 1)} `"
                                                    placeholder="Type jouw antwoordoptie..."
                                                    class="vragen input-antwoord-optie"
                                                    v-model="option.antwoordoptie"
                                                ></Input>
                                                <div v-if="question.antwoordopties.length != 1 && isMobile" class="delete-button" @click="verwijderAntwoordOptie(question, index)">
                                                    <img src="/assets/trash-o.svg">
                                                </div>
                                                <Input
                                                    v-if="question.type == 'meerkeuze'"
                                                    :title="`Correctheid`"
                                                    placeholder="Selecteer een correctheid..."
                                                    class="vragen input-antwoord-optie-correctheid"
                                                    type="select"
                                                    :options="options.vraagCorrectheid"
                                                    :SelectOptionValue="true"
                                                    v-model="option.correct"
                                                ></Input>
                                                <div v-if="question.antwoordopties.length != 1 && !isMobile" class="delete-button" @click="verwijderAntwoordOptie(question, index)">
                                                    <img src="/assets/trash-o.svg">
                                                </div>
                                            </div>
                                        </div>
                                        <a href="javascript:void(0)" @click="extraAntwoordOptie(question)">{{ $t('addExtraAnswerOption') }}</a>
                                    </div>
                                </div>
                                
                                <a href="javascript:void(0)" @click="extraVraag(opdracht)">{{ $t('addExtraQuestion') }}</a>
                            </div>
                            
                            
                        </div>   
                        <a href="javascript:void(0)" @click="extraOpdracht()">{{ $t('addExtraAssignment') }}</a> <br/>
                    </div>  
                </div>
            </div>
        </div>
        
    </div>
        
        
    
</template>
<script>

import NavItem from '@/components/Dashboard/NavItem.vue'
import ZijMenu from '@/components/Module/ZijMenu.vue'
import OverviewLink from '@/components/Module/OverviewLink.vue'
import Input from '@/components/Form/BetterInput.vue'
import convert from '@/utils/numberToLetter.js'
import jsPDF from 'jspdf'

export default{
  components: {NavItem, ZijMenu, OverviewLink, Input},
  props: ["mode"],
  data () {
      return{
          options: {
              vraagTypes: {
                  "open": "Open vraag",
                  "meerkeuze": "Meerkeuze vraag",
              },
              vraagTypesEN: {
                  "open": "Open question",
                  "meerkeuze": "Multiplechoice question",
              },
              vraagCorrectheid: {
                  1: "Correct",
                  0: "Incorrect"
              }
          },
          teVerwijderenOpdrachten: [],
          teVerwijderenVragen: [],
          teVerwijderenAntwoordOpties: [],
          bewerkModus: false,
          selectedOptions: [],
          userAnswers: [],
          answersRevealed: false,
          selectedPdf: null,
          epub_attached: false,
          epub_uploading: false,
          epub_base64: "",
          numberOfNewOpdrachten: 0,
          currentOpdrachtIndex: 0,
          currentQuestionIndex: 0,
          qeustionCounter: 1,
          meerkeuzevraag: null,
          openvraag: "",
          meerkeuzevraag2: [null],
          optieselected: false,
      }

  },
  

  mounted() {
    document.title = "Eduface";  

  
},
computed: {
    currentProgress() {
      let currentOpdrachtIndex = this.currentOpdrachtIndex || 0;
      let currentQuestionIndex = this.currentQuestionIndex || 0;
      return currentOpdrachtIndex * this.opdrachten.length + currentQuestionIndex + 1;
    },
    totalProgress() {
      return this.opdrachten.length * this.eindtoets.length;
    },
    eindtoets(){
          return this.$store.state.module_eindtoets
      },
      isMobile(){
          return this.$store.state.windowSize.width < 900
      },
      groupedQuestions() {
    const grouped = {};
    this.eindtoets.forEach((question, index) => {
      const opdracht = question.opdracht || ''; // In case opdracht is undefined
      const opdrachtID = question.opdrachtID;
      if (!grouped[opdrachtID]) {
        grouped[opdrachtID] = {
          opdracht,
          opdrachtID,
          questions: [],
        };
      }
      question.eindtoetsIndex = index;
      grouped[opdrachtID].questions.push(question);
    });
   
    return Object.values(grouped);
  },
  currentOpdracht() {
    const opdracht = this.groupedQuestions[this.currentOpdrachtIndex];
    
    return opdracht;
  },
  currentQuestion() {
    const question = this.currentOpdracht.questions[this.currentQuestionIndex];
 
    return question;
  },
  },

async created() {
    await this.$store.dispatch("get_module_eindtoets", this.$route.params.id)
  
},
watch: {
    selectedOptions: {
      deep: true,
      handler(newSelectedOptions) {
        this.optieselected = this.meerderOpties(newSelectedOptions);
      },
    },
  },
  methods:{
    navigateToFeedbackOverview() {
        this.$router.push({ 
            name: 'modules-leraar', 
            params: { id: this.$route.params.id1 } 
        });
    },
    async exporteerVragen() {
        const doc = new jsPDF();
  const maxWidth = 190;
  const imageWidth = 50;
  const startY = 10;
  const imageHeight = 16;
  const spaceAfterImage = 10;
  let textStartY = startY + imageHeight + spaceAfterImage;
  let multipleChoiceCount = 0; // Track multiple choice questions on the current page

  doc.setFontSize(15); // Set a smaller font size

  const imageUrl = '/assets/Blockbooklogowit.png';

  const loadImgData = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  const imgData = await loadImgData(imageUrl);

  const addBackgroundImages = () => {
    doc.addImage(imgData, 'PNG', 10, startY, imageWidth, imageHeight);
  };

  addBackgroundImages();

  this.eindtoets.forEach((item, index) => {
    let content = `Opdracht: ${item.opdracht}\nVraag: ${item.vraag}\n`;

    if (item.type === 'open') {
        content += `Schrijf hieronder je antwoord:\n`;
      // Adding several "\n" for visual space
      for (let i = 0; i < 7; i++) {
        content += "\n";
      } // Adjust this value to increase space after the prompt
      
      content += `Correct Antwoord: ${item.antwoordopties[0].antwoordoptie}\nUitleg: ${item.explanation}\n\n`;
    } else if (item.type === 'meerkeuze') {
      const answerLabels = ['a', 'b', 'c', 'd', 'e', 'f', 'g']; // Extend as needed
      item.antwoordopties.forEach((option, idx) => {
        content += `${answerLabels[idx]}. ${option.antwoordoptie}\n`;
      });

      const correctAnswer = item.antwoordopties.find(option => option.correct === 1).antwoordoptie;
      content += `Correct Antwoord: ${correctAnswer}\nUitleg: ${item.explanation}\n\n`;

      multipleChoiceCount++;
    }

    let textLines = doc.splitTextToSize(content, maxWidth);

    if (textStartY + 10 * textLines.length > 280 || (item.type === 'meerkeuze' && multipleChoiceCount > 2)) {
      doc.addPage();
      addBackgroundImages();
      textStartY = startY + imageHeight + spaceAfterImage;
      multipleChoiceCount = item.type === 'meerkeuze' ? 1 : 0; // Reset or set to 1 based on type
      doc.setFontSize(15); // Ensure font size is reset after adding a new page
    }

    textLines.forEach((line) => {
      doc.text(line, 10, textStartY);
      textStartY += 10; // Adjust for line height
    });

    // Adjusting space after each section
    textStartY += item.type === 'open' ? 60 : 20; // Add more space for open questions

    // Do not automatically add a new page after an open question anymore
  });

  doc.save('Eindtoets.pdf');
},
    
  async exporteerVragenEN() {
        const doc = new jsPDF();
  const maxWidth = 190;
  const imageWidth = 50;
  const startY = 10;
  const imageHeight = 16;
  const spaceAfterImage = 10;
  let textStartY = startY + imageHeight + spaceAfterImage;
  let multipleChoiceCount = 0; // Track multiple choice questions on the current page

  doc.setFontSize(15); // Set a smaller font size

  const imageUrl = '/assets/Blockbooklogowit.png';

  const loadImgData = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  const imgData = await loadImgData(imageUrl);

  const addBackgroundImages = () => {
    doc.addImage(imgData, 'PNG', 10, startY, imageWidth, imageHeight);
  };

  addBackgroundImages();

  this.eindtoets.forEach((item, index) => {
    let content = `Assignment: ${item.opdracht}\nQuestion: ${item.vraag}\n`;

    if (item.type === 'open') {
        content += `Write your answer below:\n`;
      // Adding several "\n" for visual space
      for (let i = 0; i < 7; i++) {
        content += "\n";
      } // Adjust this value to increase space after the prompt
      
      content += `Correct Answer: ${item.antwoordopties[0].antwoordoptie}\Explanation: ${item.explanation}\n\n`;
    } else if (item.type === 'meerkeuze') {
      const answerLabels = ['a', 'b', 'c', 'd', 'e', 'f', 'g']; // Extend as needed
      item.antwoordopties.forEach((option, idx) => {
        content += `${answerLabels[idx]}. ${option.antwoordoptie}\n`;
      });

      const correctAnswer = item.antwoordopties.find(option => option.correct === 1).antwoordoptie;
      content += `Correct Answer: ${correctAnswer}\nExplanation: ${item.explanation}\n\n`;

      multipleChoiceCount++;
    }

    let textLines = doc.splitTextToSize(content, maxWidth);

    if (textStartY + 10 * textLines.length > 280 || (item.type === 'meerkeuze' && multipleChoiceCount > 2)) {
      doc.addPage();
      addBackgroundImages();
      textStartY = startY + imageHeight + spaceAfterImage;
      multipleChoiceCount = item.type === 'meerkeuze' ? 1 : 0; // Reset or set to 1 based on type
      doc.setFontSize(15); // Ensure font size is reset after adding a new page
    }

    textLines.forEach((line) => {
      doc.text(line, 10, textStartY);
      textStartY += 10; // Adjust for line height
    });

    // Adjusting space after each section
    textStartY += item.type === 'open' ? 60 : 20; // Add more space for open questions

    // Do not automatically add a new page after an open question anymore
  });

  doc.save('Endtest.pdf');
},
    meerderOpties(selectedOptions) {
      for (const key in selectedOptions) {
        if (selectedOptions[key] === true) {
          return true;
        }
      }
      return false;
    },
    checkAntwoorden(){
        console.log(this.selectedOptions)
        console.log(this.optieselected)
    },
    previousOpdracht() {
    if (this.currentOpdrachtIndex > 0) {
      this.currentOpdrachtIndex--;
      this.currentQuestionIndex = 0;
      this.answersRevealed=false;
      this.meerkeuzevraag=null;
      this.openvraag="";
      this.optieselected=false;
      this.qeustionCounter--; // Reset to the first question of the new opdracht
      for (const key in this.selectedOptions) {
        this.selectedOptions[key] = false;
      }
    }
  },
  nextOpdracht() {
    if (this.currentOpdrachtIndex < this.groupedQuestions.length - 1) {
      this.currentOpdrachtIndex++;
      this.currentQuestionIndex = 0;
      this.answersRevealed=false;
      this.meerkeuzevraag=null;
      this.openvraag="";
      this.meerkeuzevraag2=null;
      this.optieselected=false;
      this.qeustionCounter++; // Reset to the first question of the new opdracht
      for (const key in this.selectedOptions) {
        this.selectedOptions[key] = false;
      }
    }
  },
   
    nextQuestion() {
      if (this.currentQuestionIndex < this.currentOpdracht.questions.length - 1) {
        this.currentQuestionIndex++;
        this.qeustionCounter++;
        this.answersRevealed=false;
        this.meerkeuzevraag=null;
        this.optieselected=false;
        this.openvraag="";
        for (const key in this.selectedOptions) {
        this.selectedOptions[key] = false;
      }

        
      }
    },
    previousQuestion() {
      if (this.currentQuestionIndex > 0) {
        this.currentQuestionIndex--;
        this.qeustionCounter--;
        this.answersRevealed=false;
        this.meerkeuzevraag=null;
        this.optieselected=false;
        this.openvraag="";
        for (const key in this.selectedOptions) {
        this.selectedOptions[key] = false;
      }

      }
    },
   
      async onEpubPicked (event) {
          const files = event.target.files
          this.epub_uploading = true
          getBase64(files[0], base64 => {
              this.epub_uploading = false
              this.epub_attached = true 
              this.epub_base64 = base64
              // console.log(this.base64)
          })
          
      },
      submit_formulier(){
          
          this.selectedPdf = null;
          this.epub_attached = false;
          this.$refs.pdfInput.value = '';
      },
      toggleAnswersRevealed() {
          this.answersRevealed = !this.answersRevealed
      },
      bewerken(){
          this.bewerkModus = true
      },
      async slaOpBewerken(){
            await this.$store.dispatch("moduleTentamenwijzigingen", {
                module_eindtoets: this.$store.state.module_eindtoets, 
                groupedQuestions: this.groupedQuestions,
                teVerwijderenOpdrachten: this.teVerwijderenOpdrachten,
                teVerwijderenVragen: this.teVerwijderenVragen,
                teVerwijderenAntwoordOpties: this.teVerwijderenAntwoordOpties,
            })
            if(this.$store.state.snackbarType == "success"){
                this.teVerwijderenOpdrachten = []
                this.teVerwijderenVragen = []
                this.teVerwijderenAntwoordOpties = []
                this.bewerkModus = false
            }
        },
      async annuleerBewerken(){
        await this.$store.dispatch("get_module_eindtoets", this.$route.params.id)
          this.teVerwijderenOpdrachten = []
          this.teVerwijderenVragen = []
          this.teVerwijderenAntwoordOpties = []
          this.bewerkModus = false
      },    
      convert(number){
          return convert(number)
      },
      extraAntwoordOptie(question){
          const eindtoetsIndex = question.eindtoetsIndex
          const isOpen = question.type == "open"
          this.eindtoets[eindtoetsIndex].antwoordopties.push({
              antwoordoptieID: -1,
              antwoordoptie: "",
              correct: isOpen ? 1 : 0,
          })
      }, 
      verwijderAntwoordOptie(question, index){
          const eindtoetsIndex = question.eindtoetsIndex
          if(this.eindtoets[eindtoetsIndex].antwoordopties.length == 1) return

          this.teVerwijderenAntwoordOpties.push(this.eindtoets[eindtoetsIndex].antwoordopties[index].antwoordoptieID)
          this.eindtoets[eindtoetsIndex].antwoordopties.splice(index, 1)
      },
      extraVraag(opdracht){
          console.log(opdracht)
          const opdrachtID = opdracht.questions[0].opdrachtID
          this.eindtoets.push({
            eindtoetsID: this.eindtoets[0].eindtoetsID,
              opdrachtID: opdrachtID,
              vraagID: -1,
              opdracht: opdracht.opdracht,
              vraag: "",
              type: "open",
              antwoordopties: [
                  {
                      antwoordoptieID: -1,
                      antwoordoptie: "",
                      correct: 1,
                  }
              ],
              eindtoetsIndex: this.eindtoets.length,
          })
      },
      verwijderVraag(opdracht, question){
          if(opdracht.questions.length == 1) return

          const eindtoetsIndex = question.eindtoetsIndex
          this.teVerwijderenVragen.push(this.eindtoets[eindtoetsIndex].vraagID)
          this.eindtoets.splice(eindtoetsIndex, 1)
      },
      extraOpdracht(){
          this.numberOfNewOpdrachten += 1
          this.eindtoets.push({
            eindtoetsID: this.eindtoets[0].eindtoetsID,
              opdrachtID: -this.numberOfNewOpdrachten,
              vraagID: -1,
              opdracht: "",
              vraag: "",
              type: "open",
              antwoordopties: [
                  {
                      antwoordoptieID: -1,
                      antwoordoptie: "",
                      correct: 1,
                  }
              ],
              eindtoetsIndex: this.eindtoets.length,
          })
      },
      verwijderOpdracht(opdrachtIndex){
          const opdrachtID = this.groupedQuestions[opdrachtIndex].opdrachtID

          this.teVerwijderenOpdrachten.push(opdrachtID)
          this.groupedQuestions.splice(opdrachtIndex, 1)
          this.$store.state.module_eindtoets = this.eindtoets.filter(vraag => vraag.opdrachtID != opdrachtID)
      },
      getNumCorrectAnswers(question){
          return question.antwoordopties.filter(optie => optie.correct == 1).length
      }
  }
  
}

</script>
<style lang="scss" >
.module-oefententamen{
  width: 100%;
  padding: 0px 0px;

    

    .container{
       
      position: relative; /* Create a positioning context for child divs */
          width: 100%; /* Set the width and height as needed */
          height: 600px;

          .module-blauw{
        position: absolute;
        z-index: 1;
        right: 10%;

        @media (max-width: 750px) {
            width: 40%; /* Adjust for smaller screens */
          }
      
    }
    .module-oranje{
        position: absolute;
        z-index: 1;
        left: 12%;
        bottom: 90px;
      

        @media (max-width: 750px) {
            display: none; /* Adjust for smaller screens */
          }
      
    }

    .logo-hoedje{
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        position: relative;
        z-index: 2;
    }
    .blockbook-vragen{
        width: 100%;
     display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;

        .blockbook-vraag{
          
    border-radius: 20px;
    background-color: #eeeff8;
    width: 50%;
    min-height: 400px;
   flex-direction: column;
    align-items: center; /* To center the content horizontally */
    justify-content: center;
    position: relative;

        .answerRevealed{
            display: flex;
            justify-content: center;
        text-align: center;
        margin-top: 30px;
        }
    .verder{
            display: flex;
            justify-content: center;
        text-align: center;
        font-weight: bold;
        color: white;
        

        .verder2{
            font-size: 20px;
            width: 100px;
            border-radius: 20px;
            background-color: #C2C2C2 !important;
        }
        .verder1{
            font-size: 20px;
            width: 100px;
            border-radius: 20px;
            background-color: $secondary-color !important;
        }
        }
    .buttonss{
        display: flex;
        justify-content: space-between;

        .next-none{
            display: none;
        }

        .next{
        background: #fff!important;
    color: #004aad!important;
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 0px;
    }
    .previous{
        background: #fff!important;
    color: #004aad!important;
    position: absolute;
    left: 0px;
    top: 0px;
    margin-top: 0px;
    }
        
    }

    .vraagvan{
        display: flex;
        justify-content: center;
        text-align: center;
      
        padding-top:   10px;
        margin-bottom: 30px; 
    }
    .vragen{
        display: flex;
        justify-content: center;
        text-align: center;
        margin: 0px 100px;
       
        
    }
    .select{
            display: flex;
            justify-content: center;
            text-align: center;
            margin-bottom: 10px;
        }
        .antwoorden-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.antwoordopties {
    display: flex;
    justify-content: center;
    
}


/* To stack radio/checkbox inputs vertically within the label */

   
    
  }

    }
         
    }
   
  .goback{
      margin-right: 0px;
  }

  .ul{
      margin-left: 20px;
  }

  .c-black{
      color: #000;
  }

  .module-aanpassen{
      display: flex;
      justify-content: space-between;
      padding: 0 10px; 

      .aanpassen{
          margin-top: 20px;
          margin-right: 20px;
          width: 200px;
      }
  }

  .overview{
      display: flex;
      border-top: 1px solid #000;
      margin-top: 20px;
  }
  
  .vraag-view{
      margin-bottom: 10px;
  }

  .pdf{
      margin-top: 90px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      
      
      .btn{
          margin-left: 20px;
      }
      .bevestigen{
          margin-left: 20px;
          color: white;
          width: 100px;
          height: 30px;
          border-radius: 20px;
          background-color: #EC7926;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:hover{
              opacity: 0.6;
          }
      }
  }
  .contentBody {
      background: #fff;
      border-radius: 10px;
  }
  .mainBody{
      width: 100%;
      padding: 20px 0px;

      .text-area{
        width: 80%;
        display: flex;
        justify-content: center;
        text-align: center;
      }
      .custom-textarea {
          border: 1px solid #ccc; /* Standard border style */
         
          width: 100%; /* Make the textarea expand to the available width */
          display: flex;
        justify-content: center;
        text-align: center;
   
          }
  
  }
  .shadow {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  .p-5 {
      padding: 40px !important;
  }

  .input-opdracht{
      width: 100%;
      font-weight: bold;
  }

  .antwoord-opties{
      padding-left: 20px;

      .input-antwoord-optie{
          width: 100%;
      }

      .input-antwoord-optie-correctheid{
          width: 150px;
          margin-left: 10px;
          
      }
  }

  .input-vraag{
      width: 100%;
      margin-right: 10px;
  }

  .input-vraag-type{
      width: 250px;
      
  }

  .vraag{
      margin-bottom: 30px;

      &:nth-last-child(2){
          margin-bottom: 0px;
      }
  }

  .opdracht{
      
      margin-top: 50px;

      &:first-child{
          margin-top: 0px;

          &.opdracht1{
              margin-top: 15px;

          }
      }
      
      &.opdracht1{
          margin-top: 30px;

      }

      
  }

  .alle-vragen{
      padding-left: 20px;
  }

  .input-antwoord-optie-details{
      display: flex;
  }

  .input-vraag-details{
      display: flex;
  }

  .input-opdracht-details{
      display: flex;
  }

  .delete-button{
      display: flex;
      width: 20px;
      height: 20px;
      padding: 5px;
      border: 1px solid #787878;
      border-radius: 4px;
      align-items: center;
      justify-content: center;
      margin-top: 34px;
      margin-left: 10px;
      cursor: pointer;

      &:hover{
          opacity: 0.5;
      }

      img{
          width: 20px;
          height: 20px;
      }
  }

  
}    

@media (max-width: 900px){
  .module-oefententamen{

    .container{

        .module-blauw{
            right: 00px;
        }
        .module-oranje{
            left: 0px;
        }
        .blockbook-vragen{
        

        .blockbook-vraag{
            width: 80%;
        }
    }
    }
    
      .better-input .input-header{
          div{
              display: flex;
              align-items: flex-end;
          }
      }
      .mainBody{
          width: 100%;

          .custom-textarea{
            width: 100%;
          }
      }
      .blockbook-vraag{
        width: 95%;
      }
      .logo-hoedje{
        width: 95%;
      }
      .input-vraag{
          margin-right: 0px;
          width: calc(100% - 42px);
      }

      .antwoord-opties{
          .input-antwoord-optie{
              width: calc(100% - 42px);
          }
      }
      
      
      .input-antwoord-optie-details{
          flex-wrap: wrap;
          align-items: flex-end;

          .input-antwoord-optie-correctheid{
              margin-left: 0px;
              width: 100%;
              margin-bottom: 20px;
          }

          .delete-button{
              margin-top: 0px;
              margin-bottom: 10px;
          }

          

      }

      .input-vraag-details{
          flex-wrap: wrap;

          .input-vraag-type{
              width: 100%;
          }
      }
  }

  
  
      
      
}
    

</style>