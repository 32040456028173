<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import Footer1 from "@/components/Frontpage/Footer2.vue";

const carousel = ref(null)
const model = ref(0)
const totalSlide = ref(6)
const router = useRouter()

function preventDefault(e) {
  e.preventDefault()
}

function checkScrollDirection(event) {
  disableScroll()
  if (directionIsUp(event)) {
    if (model.value > 0) model.value--
  } else {
    if (totalSlide.value - 1 > model.value) model.value++
  }
}

function directionIsUp(event) {
  if (event.wheelDelta) return event.wheelDelta > 0
  return event.deltaY < 0
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false
try {
  window.addEventListener(
    'test',
    null,
    Object.defineProperty({}, 'passive', {
      get: function () {
        supportsPassive = true
      }
    })
  )
} catch (e) {}

var wheelOpt = supportsPassive ? { passive: false } : false
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'

function disableScroll() {
  window.addEventListener('DOMMouseScroll', preventDefault, false) // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt) // modern desktop
}

function enableScroll() {
  window.removeEventListener('DOMMouseScroll', preventDefault, false)
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt)
}

onMounted(() => {
  carousel.value?.addEventListener('wheel', checkScrollDirection)
  document.title = "Hbo AI Onderwijsassistent | Hbo feedbacktool | Marlou Eduface";
    document.querySelector('meta[name="description"]').setAttribute("content", "Geef feedback met jouw onderwijsassistent gemaakt voor het hbo. De feedback tool is gemaakt specifiek voor het hbo en kan alle studenten opdrachten voorzien van hoge kwaliteit feedback.");
  
  window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})

const menuActive = ref(false)
const windowWidth = ref(window.innerWidth)

function handleResize() {
  windowWidth.value = window.innerWidth
}

function toggleMenu() {
  menuActive.value = !menuActive.value
  console.log(menuActive.value)
}

function goToTeamPage() {
  router.push({name: "het-team"})
}

function goToHowItWorksPage() {
  router.push('/hoe-werkt-het')
}
</script>


<template>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />


  <div class="edHome" >
    <div class="fixedMenu">
      <header class="header">
        <div v-if="!menuActive" class="hamburger-lines" @click="toggleMenu">
            <span class="line line1"></span>
            <span class="line line2"></span>
            <span class="line line3"></span>
        </div>
        <v-btn v-if="!menuActive && windowWidth < 992"
       flat
       class="rounded-pill border loginBtn3"
       @click="$router.push({ name: 'signup' })">
    Signup
</v-btn>
        <div class="eContainer banner mb-0" :class="{ 'is-active': menuActive }">
          <div class="eRow mt-2 mb-0">
            <div class="col-sm-5 col-xl-2 banner1 mb-0">
              <img
                @click="$router.push({ name: 'Home' })"
                src="/assets/logo.png"
                class="logo"
                alt="logo"
                width="190"
              />
              <img @click="toggleMenu"  v-if="menuActive && windowWidth < 576" src="/assets/cross.svg" width="30" class="cross" />
            </div>
            <!-- <input class="checkbox" type="checkbox" name="" id="" v-model="$store.state.mobileExtend"/> -->
            <div class="col-xl-5 col-sm-7  text-sm-end order-xl-2 mt-lg-2">
              <v-btn
                flat
                class="rounded-pill border text-capitalize loginBtn2 mt-2"
                @click="$router.push({ name: 'signup' })"
                >Signup</v-btn
              >
              <v-btn
                flat
                class="rounded-pill border text-capitalize loginBtn mt-2"
                @click="$router.push({ name: 'log-in' })"
                >Login</v-btn
              >
            </div>
            <div class="col-12 col-xl-5  text-lg-end order-xl-1 mb-0">
              <ul>
                <li>
                  <div @click="$router.push({ name: 'hoe-het-werkt' })" class="links">
                    Hoe werkt het
                  </div>
                </li>
                <li>
                  <div @click="$router.push({ name: 'het-team' })" class="links">Over ons</div>
                </li>
                <li>
                  <v-menu transition="scale-transition">
                    <template v-slot:activator="{ props }">
                      <div class="links dropdown" v-bind="props">Onderwijsassistent</div>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title
                          @click="$router.push({ name: 'assistentpaginaOmar' })"
                          class="assistentpage"
                          >Omar</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title
                          @click="$router.push({ name: 'assistentpaginaMarlou' })"
                          class="assistentpage"
                          >Marlou</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title
                          @click="$router.push({ name: 'assistentpaginaRuben' })"
                          class="assistentpage"
                          >Ruben</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </div>

    <div class="eContainer my-4 pb-5">
      <div class="eRow md-my-16">
        <div class="col-md-5">
          <h3 class="text-h4 mt-sm-16 mt-md-16 pt-md-16 font-weight-bold">AI Marlou</h3>
          <p class="mt-5 text-h6">
            Hallo, mijn naam is Marlou. Ik ben de assistent
waar je altijd naar hebt gezocht, maar die nu pas
beschikbaar is.
          </p>

          <div class="pt-4 mt-md-16">
            <v-btn class="btn rounded-0" @click="$router.push({name:'signup'})">Samenwerken met Marlou</v-btn>
          </div>
        </div>
        <div class="col-md-7">
          <div class="mt-5 my-md-0 pt-md-4">
            <v-responsive :aspect-ratio="16 / 9" class="video shadow"
              ><iframe
                class="w-100 h-100"
                src="https://app.supademo.com/embed/clvkyla770ut5769d1gnmtpab"
                allow="clipboard-write"
                frameborder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowfullscreen
              ></iframe>
            </v-responsive>
          </div>
        </div>
      </div>
    </div>

    <div class="eContainer my-4 stappen">
      <h3 class="text-h3 my-6 text-center">Start in 3 stappen</h3>
      <div class="eRow mt-4 mt-lg-0 gCard">
        <div class="col-md-4 my-3">
          <div class="cCard">
            <span class="num">1</span> <img src="/assets/star.svg" />
            <h4 class="text-h4">
              Maak een gratis <br />
              account aan!
            </h4>
            <div class="hoverShow">
              <v-responsive :aspect-ratio="16 / 9">
                <video class="w-100 h-100" controls>
                  <source src="/assets/video.mp4" type="video/mp4" />
                </video>
              </v-responsive>
            </div>
          </div>
        </div>
        <div class="col-md-4 my-3">
          <div class="cCard">
            <span class="num">2</span> <img src="/assets/star.svg" />
            <h4 class="text-h4">Upload jouw eerste beoordelingsformulier of rubriek</h4>
            <div class="hoverShow">
              <v-responsive :aspect-ratio="16 / 9">
                <video class="w-100 h-100" controls>
                  <source src="/assets/video.mp4" type="video/mp4" />
                </video>
              </v-responsive>
            </div>
          </div>
        </div>
        <div class="col-md-4 my-3">
          <div class="cCard">
            <span class="num">3</span> <img src="/assets/star.svg" />
            <h4 class="text-h4">
              Upload de opdrachten van jouw studenten en zet mij aan het werk!
            </h4>
            <div class="hoverShow">
              <v-responsive :aspect-ratio="16 / 9">
                <video class="w-100 h-100" controls>
                  <source src="/assets/video.mp4" type="video/mp4" />
                </video>
              </v-responsive>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-16">
      <div class="eContainer my-4">
        <h3 class="text-h3 mt-12 text-center">De reden van mijn bestaan</h3>
      </div>
      <div
        id="carousel"
        class="pt-10"
        ref="carousel"
        @mouseover="disableScroll"
        @mouseleave="enableScroll"
      >
      <v-carousel class="carousel" v-model="model" direction="vertical">
          <v-carousel-item>
            <div class="eContainer">
              <div class="eRow">
                <div class="col-md-6 imgDiv">
                  <div class="text-end pr-12">
                    <img src="/assets/Marlouprofiel.png" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="cCard p-5 text-h5">
                    Gemiddeld werken docenten meer dan <span class="textGreen">10 uur</span> per
                    week over en <span class="textGreen">96%</span> van de docenten geven aan dat ze
                    de wekelijkse taken niet afkrijgen.
                  </div>
                </div>
              </div>
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div class="eContainer">
              <div class="eRow">
                <div class="col-md-6 imgDiv">
                  <div class="text-end pr-12">
                    <img src="/assets/Marlouprofiel.png" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="cCard p-5 text-h5">
                    <span class="textGreen">87%</span> van de docenten geeft aan dat ze regelmatig
                    in het <span class="textGreen">weekend</span> en tijdens <span class="textGreen">vakanties</span>
                    bezig zijn met het geven van feedback of het maken van oefenmateriaal.
                  </div>
                </div>
              </div>
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div class="eContainer">
              <div class="eRow">
                <div class="col-md-6 imgDiv">
                  <div class="text-end pr-12">
                    <img src="/assets/Marlouprofiel.png" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="cCard p-5 text-h5">
                    Hoge werkstress en een verstoorde werk-privébalans zorgt vaak voor een vermindering van de tevredenheid van docenten en een vermindering van de onderwijskwaliteit. 
                  </div>
                </div>
              </div>
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div class="eContainer">
              <div class="eRow">
                <div class="col-md-6 imgDiv">
                  <div class="text-end pr-12">
                    <img src="/assets/Marlouprofiel.png" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="cCard p-5 text-h5">
                    Marlou is er om dit probleem op te lossen! De tool helpt jou om routinetaken over te nemen zoals het voorbereiden van lessen en het beoordelen van opdrachten. 
                  </div>
                </div>
              </div>
            </div>
          </v-carousel-item>
        </v-carousel>
      </div>
    </div>
    <Footer1></Footer1>
  </div>

</template>

<style>
.v-window__left,
.v-window__right {
  display: none !important;
}
.v-carousel__controls {
  background: #eaeaea !important;
  height: 3px !important;
  width: 300px !important;
  top: 150px !important;
  left: calc(50% - 180px) !important;
  transform: rotate(90deg);
}
.v-carousel__controls .v-btn {
  background: #eaeaea !important;
  width: 15px !important;
  height: 15px !important;
  margin-right: 5px !important;
}
.v-carousel__controls .v-btn.v-btn--active {
  background: #052333 !important;
}
@media (max-width: 767px) {
  .v-carousel__controls {
    left: calc(0% - 130px) !important;
  }
  .carousel .imgDiv {
    display: none;
  }
  .carousel .cCard {
    margin-left: 25px;
  }
}
.fixedMenu {
	padding: 36px 0 !important;
	margin-bottom: 60px !important;
	
  }
  .fixedMenu .header {
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	background: #fff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
	padding: 5px 0;
	position: fixed;
  }
</style>

<style scoped>
/* header css */
.fixedMenu {
  padding: 36px 0 !important;
  margin-bottom: 60px !important;
}
.fixedMenu .header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  padding: 5px 0;
  position: fixed;
}
.header {
  height: auto !important;
}
.header ul {
  list-style: none;
  margin-top: 10px;
  margin-bottom: 0;
}
.header ul li {
  display: inline-block;
  padding: 5px;
}
.header ul li .links,
.dropdownBtn button {
  display: block;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 18px !important;
  font-weight: bold !important;
  margin: 0;
}
.header .v-btn__overlay {
  background-color: transparent !important;
}
.header .v-btn__content {
  padding: 5px 10px;
  cursor: pointer;
  font-size: 18px !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
}
.loginBtn2,
.loginBtn {
  letter-spacing: normal !important;
}
.dropdown {
  position: relative;
  padding-right: 20px !important;
}
.dropdown:after {
  position: absolute;
  content: '❮';
  right: 0;
  font-size: 16px;
  transform: rotate(-90deg);
  color: #414042 !important;
}
@media (max-width: 576px) {
  .logo {
    width: 150px;
  }
  .logo1{
    width: 400px;
  }
  .loginBtn2,
  .loginBtn {
    padding: 0px 5px !important;
    font-size: 10px !important;
  }
}
/* header css end */
.logo{
  cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
}
.loginBtn3 {

color: #fff !important;
background-color: 00e075;
margin-right: 80px;
margin-top: 60px !important;

}
.banner{
  margin-bottom: 10px;



  .banner1{
    display: flex;
    align-items: center;

    .overons{
      align-items: center;
      justify-content: center;
      margin-left: 170px;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
    }
    .hoehetwerkt{
      align-items: center;
      justify-content: center;
      margin-left: 150px;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
            &:hover{
                opacity: 0.5;
            }
    }
  }
}
@media (max-width: 767px) {
	.overons,.hoehetwerkt  {
    margin-left: 15px !important;
        font-size: 14px !important;
        line-height: 1;
  }

  .logo{

    width: 150px;
        height: auto;
  }
}
@import '../../public/assets/grid.css';
.carousel img {
  max-width: 100%;
}
.video {
  padding: 60px 0;
}

.shadow {
  box-shadow: rgb(48, 63, 79) 50px 50px 56px -9px !important;
}

.num {
  font-size: 28px;
  display: inline-block;
  color: #bdbec0;
  font-weight: 700;
}
.gCard img {
  vertical-align: middle;
  margin-left: 5px;
}
.stappen .cCard {
  position: relative;
  height: 260px;
}
.stappen .cCard:hover {
  height: fit-content;
}
.hoverShow {
  display: none;
  margin-top: 15px;
}
.stappen .cCard:hover .hoverShow {
  display: block;
}
</style>
