<template>
    <div v-if="showBanner" class="cookie-banner">
      <div class="cookie-content">
        <div class="cookie-header">
          <h2>Cookies</h2>
          <button class="close-button" @click="handleConsent(false)">✕</button>
        </div>
        <p>Om jouw assistent perfect aan te laten sluiten bij jou, gebruiken we cookies.</p>
        <div class="cookie-actions">
          <button class="cancel-button" @click="handleConsent(false)">Niet toepassen</button>
          <button class="save-button" @click="handleConsent(true)">Toepassen</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showBanner: false,
      };
    },
    created() {
      // Check if the cookie consent was already given
      const consentGiven = localStorage.getItem('cookieConsent');
      if (!consentGiven) {
        this.showBanner = true;
      }
    },
    methods: {
      handleConsent(consent) {
        localStorage.setItem('cookieConsent', consent);
        this.showBanner = false;
      },
    },
  };
  </script>
  
  <style scoped>
  .cookie-banner {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 350px;
    width: 90%;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .cookie-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .cookie-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .cookie-header h2 {
    margin: 0;
    font-size: 1.5rem;
  }
  
  .close-button {
    background: none !important;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    color: purple !important;
  }
  
  .cookie-actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }
  
  .cancel-button, .save-button {
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #333;
    cursor: pointer;
    font-size: 1rem;
    flex: 1;
    margin: 0 5px;
    text-align: center;
  }
  
  .cancel-button {
    background-color: white !important;
    color: #333 !important;
  }
  
  .save-button {
    background-color: #00e676;
    color: white;
  }
  
  .save-button:hover {
    background-color: #00c853;
  }
  
  .cancel-button:hover {
    background-color: #f5f5f5;
  }
  
  @media (max-width: 600px) {
    .cookie-banner {
      width: 95%;
      padding: 15px;
    }
  
    .cookie-header h2 {
      font-size: 1.2rem;
    }
  
    .cookie-actions button {
      padding: 8px 15px;
      font-size: 0.9rem;
    }
  }
  </style>
  